import { Grid } from "@mui/material"
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../../components/app-loader/app-loader";
import { FlexibleItemCard } from "../../../components/flexible-item-card/flexible-item-card";
import { IMAGEBASEURL, REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { SINGLEKITCHENDETAILSGUESTVIEWPARAM, SLASHPATH } from "../../../constants/routes-constant";
import { foodPreferenceData } from "../../../mock-data/food-preferences";
import { useAppSelector } from "../../../store/store";
import { FoodSearchFilter } from "../food-search-filter/food-search-filter"

export const SearchFoodByHost = () => {
  const { foodPreference } = useAppSelector((state) => state)
  const navigate = useNavigate();
  return (
    <>
      <FoodSearchFilter />
      { 
     foodPreference?.hostSearchStatus === REQUESTSTATUS.PENDING ? <AppLoader /> :
     <>
      <div className="tertiary-heading black-color font-weight-700"> Host for you </div>
      <Grid container spacing={2} className="margin-top-2 margin-top-sm">
        {
          !!foodPreference?.hostBasedFoods?.length  ?
        
        ((foodPreference?.hostBasedFoods))?.map((singleKitchenItem: any) => {
          return (
            <Grid item xs={12} md={4} lg={3} spacing={3} key={singleKitchenItem?._id}>
            <FlexibleItemCard
                      foodImg={`${IMAGEBASEURL}${singleKitchenItem?.selectedCoverPhoto}`}
                      foodName={singleKitchenItem?.name}
                      md={12}
                      foodLocation={
                        singleKitchenItem?.location?.address?.locationName
                      }
                      hostName={singleKitchenItem?.userName}
                      foodRating={singleKitchenItem?.userRating?.toFixed(1)}
                      closeTime={singleKitchenItem?.servingTimeTo}
                      openTime={singleKitchenItem?.servingTimeFrom}
                      recipeName='About Us'
                      recipeDescription={singleKitchenItem?.recipeDescription}
                      maxWidth="300px"
                      // minWidth="244px"
                      gridClassName="height-100"
                      mainFunction={() =>
                        navigate(
                          `${SLASHPATH}${SINGLEKITCHENDETAILSGUESTVIEWPARAM}${SLASHPATH}${singleKitchenItem?._id}`
                        )
                      }
                      // iconFunction={() => {
                      //   wishlistOpenModalHandler();
                      //   setHostId(singleKitchenItem?.userId);
                      // }}
                    />
              {/* <FoodImageCard foodImg={foodData.foodImg}
                        foodName={foodData.foodName}
                        foodLocation={foodData.foodLocation}
                        hostName={foodData.hostName}
                        foodRating={foodData.foodRating}
                        timingStatus={foodData.timingStatus}
                        timing={foodData.timing}
                        recipeName={foodData.recipeName}
                        recipeDescription={foodData.recipeDescription} /> */}
            </Grid>
          );
        })
        : <div className="center-text margin-auto-x secondary-heading margin-top-3 margin-top-sm"> No <span className="primary-color"> host  </span> found </div>
      }
      </Grid>
      </>
}
    </>
  )
}