import { Grid } from "@mui/material";

import { AppAlert } from "../app-alert/app-alert";
import { AppBreadcrumbs } from "../app-breadcrumbs/app-breadcrumbs";

import { useAppSelector } from "../../store/store";

export const AlertBreadcrumbs = () => {

  const { breadcrumb } = useAppSelector(state => state)
  const { alert } = useAppSelector(state => state)

  return (
    <Grid container alignItems='center'>
      <Grid
        item
        sm={12}
        xs={12}
        lg={8}
        className="margin-bottom-1 margin-top-2"
        sx={{ display: "flex", textAlign: "center" }}
      >
        {breadcrumb.showBreadcrumb && <AppBreadcrumbs />}
      </Grid>
      <Grid item sm={12} lg={4} xs={12}>
        {alert?.showAlert && <AppAlert />}
      </Grid>
    </Grid>
  )
}