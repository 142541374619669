import { createSlice } from "@reduxjs/toolkit";
import {
    completeTrainingThunkFulfilledReducer, completeTrainingThunkPendingReducer,
    completeTrainingThunkRejectedReducer, trainingDataThunkFulfilledReducer,
    trainingDataThunkPendingReducer, trainingDataThunkRejectedReducer
}
    from "./training-data-reducer";
import { completeTrainingThunk, trainingDataThunk } from "./training-list-actions";

const initialState = {
    mandatory: [],
    optional: [],
    total: [],
    status: 'idle'
};

export const trainingSlice = createSlice({
    name: "training",
    initialState,
    reducers: {},
    extraReducers(builder) {
        trainingDataThunkPendingReducer(builder, trainingDataThunk)
        trainingDataThunkFulfilledReducer(builder, trainingDataThunk)
        trainingDataThunkRejectedReducer(builder, trainingDataThunk)

        completeTrainingThunkPendingReducer(builder, completeTrainingThunk)
        completeTrainingThunkFulfilledReducer(builder, completeTrainingThunk)
        completeTrainingThunkRejectedReducer(builder, completeTrainingThunk)
    }
});

export const trainingActions = trainingSlice.actions;

export default trainingSlice.reducer;
