import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { IGLOBALIMAGEUPLOADPROP, IIMAGEINFO } from "./image-upload-interface";
import DummyPhoto from "../../assets/images/global-image-upload/dummy-photo.svg";
import "./image-upload.scss";
import { nanoid } from "@reduxjs/toolkit";
import { checkImage } from "../../utils/validations-schema-utils";
import useDragAndDrop from "../../hooks/use-drag-and-drop";
import CancelIcon from "@mui/icons-material/Cancel";
import { MUIERRORCOLOR, MUIPRIMARYTITLE } from "../../constants/mui-styles-constant";
import { IMAGEBASEURL } from "../../constants/axios-api-constant";
import { ImagePreviewCard } from "../image-preview-card/image-preview-card";
import { useEffect } from "react";

export const ImageUpload: React.FC<any> = (props: any) => {

  // console.log(props)
  const {

    markupImage, imagesArray = [], acceptedFileType, setImagesArray, name, deleteUploadedImage, showImageView,
    multiple, imgName, textClassName, text, text2, fileUploadContainerClassName, required, text3,dummyText,
    setimagNotUpload, binary, errors, touched, setFieldValue, label, isImage, show , premisesImage, 
  } = props;

  const {
    dragOver, setDragOver, onDragOver, onDragLeave, onDragEnter,
    fileDropError, setFileDropError }: any = useDragAndDrop();

    // for uploading the same picture again
    const emptyTarget = (event:any) => {
      const { target = {} } = event || {};
      target.value = "";
    }
    

  // console.log(props)
  // console.log(!!!(imagesArray?.[0]?.imgPath))
  const [error, setError] = useState('')

  useEffect(() => {
    const alertTimer = setTimeout(() => {
      if (error?.length) {
        setError('')
      }
    }, 4000);
    return () => clearTimeout(alertTimer)
  }, [error])

  const onSelectFile = (event: any) => {
    // props.disabledHandler();
    setError('')
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    // console.log(imagesArray?.length)
    const newfile = [...event.target.files]
    if ((newfile.length + imagesArray?.length) > 4) { setError('max 4 files allowed'); return; }
    if (binary) {
      mergeFile(newfile)
    }
    // if (binary) {
    //   mergeFile(newfile)
    //   let err = ""
    //   let newImages: any = []

    //   newfile.forEach((file: any) => {
    //     err = checkImage(file)
    //     return newImages.push(file)
    //   })
    //   // console.log(err)

    //   if (err.length) return setError(err);
    //   if (!multiple) {
    //     setImagesArray?.([newImages[0]])
    //     { setFieldValue && setFieldValue(name, [newImages[0]]) }
    //     return;
    //   }
    //   if (imagesArray.length > 4) return setError('max 4 images allowed');
    //   setImagesArray?.([...imagesArray, ...newImages]);
    //   { setFieldValue && setFieldValue(name, [...imagesArray, ...newImages]) }
    //   return;
    // }
    // fileReader(selectedFilesArray);
    setimagNotUpload?.(false);

  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    setError('')

    let files = [...event.dataTransfer.files];
    const newfile = [...event.dataTransfer.files]

    if ((newfile.length + imagesArray?.length) > 4) {

      setError('max 4 files allowed');
      setDragOver(false);
      return;

    }
    if (binary) {
      mergeFile(newfile)
    }
    // console.log(event.dataTransfer.files)
    // if (binary) {
    //   let err = ""
    //   let newImages: any = []
    //   newfile.forEach((file: any) => {
    //     err = checkImage(file)

    //     return newImages.push(file)
    //   })
    //   // console.log(err)
    //   if (err.length) return setError(err);

    //   if (!multiple) {
    //     setImagesArray?.([newImages[0]])
    //     { setFieldValue && setFieldValue(name, [newImages[0]]) }
    //     return;
    //   }
    //   if (imagesArray.length > 4) return setError('max 4 images allowed');
    //   setImagesArray?.([...imagesArray, ...newImages]);
    //   { setFieldValue && setFieldValue(name, [...imagesArray, ...newImages]) }
    //   return;
    // }
    // fileReader(files);
    setDragOver(false);
    props.setimagNotUpload?.(false);
  };

  // const fileReader = (files: any) => {

  //   let uploadedImages: IIMAGEINFO[] = [];
  //   files.map((file: any) => {
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       const BASE64URL: any = reader.result;
  //       let obj: IIMAGEINFO = {
  //         id: nanoid(),
  //         imgPath: BASE64URL,
  //         name: file.name,
  //         size: file.size,
  //       };
  //       uploadedImages = [...uploadedImages, obj];
  //       if (multiple) {
  //         setImagesArray?.([...imagesArray, ...uploadedImages]);
  //       } else {
  //         setImagesArray?.([...uploadedImages]);
  //       }
  //     }
  //     return uploadedImages;
  //   });
  // }

  const mergeFile = (files: any) => {
    let err = ""
    let newImages: any = []

    files.forEach((file: any) => {
      err = checkImage(file)
      return newImages.push(file)
    })
    // console.log(err)

    if (err.length) return setError(err);
    if (!multiple) {
      setImagesArray?.([newImages[0]])
      { setFieldValue && setFieldValue(name, [newImages[0]]) }
      return;
    }
    if (imagesArray.length >= 4) return setError('max 4 images allowed');
    setImagesArray?.([...imagesArray, ...newImages]);
    { setFieldValue && setFieldValue(name, [...imagesArray, ...newImages]) }
    return;
  }

  const setImage = (image: any) => {
    if (typeof (image?.imgPath) === 'string') return image?.imgPath;
    if (image?.imgPath?.match(/icons/)) return `${IMAGEBASEURL}${imagesArray}`;
    if (imagesArray?.[0]?.imgPath) return;

  }

  const setImageSrc = (imageSrc: any) => {
    // console.log(imageSrc)
    if (!!!(imageSrc)) return '';
    if (typeof (imageSrc?.imgPath) === 'string') return imageSrc.imgPath;
    if (imageSrc?.imgPath?.match?.(/icons/)) return `${IMAGEBASEURL}${imageSrc.imgPath}`;
    if (imageSrc?.url?.match?.(/users/)) return `${IMAGEBASEURL}${imageSrc.url}`;
    // return URL?.createObjectURL?.(imageSrc)

  }

  const deleteUploadedImages = (id: number, set: any,e:any) => {
    // console.log({id})
    const deletingObj = imagesArray?.filter((item: any) => item !== id);
    // console.log(deletingObj)
    setImagesArray(deletingObj);
    set(name, deletingObj?.length ? deletingObj : "")
    // disabledHandler();
  };
  return (
    <>
      <div className={`global-image-uploader ${fileUploadContainerClassName}`}>
        <label
          className={`${errors && touched && "error-color"}`}
        >
          <div className={` "primary-title" ${errors && touched && "error-color"}`}>
            {label}
            <span className="error-color primary-title">{(required ?? true) && " *"}</span>

          </div>
        </label>
        {!!error.length && <div className="center-text error-color">{error}</div>}
        <label htmlFor="imageInput" className="cursor-pointer">
          <div
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={(e: React.DragEvent<HTMLDivElement>) => handleDrop(e)}
            className={
              `border-radius-4 upload-container-height ${dragOver && "dragged "} 
              ${errors && touched ? 'border-red' : 'border-dashed'}`
            }
          >

            {
             
              <input
              type="file"
              id={show && multiple ? "moreImage" : ( premisesImage || show) ? "" : "imageInput"}
              name={name}
              onChange={onSelectFile}
              multiple={multiple}
              accept="image/png , image/jpeg, image/webp"
              hidden
            />
            }
            {/* {
                  !multiple && !!imgName ? <img className="width-100 height-100"
                    // src={
                    // typeof (imagesArray?.[0]?.imgPath) === 'string' ? imagesArray?.[0]?.imgPath :  
                    // imagesArray?.[0]?.imgPath?.match(/icons/) ? `${IMAGEBASEURL}${imagesArray}` :
                    // URL?.createObjectURL?.(imagesArray?.[0])} 
                    src={setImageSrc(imagesArray?.[0])}
                    alt=""
                    />
                    :
                  <img src={markupImage || DummyPhoto} alt="dummy-and-original" />
                  } */}
            {!show && !premisesImage &&
              <div className="image-container center-text margin-auto-x position-relative margin-bottom-0" 
              >
                <img src={markupImage || DummyPhoto} alt="dummy-and-original" />
              </div>
            }
            {
              premisesImage &&
              <>
              <div className="image-container center-text margin-auto-x position-relative margin-bottom-0"
              onClick={()=>showImageView?.()}
              >
                <img src={DummyPhoto} alt="dummy-and-original"  onClick={()=>showImageView?.()}/>
              </div>
                <div className="center-text"> { dummyText }</div>
                </>
            }
            {
              (show) &&
              <>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                  {
                    (!!imagesArray?.length &&  !premisesImage) && imagesArray?.map((single: any) => (
                      <Grid item sm={12} xs={12} md={4} marginBottom={2}>
                        <ImagePreviewCard
                          key={single?._id || single?.lastModified}
                          singleMenuImgData={single}
                          deleteUploadedImage={(e:any) => {e.preventDefault(); deleteUploadedImages?.(single, setFieldValue,e)}}
                        />
                      </Grid>
                    ))
                  }
                  {
                    show && multiple && imagesArray.length < 4 && 
                    <Grid item sm={12} xs={12} md={4}>
                      <label htmlFor="moreImage">
                        <div className="center-text border-dashed cursor-pointer">
                          <img src={markupImage || DummyPhoto} alt="dummy-and-original" />
                          <div className="tertiary-title">Add image</div>
                        </div>
                      </label>
                    </Grid>
                  }
                </Grid>
              </>
            }
            {/* {!multiple && !!imgName &&
                  <div
                    className="delete-dummy-photo cursor-pointer"
                  >
                    <div
                      onClick={() =>
                        deleteUploadedImage?.()
                      }
                      className="cancel"
                    >
                      <CancelIcon sx={{ fontSize: MUIPRIMARYTITLE, color: MUIERRORCOLOR }} />
                    </div>
                  </div>
                } */}
            {/* {!!imgName &&
                <div className="tertiary-title ">
                  {imgName}
                </div>
              } */}
            {
            (!show) && <div className="secondary-title center-text">
              <span className={`black-color ${textClassName}`}>
                {text || "Drag and drop, or "}
                <span className="primary-color"> {text3 || "Browse"} </span>  {text2 || 'your file'}
              </span>
            </div>
            }
          </div>
        </label>
        {
          errors && touched && <p className="error-color">{errors}</p>
        }
      </div>
    </>
  );
};
