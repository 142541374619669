
import { Link } from "react-router-dom";

// MUI
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Data
import { dropdownData } from "./dropdown-menu-data";


// Interface
import { IDROPDOWNDATA } from "./dropdown-menu-interface";

// Custom Hook
import { useDropdownMenu } from "./use-dropdown-menu";

// MUI Styles
import { tooltipComponenetsProps } from "./dropdown-menu-mui-style";
import { ClickAwayListener, Tooltip } from "@mui/material";

// SCSS
import './dropdown-menu.scss'

// Component Function Starts Here
const NotificationMenu = () => {

  const { open, handleClickNotification, handleCloseNotification, handleClose } = useDropdownMenu();



  const MenuCard = (<div className="dropdown-menu">
    <div className="">
      {dropdownData.map((item: IDROPDOWNDATA) => (
        <div key={item.id}>
          <div className="flex dropdown-menu-row align-center cursor-pointer"
            onClick={() => handleCloseNotification(item.path)}
          >
            <div className="dropdown-menu-row-icon">{item.icon}</div>
            <span className="font-weight-400 tertiary-title ">{item.title}</span>
          </div>
        </div>
      ))}
    </div>
  </div>)

  return (
    <div>

      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Tooltip
            arrow
            placement="bottom-start"
            componentsProps={tooltipComponenetsProps}
            PopperProps={{
              disablePortal: true,
            }}
            // onClose={handleClose}
            open={open}
            title={MenuCard}
          >
            <Button
              onClick={handleClickNotification}
              sx={{ minWidth: 'auto', color: "black", padding: '0px 8px' }}
            >
              <ExpandMoreIcon />
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>

    </div>
  );
};

export default NotificationMenu;
