import { createAsyncThunk } from "@reduxjs/toolkit";
import {FOODPREFERENCESSEARCHBYCUISINESAPI, FOODPREFERENCESSEARCHBYHOSTAPI, FOODPREFERENCESSEARCHBYLOCATIONAPI, FOODPREFERENCESSEARCHLOCATIONAPI} from "../../constants/axios-api-constant";
import { getDataAPI, postDataAPI } from "../../lib/axios";


export const searchFoodPreferencesByLocationThunk = createAsyncThunk("food-preference/by-locations",
  async ({ locationSearchParameters, axiosInstance , openAlert}: any, { rejectWithValue }) => {
    // console.log(locationSearchParameters)
    try {
      const response = await postDataAPI(FOODPREFERENCESSEARCHBYLOCATIONAPI, axiosInstance, locationSearchParameters);
      // console.log(response)
      const formatData = response?.data?.data
      const responseData = {
        locationBasedFoods: response,
      }

      return responseData;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert?.(errorData?.error)
      return rejectWithValue(errorData);
    }
  }
);

export const searchFoodPreferencesByHostThunk = createAsyncThunk("food-preference/by-host",
  async ({ hostSearchParameters, axiosInstance , openAlert }: any, { rejectWithValue }) => {
    // console.log({ hostSearchParameters })
    try {
      const response = await postDataAPI(FOODPREFERENCESSEARCHBYHOSTAPI, axiosInstance, hostSearchParameters);
      // console.log(response)
      const formatData = response?.data?.data
      const responseData = {
        hostBasedFoods: response,
      }

      return responseData;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert?.(errorData?.error)
      return rejectWithValue(errorData);
    }
  }
);
export const searchFoodPreferencesByCuisinesThunk = createAsyncThunk("food-preference/by-cuisine",
  async ({ cuisineSearchParameters, axiosInstance , openAlert}: any, { rejectWithValue }) => {
    // console.log({ cuisineSearchParameters })
    try {
      const response = await postDataAPI(FOODPREFERENCESSEARCHBYCUISINESAPI, axiosInstance, cuisineSearchParameters);

      // console.log(response)
      const formatData = response?.data?.data
      const responseData = {
        cuisineBasedFoods: response,
      }

      return responseData;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert?.(errorData?.error)
      return rejectWithValue(errorData);
    }
  }
);

export const searchlocationforFoodPreferenceLocationSearchThunk = createAsyncThunk("food-preference/location-for-location-search",
  async ({ axiosInstance, searchParam , openAlert }: any, { rejectWithValue }: any) => {
    try {
      const response: any = await getDataAPI(`${FOODPREFERENCESSEARCHLOCATIONAPI}?search=${searchParam}`, axiosInstance)
      // console.log(response)
      const formatData = response?.data?.data
      const responseData = {
        locations: formatData
      }
      return responseData
    } catch (error:any) {
      // console.log(error)
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert?.(errorData?.error)
      rejectWithValue(errorData)
    }
  }
);

export const getSelectedFoodPreferenceLocationSearchDetailsThunk = createAsyncThunk("food-preferences/selected-food-preference-location-details",
  async ({ axiosInstance, locationId , openAlert}: any, { rejectWithValue }: any) => {
    // console.log(locationId)
    try {
      const response: any = await getDataAPI(`${FOODPREFERENCESSEARCHLOCATIONAPI}/${locationId}`, axiosInstance)
      // console.log(response)
      const formatData = response?.data?.data
      const responseData = {
        coordinates: formatData
      }
      return responseData;
    } catch (error:any) {
      // console.log(error)
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert?.(errorData?.error)
      rejectWithValue(errorData)
    }
  }
)
