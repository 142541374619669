// import { selectMenudata } from "./upsert-recipe"

let selectMenudata = [
    { id: "0", Value: '', name: "" },
    { id: "1", Value: 'Chicken', name: "Chicken", measure: 'KG' },
    { id: "2", Value: 'Extra Virgin Oil', name: "Extra Virgin Oil", measure: 'Tablespoon' },
    { id: "3", Value: 'Chilli Powder', name: "Chilli Powder", measure: 'Tablespoon' },
    { id: "4", Value: 'Onion', name: "Onion", measure: 'KG' },
    { id: "5", Value: 'Party', name: "Party", measure: '4' },
]
export const addRecipeFormData = [
    {
        title: 'Ingredient',
        tooltipTitle: 'Ingredient name in its most basic form. E.g., if your recipe calls for Melted Butter, its best to enter Just Butter in this box.',
    },
    {
        title: 'Measure',
        tooltipTitle: 'Unit of measure. Examples: tablespoons, teaspoons, ml, grams, etc',

    },
    {
        title: 'Quantity',
        tooltipTitle: 'Quantity: Enter the number of portions. Examples: 3,3 1/2, 3.5',

    },
]

export const ADDRECIPEFORMICDATA = [
    {
        id: 0,
        name: 'title',
        label: 'Title',
        columnHousing: 12,
        popupInfo: 'Give your Recipe a Unique Title. Example: Chicken Tikka'
    },
    {
        id: 1,
        name: 'description',
        label: 'Description',
        columnHousing: 12,
        popupInfo: 'Say few words that describes the Recipe'
    },
    {
        id: 2,
        name: 'categorisation',
        label: 'Categorisation of the recipe',
        columnHousing: 12,
        popupInfo: 'Define the category of the recipe. Example: Breakfast',
        selectMenudata: [
            { id: "0", Value: '', name: "" },
            { id: "1", Value: 'breakfast', name: "Breakfast" },
            { id: "2", Value: 'lunch', name: "Lunch" },
            { id: "3", Value: 'brunch', name: "Brunch" },
            { id: "4", Value: 'dinner', name: "Dinner" },
            { id: "5", Value: 'party', name: "Party" },
        ]
    },
    {
        id: 3,
        name: 'mainIngredient',
        label: 'Main Ingredient',
        columnHousing: 6,
        popupInfo: 'Only enter ONE main ingredient that is used in the recipe. Example: Chicken'
    },
    {
        id: 4,
        name: 'cusine',
        label: 'Cuisine',
        columnHousing: 6,
        popupInfo: 'Example. Chinese , Asian. Leave blank if not confirmed'
    },
    {
        id: 5,
        name: 'recipeList',
        columnHousing: 12,
        recipeList: true
    },
    {
        id: 8,
        name: 'numberOfServings',
        label: 'Number of Serving(s)',
        columnHousing: 6,
        type: 'number'
    },
    {
        id: 9,
        name: 'hrs',
        label: 'Cooking Time',
        columnHousing: 3,
        startIcon: 'Hours',
        popupInfo: 'Time to make the recipe, including any baking, resting etc. Leave blank if unknown.',
        type: 'number'

    },
    {
        id: 10,
        name: 'minutes',
        label: '\u00a0\u00a0', // just empty space to cover up spacing issue
        columnHousing: 3,
        startIcon: 'Minutes',
        type: 'number'

    },
    {
        id: 11,
        name: 'cookingInstructions',
        label: 'Cooking Instructions',
        columnHousing: 12,
        popupInfo: 'Step by step explaination of cooking instructions.',
        isMultiLine: true
    },
    {
        id: 12,
        name: 'recipeImages',
        label: 'Upload a photo of your recipe:',
        columnHousing: 12,
    },

]

export const recipeList = [
    {
        id: 1,
        title: 'Ingredient',
        tooltipTitle: 'Ingredient name in its most basic form. E.g., if your recipe calls for Melted Butter, its best to enter Just Butter in this box.',
        name: (index: number) => { return `recipeList.${index}.ingredient` },
        menuData: selectMenudata,
        select: true
    },
    {
        id: 2,
        title: 'Measure',
        tooltipTitle: 'Unit of measure. Examples: tablespoons, teaspoons, ml, grams, etc',
        name: (index: number) => { return `recipeList.${index}.measure` },
        select: false
    },
    {
        id: 3,
        title: 'Quantity',
        tooltipTitle: 'Quantity: Enter the number of portions. Examples: 3,3 1/2, 3.5',
        name: (index: number) => { return `recipeList.${index}.quantity` },
        select: false
    },
]

export const nutricsInitialValues: any = {
    calories: {
        value: 0,
        unit: "kcal"
    },
    total_fat: {
        value: 0,
        unit: "g"
    },
    saturated_fat: {
        value: 0,
        unit: "g"
    },
    cholesterol: {
        value: 0,
        unit: "mg"
    },
    sodium: {
        value: 0,
        unit: "mg"
    },
    total_carbohydrate: {
        value: 0,
        unit: "g"
    },
    dietary_fiber: {
        value: 0,
        unit: "g"
    },
    sugars: {
        value: 0,
        unit: "g"
    },
    protein: {
        value: 0,
        unit: "g"
    },
    potassium: {
        value: 0,
        unit: "mg"
    }
}
