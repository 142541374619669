import { createSlice } from "@reduxjs/toolkit";
import { allRestrictedFoodsThunkFulfilledReducer, allRestrictedFoodsThunkPendingReducer, allRestrictedFoodsThunkRejectedReducer, foodRestrictionsThunkFulfilledReducer, foodRestrictionsThunkPendingReducer, foodRestrictionsThunkRejectedReducer } from "./food-restrictions-reducers";
import { foodRestrictionsThunk, getRestrictedFoodsThunk } from "./food-restrictions-thunk-actions";


const initialState: any = { 

  foodRestrictions: [],
};


const foodItemSlice = createSlice({
  name: 'foodRestriction',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    foodRestrictionsThunkPendingReducer(builder, foodRestrictionsThunk)
    foodRestrictionsThunkFulfilledReducer(builder, foodRestrictionsThunk)
    foodRestrictionsThunkRejectedReducer(builder, foodRestrictionsThunk)

    allRestrictedFoodsThunkPendingReducer(builder,getRestrictedFoodsThunk)
    allRestrictedFoodsThunkFulfilledReducer(builder,getRestrictedFoodsThunk)
    allRestrictedFoodsThunkRejectedReducer(builder,getRestrictedFoodsThunk)
  } 
})
// export const foodActions = foodRestrictionSlice.actions
export default foodItemSlice.reducer;