import { Navigate } from "react-router-dom";
import { getDataFromStorage } from "../utils/views-utils";

export const PrivateRoutes: React.FC<any> = (props: any) => {
  const { allowedRoles } = props;
  const auth = getDataFromStorage('auth')
  // const  { auth } = useAppSelector(state => state)

  return (
  !auth?.token ? (
      <Navigate to="/authentication/signin" />
    ) :  auth?.role?.find((role:any) => allowedRoles?.includes(role))  ? <> {props.children} </> : (
      <> <Navigate to="/unauthorized"/> </> 
    )
  )
  //uncomment it if backedn does not work
  // return <>{props.children}</>
};
