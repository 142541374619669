import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getAuditLogThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, {payload}:any) => {
        // console.log(payload);
        state.status = REQUESTSTATUS.PENDING
    });
}

export const getAuditLogsThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload.meta, 'payload');
            state.auditLogsData = payload.auditlogs;
            state.meta = payload.meta;
            state.status = REQUESTSTATUS.SUCCEEDED
        });
}

export const getAuditLogsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log('Failed', payload);
        });
}

export const getUserAuditshunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, {payload}:any) => {
        // console.log(payload);
        state.status = REQUESTSTATUS.PENDING
    });
}

export const getUserAuditsThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.allUserAudits = payload;
            state.status = REQUESTSTATUS.SUCCEEDED
        });
}

export const getUserAuditsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log('Failed', payload);
        });
}


