// MUI
import Button from "@mui/material/Button";

// Custom Hook
import { useStatusChangeMenu } from "./use-status-change-menu";

// MUI Styles
import { STATUSCHANGETOOLTIP } from "./status-change-menu-mui-style";
import { CircularProgress, ClickAwayListener, Tooltip } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// SCSS
import './status-change-menu.scss'
import { ISTATUSCHANGEMENU } from "./status-change-menu-interface";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { completeBookingRequestThunk } from "../../../../store/booking/booking-thunk-action";
import { useAppDispatch } from "../../../../store/store";
import { axiosInstance } from "../../../../lib/axios";
import { useAppAlert } from "../../../../components/app-alert/use-app-alert";

// Component Function Starts Here
export const StatusChangeMenu: React.FC<ISTATUSCHANGEMENU> = (props: ISTATUSCHANGEMENU) => {


  const { open, handleClickDropDown, handleClose, acceptBooking, ISGUEST, buttonId, buttonStatus, checkGuestAndStatus,
    status, rejectionBookingStatus, bookingId, IS_SUPERADMIN, adminCancelAllowedStatus
  } = useStatusChangeMenu(props);
  // console.log(buttonStatus);
  const dispatch = useAppDispatch()
const {openAlert} = useAppAlert()


  const MenuCard = (
    <div className="status-change-menu bg-white-color flex">
      <div className="change-btn-modal flex flex-column">
        {!ISGUEST && !IS_SUPERADMIN &&
          <button
            onClick={() => acceptBooking(bookingId)}
            className="accept-btn text-capitlized status-change-menu-btn cursor-pointer secondary-title font-weight-500 white-color font-family-roboto ">
            Accept
          </button>
        }
        <button
          onClick={rejectionBookingStatus}
          className="reject-btn text-capitlized status-change-menu-btn cursor-pointer secondary-title font-weight-500 white-color font-family-roboto ">
          {ISGUEST || IS_SUPERADMIN ? "Cancel" : "Reject"}
        </button>
      </div>
    </div>
  )

  return (
    <div>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Tooltip
            arrow
            placement="bottom-end"
            componentsProps={STATUSCHANGETOOLTIP}
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            title={MenuCard}
          >

            <Button value={status} onClick={(event: React.MouseEvent<HTMLButtonElement>) => { (IS_SUPERADMIN && adminCancelAllowedStatus.includes(status)) ? handleClickDropDown(event) : status === 'NEW' && handleClickDropDown(event) }}
              className={`status-${checkGuestAndStatus().toLowerCase()} white-color text-capitlized status-button secondary-title`}
              disabled={buttonStatus === REQUESTSTATUS.PENDING && buttonId === bookingId}>
              {(buttonStatus === REQUESTSTATUS.PENDING && buttonId === bookingId) ?
                <div className="status-spinner flex align-center">
                  <CircularProgress size="16px" sx={{ color: 'white', mr: '5px', mb: '3px' }} /> <div>Wait...</div>
                </div> : checkGuestAndStatus()}

              {checkGuestAndStatus() === 'Confirmed' && <CheckCircleOutlineIcon fontSize="small" sx={{ '&:hover' : { color:'tomato' } }}
                onClick={() => dispatch(completeBookingRequestThunk({ axiosInstance, bookingId, dispatch, openAlert }))
                } />} {/* later would be done after payment  */}
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};
