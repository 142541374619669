import { useState  , useEffect } from 'react';
// MUI
import { Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
// Component
import { dietaryInfo } from '../../../../../../mock-data/food-preferences';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { setSelectedDietary } from '../../../../../../store/food-preference/food-preference-slice';
import { useParams } from 'react-router-dom';
import { useAxiosInterceptors } from '../../../../../../hooks/use-axios-interceptors';
import { useAppAlert } from '../../../../../../components/app-alert/use-app-alert';
import { prepareFoodSearchData } from '../../../../food-search-filter/food-search-filter-data';

const MenuProps = {
  PaperProps: {
    sx: {
      width: 'auto',
      borderRadius: '10px',
      // p: '1.5rem',
      mx: {xs:'0rem', md:'3rem'},
      maxHeight:'240px',
      my: '1rem',
      color: '#E0E0E0',
      background: '#333333',
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "inherit !important"
      },
    },
  },
};

const Dietary = () => {

  const [dietaryIn, setDietary] = useState<string[]>([]);
  const { referenceData } = useAppSelector(state =>  state)
  const { searchType } = useParams();
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useAppDispatch();
  const { openAlert} = useAppAlert();
 const {  foodPreference } = useAppSelector( state  => state )
  // console.log(referenceData?.dietary_attribures)

  // const handleDietary:any = (event: SelectChangeEvent<typeof dietaryIn>) => {
  //   const { target: { value }} = event;
  //   console.log(event)
  //   setDietary(typeof value === 'string' ? value.split(',') : value);
  // };
  const setDietaryId = (diet:any) => {
    // console.log(diet)
    setDietary([...dietaryIn,diet?.id])
  }
  useEffect(()=> {
    dispatch(setSelectedDietary(dietaryIn))
  },[dietaryIn?.length])
  
  // useEffect(()=>{
  //   prepareFoodSearchData( searchType , foodPreference , openAlert, dispatch , axiosInstance)
  // },[dietaryIn?.length])
  return (
    <>
      {/* <FormControl  sx={{ml:1}}> */}
            <Select
            fullWidth
              sx={
                { borderRadius: '45px', 
                // width: '118.73px', 
                height:'36.24px' }}
              displayEmpty
              multiple 
              value={dietaryIn}
              // onChange={handleDietary} 
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <span className='tabs-span'>Dietary</span>;
                }

                return <span className='tabs-span'>Dietary</span>;
              }}
              autoWidth
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {!!referenceData?.dietary_attribures && referenceData?.dietary_attribures?.map((dietary:any) => (
                <MenuItem 
                sx={{ pb: .5 }} 
                key={dietary.id} value={dietary.name} onClick={() => setDietaryId(dietary)}>
                  <Checkbox checked={dietaryIn.indexOf(dietary.id) > -1}
                    sx={{
                      p: .5,
                      m: 0,
                      color:'#E0E0E0 !important',
                      "&.Mui-checked": { color: '#FF611D !important' },
                    }}
                  />
                  <ListItemText sx={{ p: 0, ml: 1 }} primary={dietary.name} />
                </MenuItem>
              ))}
              {/* {dietaryInfo.map((dietary) => (
                <MenuItem sx={{ p: 0 }} key={dietary.value} value={dietary.dietaryName}>
                  <Checkbox checked={dietaryIn.indexOf(dietary.dietaryName) > -1}
                    sx={{
                      pr: 1.5,
                      m: 0,
                      color:'#E0E0E0 !important',
                      "&.Mui-checked": { color: '#FF611D !important' },
                    }}
                  />
                  <ListItemText sx={{ p: 0, m: 2 }} primary={dietary.dietaryName} />
                </MenuItem>
              ))} */} 
            </Select>
          {/* </FormControl> */}
    </>
  )
}

export default Dietary