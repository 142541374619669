import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const ThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });
  };


  
  export const addDbsCheckThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.SUCCEEDED;
      state.dbsCheck._id = payload.dbsCheckData._id;
      state.dbsCheck.status= payload.dbsCheckData?.dbsCheck?.status?.toString();
      state.dbsCheck.file = payload.dbsCheckData?.dbsCheck?.file;
      state.postDbsData.dbsStatus = payload?.dbsCheckData?.dbsCheck?.status?.toString();
      state.postDbsData.dbsFile = payload?.dbsCheckData?.dbsCheck?.File
    });
  };
  
  export const addDbsCheckThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.FAILED;
      state.postDbsData = {}
    });
  };

  export const sentContractThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
  };
  
  export const sentContractThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.FAILED;
    });
  };



  export const addlicenseThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
  };
  
  export const addlicenseThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.FAILED;
    });
  };
 
  export const myUserProfileDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.SUCCEEDED;
      // state.dbsCheck = payload.dbsCheckData;
      state.veriffStatus =  payload.veriffStatus;
      state.contractBookData = payload.contractBookData;
      // state.license = payload.license
    });
  };
  
  export const myUserProfileDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.FAILED;
    });
  };

  export const getDbsCheckDataThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
      state.getDbsStatus = REQUESTSTATUS.PENDING;
    });
  };
  export const getDbsCheckDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.getDbsStatus = REQUESTSTATUS.SUCCEEDED;
      state.dbsCheck._id = payload.dbsCheckData._id;
      state.dbsCheck.status= payload.dbsCheckData?.dbsCheck?.status?.toString();
      state.dbsCheck.file = payload.dbsCheckData?.dbsCheck?.file;
      state.postDbsData.dbsStatus = payload?.dbsCheckData?.dbsCheck?.status.toString();
      state.postDbsData.dbsFile = payload?.dbsCheckData?.dbsCheck?.file
      state.veriffStatus = payload.veriffStatus
    });
  };
  
  export const getDbsCheckDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.getDbsStatus = REQUESTSTATUS.FAILED;
    });
  };
  export const getDbsCheckDataFileThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.statusDbsFile = REQUESTSTATUS.SUCCEEDED;
      state.dbsCheck._id = payload.dbsCheckData._id;
      state.dbsCheck.status= payload.dbsCheckData?.dbsCheck?.status?.toString();
      state.dbsCheck.file = payload.dbsCheckData?.dbsCheck?.file;
      state.postDbsData.dbsStatus = payload?.dbsCheckData?.dbsCheck?.status?.toString();
      state.postDbsData.dbsFile = payload?.dbsCheckData?.dbsCheck?.file
      state.veriffStatus = payload.veriffStatus
    });
  };
  
  export const getDbsCheckDataFileThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.statusDbsFile = REQUESTSTATUS.FAILED;
    });
  };

  export const getDbsCheckDataFileThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
      state.statusDbsFile = REQUESTSTATUS.PENDING;
    });
  };
  

  export const getLicenseDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.getLicenseStatus = REQUESTSTATUS.SUCCEEDED;
      state.licenseData = payload.licenseData;
      state.contractBookData = payload.contractBookData;
    
    });
  };
  
  export const getLicenseDataThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
      state.getLicenseStatus = REQUESTSTATUS.PENDING;
    });
  };

  export const getLicenseDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.getLicenseStatus = REQUESTSTATUS.FAILED;
    });
  };
  export const getLicenseDataPremisesFileThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.statusPermisesFile = REQUESTSTATUS.SUCCEEDED;
      state.licenseData = payload.licenseData;
      state.contractBookData = payload.contractBookData;
    
    });
  };
  
  export const ggetLicenseDataPremisesFileThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
      state.statusPermisesFile = REQUESTSTATUS.PENDING;
    });
  };

  export const getLicenseDataPremisesFileThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.statusPermisesFile = REQUESTSTATUS.FAILED;
    });
  };

  export const getContractPdfThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
      state.getContractStatus = REQUESTSTATUS.PENDING;
    });
  };

  export const getContractPdfThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.getContractStatus = REQUESTSTATUS.SUCCEEDED;
      state.contractPdfLink = payload.contractPdfLink;
      // state.contractBookData = payload.contractBookData;
    
    });
  };
  
  export const getContractPdfThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.getContractStatus = REQUESTSTATUS.FAILED;
    });
  };


  export const addDbsCheckReducer = (state:any , {payload}:any) => {
    // console.log(payload)
    state.postDbsData = payload
  }

  export const submitVeriffReducer = (state:any, {payload}:any) => {
    // console.log(payload)
    state.veriffStatus = payload
  }