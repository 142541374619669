
export const xsNoneSmFlex = {
    display: {
        xs: "none",
        sm: "flex",
    },
}
export const xsFlexSmNone = {
    display: {
        xs: "flex",
        sm: "none",
    },
}

export const xsNoneMdFlex = {
    display: {
        xs: "none",
        md: "flex",
    },
}