// MUI
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// Images
import HomeIcon from "../../assets/images/layout/sidebar/home.png";
import MenuIcon from "../../assets/images/layout/sidebar/menu.png";
import ListingIcon from "../../assets/images/layout/sidebar/listing.png";
import BookingIcon from "../../assets/images/layout/sidebar/booking.png";
import ReviewsIcon from "../../assets/images/layout/sidebar/reviews.png";

//------- Commented code is not a part of beta version, we'll include in next version------ 

import LogisticsIcon from "../../assets/images/layout/sidebar/logistics.png";
import RecipeIcon from "../../assets/images/layout/sidebar/recipe.png";
import CalendarIcon from "../../assets/images/layout/sidebar/calendar.png";
import CalendarIconProfile from "../../assets/images/layout/sidebar/calendar-profile.png";
import HelpIcon from "../../assets/images/layout/sidebar/help.png";
import AccountSettingIcon from "../../assets/images/layout/sidebar/account-setting.png";

import ProfileIcon from "../../assets/images/layout/sidebar/profile.png";


//
import ManageIcon from "../../assets/images/layout/sidebar/manage.png";
import AuditLogIcon from "../../assets/images/layout/sidebar/audit-logs.png";
import WishListIcon from "../../assets/images/layout/sidebar/my-wishlist.png";
import FoodIcon from "../../assets/images/layout/sidebar/food-preference.png";
// Interfaces
import {
  ISIDEBARDATA,
  ISIDEBARICONS,
} from "./sidebar-interface";
import { 
  ACCOUNTSETTINGSPATH, APIINVENTORYPATH, AUDITASSIGNMENTANNOUNCEDVISITPATH, AUDITASSIGNMENTPATH, AUDITASSIGNMENTUNANNOUNCEDVISITPATH, AUDITLOGSPATH, AUDITSPATH, BACKUPPATH, CREATEMENUPATH, FOODPREFERENCEPATH, HELPPATH, HOMEPATH, LOGISTICSPATH, MANAGEPATH, MENUPATH, 
  MENUPREVIEWPATH, MYBOOKINGSPATH, MYCALENDARPATH, MYINVOICEPATH, MYLISTINGSPATH, MYWHISHLISTPATH, OVERALLRATINGSPATH, 
  RATEGUESTPATH, RATEHOSTPATH, RATINGRECORDSPATH, RECIPEMANAGEMENTPATH, REVIEWSANDFEEDBACKPATH, SLASHPATH } from "../../constants/routes-constant";

import { USERROLES } from "../../constants/roles-constant";


export const sidebarData: ISIDEBARDATA[] = [
  {
    id: "1",
    title: "Home",
    path: HOMEPATH,
    allowedRoles:[USERROLES.HOST,USERROLES.GUEST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    icon: <img src={HomeIcon} alt="home" />,
  },
  {
    id: "2",
    title: "Menu",
    path: MENUPATH,
    allowedRoles:[USERROLES.HOST],
    icon: <img src={MenuIcon} alt="menu" />,
    iconClosed: <AddIcon />,
    iconOpened: <RemoveIcon />,

    subNav: [
      {
        id: "1",
        title: "Menu Preview",
        allowedRoles:[USERROLES.HOST],
        path: MENUPATH + SLASHPATH + MENUPREVIEWPATH,
      },
      {
        id: "2",
        title: "Create Menu",
        allowedRoles:[USERROLES.HOST],
        path: MENUPATH + SLASHPATH + CREATEMENUPATH,
      },
    ],
  },
  {
    id: "133.6",
    title: "My Audits",
    path: AUDITSPATH,
    allowedRoles:[USERROLES.COMPLIANCE],
    icon: <img src={AuditLogIcon} alt="home" />,
  },
  {
    id: "3",
    title: "My Listings",
    path: MYLISTINGSPATH,
    allowedRoles:[USERROLES.HOST],
    icon: <img src={ListingIcon} alt="my-listing" />,
  },
  {
    id: "1.1",
    title: "Food Preferences",
    path: FOODPREFERENCEPATH,
    allowedRoles:[USERROLES.GUEST],
    icon: <img src={FoodIcon} alt="home" />,
  },
  {
    id: "1.2",
    title: "Wishlists",
    path: MYWHISHLISTPATH,
    allowedRoles:[USERROLES.GUEST],
    icon: <img src={WishListIcon} alt="home" />,
  },
  {
    id: "4",
    title: "My Bookings",
    path: MYBOOKINGSPATH,
    allowedRoles:[USERROLES.HOST,USERROLES.GUEST],
    icon: <img src={BookingIcon} alt="my-booking" />,
  },
  // {
  //   id: "5",
  //   title: "Invoice",
  //   path: MYINVOICEPATH,
  //   allowedRoles:[USERROLES.HOST],
  //   icon: <img src={InvoiceIcon} alt="invoice" />,
  // },
  {
    id: "6",
    title: "Logistics",
    path: LOGISTICSPATH,
    allowedRoles:[USERROLES.HOST],
    icon: <img src={LogisticsIcon} alt="logistics" />,
  },
  {
    id: "7",
    title: "Reviews & Feedbacks",
    path: REVIEWSANDFEEDBACKPATH,
    allowedRoles:[USERROLES.HOST, USERROLES.GUEST],
    icon: <img src={ReviewsIcon} alt="reviews" />,
    iconClosed: <AddIcon />,
    iconOpened: <RemoveIcon />,
    subNav: [
      {
        id: "1",
        title: "Overall rating",
        allowedRoles:[USERROLES.HOST, USERROLES.GUEST],
        path: REVIEWSANDFEEDBACKPATH + SLASHPATH + OVERALLRATINGSPATH,
      },
      {
        id: "2",
        title: "Rate guest",
        allowedRoles:[USERROLES.HOST],
        path: REVIEWSANDFEEDBACKPATH + SLASHPATH + RATINGRECORDSPATH,
      },

      {
        id: "3",
        title: "Rate host",
        allowedRoles:[USERROLES.GUEST],
        path: REVIEWSANDFEEDBACKPATH + SLASHPATH + RATINGRECORDSPATH,
      },
    ],
  },
  // {
  //   id: "8",
  //   title: "Analytics & Reports",
  //   path: ANALYTICSANDREPORTSPATH,
  //   icon: <img src={ReportsIcon} alt="reports" />,
  // },
  // {
  //   id: "9",
  //   title: "Trainings",
  //   path: TRAININGSPATH,
  //   icon: <img src={TraningIcon} alt="training" />,
  // },
  {
    id: "10",
    title: "Recipe management",
    path: RECIPEMANAGEMENTPATH,
    allowedRoles:[USERROLES.HOST],
    icon: <img src={RecipeIcon} alt="recipe-management" />,
  },
  {
    id: "11",
    title: "My calendar",
    path: MYCALENDARPATH,
    allowedRoles:[USERROLES.HOST,USERROLES.GUEST, USERROLES.COMPLIANCE],
    icon: <img src={CalendarIcon} alt="calendar" />,
  },
  {
    id: "12",
    title: "Help",
    path: HELPPATH,
    allowedRoles:[USERROLES.HOST,USERROLES.GUEST],
    icon: <img src={HelpIcon} alt="help" />,
  },
  // {
  //   id: "13",
  //   title: "IT Help Desk",
  //   path: ITHELPDESKPATH,
  //   icon: <img src={ITHelpIcon} alt="it-help" />,
  // },

  {
    id: "2.1",
    title: "Manage",
    path: MANAGEPATH,
    allowedRoles:[USERROLES.SUPERADMIN],
    icon: <img src={ManageIcon} alt="home" />,
  },
  {
    id: "2.2",
    title: "Audit Logs",
    path: AUDITLOGSPATH,
    allowedRoles:[USERROLES.SUPERADMIN],
    icon: <img src={AuditLogIcon} alt="home" />, 
  },
  {
    id: "2.3",
    title: "Backup",
    path: BACKUPPATH,
    allowedRoles:[USERROLES.SUPERADMIN],
    icon: <img src={HomeIcon} alt="home" />, 
  },
  // {
  //   id: "2.4",
  //   title: "Api Inventory",
  //   path: APIINVENTORYPATH,
  //   allowedRoles:[USERROLES.SUPERADMIN],
  //   icon: <img src={InventoryIcon} alt="home" />, 
  // },
  {
    id: "2.5",
    title: "Audit assignment",
    path: AUDITASSIGNMENTPATH,
    allowedRoles:[USERROLES.SUPERADMIN],
    icon: <img src={MenuIcon} alt="menu" />,
    iconClosed: <AddIcon />,
    iconOpened: <RemoveIcon />,

    subNav: [
      {
        id: "1",
        title: "Initial Visit",
        allowedRoles:[USERROLES.SUPERADMIN],
        path: AUDITASSIGNMENTPATH,
      },
      {
        id: "2",
        title: "Announced visit",
        allowedRoles:[USERROLES.SUPERADMIN],
        path: AUDITASSIGNMENTPATH + SLASHPATH + AUDITASSIGNMENTANNOUNCEDVISITPATH,
      },
      {
        id: "3",
        title: "Unannounced visit",
        allowedRoles:[USERROLES.SUPERADMIN],
        path: AUDITASSIGNMENTPATH + SLASHPATH + AUDITASSIGNMENTUNANNOUNCEDVISITPATH,
      },
    ],
  },
  {
    id: "14",
    title: "Account settings",
    path: ACCOUNTSETTINGSPATH,
    allowedRoles:[USERROLES.HOST,USERROLES.GUEST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    icon: <img src={AccountSettingIcon} alt="account-settings" />,
  },
];


export const sidebarIconData: ISIDEBARICONS[] = [
  {
    id: "1",
    icon: <img src={ProfileIcon} alt="profile-icon" />,
    path: ACCOUNTSETTINGSPATH,
  },
  {
    id: "2",
    icon: <img src={CalendarIconProfile} alt="calendar-icon" />,
    path: MYCALENDARPATH,
  },
];
