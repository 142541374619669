import { createSlice } from "@reduxjs/toolkit";
// import {  getkitchenDetailsFulfilledReducer, getLogisticBookingsFulfilledThunk, getLogisticRecipesFulfilledThunk, getlogisticsMenuFulfilledThunk, patchLogisticsTimerFulfilledReducer, patchLogisticsTimerPendingReducer, patchLogisticsTimerRejectReducer } from "./logistics-reducers";
// import { getBookingsThunk, getFacilityHouseRuleThunk, getlogisticsDataThunk, getRecipesThunk, patchLogisticsMenuThunk, patchLogisticsTimerthunk } from "./logistics-thunk-actions";
import {getIngredientsFulfilledThunk, getkitchenDetailsFulfilledReducer, getLogisticBookingsFulfilledThunk, getLogisticRecipesFulfilledThunk, 
  getlogisticsMenuFulfilledThunk, getlogisticsMenuPendingReducer, getlogisticsMenuRejectReducer, patchLogisticsTimerFulfilledReducer, 
  patchLogisticsTimerPendingReducer, patchLogisticsTimerRejectReducer } from "./logistics-reducers";
import {  getIngredientsThunk, getBookingsThunk, getFacilityHouseRuleThunk, getlogisticsDataThunk, getRecipesThunk, patchLogisticsMenuThunk, patchLogisticsTimerthunk } from "./logistics-thunk-actions";

const logisticsSlice = createSlice({
  name: "logistics",
  initialState: {
    menuBooking:[] as any,
    coverImg: '',
    shoppingReview: {}as any,
    getLogisticStatus: '',
    bookingID: '',
    ingredients: [] as any,
    bookings: [],
    facilities: [],
    houseRules: [] as any,
    logisticRecipes: []as any,
    status: '',
    
    trackerAccessMsg: '',
    timerStatus: '',
    timerMessage: '',
  },
  reducers: {
    
  },



  extraReducers(builder) {
    getlogisticsMenuPendingReducer(builder, getlogisticsDataThunk)
    getlogisticsMenuRejectReducer(builder, getlogisticsDataThunk)
    getlogisticsMenuFulfilledThunk(builder, getlogisticsDataThunk)

    getkitchenDetailsFulfilledReducer(builder, getFacilityHouseRuleThunk)
    getLogisticBookingsFulfilledThunk(builder, getBookingsThunk)
    getLogisticRecipesFulfilledThunk(builder, getRecipesThunk)
    getIngredientsFulfilledThunk(builder, getIngredientsThunk)

    patchLogisticsTimerPendingReducer(builder, patchLogisticsTimerthunk)
    patchLogisticsTimerFulfilledReducer(builder, patchLogisticsTimerthunk)
    patchLogisticsTimerRejectReducer(builder, patchLogisticsTimerthunk)

  }
});

// export const { setShowTable} = manageSlice.actions;
export default logisticsSlice.reducer;