
// Icons
import changePasswordIcon from '../../../assets/images/layout/header/dropdown-menu/change-password.png'
import logoutIcon from '../../../assets/images/layout/header/dropdown-menu/logout.png'


// Interfaces
import { IDROPDOWNDATA } from './dropdown-menu-interface';
import { CHANGEPASSWORDPATH, LOGOUTPATH } from "../../../constants/routes-constant";



export const dropdownData: IDROPDOWNDATA[] = [

  {
    id: "2",
    icon: <img src={changePasswordIcon} alt='change-password' />,
    title: "Change Password",
    path: CHANGEPASSWORDPATH,
  },
  {
    id: "4",
    icon: <img src={logoutIcon} alt='logout' />,
    title: "Logout",
    path: LOGOUTPATH,
  },
];

