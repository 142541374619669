import { createSlice, nanoid } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

// Get data from assets/data
import {
  orderCardsData, profileCompletionStatusData, TopMenuItemsData, FeatureData,
  operationData, bookingColumnsData,
} from "../../mock-data/dashboard-mock-data";
import {
  getComplianceDetailsThunkFulfilledReducer, getComplianceDetailsThunkPendingReducer, getDashboardDetailsThunkFulfilledReducer,
  boostMyListingRejectedReducer, boostMyListingFulfilledReducer, boostMyListingPendingReducer,
  listingGuestThunkFulfilledReducer, listingGuestThunkPendingReducer, listingGuestThunkRejectedReducer,
  topMenuRejectedReducer, topMenuFulfilledReducer, topMenuPendingReducer, getTopRatedKitchensThunkRejectedReducer, getTopRatedKitchensThunkFulfilledReducer, getTopRatedKitchensThunkPendingReducer, getSingleKitchensDetailsThunkRejectedReducer, getSingleKitchensDetailsThunkFulfilledReducer, getSingleKitchensDetailsThunkPendingReducer, getRatingsByIdThunkRejectedReducer, getRatingsByIdThunkFulfilledReducer, getRatingsByIdThunkPendingReducer, getBestListingsByUserIdThunkRejectedReducer, getBestListingsByUserIdThunkFulfilledReducer, getBestListingsByUserIdThunkPendingReducer
} from "./dashboard-reducers";
import {
  boostListingGuestThunk, boostMyListingThunk, getBestListingsByUserIdThunk, getComplianceDashboardData, getHostDashboardData,
  getRatingsByIdThunk,
  getSingleKitchensDetailsThunk,
  getTopMenuDashboardData,
  getTopRatedKitchensThunk
} from "./dashboard-thunk-actions";

const initialState = {
  dashboardDetails: [],
  acceptedOrders: { count: 0, percentage: 0 },
  cancelledOrders: { count: 0, percentage: 0 },
  newBookingDetails: [],
  upcomingBookingDetails: [],
  overAllRating: {},
  bookingColumns: bookingColumnsData,
  orderCards: orderCardsData,
  profileCompletionStatus: profileCompletionStatusData,
  operationData: operationData,
  // boostListing: boostListingData,
  topMenuItems: [],
  FeatureData: FeatureData,
  boostMyListing: '',
  message: '',
  boostListing: 0,
  boostListingGuest: [],
  boostListingGuestStatus: REQUESTSTATUS.IDLE,
  //compliance dashboard
  status: REQUESTSTATUS.PENDING,
  widgetCards: [],
  recentPerformedAudits: [],
  totalAudits: '',
  inProgressAudits: '',
  completedAudits: '',
  leftTarget: '',
  target: '',


  ratedKitchenStatus: REQUESTSTATUS.IDLE,
  ratedKitchens: [] as any,
  singleKitchen:{} as any,
  singleKitchenReviews:[] as any,
  singleKitchenBestListings: [] as any
  
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers(builder) {
    getDashboardDetailsThunkFulfilledReducer(builder, getHostDashboardData)
    getComplianceDetailsThunkFulfilledReducer(builder, getComplianceDashboardData)
    getComplianceDetailsThunkPendingReducer(builder, getComplianceDashboardData)

    boostMyListingRejectedReducer(builder, boostMyListingThunk)
    boostMyListingFulfilledReducer(builder, boostMyListingThunk)
    boostMyListingPendingReducer(builder, boostMyListingThunk)

    listingGuestThunkFulfilledReducer(builder, boostListingGuestThunk)
    listingGuestThunkPendingReducer(builder, boostListingGuestThunk)
    listingGuestThunkRejectedReducer(builder, boostListingGuestThunk)

    topMenuRejectedReducer(builder, getTopMenuDashboardData)
    topMenuFulfilledReducer(builder, getTopMenuDashboardData)
    topMenuPendingReducer(builder, getTopMenuDashboardData)

    getTopRatedKitchensThunkRejectedReducer(builder, getTopRatedKitchensThunk)
    getTopRatedKitchensThunkFulfilledReducer(builder, getTopRatedKitchensThunk)
    getTopRatedKitchensThunkPendingReducer(builder, getTopRatedKitchensThunk)


    getSingleKitchensDetailsThunkRejectedReducer(builder, getSingleKitchensDetailsThunk)
    getSingleKitchensDetailsThunkFulfilledReducer(builder, getSingleKitchensDetailsThunk)
    getSingleKitchensDetailsThunkPendingReducer(builder, getSingleKitchensDetailsThunk)


    getRatingsByIdThunkRejectedReducer(builder, getRatingsByIdThunk)
    getRatingsByIdThunkFulfilledReducer(builder, getRatingsByIdThunk)
    getRatingsByIdThunkPendingReducer(builder, getRatingsByIdThunk)

    getBestListingsByUserIdThunkRejectedReducer(builder, getBestListingsByUserIdThunk)
    getBestListingsByUserIdThunkFulfilledReducer(builder, getBestListingsByUserIdThunk)
    getBestListingsByUserIdThunkPendingReducer(builder, getBestListingsByUserIdThunk)
    
  }
});


export default dashboardSlice.reducer;
