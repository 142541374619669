import { createSlice } from "@reduxjs/toolkit";
import { allInvoices } from "../../mock-data/invoice-mock-data";
import { IINVOICESLICE } from "./invoice-slice-interface";
import { hideModalReducer, showModalReducer } from "./invoice-reducers";

const initialState:IINVOICESLICE = {
  showModal: false,
  invoiceData: allInvoices,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    showModal: showModalReducer,
    hideModal: hideModalReducer,
  },
});

export const { showModal, hideModal } = invoiceSlice.actions;
export default invoiceSlice.reducer;
