import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

import { 
  getThunkPendingReducer, getUserLatestNotificationsThunkFulfilledReducer, getUserLatestNotificationsThunkRejectedReducer, 
  getUserNotificationsThunkFulfilledReducer, getUserNotificationsThunkRejectedReducer, 
  markUserNotificationsDeleteAllThunkFulfilledReducer, markUserNotificationsDeleteAllThunkRejectedReducer, 
  markUserNotificationsDeleteThunkFulfilledReducer, markUserNotificationsDeleteThunkRejectedReducer, 
  markUserNotificationsReadAllThunkFulfilledReducer, markUserNotificationsReadAllThunkRejectedReducer, 
  markUserNotificationsReadThunkFulfilledReducer, markUserNotificationsReadThunkRejectedReducer, 
  markUserNotificationsUnReadAllThunkFulfilledReducer, markUserNotificationsUnReadAllThunkRejectedReducer, 
  markUserNotificationsUnReadThunkFulfilledReducer, markUserNotificationsUnReadThunkRejectedReducer, 
  ThunkPendingReducer } from "./layout-reducers";

import { 
  getUserLatestNotificationsThunk, getUserNotificationsThunk, markUserNotificationsDeleteAllThunk, 
  markUserNotificationsDeleteThunk, markUserNotificationsReadAllThunk, markUserNotificationsReadThunk, 
  markUserNotificationsUnReadAllThunk, markUserNotificationsUnReadThunk } from "./layout-thunk-actions";


const layoutInitialState: any = {
  mobDrawer: false,
  openDrawer: true,
  backgroundImage: '',
  status: REQUESTSTATUS.IDLE,
  getStatus: REQUESTSTATUS.IDLE,
  notifications: [] as any,
  notificationsMeta:{} as any,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState: layoutInitialState,
  reducers: {
    setMobDrawer(state, action) {
      state.mobDrawer = action.payload;
    },
    setOpenDrawer(state, action) {
      state.openDrawer = action.payload;
    },
    setBackgroundImage(state, { payload }) {
      state.backgroundImage = payload
    }
  },
  extraReducers(builder) {
    getThunkPendingReducer(builder, getUserNotificationsThunk)
    getUserNotificationsThunkFulfilledReducer(builder, getUserNotificationsThunk)
    getUserNotificationsThunkRejectedReducer(builder, getUserNotificationsThunk)

    getThunkPendingReducer(builder, getUserLatestNotificationsThunk)
    getUserLatestNotificationsThunkFulfilledReducer(builder, getUserLatestNotificationsThunk)
    getUserLatestNotificationsThunkRejectedReducer(builder, getUserLatestNotificationsThunk)

    ThunkPendingReducer(builder, markUserNotificationsReadThunk)
    markUserNotificationsReadThunkFulfilledReducer(builder, markUserNotificationsReadThunk)
    markUserNotificationsReadThunkRejectedReducer(builder, markUserNotificationsReadThunk)

    ThunkPendingReducer(builder, markUserNotificationsReadAllThunk)
    markUserNotificationsReadAllThunkFulfilledReducer(builder, markUserNotificationsReadAllThunk)
    markUserNotificationsReadAllThunkRejectedReducer(builder, markUserNotificationsReadAllThunk)

    ThunkPendingReducer(builder, markUserNotificationsUnReadThunk)
    markUserNotificationsUnReadThunkFulfilledReducer(builder, markUserNotificationsUnReadThunk)
    markUserNotificationsUnReadThunkRejectedReducer(builder, markUserNotificationsUnReadThunk)

    ThunkPendingReducer(builder, markUserNotificationsUnReadAllThunk)
    markUserNotificationsUnReadAllThunkFulfilledReducer(builder, markUserNotificationsUnReadAllThunk)
    markUserNotificationsUnReadAllThunkRejectedReducer(builder, markUserNotificationsUnReadAllThunk)

    ThunkPendingReducer(builder, markUserNotificationsDeleteThunk)
    markUserNotificationsDeleteThunkFulfilledReducer(builder, markUserNotificationsDeleteThunk)
    markUserNotificationsDeleteThunkRejectedReducer(builder, markUserNotificationsDeleteThunk)

    ThunkPendingReducer(builder, markUserNotificationsDeleteAllThunk)
    markUserNotificationsDeleteAllThunkFulfilledReducer(builder, markUserNotificationsDeleteAllThunk)
    markUserNotificationsDeleteAllThunkRejectedReducer(builder, markUserNotificationsDeleteAllThunk)


  },
});

export const { setMobDrawer, setOpenDrawer, setBackgroundImage } = layoutSlice.actions;
export default layoutSlice.reducer;
