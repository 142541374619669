import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { IAUDITASSIGNMENTSTATE, IAUDITASSIGNMENTDATA } from "./audit-assignment-slice-interface";

export const setSortedAndReversedArray = (state: IAUDITASSIGNMENTSTATE, action: any) => {
    const KEY = action.payload.key;
    state.auditsAssignmentData = !action.payload.sorted ? state.auditsAssignmentData.sort((audit1, audit2) => (audit1[KEY as keyof IAUDITASSIGNMENTDATA]! > audit2[KEY as keyof IAUDITASSIGNMENTDATA]!) ? 1 : ((audit2[KEY as keyof IAUDITASSIGNMENTDATA]! < audit1[KEY as keyof IAUDITASSIGNMENTDATA]!) ? -1 : 0)) : state.auditsAssignmentData.reverse();
};
export const getInitialAuditsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
        state.status = REQUESTSTATUS.PENDING
    });
}
export const getHostsListThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
        state.hostListStatus = REQUESTSTATUS.PENDING
    });
}
export const getComplianceOfficersThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
        state.complianceOfficersListStatus = REQUESTSTATUS.PENDING
    });
}
export const assignInitialAuditThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
        state.formSubmissionStatus = REQUESTSTATUS.PENDING
    });
}

export const getInitialAuditsThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.auditsAssignmentData = payload.initialAuditData.map((item: IAUDITASSIGNMENTDATA, index: number) => ({ ...item, generatedHostId: `00${(payload.meta.total - (payload.meta.page - 1) * 10) - index}` }));
            state.page = payload.meta.page;
            state.pages = payload.meta.pages;
            state.total = payload.meta.total;
            state.status = REQUESTSTATUS.SUCCEEDED;
        });
}

export const getInitialAuditsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        });
}

export const getAnnouncedAuditsThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.auditsAssignmentData = payload.initialAuditData.map((item: IAUDITASSIGNMENTDATA, index: number) => ({ ...item, generatedHostId: `00${(payload.meta.total - (payload.meta.page - 1) * 10) - index}` }));
            state.page = payload.meta.page;
            state.pages = payload.meta.pages;
            state.total = payload.meta.total;
            state.status = REQUESTSTATUS.SUCCEEDED;
        });
}

export const getAnnouncedAuditsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        });
}

export const getUnannouncedAuditsThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.auditsAssignmentData = payload.initialAuditData.map((item: IAUDITASSIGNMENTDATA, index: number) => ({ ...item, generatedHostId: `00${(payload.meta.total - (payload.meta.page - 1) * 10) - index}` }));
            state.page = payload.meta.page;
            state.pages = payload.meta.pages;
            state.total = payload.meta.total;
            state.status = REQUESTSTATUS.SUCCEEDED;
        });
}

export const getUnannouncedAuditsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        });
}



export const getHostsListThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.hostsList = payload.data;
            state.hostListStatus = REQUESTSTATUS.SUCCEEDED;
        });
}

export const getHostsListThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.hostListStatus = REQUESTSTATUS.FAILED;
        });
}



export const getComplianceOfficersThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.complianceOfficersList = payload.data;
            state.complianceOfficersListStatus = REQUESTSTATUS.SUCCEEDED;
        });
}

export const getComplianceOfficersThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.complianceOfficersListStatus = REQUESTSTATUS.FAILED;
        });
}


export const assignInitialAuditThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.formSubmissionStatus = REQUESTSTATUS.SUCCEEDED;
        });
}

export const assignInitialAuditThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.formSubmissionStatus = REQUESTSTATUS.FAILED;
        });
}

export const assignAnnouncedAuditThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.formSubmissionStatus = REQUESTSTATUS.SUCCEEDED;
        });
}

export const assignAnnouncedAuditThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.formSubmissionStatus = REQUESTSTATUS.FAILED;
        });
}
export const assignUnannouncedAuditThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.formSubmissionStatus = REQUESTSTATUS.SUCCEEDED;
        });
}

export const assignUnannouncedAuditThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUDITASSIGNMENTSTATE, { payload }: any) => {
            state.formSubmissionStatus = REQUESTSTATUS.FAILED;
        });
}

export const getLocationThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.locationStatus = REQUESTSTATUS.PENDING
    });
}

export const getLocationThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.locationStatus = REQUESTSTATUS.SUCCEEDED;
      state.locations = payload?.locations
      
    });
  };
  
    
  export const getLocationThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
      state.locationStatus = REQUESTSTATUS.FAILED;
  
    });
  };

  export const getSelectedLocationDetailsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.locationStatus = REQUESTSTATUS.PENDING
    });
}
  
  export const getSelectedLocationDetailsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
      state.locationStatus = REQUESTSTATUS.FAILED;
  
    });
  };

  export const getSelectedLocationDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.locationStatus = REQUESTSTATUS.SUCCEEDED;
      state.coordinates = payload?.coordinates
      
    });
  };
