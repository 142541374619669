import { REQUESTSTATUS } from "../../constants/axios-api-constant"


export const clearEditRecipeData = (state: any) => {
  state.recipeToEditData = [];

}



export const getIngredientsRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const getIngredientsSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.ingredients = payload;
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const getIngredientsPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}




export const postIngredientRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const postIngredientSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const postIngredientPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}


// ---------------Recipes CRUD-------------------------//

export const addNewRecipeRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      // console.log('failed to add', payload);

      state.status = REQUESTSTATUS.FAILED;
    });
}

export const addNewRecipeSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const addNewRecipePendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}


export const getRecipeRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const getRecipeSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.recipeToEditData = payload;
      state.nutrics = payload?.nutritions;
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const getRecipePendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}


export const getRecipesForTableRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const getRecipesForTableSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.allRecipes = payload;
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const getRecipesForTablePendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}




export const deleteRecipesRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const deleteRecipesSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const deleteRecipesPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}




export const editRecipesRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const editRecipesSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const editRecipesPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}


export const getNutritionsRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const getNutritionsSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
      // console.log('got nutrics');
      
      state.nutrics = payload;
    });
}

export const getNutritionsPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}




export const deleteRecipeImageRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const deleteRecipeImageSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;      
    });
}

export const deleteRecipeImagePendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}