import * as Yup from 'yup';

import {

  ALLCASECHARACTERSONLYVALIDATION,
  ALLCHARACTERSONLYVALIDATION, ATLEASTONELOWERCASEVALIDATION, ATLEASTONESPECIALCHARACTERVALIDATION,
  ATLEASTONEUPPERCASEVALIDATION, MAXIMUMNAMELENGTH, MAXIMUMPASSWORDLENGTHVALIDATION,
  MAXIMUMPHONENUMBERLENGTH,
  MINIMUMNAMELENGTH,
  MINIMUMPASSWORDLENGTHVALIDATION,
  MAXIMUMMENUDESCRIPTIONLENGTH, MAXIMUMMENUTITLELENGTH, MAXIMUMSIGNUPFIRSTNAMELENGTH,
  MINIMUMALLERGENSELECTION,
  MINIMUMFOODITEMSELECTION,
  MINIMUMHOUSERULESELECTION,
  MINIMUMINGREDIENTSELECTION,
  MINIMUMITEMCATEGORYSELECTION,
  MINIMUMPRICE, MINIMUMSIGNUPFIRSTNAMELENGTH, MAXUPLOADFILESIZE, ALPHANUMERICVALIDATION, ALPHANUMERICBUTNOTNUMERICALONEVALIDATION,
  UTRNUMBERVALIDATION, UTRNUMBERMAXIMUMLENGTH, MINIMUMHOSTSSELECTION

} from '../constants/validations-constant';

export const SIGNUPFORMVALIDATIONSCHEMA = Yup.object().shape({
  firstName: Yup.string().trim()
    // .min(MINIMUMNAMELENGTH, "Must be atleast 3 Characters")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .required("Required Field")
    .matches(ALLCHARACTERSONLYVALIDATION, "."),
  lastName: Yup.string().trim()
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .required("Required Field")
    .matches(ALLCHARACTERSONLYVALIDATION, "."),
  email: Yup.string()
    .email("Email is Invalid")
    .required("Required Field")
    .typeError('include "@" in email adress'),
  phoneNumber: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    // .matches(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/, 'Invalid Phone Number')// eslint-disable-line
    .matches(/^\+44\d{10}$/, 'Invalid Phone Number')// eslint-disable-line
    .required("Required Field"),
  password: Yup.string()
    .required("Required Field")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Please match the given criteria")
    .max(MAXIMUMPASSWORDLENGTHVALIDATION, "Maximum 30 characters allowed")
    .matches(/^(?=.{8,})/, "Please match the given criteria") // eslint-disable-line
    // .matches(ALLCASECHARACTERSONLYVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(ATLEASTONEUPPERCASEVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(ATLEASTONELOWERCASEVALIDATION, "Must include atleast 1 small letter") // eslint-disable-line
    .matches(ATLEASTONESPECIALCHARACTERVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(/^(?=.{6,30}$)\D*\d/, "Please match the given criteria"), // eslint-disable-line
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password and Confirmed Password Does not Match")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Must be atleast 8 characters long")
    .required("Required Field"),
});

export const SIGNINFORMVALIDATIONSCHEMA = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const FORGOTPASSWORDFORMVALIDATIONSCHEMA = Yup.object({
  email: Yup.string()
    .email("Email is in-valid")
    .required("Email is Required"),
});

export const RESETPASSWORDFORMVALIDATIONSCHEMA = Yup.object({
  // email: Yup.string().email("Email is in valid").required("Required field"),
  // code: Yup.string().required("Verification Code Required"),
  password: Yup.string()
    .required("Required Field")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Please match the given criteria")
    .max(MAXIMUMPASSWORDLENGTHVALIDATION, "Maximum 30 characters allowed")
    .matches(/^(?=.{8,})/, "Please match the given criteria") // eslint-disable-line
    // .matches(ALLCASECHARACTERSONLYVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(ATLEASTONEUPPERCASEVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(ATLEASTONELOWERCASEVALIDATION, "Must include atlease 1 small letter") // eslint-disable-line
    .matches(ATLEASTONESPECIALCHARACTERVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(/^(?=.{6,30}$)\D*\d/, "Please match the given criteria"), // eslint-disable-line
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password and Confirmed Password Does not Match")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Must be atleast 8 characters long")
    .required("Confirm Password is required"),
});

export const CHANGEPASSWORDFORMVALIDATIONSCHEMA = Yup.object({
  oldPassword: Yup.string()
    .required("Required Field"),
  newPassword: Yup.string()
    .required("Required Field")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Please match the given criteria")
    .max(MAXIMUMPASSWORDLENGTHVALIDATION, "Maximum 30 characters allowed")
    .matches(/^(?=.{8,})/, "Please match the given criteria") // eslint-disable-line
    // .matches(ALLCASECHARACTERSONLYVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(ATLEASTONEUPPERCASEVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(ATLEASTONELOWERCASEVALIDATION, "Must include atlease 1 small letter") // eslint-disable-line
    .matches(ATLEASTONESPECIALCHARACTERVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(/^(?=.{6,30}$)\D*\d/, "Please match the given criteria"), // eslint-disable-line
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Password must Match")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Must be atleast 8 characters long")
    .required("Required Field"),
});

export const LICENSEFORMVALIDATIONSCHEMA = Yup.object().shape({
  utrNumber: Yup.string().required(),
  utrNumberValue: Yup.string().max(UTRNUMBERMAXIMUMLENGTH, "not a valid utr number")
    .matches(UTRNUMBERVALIDATION, "Please provide the 10-digit utr number e.g. 12345 67890")
    .required("utr Number is required"),  // if utrNumberValue is neccessary at all
  // utrNumberValue: Yup.string().ensure().when("utrNumber", {
  //   is:(utrNumber:any) => utrNumber === "Yes",
  //   then:(SettingsSchema:any)=>SettingsSchema.required('utrNumber is required')
  // }),    // if utrvalue is neccesary if yes
  permisesPermission: Yup.string().required(),
  signMemorendum: Yup.bool().oneOf([true]),
  permisesPermissionImage: Yup.mixed().required('Attachment is Required'),
  // permisesPermissionImage: Yup.string().ensure().when("permisesPermission", {
  //   is:(permisesPermission:any) => permisesPermission === "Yes",
  //   then:(SettingsSchema:any)=>SettingsSchema.required('Attachment is Required')
  // }),   // if it is necessary at yes

});

export const KITCHENDETAILSFORMVALIDATIONSCHEMA = Yup.object({
  name: Yup.string().trim().required("Required Field"),
  kitchenLocation: Yup.string().trim().required("Required Field"),
  kitchenCoverPhoto: Yup.mixed().required('Images are Required'),
});

//TODO: remove nullable 
export const ADDFOODFORMVALIDATIONSCHEMA = Yup.object().shape({
  // itemCategory: Yup.array().nullable().required().min(MINIMUMITEMCATEGORYSELECTION, 'Required Field'),
  itemCategory: Yup.string().required('Required Field'),
  itemName: Yup.string().trim().required('Required Field')
  .matches(ALPHANUMERICBUTNOTNUMERICALONEVALIDATION, "should contains both alphabets and numbers only")
  .max(MAXIMUMMENUTITLELENGTH, "It is too Long, Must be 50 charachters"),
  itemDescription: Yup.string().trim().required('Required Field')
  .max(MAXIMUMMENUDESCRIPTIONLENGTH, "It is too Long, Must be 500 charachters"),
  ingredient: Yup.array().required().min(MINIMUMINGREDIENTSELECTION, 'Required Field'),
  dietary: Yup.string().required('Required Field'),
  allergen: Yup.array().required().min(MINIMUMALLERGENSELECTION, 'Required Field'),
  cuisin: Yup.string().required('Required Field'),
  spice: Yup.string().required('Required Field'),
  // recipe: Yup.string().required('Required Field'),
  recipe: Yup.string(),
  badge: Yup.string().required('Required Field'),
  available: Yup.string().required('Required Field'),
  age: Yup.string().required('Required Field'),
  nutritionalInfo:Yup.mixed().required('Required Field'),
  // nutritionalInfo: Yup.string(),
  // foodImages: Yup.mixed().required('Attachment must be uploaded'),
  foodImages: Yup.array().required().min(MINIMUMALLERGENSELECTION, 'Attachment is Required')
});

export const CREATEMENUFORMVALIDATIONSCHEMA = Yup.object().shape({
  title: Yup.string()
    .required("Required Field")
    .max(MAXIMUMMENUTITLELENGTH, "It is too Long, Must be 50 charachters"),
  description: Yup.string()
    .required("Required Field")
    .max(MAXIMUMMENUDESCRIPTIONLENGTH, "It is too Long, Must be 500 charachters"),
});


export const UPSERTMENUFORMVALIDATIONSCHEMA = Yup.object().shape({
  category: Yup.string().trim()
    .required("Required Field")
    .matches(ALPHANUMERICBUTNOTNUMERICALONEVALIDATION, "should contains both alphabets and numbers only")
    .max(MAXIMUMMENUTITLELENGTH, "It is too Long, Must be 50 charachters"),
  description: Yup.string().trim()
    .required("Required Field")
    .max(MAXIMUMMENUDESCRIPTIONLENGTH, "It is too Long, Must be 500 charachters"),
  menuImages: Yup.mixed().required('Attachment must be uploaded')
});

export const LISTINGFORMVALIDATIONSCHEMA = Yup.object().shape({
  name: Yup.string().required("Required Field"),
  menu: Yup.string().required("Required Field"),
  food: Yup.array().required().min(MINIMUMFOODITEMSELECTION, 'Required Field'),
  guest: Yup.string().required("Required Field"),
  description: Yup.string().required("Required Field"),
  houseRules: Yup.array().required().min(MINIMUMHOUSERULESELECTION, 'Select an option'),
  pricing: Yup.number().moreThan(MINIMUMPRICE, 'Pricing should not be zero or less than zero').required("Required Field"),
  availability: Yup.object().required("Required Field"),
});

export const ADDRECIPEFORMVALIDATIONSCHEMA = Yup.object().shape({
  title: Yup.string().required("Required Field"),
  description: Yup.string().required("Required Field"),
  recipeCategory: Yup.string().required("Required Field"),
  mainIngredient: Yup.string().required("Required Field"),
  cusine: Yup.string().required("Required Field"),

  recipeList: Yup.array().of(
    Yup.object().shape({
      ingredientTitle: Yup.string().required("Required Field"),
      measure: Yup.string().required("Required Field"),
      quantity: Yup.string().required("Required Field"),
    })
  ),

  noOfServings: Yup.string().required("Required Field"),
  cookingTimeInHour: Yup.string().required("Required Field"),
  cookingTimeInMin: Yup.string().required("Required Field"),
  cookingInstruction: Yup.string().required("Cannot be left blank"),
});

export const PERSONALDETAILSFORMVALIDATIONSCHEMA = Yup.object({
  firstName: Yup.string().trim()
    // .min(MINIMUMNAMELENGTH, "Must be atleast 3 Characters")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .required("Required Field")
    .matches(ALLCHARACTERSONLYVALIDATION, "."),
  lastName: Yup.string().trim()
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .required("Required Field")
    .matches(ALLCHARACTERSONLYVALIDATION, "."),
    phoneNumber: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    // .matches(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/, 'Invalid Phone Number')// eslint-disable-line
    .matches(/^\+44\d{10}$/, 'Invalid Phone Number')// eslint-disable-line
    .required("Required Field"),
  email: Yup.string().required("Required Field"),
  country: Yup.string().required("Required Field"),
  gender: Yup.string().required("Required Field"),
  city: Yup.string().required("Required Field"),
  address: Yup.string().trim().required("Required Field"),
  dob: Yup.string().required("Required Field"),

  // languagesSpeak: Yup.string().required("Please select"),
});
export const ABOUTUSVALIDATIONSCHEMA = Yup.object({
  aboutUs: Yup.string().trim().required("Required Field")
})


export const checkImage = (file: any) => {
  let err = ""
  if (!file) { return err = "File does not exist."; }

  if(file.type !== 'image/jpeg' && file.type !== 'image/png' )
  { return err = "Accepted file type is jpeg, jpg and png";  }

  if (file.size > MAXUPLOADFILESIZE) // 1mb
  { return err = "The largest image size is 1Mb."; }

  return err;
}

export const BOOKINGREJECTIONVALIDATIONSCHEMA = Yup.object({
  rejectionReason: Yup.string().required("Required Field"),
});

export const ADDRIBBONVALIDATIONSCHEMA = Yup.object().shape({
  title: Yup.string(),
  category: Yup.string(),
  offer: Yup.string(),
  startDate: Yup.date().when(['title', 'category', 'offer'] as any, {
    is: (title: any, category: any, offer: any) => !!title && !!category && !!offer,
    then: (SettingsSchema: any) => SettingsSchema.required('start Date is required')
  }),
  // startDate:Yup.date(),
  endDate: Yup.date().when(['title', 'category', 'offer'] as any, {
    is: (title: any, category: any, offer: any) => !!title && !!category && !!offer,
    then: (SettingsSchema: any) => SettingsSchema.required('end Date is required')
  }).min(
    Yup.ref('startDate'),
    "End date must be later than the start date"
  ),
  userImageId: Yup.string().ensure().when(['title', 'category', 'offer'] as any, {
    is: (title: any, category: any, offer: any) => !!title && !!category && !!offer,
    then: (SettingsSchema: any) => SettingsSchema.required('ribbon is reuired')
  })
})



export const ADDRECIPEVALIDATIONSCHEMA = Yup.object().shape({
  title: Yup.string().required("Required Field"),
  categorisation: Yup.string().required("Required Field"),
  cookingInstructions: Yup.string().required("Cannot be left blank"),
  recipeImages: Yup.mixed().required('Attachment must be uploaded'),
  description:  Yup.string().required("Required Field"),
  mainIngredient:  Yup.string().required("Required Field"),
  cusine:  Yup.string().required("Required Field"),
  numberOfServings:  Yup.number().integer().lessThan(1000,'Servings must be less than 1000').required("Required Field"),
  hrs:  Yup.number().min(0).max(99999,'Hours must be less than 5 digits').required("Required Field"),
  minutes:  Yup.number().min(0).max(60,'Minutes must not exceed 60').required("Required Field"),


})

export const WISHLISTVALIDATIONSCHEMA = Yup.object().shape({
  name: Yup.string().trim().matches(ALPHANUMERICBUTNOTNUMERICALONEVALIDATION, "should contain both alphabets and numbers only")
    .max(MAXIMUMNAMELENGTH, 'Please enter less than 20 characters')
    .required("Required Field"),
  category: Yup.string().required("Required Field")
});

export const NEWAUDITVALIDATIONSCHEMA = Yup.object().shape({
  hosts: Yup.array().required().min(MINIMUMHOSTSSELECTION, 'Required Field'),
  complianceOfficer: Yup.string().required("Required Field"),
  visitType: Yup.string().required("Required Field"),
  dueDate: Yup.string().required("Required Field").nullable(true)
});

export const listingValidation = Yup.object().shape({
  name: Yup.string().required("Required Field"),
  menuId: Yup.string().required("Required Field"),
  foodItems: Yup.array()
    .required()
    .min(MINIMUMFOODITEMSELECTION, "Required Field"),
  noOfGuests: Yup.number().required("Required Field"),
  price: Yup.number()
    .moreThan(0, "Required Field")
    .max(1000,'Maximum price limit is 1000')
    .required("Required Field"),
  description: Yup.string().required("Required Field"),
});

export const ADDNEWINGREDIENTVALIDATIONSCHEMA = Yup.object({
  name: Yup.string().required("Name is required"),
});