import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const ThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        // console.log(payload)
        state.status = REQUESTSTATUS.PENDING;
    });
};
export const getThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        // console.log(payload)
        state.getStatus = REQUESTSTATUS.PENDING;
    });
};

export const getUserNotificationsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.getStatus = REQUESTSTATUS.SUCCEEDED;
        state.notificationsMeta = payload?.notificationsMeta;
        state.notifications = payload?.notifications

    });
};


export const getUserNotificationsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload })
        state.getStatus = REQUESTSTATUS.FAILED;
    });
};
export const getUserLatestNotificationsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.getStatus = REQUESTSTATUS.SUCCEEDED;

    });
};


export const getUserLatestNotificationsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload })
        state.getStatus = REQUESTSTATUS.FAILED;
    });
};

export const markUserNotificationsReadThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;

    });
};


export const markUserNotificationsReadThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload })
        state.status = REQUESTSTATUS.FAILED;
    });
};

export const markUserNotificationsReadAllThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;

    });
};


export const markUserNotificationsReadAllThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload })
        state.status = REQUESTSTATUS.FAILED;
    });
};


export const markUserNotificationsUnReadAllThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;

    });
};


export const markUserNotificationsUnReadAllThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload })
        state.status = REQUESTSTATUS.FAILED;
    });
};


export const markUserNotificationsUnReadThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;

    });
};


export const markUserNotificationsUnReadThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload })
        state.status = REQUESTSTATUS.FAILED;
    });
};
export const markUserNotificationsDeleteThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;

    });
};


export const markUserNotificationsDeleteThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload })
        state.status = REQUESTSTATUS.FAILED;
    });
};
export const markUserNotificationsDeleteAllThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;

    });
};


export const markUserNotificationsDeleteAllThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload })
        state.status = REQUESTSTATUS.FAILED;
    });
};