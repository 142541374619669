import { createAsyncThunk } from "@reduxjs/toolkit"
import { INGREDIENTSAPI,  NUTRITIONSAPI, RECIPESAPI } from "../../constants/axios-api-constant"
import { RECIPEMANAGEMENTPATH,} from "../../constants/routes-constant"
import { deleteDataAPI, getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios"

export const addNewRecipeThunk = createAsyncThunk('recipes/post-recipes',
    async ({ formData, axiosInstance, openAlert, navigate }: any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(RECIPESAPI, axiosInstance, formData)

            openAlert(response.data.message, 'alert-success-message')
            navigate(RECIPEMANAGEMENTPATH)
            
            return response.data.message;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error, 'alert-error-message')
            return rejectWithValue(errorData)
        }
    }
)

export const getRecipeByIdThunk = createAsyncThunk('recipes/get-recipe-by-id',
    async ({ axiosInstance, recipeId }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(RECIPESAPI + `/${recipeId}`, axiosInstance)
            return response.data.data;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            return rejectWithValue(errorData)
        }
    }
    )

export const getIngredientsThunk = createAsyncThunk('/get-ingredients',
    async ({ axiosInstance, filter }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(INGREDIENTSAPI + `?limit=60&offset=0&filter=${filter ?? ''}`, axiosInstance)

            return response.data.data.ingredients;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }

            return rejectWithValue(errorData)
        }
    }
)

export const postIngredientThunk = createAsyncThunk('/post-ingredients',
    async ({ axiosInstance, ingredientFormData ,openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(INGREDIENTSAPI, axiosInstance, ingredientFormData)
            
            openAlert(response.data.message, 'alert-success-message')
            return response.data.message;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error, 'alert-error-message')

            return rejectWithValue(errorData)
        }
    }
)


export const getRecipesForTableThunk = createAsyncThunk('/get-recipes',
    async ({ axiosInstance, offset, filter }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(RECIPESAPI + `?limit=10${offset && `&offset= ${offset}`}${filter && `&filter=${filter}`}`, axiosInstance)

            return response.data.data;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }

            return rejectWithValue(errorData)
        }
    }
)



export const deleteRecipesThunk = createAsyncThunk('recipes/delete-recipes',
    async ({ axiosInstance, recipeId, openAlert, dispatch }: any, { rejectWithValue }) => {
        try {
            const response = await deleteDataAPI(RECIPESAPI, axiosInstance, { recipeIds: [recipeId] })

            openAlert('Recipe Deleted Successfully', 'alert-success-message')
            dispatch(getRecipesForTableThunk({ axiosInstance, offset: '', filter: '' }))
            return response.data.message;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error, 'alert-error-message')

            return rejectWithValue(errorData)
        }
    }
)






export const editRecipeByIdThunk = createAsyncThunk('recipes/edit-recipe',
    async ({ axiosInstance, recipeId, formData, openAlert, navigate }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(RECIPESAPI + `/${recipeId}`, axiosInstance, formData)
            openAlert(response.data.message, 'alert-success-message')

            navigate(RECIPEMANAGEMENTPATH)
            return response.data.data;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error, 'alert-error-message')

            return rejectWithValue(errorData)
        }
    }
)

export const getNitritionsInfoThunk = createAsyncThunk('food/get-nutritional-info',
    async ({ ingredientsArray, axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(NUTRITIONSAPI + `?query=${ingredientsArray}`, axiosInstance)

            return response.data.data;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }

            return rejectWithValue(errorData)
        }
    }
)


export const deleteRecipeImageThunk = createAsyncThunk('delete-recipe-image',
    async ({ axiosInstance, imageObject, openAlert, recipePhoto, set , id, SetRecipePhoto, recipeId }: any, { rejectWithValue }) => {
        try {
            const response = await deleteDataAPI(RECIPESAPI + `/${recipeId}/remove-image`, axiosInstance, imageObject)

            openAlert("Image Deleted Successfully", 'alert-error-message')
            const deletingObj = recipePhoto.filter((item: any) => item !== id);
            SetRecipePhoto(deletingObj);
            set("kitchenCoverPhoto", deletingObj?.length ? deletingObj : "")
            return response.data.message;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            return rejectWithValue(errorData)
        }
    }
)
