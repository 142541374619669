import { REQUESTSTATUS } from "../../constants/axios-api-constant";


export const addNewUserFromModal = (state: any, action: any) => {
  state.manageUsersData.push(action.payload)
}


export const updateSelectedUser = (state: any, action: any) => {
  state.userToUpdate = action.payload;
}
export const getMangeUsersFullfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      // console.log(payload, '😋');
      state.reminders = payload.reminders;
      state.recentActivitiesData = payload?.recentActivity;
      state.mangeUserCard = payload?.users;
    });

}

export const deleteManageUserSelectedUser = (state: any, action: any) => {
  let backup = action.payload;
  state.manageUsersData = state.manageUsersData.filter((user: any) => user.id !== backup.id)
}



export const getMangeUsersRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      // console.log(payload, 'payload');
      state.recentActivitiesData = payload?.recentActivity;
      state.mangeUserCard = payload?.users;
    });
}


// get selected user according to role

export const getUsersRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const getUsersSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.manageUsersData = payload;
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const getUsersPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });
}

// changing status of a user
export const changeUserStatusRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const changeUserStatusSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const changeUserStatusPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });
}


// not implemented delete user
export const deleteUserRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });

}
export const deleteUserSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    }
  )
}
export const deleteUserPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    })
}

// issue from BE 
export const editUserRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}
export const editUserSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    }
  )
}
export const editUserPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    })

}


export const createNewUserRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}
export const createNewUserSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    }
  )
}
export const createNewUserPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    })
}

// geting all permissions for selected user
export const getUserRightsRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}
export const getUserRightsSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
      state.permissions = payload;
    }
  )
}
export const getUserRightsPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    })
}


// editing roles for the selected ID
export const postUserRightsRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}
export const postUserRightsSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    }
  )
}
export const postUserRightsPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    })
}

// get bookings by Selected ID
export const getBookingsByIdRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}
export const getBookingsByIdSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
      state.bookingsData = payload.bookingsData;
      state.page = payload.meta.page;
      state.pages = payload.meta.pages;
    }
  )
}

export const getBookingsByIdPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    })
}

// get bookings by Selected ID
export const getListingsByUserIdRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}
export const getListingsByUserIdSuccessReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.listingDataForAdmin = payload;
      state.status = REQUESTSTATUS.SUCCEEDED;
    }
  )
}
export const getListingsByUserIdPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    })
}
