import { createSlice } from "@reduxjs/toolkit";
import { acceptBookingRequestThunkFulFilledReducer, 
  acceptBookingRequestThunkRejectReducer, 
  bookingDetailsThunkFulfilledReducer, 
  bookingDetailsThunkRejectedReducer, 
  bookingsButtonStatusThunkPendingReducer, 
  bookingsModalStatusThunkPendingReducer, 
  bookingsThunkFulfilledReducer, bookingsThunkPendingReducer, 
  bookingsThunkRejectedReducer,
   cancelBookingRequestThunkFulFilledReducer,
   cancelBookingRequestThunkRejectReducer,
   confirmBookingRequestThunkFulFilledReducer, 
   confirmBookingRequestThunkRejectReducer, 
   postBookingRequestThunkFulFilledReducer,
   postBookingRequestThunkRejectReducer,
   rejectBookingRequestThunkFulFilledReducer,
   rejectBookingRequestThunkRejectReducer
  } from "./booking-reducers";
import { IBOOKINGSTATE } from "./booking-slice-interface";
import { acceptBookingRequestThunk, confirmBookingRequestThunk, getBookingsListThunk, 
   postBookingRequestThunk, rejectBookingRequestThunk, cancelBookingRequestThunk, getBookingsDetailsThunk } from "./booking-thunk-action";

const initialState:IBOOKINGSTATE = {
  bookingsData:[],
  bookingDetails:{},
  status:'',
  buttonStatus:'',
  modalStatus:'',
  page:0,
  pages:0,
};

const  bookingSlice= createSlice({
  name: "booking",
  initialState,
  reducers: {
  },
  extraReducers(builder){
    //Booking Data Get Reducer
    bookingsThunkPendingReducer(builder,getBookingsListThunk)
    bookingsThunkRejectedReducer(builder,getBookingsListThunk)
    bookingsThunkFulfilledReducer(builder,getBookingsListThunk)

    bookingsButtonStatusThunkPendingReducer(builder,acceptBookingRequestThunk)
    acceptBookingRequestThunkRejectReducer(builder,acceptBookingRequestThunk)
    acceptBookingRequestThunkFulFilledReducer(builder,acceptBookingRequestThunk)

    bookingsButtonStatusThunkPendingReducer(builder,rejectBookingRequestThunk)
    rejectBookingRequestThunkRejectReducer(builder,rejectBookingRequestThunk)
    rejectBookingRequestThunkFulFilledReducer(builder,rejectBookingRequestThunk)

    bookingsButtonStatusThunkPendingReducer(builder,cancelBookingRequestThunk)
    cancelBookingRequestThunkRejectReducer(builder,cancelBookingRequestThunk)
    cancelBookingRequestThunkFulFilledReducer(builder,cancelBookingRequestThunk)

    bookingsButtonStatusThunkPendingReducer(builder,confirmBookingRequestThunk)
    confirmBookingRequestThunkRejectReducer(builder,confirmBookingRequestThunk)
    confirmBookingRequestThunkFulFilledReducer(builder,confirmBookingRequestThunk)

    bookingsModalStatusThunkPendingReducer(builder,getBookingsDetailsThunk)
    bookingDetailsThunkRejectedReducer(builder,getBookingsDetailsThunk)
    bookingDetailsThunkFulfilledReducer(builder,getBookingsDetailsThunk)


    


    bookingsThunkPendingReducer(builder,postBookingRequestThunk)
    postBookingRequestThunkRejectReducer(builder,postBookingRequestThunk)
    postBookingRequestThunkFulFilledReducer(builder,postBookingRequestThunk)


    


   



  }
});
export default bookingSlice.reducer;
