import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const setReducer = (state: any, action: any) => {
  state.user = action.payload;
};


export const foodThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });
}

export const getsingleMenuFoodItemsThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
      state.singleMenuFoodItem = payload.singleMenuFoodItem;
      state.selectedItemCategory = payload.selectedItemCategory
    });
}

export const getsingleMenuFoodItemsThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}
export const getSingleFoodItemThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
      state.singleFoodItem = payload.singleFoodItem;
      state.nutrics = payload?.singleFoodItem?.nutritions

    });
}

export const getSingleFoodItemThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}


export const addFoodItemThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.upsertStatus = REQUESTSTATUS.SUCCEEDED;
      // state.singleFoodItem = payload.singleFoodItem;

    });
}

export const addFoodItemThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.upsertStatus = REQUESTSTATUS.PENDING;
      // state.singleFoodItem = payload.singleFoodItem;

    });
}

export const addFoodItemThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.upsertStatus = REQUESTSTATUS.FAILED;
    });
}


export const deleteFoodItemThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
      // state.singleFoodItem = payload.singleFoodItem;
    });
}

export const deleteFoodItemThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}


export const editFoodItemThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.upsertStatus = REQUESTSTATUS.SUCCEEDED;
      // state.singleFoodItem = payload.singleFoodItem;

    });
}
export const editFoodItemThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.upsertStatus = REQUESTSTATUS.PENDING;
      // state.singleFoodItem = payload.singleFoodItem;

    });
}

export const editFoodItemThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.upsertStatus = REQUESTSTATUS.FAILED;
    });
}


export const setIdToDeleteFoodReducer = (state:any , {payload}:any) => {
  state.id = payload
}


export const getNutritionsInfoThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.nutricsStatus = REQUESTSTATUS.FAILED;
    });
}

export const getNutritionsInfoThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.nutricsStatus = REQUESTSTATUS.SUCCEEDED;
      // console.log('got nutrics');
      
      state.nutrics = payload.nutritics;
    });
}

export const getNutritionsInfoThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.nutricsStatus = REQUESTSTATUS.PENDING;
    });

}



export const getAllIngredientsThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.ingredientsStatus = REQUESTSTATUS.PENDING;
    });
}

export const getAllIngredientsThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.ingredientsStatus = REQUESTSTATUS.SUCCEEDED;
      state.allIngredients = payload.allIngredients
    });
}

export const getAllIngredientsThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.ingredientsStatus = REQUESTSTATUS.FAILED;
    });
}



export const getAllRecipesThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.recipesStatus = REQUESTSTATUS.PENDING;
    });
}

export const getAllRecipesThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.recipesStatus = REQUESTSTATUS.SUCCEEDED;
      state.allRecipes = payload.allRecipes
    
    });
}

export const getAllRecipesThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.recipesStatus = REQUESTSTATUS.FAILED;
    });
}

export const addNewIngredientsThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.addIngredientsStatus = REQUESTSTATUS.PENDING;
    });
}

export const addNewIngredientsThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.addIngredientsStatus = REQUESTSTATUS.SUCCEEDED;
      // state.allRecipes = payload.allRecipes
    
    });
}

export const addNewIngredientsThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.addIngredientsStatus = REQUESTSTATUS.FAILED;
    });
}

export const deleteFoodItemImageThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.deleteFoodItemImageStatus = REQUESTSTATUS.PENDING;
    
  });
};

export const deleteFoodItemImageThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.deleteFoodItemImageStatus = REQUESTSTATUS.SUCCEEDED;
    
  });
};

export const deleteFoodItemImageThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
      // console.log(payload)
    state.deleteFoodItemImageStatus = REQUESTSTATUS.FAILED;

  });
};