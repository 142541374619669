export const searchFieldData = [
    {
      id: 1, value: 'Location', text: 'Location'
    },
    {
      id: 2, value: 'Host', text: 'Host'
    },
    {
      id: 3, value: 'Cuisine', text: 'Cuisine'
    }
  ]