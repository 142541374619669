import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';

import App from './App';
import store from './store/store';

import './index.scss';
import "./styles/common.scss";
import "./styles/utilities.scss";
import "./styles/display.scss";
import "./styles/dimension.scss";
import "./styles/global.scss";
import "./styles/screen-media-query.scss";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(

  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>

);


