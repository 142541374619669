import { Navigate, Route, Routes } from "react-router-dom";
import {lazy , Suspense } from 'react';

import { UNAUTHORIZEDPATH } from "../constants/routes-constant";
import { routingData } from "./routes-data";
import AppLoader from "../components/app-loader/app-loader";

const Notfound = lazy(() => import("../pages/not-found"));
const Unauthorized = lazy(()=> import( "../pages/un-authorized"));


const Routing: React.FC<any> = () => {

  return (
    <>
    <Suspense 
    fallback={<AppLoader message="Please Wait!"/>}
    // fallback={null}
    >
    <Routes>
      {
        routingData.map((routing) => (
          <Route key={routing.id} element={<routing.guard allowedRoles={routing.allowedRoles}> <routing.layout /> </routing.guard>}>
            {
              !!routing.parentRoutes &&
                routing.parentRoutes.map((parentRoute: any) => (
                <Route
                  key={parentRoute.id}
                  path={parentRoute.pathLink}
                  index={parentRoute.index}
                  element={<parentRoute.guard allowedRoles={parentRoute.allowedRoles}>{parentRoute.componentName} </parentRoute.guard>}
                >
                  {
                    !!parentRoute.child && parentRoute.child?.map((childRoute: any) => (
                      <Route
                        key={childRoute.id}
                        path={childRoute.pathLink}
                        index={childRoute.index}
                        element={<childRoute.guard allowedRoles={childRoute.allowedRoles}>{childRoute.componentName}</childRoute.guard>}
                      />
                    ))
                  }
                </Route>
              ))
            }
          </Route>
        ))
      }
      <Route path={UNAUTHORIZEDPATH} element={<Unauthorized/>}/>
      <Route path="/not-found" element={<Notfound/>}/>
      <Route path="*" element={<Navigate to="/not-found"/>} />
    </Routes>
    </Suspense>
    </>
  );
};

export default Routing;
