import CancelIcon from "@mui/icons-material/Cancel";

import "./image-preview-card.scss";

import { CANCELICON } from "./image-preview-card-mui-style";
import { IMAGEBASEURL } from "../../constants/axios-api-constant";

export const ImagePreviewCard: React.FC<any> = (props: any) => {
  const { singleMenuImgData, deleteUploadedImage , canDelete , globalClick} = props;
  // console.log('menu', props)

  const setImageSrc = (imageSrc:any) => {
    // console.log(imageSrc)
    if(typeof(imageSrc) === 'undefined') return '';
    if(typeof(imageSrc) === 'string') return imageSrc;
    if(typeof(imageSrc?.imgPath) === 'string') return imageSrc.imgPath;
    if(imageSrc?.imgPath?.match?.(/icons/)) return `${IMAGEBASEURL}${imageSrc.imgPath}`;
    if(imageSrc?.url?.match?.(/users/)) return `${IMAGEBASEURL}${imageSrc.url}`;
    if(imageSrc?.url?.match?.(/icons/)) return `${IMAGEBASEURL}${imageSrc.url}`;
    return URL?.createObjectURL?.(imageSrc)
  
  }


  return (
    <>
      
            <div
              className="sub-menu-image dummy-img-box center-text"
              >
              <img
                className="dummy-img margin-auto-x"
                onClick={()=>{globalClick?.();}}
                // src={singleMenuImgData?.name && URL?.createObjectURL?.(singleMenuImgData)}
                src={setImageSrc(singleMenuImgData)}
                alt={singleMenuImgData?.name}
              />
              <div
                  className="dummy-img-name center-text"
                >
                  {singleMenuImgData?.name}
                </div>
              <div>
              {
                  (canDelete ?? true) &&
               <div
                  className="delete-dummy-photo cursor-pointer"
                >
                  <div
                    onClick={(e:any) => {
                      // console.log(e);
                      deleteUploadedImage?.(e)
                    }
                    }
                    className="cancel"
                  >
                    <CancelIcon fontSize="small" sx={CANCELICON} />
                  </div>
                </div>
            }
              </div>
              
            </div>
    </>
  );
};

