import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FOODRESTRICTIONSAPI,
  RESTRICTFOODAPI,
} from "../../constants/axios-api-constant";
import { getDataAPI, postDataAPI, patchDataAPI } from "../../lib/axios";
import { showAlertMessage } from "../alert/alert-slice";
import { updateHouseRuleDataThunk } from "../house-rules/house-rules-thunk-actions";

export const foodRestrictionsThunk = createAsyncThunk(
  "user-profile/house-rules/food-restriction",
  async ({ axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(FOODRESTRICTIONSAPI, axiosInstance);
      
      const responseData = {
        foodItem: response.data.data,
      };

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);
export const postFoodRestrictionsThunk = createAsyncThunk(
  "user-profile/house-rules/food-restriction",
  async (
    { newIngredient, axiosInstance, dispatch,openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        FOODRESTRICTIONSAPI,
        axiosInstance,
        newIngredient
      );

      const responseData = {
        allRestrictedFoods: response.data.data,
      };
      dispatch(foodRestrictionsThunk({axiosInstance}))
      openAlert("Food Ingredient is successfully added", 'alert-success-message')
      return responseData;
    } catch (error: any) {

      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const restrictFoodThunk = createAsyncThunk(
  "user-profile/house-rules/food-restriction",
  async (
    { houseRules, restrictedFoods, axiosInstance, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        RESTRICTFOODAPI,
        axiosInstance,
        { restrictedFoodIds: restrictedFoods }
      );
        // console.log(response)
      dispatch(updateHouseRuleDataThunk({ houseRules: { ...houseRules, foodRestrictionApplied:true,restrictedFoods: response.data.data.restrictedFoods } }))

      dispatch(
        showAlertMessage({
          alertMessage: "Food Restrictions are Successfully saved",
          alertType: "alert-success-message",
        })
      );
      return response.data.data.restrictedFoods;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const getRestrictedFoodsThunk = createAsyncThunk(
  "user-profile/house-rules/restricted-foods",
  async ({ axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(FOODRESTRICTIONSAPI + '?restricted=true', axiosInstance);
     
      const responseData = {
        foodItem: response.data.data,
      };
      // console.log('food Restriction', responseData);

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);