import { useEffect } from "react";
import { hideAlertMessage, showAlertMessage } from "../../store/alert/alert-slice";
import { useAppDispatch, useAppSelector } from "../../store/store"

export const useAppAlert = (props: any = null) => {

  const { alert } = useAppSelector((state) => state)
  const dispatch = useAppDispatch();

  useEffect(() => {
    const alertTimer = setTimeout(() => {
      dispatch(hideAlertMessage())
    }, 3000);
    return () => clearTimeout(alertTimer);
  }, [dispatch]);

  const closeAlert = () => dispatch(hideAlertMessage())

  const openAlert = (alertMessage: any, alertType: any = 'alert-error-message') => {
    
    dispatch(showAlertMessage({
      alertMessage,
      alertType
    }))

  }

  return { openAlert, alert, closeAlert }
}