import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../../store/store";

// Images
import { cuisineInfo, hostInfo } from "../../../../../../mock-data/food-preferences";
import { searchFieldData } from "./location-search-data";
import { useNavigate } from "react-router-dom";
import { FOODPREFERENCEPATH, FOODPREFERENCESEARCHPATH } from "../../../../../../constants/routes-constant";
import { groupBy } from "../../../../../../utils/hooks-utils";
import { SelectChangeEvent } from "@mui/material";
import { setCuisineForSearch, setHostForSearch} from "../../../../../../store/food-preference/food-preference-slice";
import { useAppAlert } from "../../../../../../components/app-alert/use-app-alert";
import { searchFoodPreferencesByHostThunk } from "../../../../../../store/food-preference/food-preference-thunk-actions";
import { useAxiosInterceptors } from "../../../../../../hooks/use-axios-interceptors";


export const useLocationSearch = (props: any) => {

  const { 
    locationValue, setLocationValue, showLocation, setOpen, setShowLocation, open,
    showCuisine, setShowCuisine, showHost, setShowHost ,
    // cuisineIn, setCuisineIn,cuisineData, setCuisineData,cuisine, setCuisine,hostIn, sethostIn,hostName, setHostName
  } = props;
const {foodPreference} = useAppSelector(state => state)
  const [cuisineIn, setCuisineIn] = useState<string[]>([]);
  const [cuisineData, setCuisineData] = useState<any>([])
  const [cuisine, setCuisine] = useState<any>([]);
  const [hostIn, sethostIn] = useState<any>(0);
  const [hostName, setHostName] = useState('')
  const navigate = useNavigate()
  const [cuisineLimit, setCuisineLimit] = useState(10);
  const { referenceData } = useAppSelector(state => state)
  const dispatch = useAppDispatch();
  const  { openAlert } = useAppAlert();
  const axiosInstance = useAxiosInterceptors();
  // const { locationData } = useAppSelector((state) => state.dashboard);

  // Handle Change
  const handleLocationChange = (event: SelectChangeEvent) => {
    setLocationValue(event.target.value as string);
    // console.log(event.target.value)
    navigate(`${FOODPREFERENCEPATH}/${event.target.value}`)
  };
  // console.log(showCuisine, 'showCuisine'); 
  // console.log(cuisine, 'cuisine');
  useEffect(() => {

    if (locationValue === 'Cuisine') {
      setShowCuisine(!showCuisine);
      setShowLocation(false)
    }
    if (locationValue === 'Host') {
      setShowHost(!showHost)
      setShowLocation(false)
    }
  }, [locationValue])


  const handleCuisineBtn = (event: any, item1: any) => {
    let findArr = cuisineData.find((ele: any) => ele.countryName === event);
    if (findArr) {
      let el = cuisineData.filter((ele: any) => ele.countryName !== event);
      setCuisineData(el);
      let fl = cuisineIn.filter((ele: any) => ele !== event);
      setCuisineIn(fl);
    } else {
      setCuisineData((item: any) => [
        ...item,
        { cuisineName: item1, countryName: event }
      ])
      setCuisineIn((item: any) => [
        ...item, event
      ]);
    }
    // console.log(cuisineIn)
  }
  dispatch(setCuisineForSearch(cuisineIn))
  const handleHostBtn = (event: any) => {
    // console.log(event)
    sethostIn(event) // for single selection
    dispatch(setHostForSearch({
      rating:event,
      name:foodPreference?.hostForSearch?.name
    }))
    // let findArr = hostIn.find((ele: any) => ele === event);
    // if (findArr) {
    //   let el = hostIn.filter((ele: any) => ele !== event);
    //   sethostIn(el);
    // } else {
    //   sethostIn((item: any) => [
    //     ...item, event
    //   ]);
    // }

  }
  // console.log(cuisineData,'cuisineData');
  const cusinesByCategory = groupBy(referenceData.cuisines, 'category')
  const cuisineArray = Object?.entries(cusinesByCategory || {})?.map(([category, cuisine]: any) => ({ category, cuisine }))
  // console.log(cusinesByCategory)
  // console.log(cuisineArray)

  const handleCuisineLimit = () => {
    setCuisineLimit(cuisineLimit === 10 ? cuisine?.cuisine?.length : 10)
  }

  const setHostInfoForSearch = () => {
    if(hostName === "") return openAlert("select host name") 
    // if(hostIn === 0) return openAlert("select host rating")   // if optional
    const host = {
      name: hostName,
      rating: hostIn,
      // ...(hostIn !== 0 && {rating: hostIn})

    }
    // console.log({ host })
    dispatch(setHostForSearch(host))
    const searchQueryHost = {
      host:  {
        name: hostName,
        rating: hostIn,
        // ...(hostIn !== 0 && {rating: hostIn})
      },
      date: {
        date: foodPreference?.dateForSearch,
        time: foodPreference?.timeForSearch,
        // ...(foodPreference?.timeForSearch !== "" && {time: foodPreference?.timeForSearch})

      },
      filter: foodPreference.filters,
      guests: {
        noofGuests: foodPreference?.numberOfGuests
        // ...(foodPreference?.numberOfGuests !== 0 && {noofGuests: foodPreference?.numberOfGuests})
      }
    }
    // console.log(!!searchQueryHost?.date)
    // console.log(searchQueryHost?.date)
    if(!!!searchQueryHost?.host?.name) return openAlert('Select host first');
    if(!!!searchQueryHost?.date?.date) return openAlert('Select date first');  // if optional 
    if(!!!searchQueryHost?.date?.time) return openAlert('Select time first');   // if optional
    if(!!!searchQueryHost?.guests?.noofGuests) return openAlert('Select guests first');   // if optional
    dispatch(searchFoodPreferencesByHostThunk({hostSearchParameters:searchQueryHost, axiosInstance , openAlert}))
    // openAlert('Go ahead')
  }

  return {
    cusinesByCategory, cuisineArray, handleHostBtn, handleCuisineBtn, handleLocationChange, cuisineIn, setCuisineIn,
    cuisineData, setCuisineData, cuisine, setCuisine, hostIn, sethostIn, navigate, referenceData, cuisineLimit, setCuisineLimit,
    locationValue, setLocationValue, showLocation, setOpen, setShowLocation, open, showCuisine, setShowCuisine,
    showHost, setShowHost, handleCuisineLimit, hostName, setHostName, setHostInfoForSearch, dispatch , setHostForSearch,foodPreference
  }

}