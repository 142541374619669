
// Ribbons

import { createAsyncThunk } from "@reduxjs/toolkit";
import { BADGESAPI } from "../../constants/axios-api-constant";
import { postDataAPI } from "../../lib/axios";
import { updatePersonalUserProfileStatusThunk } from "../personal-user/personal-user-thunk-actions";


export const badgesPostThunk = createAsyncThunk("badges/post",
  async ({ badgesPayload, openAlert, axiosInstance , dispatch , personalUser, setValue }: any, { rejectWithValue }) => {
    try {
      const response = await postDataAPI(BADGESAPI, axiosInstance, badgesPayload);
      const responseData = {
        message: response.data.message,
      };
      if (!personalUser?.profileCompletion?.badges?.completed) {
        // console.log('incomplete badge')
        dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'badges', openAlert }))
      }
      setValue(7)
      openAlert(responseData.message, 'alert-success-message')

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || ' server is not responding'
      }
      openAlert(errorData?.error || 'server is not responding')

      return rejectWithValue(errorData)
    }
  }
)