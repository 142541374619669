import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';

import { IAPPLOADERPROPS } from './app-loader-interface';
import { MUIHOVERCOLOR } from '../../constants/mui-styles-constant';

const AppLoader: React.FC<IAPPLOADERPROPS> = (props) => {

    const { message } = props;

    return (

        <div className="center-text">
            <h2
                className='secondary-heading secondary-color font-weight-600'>
                {message || 'Please wait! while processing your request'}
            </h2>
            <LinearProgress variant="indeterminate" className='bg-primary-color' sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: MUIHOVERCOLOR
                  }
                }} />
        </div>
    )
}
export default AppLoader;