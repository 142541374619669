// MUI
import Drawer from "@mui/material/Drawer";

// Components
import Sidebar from "../sidebar";

// SCSS
import "./sidebar-mobile.scss";

// MUI
import { Grid } from "@mui/material";

// Custom Hook
import { useSidebarMobile } from "./use-sidebar-mobile";

// MUI Styles
import { mobSidebarDrawer } from "./sidebar-mobile-mui-style";
import { setMobDrawer } from "../../../store/layout/layout-slice";

// Component Function Starts Here
const HeaderMobile = () => {
  const { mobDrawer, dispatch } = useSidebarMobile()

  return (
    <>
      <Drawer
        anchor={"left"}
        open={mobDrawer}
        onClose={() => dispatch(setMobDrawer(false))}
        sx={mobSidebarDrawer}
      >
        <Grid className='sidebar-mob-scroll'>
          <Sidebar />
        </Grid>
      </Drawer>
    </>
  );
};

export default HeaderMobile;
