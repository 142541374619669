import { useEffect, useState } from "react";

// MUI
import { Badge, Modal, Grid } from "@mui/material";

// Images
import doubleLeftArrowImage from "../../../../../../assets/images/food-preferences/header/double-arrow-left.png";
import doubleRightArrowImage from "../../../../../../assets/images/food-preferences/header/right-double-arrow.png"
import boostListingImage from "../../../../../../assets/images/food-preferences/header/boost-listing.png";
import bgStar from "../../../../../../assets/images/food-preferences/toggler/bg-star.png";
import CircleIcon from '@mui/icons-material/Circle';
// SCSS
import "./toggler.scss";
import { getBookingsListThunk,confirmBookingRequestThunk } from "../../../../../../store/booking/booking-thunk-action";
import { useAxiosInterceptors } from "../../../../../../hooks/use-axios-interceptors";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import dayjs from "dayjs";
import { changeTimeTo12HourFormat } from "../../../../../bookings/common/collapsable-table/collapsable-table";
import { BADGE, DOTANIMATIONANDSTYLE } from "./toggler-mui-style";
import { useAppAlert } from "../../../../../../components/app-alert/use-app-alert";

// BadgeStyle
const shapeStyles = {
  bgcolor: "#3C3C3C",
  width: 50,
  height: 70,
  textAlign: "center",
  borderRadius: "13px",
};
// Component Function Starts Here
const Toggler: React.FC<any> = () => {

  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInterceptors();
  const { openAlert } = useAppAlert();

  const { bookingsData } = useAppSelector((state)=> state.booking)
  const [displayBlock, setDisplayBlock] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const CongratulationModalOpen = (id:string) =>{
    dispatch(confirmBookingRequestThunk({axiosInstance,setOpen,bookingId:id,dispatch,openAlert}))
  } 
  const congratulationModalClose = () => setOpen(false);
  useEffect(() => {
    dispatch(getBookingsListThunk({ axiosInstance, limit: 1, offset: 0,status: 'ACCEPTED',search: '', from: '', to: '' }));
  }, [])
  
  return (
    <Grid className="toggler">
      <div className="header-toggler flex justify-center">
        <Badge
          color="success"
          badgeContent={!!bookingsData.length && <CircleIcon  sx={DOTANIMATIONANDSTYLE}/>}
          sx={BADGE}
          className="toggler-badge"
          onMouseOver={() => setDisplayBlock(true)}
          onMouseOut={() => setDisplayBlock(false)}
          
        >
          <Grid
            component="span"
            sx={shapeStyles}
            padding="0.5em"
            className="show-upcoming-bookings"

          >
            <img
              src={doubleLeftArrowImage}
              alt={doubleLeftArrowImage}
              className="arrow-img"
              onClick={() => {
                setNotificationData(true);
                setDisplayBlock(true);
              }}
            />
            <img src={boostListingImage} alt={boostListingImage} />
            {displayBlock && (
              <p className={`hover-bookings`}>Up-coming Bookings</p>
            )}
          </Grid>
        </Badge>
        <div></div>
      </div>

      {(notificationData && !!bookingsData.length) && (
        <div className="header-toggler header-toggler-two flex justify-center">
          <Badge
            color="success"
            badgeContent=""
            variant="dot"
            invisible={!!bookingsData.length}
            sx={BADGE}
            className="toggler-badge toggler-badge-two">
            <Grid
              sx={shapeStyles}
              padding="0.5em"
            >
              <img
                src={doubleRightArrowImage}
                alt={'arrow-img'}
                className="arrow-img"
                onClick={() => {
                  setNotificationData(false);
                }}
              />
              <img src={boostListingImage} alt={boostListingImage} />
              <div className="" >
                <div className="upcoming-bookings">
                  <div className="upcoming-bookings-inner">
                    <ul className="bookings-heading">
                      <li>Host Name</li>
                      <li>Menu</li>
                      <li>Date</li>
                      <li>Time</li>
                    </ul>
                    <ul className="bookings-heading-data">
                      <li>{`${bookingsData[0].host?.firstName} ${bookingsData[0].host?.lastName}`}</li>
                      <li>{bookingsData[0].listingTitle}</li>
                      <li>{dayjs(bookingsData[0].selectedTiming.date).format("MMMM DD, YYYY")}</li>
                      <li>{bookingsData[0].selectedTiming.timeRange.startTime==="Full Day"?'Full Day': 
                      changeTimeTo12HourFormat(bookingsData[0].selectedTiming.timeRange.startTime)}</li>
                    </ul>
                  </div>
                  <button
                    type="button"
                    className="orange-btn confirm-booking-btn"
                    onClick={()=>CongratulationModalOpen(bookingsData[0]._id)}
                  >
                    Confirm Bookings
                  </button>
                </div>
              </div>
              <p className="hover-bookings">Up-coming Bookings</p>
            </Grid>
          </Badge>
          <div>
            <Modal
              open={open}
              onClose={congratulationModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="confirmBooking">
                <h1>Congratulation</h1>
                <img src={bgStar} className="bgStar" />
                <p>Your booking request is submitted successfully.</p>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </Grid>
  );
};
export default Toggler;
