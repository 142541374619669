import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { deleteKitchenDetailsImageThunkFulfilledReducer, deleteKitchenDetailsImageThunkPendingReducer, deleteKitchenDetailsImageThunkRejectedReducer, getKitchenDetailsThunkFulfilledReducer, getKitchenDetailsThunkPendingReducer, getKitchenDetailsThunkRejectedReducer, getKitchenLocationThunkFulfilledReducer, getKitchenLocationThunkRejectedReducer, getSelectedKitchenLocationDetailsThunkFulfilledReducer, getSelectedKitchenLocationDetailsThunkRejectedReducer, postKitchenDetailsThunkFulfilledReducer, postKitchenDetailsThunkRejectedReducer, saveKitchenDetailsReducers, ThunkPendingReducer } from "./kitchen-reducers";
import { IKITCHENSLICE } from "./kitchen-slice-interface";
import { deleteKitchenDetailsImageThunk, getKitchenDetailsThunk, getKitchenLocationThunk, getSelectedKitchenLocationDetailsThunk, postKitchenDetailsThunk } from "./kitchen-thunk-actions";

const kitchenInitialState = {
    status: REQUESTSTATUS.IDLE,
    getKitchenStatus:REQUESTSTATUS.IDLE,
    deleteKitchenImageStatus:REQUESTSTATUS.IDLE,
    kitchenLocations: [] as any,
    kitchenId: "",
    coordinates: {} as any,
    kitchenDetails: {} as any,
    houseRules: {} as any,
    veriffStatus: {
        id: "",
        attemptId: "",
        feature: "",
        code: 0,
        action: "",
        vendorData: "",
        date: ""
    },
    badgesId: [] as any,
    dbsCheck: {
        file: "",
        status: true
    },
    myFacilities: {} as any,
    success: '',
    error: ''
};

const kitchenSlice = createSlice({
    name: "kitchen",
    initialState: kitchenInitialState,
    reducers: {
        saveKitchenDetails: saveKitchenDetailsReducers,
    },
    extraReducers(builder) {

        getKitchenDetailsThunkPendingReducer(builder, getKitchenDetailsThunk)
        getKitchenDetailsThunkFulfilledReducer(builder, getKitchenDetailsThunk)
        getKitchenDetailsThunkRejectedReducer(builder, getKitchenDetailsThunk)

        ThunkPendingReducer(builder, postKitchenDetailsThunk)
        postKitchenDetailsThunkFulfilledReducer(builder, postKitchenDetailsThunk)
        postKitchenDetailsThunkRejectedReducer(builder, postKitchenDetailsThunk)

        ThunkPendingReducer(builder, getKitchenLocationThunk)
        getKitchenLocationThunkFulfilledReducer(builder, getKitchenLocationThunk)
        getKitchenLocationThunkRejectedReducer(builder, getKitchenLocationThunk)

        ThunkPendingReducer(builder, getSelectedKitchenLocationDetailsThunk)
        getSelectedKitchenLocationDetailsThunkFulfilledReducer(builder, getSelectedKitchenLocationDetailsThunk)
        getSelectedKitchenLocationDetailsThunkRejectedReducer(builder, getSelectedKitchenLocationDetailsThunk)


        deleteKitchenDetailsImageThunkPendingReducer(builder, deleteKitchenDetailsImageThunk)
        deleteKitchenDetailsImageThunkFulfilledReducer(builder, deleteKitchenDetailsImageThunk)
        deleteKitchenDetailsImageThunkRejectedReducer(builder, deleteKitchenDetailsImageThunk)

    }

});

export const kitchenTabAction = kitchenSlice.actions;
export default kitchenSlice.reducer;
