export const SELECTPAPER = {
  marginTop: 0.3,
  maxHeight: "116px",
  background: "#FFFFFF",
  border: "1px solid #FF611D",
  boxShadow: " 0px 4px 6px rgba(0, 0, 0, 0.15)",
  borderRadius: "6px",
  overflowX: "hidden",
  position: "relative",
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor:"#FF611D"
  },
  "&::-webkit-scrollbar": {
    width: "4px",
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "1rem",
    backgroundColor: "#FF611D",
  },
  "& ul": {
    p: 0,
  },
  "& li": {
    fontSize: "12px",
  },
  "& .MuiMenuItem-root:hover": {
    backgroundColor: "transparent",
  },
}

export const BADGESELECTADDNEWMENUITEM = {
  bgcolor: "#EBEAEA !important",
  p: 1,
}
export const SPICELEVELMENUITEM = {
  border: "1px solid #747070",
  color: "#747070",
  borderRadius: "5px",
}
export const BADGESELECTIMAGE = { marginLeft: "12px" }
export const AGERESTRICTIONRADIO = { mt: 1.3 }
export const BADGESELECT = { display: "flex"}
export const AVAILABLETEXTCOLOR = (value: string) => {
  // console.log(value)
  return {
    color: value === "available" ? "#659C35 !important" : "red !important",
  }
}
export const TEXTCOLOR = (value: string) => {
  // console.log(value)
  return {
    input: {color:  "#ffffff !important"},
  }
}
export const AVAILABLESELECTCOLOR = (value: string, selectcolor:string) => {

  return {
    color: value && `${selectcolor}`,
  }
}
export const SPICECOLORANDBACKGROUND = (value: string, spiceLevelElement:string,spiceColor:string) => {
  return {
    background: `${ value === spiceLevelElement && spiceColor }`,
    "&:hover": {
      background:
        spiceColor,
      color: "white !important",
    },
    color: `${ value === spiceLevelElement && "white !important" }`,
  }
}


export const upsertAddFoodItemFormGrid = { 
  overflow: "none", 
  px: 3, 
  py: 5 
}