import { createSlice } from "@reduxjs/toolkit";
import {  houseRulesThunkPendingReducer, houseRulesThunkFulfilledReducer, houseRulesThunkRejectedReducer } from "./house-rules-reducer";
import { getHouseRuleDataThunk } from "./house-rules-thunk-actions";

const initialState: any = {
  houseRules:[]
    };

export const houseRuleDataSlice = createSlice({
  name: "house-rules",
  initialState,
  reducers: { },
  extraReducers(builder) {
    houseRulesThunkPendingReducer(builder, getHouseRuleDataThunk)
    houseRulesThunkFulfilledReducer(builder, getHouseRuleDataThunk)
    houseRulesThunkRejectedReducer(builder, getHouseRuleDataThunk)
  }
});


export const selectRibbons = (state: any) => state?.ribbons;

export default houseRuleDataSlice.reducer;