import { TableCell, TableHead, TableBody, TableRow, Avatar } from "@mui/material";
import dayjs from "dayjs";
import { StatusChangeMenu } from "../status-change-menu/status-change-menu";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { statusTableHeader } from "../../my-bookings-list/my-booking-list-data";
import React from "react";
import { ICOLAPSABLETABLE } from "./collapsable-table-interface";
import { IBOOKING } from "../../../../store/booking/booking-slice-interface";
import "./collapsable-table.scss";
import { IMAGEBASEURL } from "../../../../constants/axios-api-constant";
import { useCollapseTable } from "./use-collapsable-table";

export const changeTimeTo12HourFormat = (time: string) => {
  return dayjs('1/1/1 ' + time).format('hh:mm A');
}
export const CollapsableTable: React.FC<ICOLAPSABLETABLE> = (props: ICOLAPSABLETABLE) => {
  const { ISGUEST, SetTimeBookingCollapse, createdDate, bookingData, expandTable, handleOpenBookingDetailOrRejectionModal, index, randomColor, setexpandTable,IS_SUPERADMIN } = useCollapseTable(props);
  return (
    <>
      <TableHead>
        <TableRow sx={ { borderBottom: 0}}>
          <TableCell color="red" sx={{ color: randomColor[index], width: "200px ", fontSize: { lg: "14px", xs: "12px", }, pl: 0 }}>
            <div className="flex align-center">
              <ExpandCircleDownIcon onClick={() => setexpandTable(!expandTable)} fontSize="small"
                sx={{ mr: 1, rotate: !expandTable ? '-90deg' : '0deg', transition: 'rotate .1s linear' }} className='cursor-pointer' />
              <div>
                {createdDate}
              </div>
            </div>
          </TableCell>
          {statusTableHeader.map((header: any) => (
            <TableCell sx={{ p: "0px", fontSize: { lg: "14px", xs: "12px" }, width: "150px", }} className="font-weight-400 dark-gray-color" align="center" key={header.id}>
              {(ISGUEST && header.headername === 'Guest') ? 'Host' : header.headername}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {expandTable && (
        <TableBody className="collapseable-table-body">
          {bookingData.map((booking: IBOOKING) => (
            <TableRow key={booking._id}  sx={ { borderBottom: 0}}>
              {
                Array.from([booking.listingTitle, booking.bookingId, booking[ISGUEST ? 'host' : 'guest']?.profileImage,
                `${booking[ISGUEST ? 'host' : 'guest']?.firstName} ${booking[ISGUEST ? 'host' : 'guest']?.lastName}`,
                `${
                 booking.selectedTiming.timeRange.startTime!=='Full Day' ? changeTimeTo12HourFormat(booking.selectedTiming.timeRange.startTime):'Full Day'} 
                  ${
                   booking.selectedTiming.timeRange.startTime!=='Full Day' ? `to ${changeTimeTo12HourFormat(booking.selectedTiming.timeRange.endTime)}`:''}`,
                Object.values(booking.noOfGuests).reduce((a, b) => a + b, 0), booking[ISGUEST ? 'host' : 'guest']?.email, booking.totalPrice]).map((item, id) => {
                  return ( 
                    <React.Fragment key={id}>
                      <TableCell
                        {...(id === 0 && {
                          onClick: () => { handleOpenBookingDetailOrRejectionModal(booking, "booking-details"); }, className: "cursor-pointer listing-name",
                          sx: {"&:hover": { background: "#ff611d33" } },
                        })}
                        {...(id === 2 && { className: "flex justify-center" })} size="small" align={id === 0 ? "left" : "center"}>
                        {
                          id === 2 ? (<Avatar className="user-profile-img border-radius-rounded" src={`${IMAGEBASEURL}${item}` as string} />) :
                            (<span className={`selected-field-color tertiary-title ${!!(id === 0) && "link-color"}`}>
                              {item}
                            </span>)
                        }
                      </TableCell>
                    </React.Fragment>
                  );
                })
              }
              <TableCell size="small" align="center">
                <div>
                  <StatusChangeMenu IS_SUPERADMIN={IS_SUPERADMIN} SetTimeBookingCollapse={SetTimeBookingCollapse} rejectionBookingStatus={() => { handleOpenBookingDetailOrRejectionModal(booking, "rejection-modal") }}
                    status={booking.bookingStatus} bookingId={booking._id} />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </>
  );
};
