export const findMoreInfo: any[] = [
    {
        value: 1,
        findHeadName: 'Facilities',
        key: 'facilities',
        findHead: [
            {
                value: 1,
                _id:"635bc9e2ebbb9193e18c3bb3",
                optionName: "Paid Parking",
            },
            {
                value: 2,
                _id: "635bc9e2ebbb9193e18c3bb8",
                optionName: 'Air Conditioner',
            },
            {
                value: 3,
                _id: "635bc9e2ebbb9193e18c3bb4",
                optionName: "Free Wifi",
            },
            {
                value: 4,
                _id: "635bc9e2ebbb9193e18c3bb5",
                optionName: "Indoor Dining",
            },
            {
                value: 5,
                _id: "635bc9e2ebbb9193e18c3bc5",
                optionName: 'Outdoor Space',
            },
            {
                value: 6,
                _id: "635bc9e2ebbb9193e18c3bb2",
                optionName: 'Free Parking',
            },
            
        ]
    },
    {
        value: 2,
        findHeadName: 'Facilities for disables',
        key:"facilitiesForDisables",
        findHead: [
            {
                value: 1,
                _id: "635bc9e2ebbb9193e18c3bba",
                optionName: "Wheel Chair",
            },
            {
                value: 2,
                _id: "635bc9e2ebbb9193e18c3bbb",
                optionName: "Lift/ Excalator ",
            },
            {
                value: 3,
                _id: "635bc9e2ebbb9193e18c3bbc",
                optionName: "Ramp",
            },
            {
                value: 4,
                _id: "635bc9e2ebbb9193e18c3bbd",
                optionName: "Hand Rail ",
            },
        ]
    },
    {
        value: 3,
        findHeadName: 'Facilities for Children',
        key:"facilitiesForChildren",
        findHead: [
            {
                value: 1,
                _id: "635bc9e2ebbb9193e18c3bbe",
                optionName: "High Chair",
            },
            {
                value: 2,
                _id: "635bc9e2ebbb9193e18c3bc0",
                optionName: "Play Area",
            },
            {
                value: 3,
                _id: "635bc9e2ebbb9193e18c3bc1",
                optionName: "Baby Books",
            },
            {
                value: 4,
                _id: "635bc9e2ebbb9193e18c3bbf",
                optionName: "Baby Toys",
            },
        ]
    },
    {
        value: 4,
        findHeadName: 'House Rules',
        key:"houseRules",
        findHead: [
            {
                value: 1,
                _id:"foodRestrictionApplied",
                optionName: 'Food Restrictions',
            },
            {
                value: 2,
                _id:"petsRestrictionApplied",
                optionName: 'Pets Restricted',
            },
            {
                value: 3,
                _id:"dressCodeRestrictionApplied",
                optionName: 'Dress Code Restricted',
            },
            {
                value: 3,
                _id:"smokingRestrictionApplied",
                optionName: 'Smoking Restriction',
            },
        ]
    },
    {
        value: 5,
        findHeadName: 'Host Language Proficiences',
        key:"hostLanguageProficiences",
        findHead: [
            {
                value: 1,
                _id:"English",
                optionName: 'English',
            },
            {
                value: 2,
                _id: 'Korean',
                optionName: 'Korean',
            },
            {
                value: 3,
                _id: 'Turkish',
                optionName: 'Turkish',
            },
            {
                value: 1,
                _id:"Arabic",
                optionName: 'Arabic',
            },
            {
                value: 2,
                _id: 'French',
                optionName: 'French',
            },
            {
                value: 3,
                _id: 'Spanish',
                optionName: 'Spanish',
            },
        ]
    },

]