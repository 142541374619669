import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { nutricsInitialForFooodFormValues } from "../../features/food/upsert-food-item/upsert-food-item-data";
import { addFoodItemThunkFulfilledReducer, addFoodItemThunkPendingReducer, addFoodItemThunkRejectedReducer, addNewIngredientsThunkFulfilledReducer, addNewIngredientsThunkPendingReducer, addNewIngredientsThunkRejectedReducer, deleteFoodItemImageThunkFulfilledReducer, deleteFoodItemImageThunkPendingReducer, deleteFoodItemImageThunkRejectedReducer, deleteFoodItemThunkFulfilledReducer, deleteFoodItemThunkRejectedReducer, editFoodItemThunkFulfilledReducer, editFoodItemThunkPendingReducer, editFoodItemThunkRejectedReducer, foodThunkPendingReducer, getAllIngredientsThunkFulfilledReducer, getAllIngredientsThunkPendingReducer, getAllIngredientsThunkRejectedReducer, getAllRecipesThunkFulfilledReducer, getAllRecipesThunkPendingReducer, getAllRecipesThunkRejectedReducer, getNutritionsInfoThunkFulfilledReducer, getNutritionsInfoThunkPendingReducer, getNutritionsInfoThunkRejectedReducer, getSingleFoodItemThunkFulfilledReducer, getSingleFoodItemThunkRejectedReducer, getsingleMenuFoodItemsThunkFulfilledReducer, getsingleMenuFoodItemsThunkRejectedReducer, setIdToDeleteFoodReducer } from "./food-reducers";
import { addFoodItemThunk, addNewIngredientsThunk, deleteFoodItemImageThunk, deleteFoodItemThunk, editFoodItemThunk, getAllIngredientsThunk, getAllRecipesThunk, getNutritionsInfoThunk, getSingleFoodItemThunk, getsingleMenuFoodItemsThunk } from "./food-thunk-actions";

const foodInitialState :any = {

    status: REQUESTSTATUS.IDLE,
    upsertStatus: REQUESTSTATUS.IDLE,
    error: '',
    success: '',
    message: '',
    singleMenuFoodItem: {} as any,
    singleFoodItem: {} as any,
    selectedItemCategory: {} as any,
    id: '',
    nutrics:nutricsInitialForFooodFormValues,
    nutricsStatus:REQUESTSTATUS.IDLE,
    ingredientsStatus:REQUESTSTATUS.IDLE,
    addIngredientsStatus:REQUESTSTATUS.IDLE,
    allIngredients:[] as any,
    recipesStatus:REQUESTSTATUS.IDLE,
    deleteFoodItemImageStatus:REQUESTSTATUS.IDLE,
    allRecipes:[] as any,
}

const authSlice = createSlice({

    name: 'food',
    initialState: foodInitialState,
    reducers: {
        setIdToDeleteFood:setIdToDeleteFoodReducer
    },
    extraReducers(builder) {
        // getsingleMenuFoodItem
        foodThunkPendingReducer(builder, getsingleMenuFoodItemsThunk)
        getsingleMenuFoodItemsThunkRejectedReducer(builder, getsingleMenuFoodItemsThunk)
        getsingleMenuFoodItemsThunkFulfilledReducer(builder, getsingleMenuFoodItemsThunk)

        // getsingleFoodItem
        foodThunkPendingReducer(builder, getSingleFoodItemThunk)
        getSingleFoodItemThunkRejectedReducer(builder, getSingleFoodItemThunk)
        getSingleFoodItemThunkFulfilledReducer(builder, getSingleFoodItemThunk)

        // add food item
        addFoodItemThunkPendingReducer(builder, addFoodItemThunk)
        addFoodItemThunkRejectedReducer(builder, addFoodItemThunk)
        addFoodItemThunkFulfilledReducer(builder, addFoodItemThunk)

        //delete food item
        foodThunkPendingReducer(builder, deleteFoodItemThunk)
        deleteFoodItemThunkRejectedReducer(builder, deleteFoodItemThunk)
        deleteFoodItemThunkFulfilledReducer(builder, deleteFoodItemThunk)

        // edit fod item
        editFoodItemThunkPendingReducer(builder, editFoodItemThunk)
        editFoodItemThunkRejectedReducer(builder, editFoodItemThunk)
        editFoodItemThunkFulfilledReducer(builder, editFoodItemThunk)

        //nutics info 
        getNutritionsInfoThunkPendingReducer(builder, getNutritionsInfoThunk)
        getNutritionsInfoThunkRejectedReducer(builder, getNutritionsInfoThunk)
        getNutritionsInfoThunkFulfilledReducer(builder, getNutritionsInfoThunk)

        //all ingredients 
        getAllIngredientsThunkPendingReducer(builder, getAllIngredientsThunk)
        getAllIngredientsThunkRejectedReducer(builder, getAllIngredientsThunk)
        getAllIngredientsThunkFulfilledReducer(builder, getAllIngredientsThunk)

        //all recipes
        getAllRecipesThunkPendingReducer(builder, getAllRecipesThunk)
        getAllRecipesThunkRejectedReducer(builder, getAllRecipesThunk)
        getAllRecipesThunkFulfilledReducer(builder, getAllRecipesThunk)

        //add new ingredients 
        addNewIngredientsThunkPendingReducer(builder, addNewIngredientsThunk)
        addNewIngredientsThunkRejectedReducer(builder, addNewIngredientsThunk)
        addNewIngredientsThunkFulfilledReducer(builder, addNewIngredientsThunk)

        
        deleteFoodItemImageThunkPendingReducer(builder, deleteFoodItemImageThunk)
        deleteFoodItemImageThunkFulfilledReducer(builder, deleteFoodItemImageThunk)
        deleteFoodItemImageThunkRejectedReducer(builder, deleteFoodItemImageThunk)


    }
})

export const { setIdToDeleteFood } = authSlice.actions;

export default authSlice.reducer;