import { createSlice } from "@reduxjs/toolkit";
import { ADDRECIPEFORMICDATA, nutricsInitialValues } from "../../features/recipes/upsert-recipe/recipe-lists-form-data";
import {
  addNewRecipeRejectedReducer, addNewRecipeSuccessReducer, addNewRecipePendingReducer, getIngredientsPendingReducer,
  getRecipePendingReducer, getRecipeSuccessReducer, getRecipeRejectedReducer, getIngredientsSuccessReducer,
  getIngredientsRejectedReducer, clearEditRecipeData, getRecipesForTableRejectedReducer, getRecipesForTableSuccessReducer,
  getRecipesForTablePendingReducer, deleteRecipesRejectedReducer, deleteRecipesPendingReducer, deleteRecipesSuccessReducer,
  editRecipesRejectedReducer, editRecipesSuccessReducer, editRecipesPendingReducer, postIngredientRejectedReducer,
  postIngredientSuccessReducer, postIngredientPendingReducer, getNutritionsRejectedReducer,
  getNutritionsSuccessReducer, getNutritionsPendingReducer, deleteRecipeImageRejectedReducer, deleteRecipeImageSuccessReducer,
  deleteRecipeImagePendingReducer
} from "./recipe-reducers";
import {
  addNewRecipeThunk, deleteRecipeImageThunk, deleteRecipesThunk, editRecipeByIdThunk,
  getIngredientsThunk, getNitritionsInfoThunk, getRecipeByIdThunk, getRecipesForTableThunk,
  postIngredientThunk
} from "./recipe-thunk-actions";

const recipeInitialState = {
  addRecipeFormData: ADDRECIPEFORMICDATA,
  recipeToEditData: [],
  ingredients: [],
  allRecipes: [],
  nutrics: nutricsInitialValues

};

const recipeSlice = createSlice({
  name: "recipe",
  initialState: recipeInitialState,
  reducers: {
    clearRecipeFormData: clearEditRecipeData

  },
  extraReducers(builder) {
    getIngredientsPendingReducer(builder, getIngredientsThunk)
    getIngredientsSuccessReducer(builder, getIngredientsThunk)
    getIngredientsRejectedReducer(builder, getIngredientsThunk)

    postIngredientRejectedReducer(builder, postIngredientThunk)
    postIngredientSuccessReducer(builder, postIngredientThunk)
    postIngredientPendingReducer(builder, postIngredientThunk)

    addNewRecipePendingReducer(builder, addNewRecipeThunk)
    addNewRecipeSuccessReducer(builder, addNewRecipeThunk)
    addNewRecipeRejectedReducer(builder, addNewRecipeThunk)

    getRecipePendingReducer(builder, getRecipeByIdThunk)
    getRecipeSuccessReducer(builder, getRecipeByIdThunk)
    getRecipeRejectedReducer(builder, getRecipeByIdThunk)


    getRecipesForTableRejectedReducer(builder, getRecipesForTableThunk)
    getRecipesForTableSuccessReducer(builder, getRecipesForTableThunk)
    getRecipesForTablePendingReducer(builder, getRecipesForTableThunk)

    deleteRecipesRejectedReducer(builder, deleteRecipesThunk)
    deleteRecipesPendingReducer(builder, deleteRecipesThunk)
    deleteRecipesSuccessReducer(builder, deleteRecipesThunk)

    editRecipesRejectedReducer(builder, editRecipeByIdThunk)
    editRecipesSuccessReducer(builder, editRecipeByIdThunk)
    editRecipesPendingReducer(builder, editRecipeByIdThunk)

    getNutritionsRejectedReducer(builder, getNitritionsInfoThunk)
    getNutritionsSuccessReducer(builder, getNitritionsInfoThunk)
    getNutritionsPendingReducer(builder, getNitritionsInfoThunk)

    deleteRecipeImageRejectedReducer(builder, deleteRecipeImageThunk)
    deleteRecipeImageSuccessReducer(builder, deleteRecipeImageThunk)
    deleteRecipeImagePendingReducer(builder, deleteRecipeImageThunk)
  }
});

export const { clearRecipeFormData } = recipeSlice.actions;

export default recipeSlice.reducer;
