import { createAsyncThunk } from "@reduxjs/toolkit";
import { COMPLETETRAININGAPI, TRAININGSAPI } from "../../constants/axios-api-constant";
import { getDataAPI, postDataAPI } from "../../lib/axios";
import { personalUsertrainingDataUpdate } from "../personal-user/personal-user-slice";
import { updatePersonalUserProfileStatusThunk } from "../personal-user/personal-user-thunk-actions";

export const trainingDataThunk = createAsyncThunk('training/user-trainings',
    async ({axiosInstance,dispatch, personalUser, openAlert}:any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(TRAININGSAPI, axiosInstance)
            // console.log(response)
            const responseData = {
                mandatory: response.data.mandotary,
                optional: response.data.optional,
                total: response.data.totals,
            }
            const formatData = response?.data
            const responseData2 = {
              trainingsDetails: {
                trainingDetails: [
                  {
                    id: 0,
                    trainingData: formatData?.mandotary
                  },
                  {
                    id: 1,
                    trainingData: formatData?.optional
                  }
                ],
                totalTrainingsDetail: formatData?.totals
              },
            };
            const {mandotary , optional } = formatData?.totals
            const isMandatoryCompleted = mandotary?.completed === mandotary?.total
            const isOptionalCompleted = optional?.completed === optional?.total 
            // console.log({mandotary, optional , isMandatoryCompleted , isOptionalCompleted})
            dispatch(personalUsertrainingDataUpdate(responseData2))
            const scenerio:any =  [ mandotary?.completed , mandotary?.total , optional?.total  , optional?.completed] 
            // if( mandotary?.completed === undefined )
            if (!personalUser?.profileCompletion?.trainings?.completed && isMandatoryCompleted && isOptionalCompleted && !scenerio?.includes?.(undefined) ) {
                // console.log('incomplete trainingsss')
                dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'trainings', openAlert }))
              }
            return responseData;
        } catch (error: any) {
            // console.log('catch', error);
            const errorData = {
                error: error?.response?.data?.message
            }
            return rejectWithValue(errorData)
        }
    }
)
export const completeTrainingThunk = createAsyncThunk('training/user-trainings-mark-completed',
    async ({ trainingId, axiosInstance , dispatch , personalUser , openAlert}:any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(COMPLETETRAININGAPI, axiosInstance, {trainingId}, )

            // const responseData = {
            //     mandatory: response.data.mandotary,
            //     optional: response.data.optional,
            //     total: response.data.totals,
            // }
            // console.log(response);
            dispatch(trainingDataThunk({ axiosInstance, dispatch, personalUser }))
            
            // return response;
        } catch (error: any) {
            // console.log('catch', error);
            const errorData = {
                error: error?.response?.data?.message
            }
            return rejectWithValue(errorData)
        }
    }
)