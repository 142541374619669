import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const foodRestrictionsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            state.error = payload.error;

        });
}

export const foodRestrictionsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log("food Restrictions", payload);
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.foodRestrictions = payload
        });
}
export const foodRestrictionsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING
        });
}
export const allRestrictedFoodsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            state.error = payload.error;

        });
}

export const allRestrictedFoodsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log("food Restrictions", payload);
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.allRestrictedFoods = payload
        });
}
export const allRestrictedFoodsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING
        });
}