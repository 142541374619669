import { createAsyncThunk } from "@reduxjs/toolkit";
import { LISTINGSAPI, CHANGELISTINGSTATUS, SENDBOOKINGREQUEST, BOOKINGSAPI } from "../../constants/axios-api-constant";
import {  MYLISTINGSPATH } from "../../constants/routes-constant";
import { deleteDataAPI, getDataAPI, patchDataAPI, postDataAPI, putDataAPI } from "../../lib/axios";

export const getListingsThunk = createAsyncThunk("get-listings",
  async ({ axiosInstance, offset, searchText,openAlert }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(LISTINGSAPI + `?limit=10&offset=${offset ?? '0'}&filter=${searchText ?? ''}`, axiosInstance);
      // return response.data.data.listings;
      return response.data.data;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      openAlert(errorData.error, 'alert-error-message');

      return rejectWithValue(errorData);
    }
  }
);


export const postListingsThunk = createAsyncThunk("post-listings",
  async ({ navigate, listingCredentials, axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await postDataAPI(
        LISTINGSAPI,
        axiosInstance,
        listingCredentials
      );
      // console.log("listings post", response);
      openAlert("Listing added Successfully", 'alert-success-message')
      navigate(MYLISTINGSPATH)
      return response.data.data;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error: error.response.data.message,
      };
      openAlert(error.response.data.message, 'alert-success-message')
      return rejectWithValue(errorData);
    }
  }
);

export const deleteListingsThunk = createAsyncThunk("delete-listings",
  async ({ axiosInstance, listingIDsToDelete, openAlert, dispatch, offset }: any, { rejectWithValue }) => {
    try {
      // console.log("listings deleted", listingIDsToDelete);
      const response = await deleteDataAPI(LISTINGSAPI, axiosInstance, listingIDsToDelete);
      dispatch(getListingsThunk({ axiosInstance, offset: offset, searchText: '' }))

      openAlert("Listing(s) Deleted Successfully", 'alert-error-message')
      return response.data.message;
    }
    catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const changeListingStatusThunk = createAsyncThunk("change-listings-status",
  async ({ axiosInstance, listingToChangeStatus, openAlert, dispatch, offset }: any, { rejectWithValue }) => {
    try {
      const response = await putDataAPI(CHANGELISTINGSTATUS, axiosInstance, listingToChangeStatus);
      openAlert(response.data.message, 'alert-success-message')
      // console.log(response);
      dispatch(getListingsThunk({ axiosInstance, offset: offset, searchText: '' }))
      return response.data.message;
    }
    catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const getListingByIDThunk = createAsyncThunk("get-listing-by-ID",
  async ({ axiosInstance, listId, openAlert, setEditListingData }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${LISTINGSAPI}/${listId}`, axiosInstance);
      // console.log(response);
      setEditListingData(response.data)
      return response.data.data;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      openAlert(errorData.error, 'alert-success-message')

      return rejectWithValue(errorData);
    }
  }
);



export const viewListingByIDThunk = createAsyncThunk("view-listing-by-ID",
  async ({ axiosInstance, listId, dispatch }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${LISTINGSAPI}/${listId}/view`, axiosInstance);
      // console.log(response.data.data);
      const userId = response.data.data.host._id      
      // dispatch(getRatingsByIdThunk({ axiosInstance, userId }))
      return response.data.data;

    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error: error.response.data.message,
      };

      return rejectWithValue(errorData);
    }
  }
);



export const patchListingThunk = createAsyncThunk("patch-listing",
  async ({ navigate, listingCredentials, axiosInstance, openAlert, listId }: any, { rejectWithValue }) => {
    try {

      const response = await patchDataAPI(`${LISTINGSAPI}/${listId}`, axiosInstance, listingCredentials);
      // console.log(response);
      openAlert('Listing Updated Successfully', 'alert-success-message')
      navigate(MYLISTINGSPATH)
      return response.data.data;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      openAlert(errorData.error, 'alert-success-message')


      return rejectWithValue(errorData);
    }
  }
);


export const sendBookingRequestThunk = createAsyncThunk("send-booking-request",
  async ({ axiosInstance, bookingData, openAlert, dispatch, listId }: any, { rejectWithValue }) => {
    try {

      const response = await postDataAPI(SENDBOOKINGREQUEST, axiosInstance, bookingData);
      openAlert(response.data.message, 'alert-success-message')
      dispatch(viewListingByIDThunk({ axiosInstance, listId }))
      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      openAlert(errorData.error, 'alert-error-message')

      return rejectWithValue(errorData);
    }
  }
);




export const getRatingsByIdThunk = createAsyncThunk("get-top-reviews",
  async ({ axiosInstance, userId }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${BOOKINGSAPI}/getrating?userId=${userId}&limit=2&offset=0`, axiosInstance);
      return response.data.data.reviews;

    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };

      return rejectWithValue(errorData);
    }
  }
);
