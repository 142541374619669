import { createAsyncThunk } from "@reduxjs/toolkit";
import { BOOKINGSAPI, GETUSERCOUNTACTIVITYAPI, LISTINGSAPI, USERS, USERSRIGHTS } from "../../constants/axios-api-constant";
import { deleteDataAPI, getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";

export const getMangeUsersThunk = createAsyncThunk('mange-users',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(GETUSERCOUNTACTIVITYAPI, axiosInstance);

            const responseData = {
                data: response.data.data
            }
            return responseData.data;
        } catch (error) {
            const errorData = {
                error: error
            }
            rejectWithValue(errorData)
        }
    }
)

export const getUsersThunk = createAsyncThunk('get-selected-role-users',
    async ({ axiosInstance, role, search, offset }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(USERS + `?by-role=${role ?? ''}&limit=10&offset=${offset ?? 0}${search && `&search=${search}`}`, axiosInstance);
            // console.log('get users', response);

            return response.data.data;

        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            rejectWithValue(errorData)
        }
    }
)

export const createNewUserThunk = createAsyncThunk('create-new-user',
    async ({ axiosInstance, newUser, openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(USERS, axiosInstance, newUser);
            // console.log('user created', response.data);
            openAlert(response.data.message, 'alert-success-message')


            return response.data.message;

        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(error.response.data.message, 'alert-error-message')
            // console.log(error.response.data);

            rejectWithValue(errorData)
        }
    }
)


// working and integrated
export const changeUserStatusThunk = createAsyncThunk('change-user-status',
    async ({ axiosInstance, userId, status, currentRole, dispatch, openAlert }: any, { rejectWithValue }) => {
        // console.log(userId, status, currentRole, dispatch);

        try {
            const response = await getDataAPI(USERS + `/${userId}/change-status?isActive=${status}`, axiosInstance);
            // console.log('change status', response.data);
            dispatch(getUsersThunk({ axiosInstance, role: currentRole, search: '' }))
            openAlert(response.data.message, 'alert-success-message')
            return response.data.message;

        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error, 'alert-error-message')
            rejectWithValue(errorData)
        }
    }
)


// delete user ( not implemented: just the thunks 
export const deleteUserThunk = createAsyncThunk('delete-user',
    async ({ axiosInstance, userId, openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await deleteDataAPI(USERS + `/${userId}`, axiosInstance);
            // console.log('deleted user', response.data);
            openAlert(response.data.message, 'alert-success-message')

            return response.data.message;

        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error, 'alert-error-message')

            rejectWithValue(errorData)
        }
    }
)

// issue from BE needs to be resolved for it to work
export const editUserThunk = createAsyncThunk('edit-user',
    async ({ axiosInstance, userId, dispatch, editedUser }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(USERS + `/${userId}`, axiosInstance, editedUser);
            // console.log('deleted user', response.data);
            dispatch(getUsersThunk({ axiosInstance }))
            return response.data.message;

        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            rejectWithValue(errorData)
        }
    }
)



export const getUserRightsThunk = createAsyncThunk('get-user-rights',
    async ({ axiosInstance, userId }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(USERSRIGHTS + `/${userId}`, axiosInstance);
            // console.log('got the rights', response.data.data);
            return response.data.data;

        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            rejectWithValue(errorData)
        }
    }
)

export const postUserRightsThunk = createAsyncThunk('change-user-rights',
    async ({ axiosInstance, assignRoleData, dispatch }: any, { rejectWithValue }) => {
        // console.log(assignRoleData);
        
        try {
            const response = await postDataAPI(USERSRIGHTS, axiosInstance, assignRoleData);
            // console.log('posted successfully', response.data);
            dispatch(getUserRightsThunk({ axiosInstance, userId: assignRoleData.userId }))
            // return response.data.message;

        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            rejectWithValue(errorData)
        }
    }
)


// export const getBookingsByIdThunk = createAsyncThunk('get-booking-for-admin',
//     async ({ axiosInstance, id }: any, { rejectWithValue }) => {
//         console.log('booking thunk', id);

//         try {
//             const response = await getDataAPI(`${BOOKINGSAPI}/${id}/view`, axiosInstance,);
//             console.log('Booking got by selected Id', response.data.data);
//             // dispatch(getUserRightsThunk({ axiosInstance, userId: assignRoleData.userId }))
//             return response.data.data;

//         } catch (error: any) {
//             const errorData = {
//                 error: error.response.data.message
//             }
//             rejectWithValue(errorData)
//         }
//     }
// )

export const getBookingsByIdThunk = createAsyncThunk('get-booking-for-admin',
    async ({ axiosInstance, hostId, limit, offset, status, search, from, to }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`${BOOKINGSAPI}?hostId=${hostId}&to=${to}&from=${from}&search=${search ?? ''}&status=${status ?? 'ACCEPTED'}&offset=${offset ?? 0}&limit=${limit ?? 10}`, axiosInstance);
             const responseData = {
                bookingsData: response.data.bookings,
                meta: response.data.meta
            }
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message || 'Server is not responding. Please Try Again Later'
            }
            return rejectWithValue(errorData)
        }
    }
);


export const getListingsByUserIdThunk = createAsyncThunk('get-listings-for-admin',
    async ({ axiosInstance, userId, filter, offset }: any, { rejectWithValue }) => {
        // console.log('lisitings thunk', userId);
        try {
            const response = await getDataAPI(`${LISTINGSAPI}?limit=10${!!offset ? `&offset=${offset}` : ''}${!!filter ? `&filter=${filter}` : ''}&hostId=${userId}`, axiosInstance);
            // console.log('Listings got by selected Id', response.data.data.listings);
            // dispatch(getUserRightsThunk({ axiosInstance, userId: assignRoleData.userId }))
            return response.data.data;

        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            rejectWithValue(errorData)
        }
    }
)
