import { createAsyncThunk } from "@reduxjs/toolkit";
import { MENUAPI, ADDFOODITEMAPI, FOODITEMAPI, NUTRITIONSAPI, INGREDIENTSAPI, RECIPESAPI, ALLINGREDIENTSAPI, DELETEFOODITEMSIMAGESAPI } from "../../constants/axios-api-constant";
import { deleteDataAPI, getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";

export const getsingleMenuFoodItemsThunk = createAsyncThunk("food/get-single-menu-food-items",
    async ({ menuId, openAlert, axiosInstance }: any, { rejectWithValue }) => {
        // console.log(menuId)
        try {
            const response = await getDataAPI(`${MENUAPI}/${menuId}`, axiosInstance);
            // console.log(response)
            const formatResponse = response?.data;
            // console.log(formatResponse)
            const responseData = {
                singleMenuFoodItem: formatResponse?.data,
                selectedItemCategory: {
                    id: formatResponse?.data?._id,
                    name: formatResponse?.data?.category
                }
            };

            // openAlert('Menu added successfully', 'alert-success-message')
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message  || 'Server is not responding. Please Try Again Later'
            }
            openAlert(errorData.error)

            return rejectWithValue(errorData)
        }
    }
)

export const addFoodItemThunk = createAsyncThunk("food/add-food-item",
    async ({ menuId, foodItemData, openAlert, axiosInstance, setFoodItemImageData, closeHandler }: any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(`${ADDFOODITEMAPI}/${menuId}`, axiosInstance, foodItemData);
            // console.log('MENUAPI ', response)
            const responseData = {
                food: response.data
            }
            setFoodItemImageData("")
            closeHandler?.()
            openAlert('Food Item Added Successfully', 'alert-success-message')
            return responseData;
        } catch (error: any) {
            // console.log(error)
            setFoodItemImageData("")
            const errorData = {
                error: error?.response?.data?.message || 'server is not responding'
              }
        
              openAlert(errorData.error)

            return rejectWithValue(errorData);
        }
    }
);

export const deleteFoodItemThunk = createAsyncThunk("food/delete-food-item",

    async ({ menuId, foodId, openAlert, axiosInstance, setDeleteModalOpen, dispatch }: any, { rejectWithValue }) => {
        try {
            const response = await deleteDataAPI(`${FOODITEMAPI}/${foodId}`, axiosInstance);

            const responseData = {
                message: response?.data?.message,
            };

            setDeleteModalOpen(false);

            dispatch(getsingleMenuFoodItemsThunk({ menuId, openAlert, axiosInstance }))

            openAlert('Food item deleted successfully', 'alert-success-message')
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message || 'server is not responding'
              }
        
              openAlert(errorData.error)

            return rejectWithValue(errorData)
        }
    }
)

export const editFoodItemThunk = createAsyncThunk("food/edit-food-item",
    async ({ foodItemId, foodItemData, openAlert, axiosInstance, setFoodItemImageData , closeHandler}: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(`${FOODITEMAPI}/${foodItemId}`, axiosInstance, foodItemData);
            // console.log(response)
            const responseData = {
                message: response?.data?.message,
            };

            setFoodItemImageData("")
            closeHandler?.()
            openAlert('Food Item Edited successfully', 'alert-success-message')
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message || 'server is not responding'
              }
        
              openAlert(errorData.error)

            return rejectWithValue(errorData)
        }
    }
)

export const getSingleFoodItemThunk = createAsyncThunk("food/get-single-food-item",
    async ({ foodItemId, axiosInstance, openAlert, setFoodItemImageData }: any, { rejectWithValue }) => {
        // console.log(foodItemId)
        try {
            const response = await getDataAPI(`${FOODITEMAPI}/${foodItemId}`, axiosInstance);
            // console.log(response)
            const formatResponse = response.data;
            // console.log(formatResponse)
            const responseData = {
                singleFoodItem: formatResponse?.data?.[0],
            };
            setFoodItemImageData?.(responseData?.singleFoodItem?.foodImages)

            // openAlert('Menu added successfully', 'alert-success-message')
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
            }
            openAlert(errorData.error)

            return rejectWithValue(errorData)
        }
    }
)


export const getNutritionsInfoThunk = createAsyncThunk('food/get-nutritional-info',
    async ({ ingredientsArray, axiosInstance, set, setFormikField , openAlert }: any, { rejectWithValue }) => {
        try {
            // console.log('string from thunk', ingredientsArray);
            const response = await getDataAPI(NUTRITIONSAPI + `?query=${ingredientsArray}`, axiosInstance)
            // console.log(response)
            const responseData = {
                nutritics: response?.data?.data 
            }
            set?.(true)
            setFormikField("nutritionalInfo", "Nurtitions calculated");

            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message || 'server is not responding'
              }
        
              openAlert(errorData.error)

            return rejectWithValue(errorData)
        }
    }
)


export const getAllIngredientsThunk = createAsyncThunk("food/get-all-ingredients",
    async ({ openAlert, axiosInstance, limit, offset }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`${INGREDIENTSAPI}?limit=${limit}&offset=${offset}`, axiosInstance);
            // console.log(response)
            const formatResponse = response?.data?.data;
            // console.log(formatResponse)
            const responseData = {
                allIngredients: formatResponse?.ingredients,
            };

            // openAlert('Menu added successfully', 'alert-success-message')
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message  || 'Server is not responding. Please Try Again Later'
            }
            openAlert(errorData.error)

            return rejectWithValue(errorData)
        }
    }
)

export const getAllRecipesThunk = createAsyncThunk("food/get-recipes",
    async ({ openAlert, axiosInstance, limit, offset }: any, { rejectWithValue }) => {
        // console.log(menuId)
        try {
            const response = await getDataAPI(`${RECIPESAPI}?limit=${limit}&offset=${offset}`, axiosInstance);
            // console.log(response)
            const formatResponse = response?.data?.data;
            // console.log(formatResponse)
            const responseData = {
                allRecipes: formatResponse?.recipes,
             
            };

            // openAlert('Menu added successfully', 'alert-success-message')
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message  || 'Server is not responding. Please Try Again Later'
            }
            openAlert(errorData.error)

            return rejectWithValue(errorData)
        }
    }
)

export const addNewIngredientsThunk = createAsyncThunk("food/add-new-ingredients",
    async ({ ingredientData,axiosInstance , openAlert}: any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(`${INGREDIENTSAPI}`, axiosInstance, ingredientData);
            // console.log(response)
            const formatResponse = response?.data?.data;
            // console.log(formatResponse)
            const responseData = {
                allIngredients: formatResponse,
            };

            // openAlert('Menu added successfully', 'alert-success-message')
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message  || 'Server is not responding. Please Try Again Later'
            }
            openAlert(errorData.error)

            return rejectWithValue(errorData)
        }
    }
)

export const deleteFoodItemImageThunk = createAsyncThunk("food/delete-food-item-image",
  async ({ imageId ,axiosInstance, imageUrl ,openAlert, foodItemImageData, set , id, setFoodItemImageData }: any, { rejectWithValue }) => {
    // console.log(coverImageDetail)
    try {
      // console.log("listings deleted", listingIDsToDelete);
      const response = await deleteDataAPI(`${DELETEFOODITEMSIMAGESAPI}/${imageId}/remove-image`, axiosInstance, imageUrl);

      openAlert("Image Deleted Successfully", 'alert-error-message')
      const deletingObj = foodItemImageData?.filter((item: any) => item !== id);
      setFoodItemImageData?.(deletingObj);
      set("foodImages", deletingObj?.length ? deletingObj : "")
      return response.data.message;
    }
    catch (error: any) {
        const errorData = {
            error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
          }
          openAlert?.(errorData.error)
    //   console.log(errorData.error)
      return rejectWithValue(errorData);
    }
  }
);