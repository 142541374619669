import { useState, useEffect } from 'react';
// MUI
import { Checkbox, FormControl, Grid, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select } from '@mui/material';
// Component
import { findMoreInfo } from './find-more-data';
import { groupBy, groupByspecificField } from '../../../../../../utils/hooks-utils';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import {
  setSelectedHostLanguageProficiences, setSelectedFacilities, setSelectedHouseRules, setSelectedFacilitiesForChildren, setSelectedFacilitiesForDisables
}
  from "../../../../../../store/food-preference/food-preference-slice";

const MenuProps = {
  PaperProps: {
    sx: {
      overflow:'auto',
      width: '450px',
      maxWidth:'500px',
      borderRadius: '10px',
      maxHeight: '240px',
      p: '.5rem',
      pl:'1rem',
      // mx: '12rem',
      mx: {xs:'0rem', md:'1rem'},  // for special cases
      my: '1rem',
      color: '#E0E0E0',
      background: '#333333',
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "inherit !important"
      },
    },
  },
};

const FindMore = () => {

  const [findMoreIn, setFindMore] = useState<string[]>([]);
  const [findMoreData, setFindMoreData] = useState<any>([])
  const dispatch = useAppDispatch();
  const { foodPreference } = useAppSelector(state => state)
  // console.log({foodPreference})
  // const handleFindMore = (event: SelectChangeEvent<typeof findMoreIn>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   // setFindMore(
  //   //   typeof value === 'string' ? value.split(',') : value,
  //   // );
  //   console.log('sdff', value)
  // };
  const handleClickBtn = (event: any, item1: any) => {
    let findArr = findMoreData.find((ele: any) => ele.serviceName === event);
    if (findArr) {
      let el = findMoreData.filter((ele: any) => ele.serviceName !== event);
      setFindMoreData(el);
      let fl = findMoreIn.filter((ele: any) => ele !== event);
      setFindMore(fl);
    } else {
      setFindMoreData((item: any) => [
        ...item,
        { facilityName: item1, serviceName: event }
      ])
      setFindMore((item: any) => [
        ...item, event
      ]);
    }
  }
  // console.log(findMoreIn, 'fsdfs');
  // console.log(findMoreData, ' findMoreData')
  useEffect(() => {
    const grouped = groupByspecificField(findMoreData, 'facilityName', 'serviceName')
    const arrays = Object.entries(grouped).map(([facilityName, serviceName]: any) => ({ facilityName, serviceName }))
    // console.log({ arrays })
    // console.log({ grouped })
    dispatch(setSelectedFacilities(grouped['facilities'] || []))
    dispatch(setSelectedFacilitiesForDisables(grouped['facilitiesForDisables'] || []))
    dispatch(setSelectedFacilitiesForChildren(grouped['facilitiesForChildren'] || []))
    dispatch(setSelectedHouseRules(grouped['houseRules'] || []))
    dispatch(setSelectedHostLanguageProficiences(grouped['hostLanguageProficiences'] || []))
  }, [findMoreData.length])

  useEffect(() => {
    // const filters = {
    //   price,
    //   offers,
    //   dietary,
    //   facilities,
    //   facilitiesForDisables,
    //   facilitiesForChildren,
    //   houseRules,
    //   hostLanguageProficiences
    // }
  }, [])
  return (
    <>
      {/* <FormControl> */}
      <Select
        fullWidth
        sx={{
          borderRadius: '45px',
          // width: '118.73px', 
          height: '36.24px'
        }}
        displayEmpty
        multiple
        size={'small'}
        value={findMoreIn}
        // onChange={handleFindMore}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span className='tabs-span'>Find More</span>;
          }

          return <span className='tabs-span'>Find More</span>;
        }}
        autoWidth
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {findMoreInfo.map((findMore) => (

          <div key={findMore.value}>
            <>
              <ListSubheader
                disableSticky
                sx={{ px: 0, backgroundColor: 'transparent', color: '#E0E0E0', fontSize: '13px' }}>{
                findMore.findHeadName}
                </ListSubheader>
              <Grid container
              // justifyContent={"space-between"}
              >

                {findMore.findHead.map((findHeads: any) => {
                  return (
                    <Grid item 
                    xs={12} 
                    md={4} 
                    key={findHeads.value}>
                      <MenuItem
                        sx={{
                          p: 0,
                          // pr: 4, pb: 0, 
                          m: 0
                        }}
                        onClick={() => { return handleClickBtn(findHeads._id, findMore.key); }}
                        key={findHeads.value} value={findHeads.optionName}>
                        <Checkbox checked={findMoreIn.indexOf(findHeads._id) > -1}
                          // onClick={()=>{return handleClickBtn(findHeads.optionName, findMore.findHeadName );}}
                          sx={{
                            // px: 1,
                            px: .5,
                            m: 0,
                            color: '#E0E0E0 !important',
                            "&.Mui-checked": { color: '#FF611D !important' },
                          }}
                        />
                        <ListItemText primaryTypographyProps={{ fontSize: '12px' }} primary={findHeads.optionName} />
                      </MenuItem>
                    </Grid>
                  )
                })}
              </Grid>
            </>
          </div>
        ))}
      </Select>
      {/* </FormControl> */}
    </>
  )
}

export default FindMore