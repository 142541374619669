import { createAsyncThunk } from "@reduxjs/toolkit";
import { KITCHENDETAILSAPI } from "../../constants/axios-api-constant";
import {  getDataAPI, patchDataAPI } from "../../lib/axios";
import { groupBy } from "../../utils/hooks-utils";
// import { bookingsThunkFulfilledReducer } from "../booking/booking-reducers";

export const getHouseRulesThunk = createAsyncThunk('house-rules-logistics',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(KITCHENDETAILSAPI, axiosInstance)
            const responseData = {
                houseRules: response.data.data.houseRules,
            }

            return responseData;
        } catch (error: any) {
            return rejectWithValue(error)
        }
    }
)

export const getFacilityListThunk = createAsyncThunk('facilities-logistics',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        // console.log(axiosInstance)
        try {
            const response = await getDataAPI("user-profile/list-facility", axiosInstance)
            // console.log(response);
            let groupedData: any[] = [];
            const groupFacilityByCategory = groupBy(response.data.data, "category");
            const selectedFacilities = Object.entries(groupFacilityByCategory).map(
                ([category, facility]) => ({ category, facility }));
            selectedFacilities.forEach((i: any) => groupedData.push(...i.facility))

            // return response.data.data;
            // console.log(groupedData);

            return groupedData;
        } catch (error: any) {
            // console.log('catch', error);
            const errorData = {
                error: error.response.data.message
            }
            return rejectWithValue(errorData)
        }
    }
)


export const getBookingsThunk = createAsyncThunk('bookinglist',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI('bookings?status=CONFIRMED&offset=0&limit=100', axiosInstance);

            // console.log(response);
            const responseData = {
                bookings: response.data.bookings
            }
            return responseData


        } catch (error) {
            // console.log(error);

        }
    }
)

export const getIngredientsThunk = createAsyncThunk('food/ingredients',
    async ({ axiosInstance }: any) => {
        try {
            const response = await getDataAPI('ingredients', axiosInstance)

            const responseData = {
                foodIngredients: response.data
            }

            return responseData.foodIngredients.data

        } catch (error) {
            // console.log(error);
        }
    }
)

export const getlogisticsDataThunk = createAsyncThunk('logistics',
    async ({ bookingId, openAlert, axiosInstance }: any, { rejectWithValue }) => {
        // console.log(bookingsThunkFulfilledReducer);

        try {
            const response = await getDataAPI(`logistics/${bookingId}`, axiosInstance);
            // console.log(response.data.data);
            
            // openAlert(response?.data?.message, 'alert-success-message')

            const responseData = {
                menuData: response.data.data,
                trackerAccessMsg: response?.data?.message
            }

            // console.log(responseData.menuData);

            // console.log(responseData, 'menu-data');
            return responseData

        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message
            }
            openAlert(errorData.error, 'alert-error-message')
            return rejectWithValue(errorData)
        }
    }
)

export const patchLogisticsMenuThunk = createAsyncThunk('menu/logistics',
    async ({ notes, bookingId, axiosInstance }: any) => {
        // console.log(notes, bookingId);
        const menu = { notes: notes }
        try {
            const response = await patchDataAPI(`logistics/menu/${bookingId}`, axiosInstance, { menu });
            return response.data.data
        } catch (error) {
            // console.log(error);
        }
    }
);

export const patchLogisticsRecipeThunk = createAsyncThunk('recipe/logistics',
    async ({ axiosInstance, values, bookingId }: any) => {
        const recipe = {
            notes: values.stickyNote,
            recipesList: values.chooseRecipe
        }
        // console.log(recipe);

        try {
            const response = await patchDataAPI(`logistics/recipe/${bookingId}`, axiosInstance, { recipe })
            // console.log(response.data.data);

            return response.data.data

        } catch (error) {
            // console.log(error);

        }
    }
)

export const patchHouseHoldThunk = createAsyncThunk('household',
    async ({ axiosInstance, bookingId, initialValues }: any) => {
        // console.log(initialValues);

        try {
            const response = await patchDataAPI(`logistics/house-cleanliness/${bookingId}`, axiosInstance, initialValues)
            // console.log(response.data);

            return response.data

        } catch (error) {
            // console.log(error);
        }

    }
)

export const patchLogisticsShoppingThunk = createAsyncThunk('shopping/list',
    async ({ bookingId, axiosInstance, values }: any, { rejectWithValue }) => {

        const shoppingList = {
            notes: values.stickyNote,
            recipesList: values.chooseShopping
        }

        try {
            const response = await patchDataAPI(`logistics/shopping-list/${bookingId}`, axiosInstance, { shoppingList });
            // console.log(response.data.data);

            return response.data.data

        } catch (error) {
            // console.log(error);

        }
    }
)

export const getRecipesThunk = createAsyncThunk('recipes',
    async ({ bookingId, axiosInstance }: any, { rejectWithValue }) => {

        try {
            const response = await getDataAPI('recipes', axiosInstance);
            const responseData = {
                recipesData: response.data.data
            }
            // console.log(responseData.recipesData );
            return responseData.recipesData

        } catch (error) {
            // console.log(error);
        }

    }
)

export const getFacilityHouseRuleThunk = createAsyncThunk('facility/houserules',
    async ({ axiosInstance }: any) => {
        try {
            const response = await getDataAPI('user-profile/kitchen-details', axiosInstance);
            // console.log(response);

            const responseData = {
                kitchenData: response.data.data
            }
            //console.log(responseData.kitchenData);
            return responseData.kitchenData
        } catch (error) {

        }
    }
);


export const patchLogisticsTimerthunk = createAsyncThunk('timer',
    async ({ bookingID, timerBody, axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(`logistics/timer/${bookingID}`, axiosInstance, timerBody)
            // console.log(response?.data, "response");

            const responseData = {
                message: response?.data?.message
            }
            // openAlert(responseData.message, 'alert-success-message')
            return responseData;

        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message
            }
            // openAlert(errorData.error, 'alert-error-message')

            return rejectWithValue(errorData)

        }
    }
)
