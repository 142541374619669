import { NavLink } from "react-router-dom";

// Sidebar Data
import { sidebarData, sidebarIconData } from "./sidebar-data"

// Components
import SidebarMenu from "./sidebar-menu/sidebar-menu";

// Images

import profileIcon from "../../assets/images/layout/header/profile-icon.png";
import Logo from "../../assets/images/layout/header/logo.png";
import collapsedIcon from "../../assets/images/layout/header/collapsed-logo.png";

// SCSS
import "./sidebar.scss";

// Interfaces
import { ISIDEBARDATA, ISIDEBARICONS } from "./sidebar-interface";

// Custom Hook
import { useSidebar } from "./use-sidebar";
import { setMobDrawer } from "../../store/layout/layout-slice";
import { IMAGEBASEURL } from "../../constants/axios-api-constant";

// Component
const Sidebar = () => {

  const { openDrawer,auth, handleImage, dispatch, personalUser, navigate }:any = useSidebar();
  
  return (
    <div className="sidebar">
      {/* Sidebar logo */}
      <div className="sidebar-container">
        <div className="sidebar-logo cursor-pointer" onClick={()=>navigate('/')}>
          <img
            src={openDrawer ? Logo : collapsedIcon}
            alt="smd-logo"
            className={`${openDrawer} && "logo-img-icon"`}
          />
        </div>
        {openDrawer && (
          <div className="img-profile-box">
            <div>
              <label htmlFor="upload-button">
                <img
                  src={ 
                    personalUser?.user.profileImage ?
                  `${IMAGEBASEURL}${personalUser?.user.profileImage}`: 
                  profileIcon
                  // `${IMAGEBASEURL}${personalUser?.user?.profileImage}` || profileIcon
                    // auth?.user?.profileImage === 'string' ? profileIcon
                    //     : 
                    //     auth?.user?.profileImage ? auth?.user?.profileImage : profileIcon
                  }
                  className={
                   `sidebarImageUpload ${auth.setUploadProfileImage && !personalUser?.user?.profileImage
                      && "imageSlider-Profile-Error"
                      }`
                  }
                  alt="profile"
                />
              </label>
              <input
                type="file"
                className="img-upload-type"
                id="upload-button"
                accept="image/*"
                onChange={handleImage}
                disabled={!auth.setUploadProfileImage}
              />
            </div>
            <div>
              <p className="white-color secondary-title">
                {auth.user?.firstName} {auth.user?.lastName}
              </p>
              <p className="white-color secondary-title">{auth.role?.[0]}</p>
            </div>
            <div className="profile-icon-sidebar">
              {sidebarIconData.map((item: ISIDEBARICONS) => (
                <NavLink
                  key={item.id}
                  to={item.path}
                  className="icon-hover cursor-pointer"
                  onClick={() => dispatch(setMobDrawer(false))}
                >
                  {item.icon}
                </NavLink>
              ))}
            </div>
          </div>
        )}

        <div
          className={` sidebar-scrollbar ${!openDrawer ? "side-nav-closed-height" : "sideNav"
            }`}
        >
          {sidebarData.map((item: ISIDEBARDATA) => {
            return (
              <SidebarMenu
                item={item}
                key={item.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
