import sadEmoji from "../assets/images/reviews-and-feedback/component/modal-rating/sad.png";
import normalEmoji from "../assets/images/reviews-and-feedback/component/modal-rating/normal.png";
import happyEmoji from "../assets/images/reviews-and-feedback/component/modal-rating/happy.png";
import soHappyEmoji from "../assets/images/reviews-and-feedback/component/modal-rating/so-happy.png";
import lovelyEmoji from "../assets/images/reviews-and-feedback/component/modal-rating/lovely.png";

import { IRATINGEMOJIMODALMAIN } from "../components/rating-modal/rating-modal-interface";
import { IRATINGS } from "../features/reviews-and-feedback/common/relevance-rating-filter/relevance-rating-filter-interface";

// USEFUL
export const ratingEmojiModalMain: IRATINGEMOJIMODALMAIN[] = [
  {
    id: 1,
    modalSubHeading: "Dining Experience",
    modalSubHeadingDetails: [
      {
        id: 1,
        emojiHeading: "Dining Space",
        emojiHeadingValue: "diningSpace",

        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 4,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    modalSubHeading: "Food Experience",
    modalSubHeadingDetails: [
      {
        id: 1,
        emojiHeading: "Hygiene Level",
        emojiHeadingValue: "hygineLevel",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 0,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 5,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 2,
        emojiHeading: "Portion",
        emojiHeadingValue: "portaion",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 3,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 0,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 3,
        emojiHeading: "Food Quality",
        emojiHeadingValue: "foodQuality",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 3,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 0,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 4,
        emojiHeading: "Taste",
        emojiHeadingValue: "taste",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 4,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    modalSubHeading: "Host Experience",
    modalSubHeadingDetails: [
      {
        id: 1,
        emojiHeading: "Professionalism",
        emojiHeadingValue: "professionalism",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 4,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 2,
        emojiHeading: "Reasonable Adjustment",
        emojiHeadingValue: "reasonableAdjustment",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 0,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 5,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 3,
        emojiHeading: "Timeliness",
        emojiHeadingValue: "timeliness",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 4,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
    ],
  },
];

export const ratings: IRATINGS[] = [
  { id: 1, value: 5, text: "Excellent", average: 34 },
  { id: 2, value: 4, text: "Very Good", average: 21 },
  { id: 3, value: 3, text: "Good", average: 16 },
  { id: 4, value: 2, text: "Fair", average: 10 },
  { id: 5, value: 1, text: "Poor", average: 8 },
];

export const relevances: any = [
  { id: 1, value: 'all', text: "All" },
  { id: 2, value: true, text: "Latest" },
  { id: 3, value: false, text: "Older" },
];

export const rateYourExperienceData: any[] = [
  {
    id: 1,
    modalSubHeading: "Dining Experience",
    modalSubHeadingDetails: [
      {
        id: 1,
        emojiHeading: "Dining Space",
        emojiHeadingValue: "diningSpace",

        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 4,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    modalSubHeading: "Food Experience",
    modalSubHeadingDetails: [
      {
        id: 1,
        emojiHeading: "Hygiene Level",
        emojiHeadingValue: "hygineLevel",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 0,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 5,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 2,
        emojiHeading: "Portion",
        emojiHeadingValue: "portaion",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 3,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 0,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 3,
        emojiHeading: "Food Quality",
        emojiHeadingValue: "foodQuality",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 3,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 0,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 4,
        emojiHeading: "Taste",
        emojiHeadingValue: "taste",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 4,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    modalSubHeading: "Host Experience",
    modalSubHeadingDetails: [
      {
        id: 1,
        emojiHeading: "Professionalism",
        emojiHeadingValue: "professionalism",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 4,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 2,
        emojiHeading: "Reasonable Adjustment",
        emojiHeadingValue: "reasonableAdjustment",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 0,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 5,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
      {
        id: 3,
        emojiHeading: "Timeliness",
        emojiHeadingValue: "timeliness",
        emojiData: [
          {
            userRatingEmoji: sadEmoji,
            id: 1,
            userRating: 0,
            altRatingEmoji: "sad-emoji",
          },
          {
            userRatingEmoji: normalEmoji,
            id: 2,
            userRating: 0,
            altRatingEmoji: "normal-emoji",
          },
          {
            userRatingEmoji: happyEmoji,
            id: 3,
            userRating: 0,
            altRatingEmoji: "happy-emoji",
          },
          {
            userRatingEmoji: soHappyEmoji,
            id: 4,
            userRating: 4,
            altRatingEmoji: "so-happy-emoji",
          },
          {
            userRatingEmoji: lovelyEmoji,
            id: 5,
            userRating: 0,
            altRatingEmoji: "lovely-emoji",
          },
        ],
      },
    ],
  },
];
// USEFUL