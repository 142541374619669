import { createSlice } from "@reduxjs/toolkit";

import { showAlertMessageReducer, hideAlertMessageReducer } from "./alert-reducers";

import { IALERTSTATE } from "./alert-slice-interface";


const alertInitialState: any = {

    showAlert: false,
    alertMessage: '',
    alertType: 'alert-success-message'
}

const alertSlice = createSlice({

    name: 'alert',

    initialState : alertInitialState,

    reducers: {

        showAlertMessage: showAlertMessageReducer,
        hideAlertMessage: hideAlertMessageReducer
    }
})

export const { showAlertMessage, hideAlertMessage } = alertSlice.actions;

export default alertSlice.reducer;