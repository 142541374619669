import { lazy } from 'react';

import MainLayout from "../layout/main-layout/main-layout";
import { AuthLayout } from "../layout/auth-layout/auth-layout";

import { PrivateRoutes } from "./private-routes";
import { AuthProtect } from "./auth-protect";

import { USERROLES } from "../constants/roles-constant";

import { Navigate } from "react-router-dom";

import Foodpreferencessearch  from "../pages/food-preference-search"

import {
  ACCOUNTSETTINGSPATH, ADDFOODITEMPATH, ADDLISTINGSPATH, ADDRECIPEPATH, APIINVENTORYPATH, AUDITLOGSPATH, BACKUPPATH,
  CHANGEPASSWORDPATH, CREATEMENUPATH, EDITFOODITEMPATH, EDITLISTINGSPATH, EDITMENUPATH, EMAILVERIFICATIONPATH,
  EMPTYPATH, FOODPREFERENCEPATH, FOODPREVIEWPATH, FORGOTPASSWORDPATH,  HELPPATH, HOMEPATH, RATEHOSTPATH, 
  LOGISTICSPATH, MANAGEPATH, MENUPATH, MENUPREVIEWPATH, MYBOOKINGSPATH, MYCALENDARPATH, MYINVOICEPATH, MYLISTINGSPATH,
  MYWHISHLISTHOSTPATH, MYWHISHLISTLISTINGSPATH, MYWHISHLISTPATH,  OVERALLRATINGSPATH, RATEGUESTPATH, 
  RECIPEMANAGEMENTPATH, RESETPASSWORDPATH, REVIEWSANDFEEDBACKPATH, SIGNINPATH, SIGNUPPATH, SINGLEINVOICEDETAILPATH, 
  SINGLELISTINGSGUESTPATH, SINGLELISTINGSPATH, RATINGRECORDSPATH, AUDITASSIGNMENTPATH, 
  AUDITASSIGNMENTANNOUNCEDVISITPATH, AUDITASSIGNMENTUNANNOUNCEDVISITPATH, AUDITSPATH, HOSTUSERDETAILSPATH,
   AUDITASSIGNMENTINITIALVISITPATH, FOODPREFERENCESEARCHPATH, SINGLEKITCHENDETAILSGUESTVIEWPATH, MANAGEUSERSTYPESPATH, HOSTPERSONALPROFILEDETAILSADMINVIEWPATH, HOSTLISTINGSADMINVIEWPATH, HOSTBOOKINGSADMINVIEWPATH
} from "../constants/routes-constant";


const Changepassword = lazy(() => import("../pages/change-password"));
const Signin = lazy(() => import("../pages/signin"));
const Signup = lazy(() => import("../pages/signup"));
const Resetpassword = lazy(() => import("../pages/reset-password"));
const Forgotpassword = lazy(() => import("../pages/forgot-password"));
const Emailverification = lazy(() => import("../pages/email-verification"));


const Accountsettings = lazy(() => import("../pages/account-settings"));
const Dashboards = lazy(() => import("../pages/dashboard/dashboards"));
const Reviewsandfeedbackindex = lazy(() => import("../pages/reviews-and-feedback-index"));
const Overallratings = lazy(() => import("../pages/overall-ratings"));
const Rateguest = lazy(() => import("../pages/rate-guest"));
const Ratingrecords = lazy(() => import("../pages/rating-records"));
const Menuindex = lazy(() => import("../pages/menu-index"));
const Menuitems = lazy(() => import("../pages/menu-items"));
const Upsertmenu = lazy(() => import("../pages/upsert-menu"));
const Upsertfooditem = lazy(() => import("../pages/upsert-food-item"));
const Fooditempreview = lazy(() => import("../pages/food-item-preview"));
const Listingindex = lazy(() => import("../pages/listing-index"));


const Upsertlisting = lazy(() => import("../pages/upsert-listing"));
const Singlelistingdetail = lazy(() => import("../pages/single-listing-detail"));
const Listingitems = lazy(() => import("../pages/listing-items"));
const Mycalendar = lazy(() => import("../pages/my-calendar"));
const Bookingsrecord = lazy(() => import("../pages/my-bookings"));
const Logisticspage = lazy(() => import("../pages/logistics"));
const Helpfaq = lazy(() => import("../pages/help-faq"));
const Invoiceindex = lazy(() => import("../pages/menu-items"));
const Invoicerecord = lazy(() => import("../pages/invoice-records"));
const Singleinvoicedetail = lazy(() => import("../pages/single-invoice-detail"));
const Ratehost = lazy(() => import("../pages/rate-host"));
// const Ratehostrecords = lazy(() => import("../pages/rate-host-records"));





const Foodpreferences = lazy(() => import("../pages/food-preference"));
const Recipeindex = lazy(() => import("../pages/recipe"));
const Recipelists = lazy(() => import("../pages/recipe-lists"));
const Upsertrecipe = lazy(() => import("../pages/upsert-recipe"));
const Apiinventory = lazy(() => import("../pages/api-inventory"));
const Auditlogs = lazy(() => import("../pages/audit-logs"));
const Managee = lazy(() => import("../pages/mange"));
const Backup = lazy(() => import("../pages/back-up"));


const Viewsinglelistingguest = lazy(() => import("../pages/view-single-listing-guest"))
const Mywishlistsindex = lazy(()=>import("../pages/my-wishlists-index"))
const Mywishlists = lazy(() => import("../pages/my-wishlists"))
const Wishlistshost = lazy(() => import("../pages/wishlists-host"))
const Wishlistslistings = lazy(() => import("../pages/wishlists-listings"))

const AuditassignmentIndex = lazy(()=>import("../pages/audit-assignment-index"))
const Auditassignment = lazy(()=>import("../pages/audit-assignment"))

const Auditslists = lazy(()=>import("../pages/audits-lists"))
const Singleuserdetails = lazy(()=>import("../pages/single-user-details"))

const Foodpreferencesindex = lazy(()=>import("../pages/food-preference-index"))
// const Foodpreferencessearch = lazy(() => import("../pages/food-preference-search"))

const Singlekitchendetailsguestview = lazy(() => import("../pages/single-kitchen-details-guest.view"))
const Manageindex = lazy(() => import("../pages/manage-index"))
const Manageuserlists = lazy(() => import("../pages/manage-user-lists"))
const Singlehostdetailsadminview = lazy(() => import("../pages/single-host-details-admin-view"))

const Hostlistingadminview = lazy(() => import("../pages/host-listings-admin-view"))

const Hostbookingadminview = lazy(() => import("../pages/host-bookings-admin-view"))

export const routesData = [

  {
    id: 1,
    pathLink: HOMEPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.HOST, USERROLES.GUEST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    componentName: <Dashboards />,
  },
  {
    id: 2,
    pathLink: ACCOUNTSETTINGSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.HOST, USERROLES.GUEST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    componentName: <Accountsettings />,
  },
  {
    id: 3.67,
    pathLink: HOSTUSERDETAILSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    componentName: <Singleuserdetails />,
  },
  {
    id: 3.698,
    pathLink: HOSTPERSONALPROFILEDETAILSADMINVIEWPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SUPERADMIN],
    componentName: <Singlehostdetailsadminview />,
  },
  {
    id: 3,
    pathLink: CHANGEPASSWORDPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST, USERROLES.HOST,USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    componentName: <Changepassword />,
  },

  {
    id: 4,
    pathLink: LOGISTICSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.HOST],
    componentName: <Logisticspage />,
  },
  {
    id: 5,
    pathLink: FOODPREFERENCEPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST],
    componentName: <Foodpreferencesindex />,
    child: [
      {
        id: 1,
        pathLink: EMPTYPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.GUEST],
        componentName: <Foodpreferences />,
      },
      {
        id: 2,
        pathLink: FOODPREFERENCESEARCHPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.GUEST],
        componentName: <Foodpreferencessearch />,
      },
      // {
      //   id: 3,
      //   pathLink: EDITLISTINGSPATH,
      //   guard: PrivateRoutes,
      //   allowedRoles: [USERROLES.HOST],
      //   componentName: <Upsertlisting />,
      // },
      // {
      //   id: 4,
      //   pathLink: SINGLELISTINGSPATH,
      //   guard: PrivateRoutes,
      //   allowedRoles: [USERROLES.HOST],
      //   componentName: <Singlelistingdetail />,
      // },
    ]
  },
  {
    id: 6,
    pathLink: MYBOOKINGSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST, USERROLES.HOST,USERROLES.SUPERADMIN],
    componentName: <Bookingsrecord />,
  },
  {
    id: 7,
    pathLink: MYCALENDARPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST, USERROLES.HOST, USERROLES.SUPERADMIN,USERROLES.COMPLIANCE],
    componentName: <Mycalendar />,
  },
  {
    id: 8,
    pathLink: HELPPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST, USERROLES.HOST],
    componentName: <Helpfaq />,
  },
  {
    id: 9,
    pathLink: MYLISTINGSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.HOST],
    componentName: <Listingindex />,
    child: [
      {
        id: 1,
        pathLink: EMPTYPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Listingitems />,
      },
      {
        id: 2,
        pathLink: ADDLISTINGSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Upsertlisting />,
      },
      {
        id: 3,
        pathLink: EDITLISTINGSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Upsertlisting />,
      },
      {
        id: 4,
        pathLink: SINGLELISTINGSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Singlelistingdetail />,
      },
    ]
  },
  {
    id: 53,
    pathLink: SINGLELISTINGSGUESTPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST],
    componentName: <Viewsinglelistingguest />,
  },
  {
    id: 536,
    pathLink: SINGLEKITCHENDETAILSGUESTVIEWPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST],
    componentName: <Singlekitchendetailsguestview />,
  },
  {
    id: 10,
    pathLink: RECIPEMANAGEMENTPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.HOST],
    componentName: <Recipeindex />,
    child: [
      {
        id: 1,
        pathLink: EMPTYPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Recipelists />,
      },
      {
        id: 2,
        pathLink: ADDRECIPEPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Upsertrecipe />,
      },
    ]
  },
  {
    id: 10013,
    pathLink: AUDITASSIGNMENTPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SUPERADMIN],
    componentName: <AuditassignmentIndex />,
    child: [ 
      {
        id: 1,
        pathLink: EMPTYPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SUPERADMIN],
        componentName: <Auditassignment component={AUDITASSIGNMENTINITIALVISITPATH} />,
      },
      {
        id: 2,
        pathLink: AUDITASSIGNMENTANNOUNCEDVISITPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SUPERADMIN],
        componentName: <Auditassignment component={AUDITASSIGNMENTANNOUNCEDVISITPATH} />,
      },
      {
        id: 2,
        pathLink: AUDITASSIGNMENTUNANNOUNCEDVISITPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SUPERADMIN],
        componentName: <Auditassignment component={AUDITASSIGNMENTUNANNOUNCEDVISITPATH} />,
      },
    ]
  },

  {
    id: 11,
    pathLink: REVIEWSANDFEEDBACKPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST, USERROLES.HOST],
    componentName: <Reviewsandfeedbackindex />,
    child: [
      {
        id: 1,
        pathLink: OVERALLRATINGSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.GUEST, USERROLES.HOST],
        componentName: <Overallratings />,
      },

      {
        id: 2,
        pathLink: RATINGRECORDSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST],
        componentName: <Ratingrecords />,
      },
      {
        id: 3,
        pathLink: RATEGUESTPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Rateguest />,
      },
      {
        id: 3,
        pathLink: RATEHOSTPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.GUEST],
        componentName: <Ratehost />,
      },
      {
        id: 4,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.GUEST, USERROLES.HOST],
        componentName: <Navigate to={OVERALLRATINGSPATH} />
      },
    ]
  },
  {
    id: 12,
    pathLink: MENUPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.HOST],
    componentName: <Menuindex />,
    child: [
      {
        id: 1,
        pathLink: MENUPREVIEWPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Menuitems />,
      },
      {
        id: 2,
        pathLink: CREATEMENUPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Upsertmenu />,
      },
      {
        id: 3,
        pathLink: EDITMENUPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Upsertmenu />,
      },

      {
        id: 4,
        pathLink: ADDFOODITEMPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Upsertfooditem />,
      },
      {
        id: 5,
        pathLink: FOODPREVIEWPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Fooditempreview />,
      },

      {
        id: 6,
        pathLink: EDITFOODITEMPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Upsertfooditem />,
      },
      {
        id: 7,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Navigate to={MENUPREVIEWPATH} />
      },
    ]
  },
  {
    id: 13,
    pathLink: MYINVOICEPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.HOST],
    componentName: <Invoiceindex />,
    child: [
      {
        id: 1,
        pathLink: EMPTYPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Invoicerecord />,
      },
      {
        id: 2,
        pathLink: SINGLEINVOICEDETAILPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.HOST],
        componentName: <Singleinvoicedetail />,
      }
    ]
  },
  {
    id: 15,
    pathLink: APIINVENTORYPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SUPERADMIN],
    componentName: <Apiinventory />,
  },
  {
    id: 16,
    pathLink: AUDITLOGSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SUPERADMIN],
    componentName: <Auditlogs />,
  },
  {
    id: 17,
    pathLink: BACKUPPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SUPERADMIN],
    componentName: <Backup />,
  },
  {
    id: 177,
    pathLink: HOSTLISTINGSADMINVIEWPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SUPERADMIN],
    componentName: <Hostlistingadminview />,
  },
  {
    id: 178,
    pathLink: HOSTBOOKINGSADMINVIEWPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SUPERADMIN],
    componentName: <Hostbookingadminview />,
  },
  {
    id: 18,
    pathLink: MANAGEPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SUPERADMIN],
    componentName: <Manageindex />,

    child: [
      {
        id: 1,
        pathLink: EMPTYPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SUPERADMIN],
        componentName: <Managee />,
      },
      {
        id: 2,
        pathLink: MANAGEUSERSTYPESPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SUPERADMIN],
        componentName: <Manageuserlists />,
      }
    ]
    
  },
  {
    id: 180,
    pathLink: AUDITSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.COMPLIANCE],
    componentName: <Auditslists />,
  },
  {
    id: 100,
    index: true,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST, USERROLES.HOST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    componentName: <Navigate to={HOMEPATH} />
  },


  {
    id: 10000,
    pathLink: MYWHISHLISTPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST],
    componentName: <Mywishlistsindex />,
    child: [
      {
        id: 100001,
        pathLink: EMPTYPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.GUEST],
        componentName: <Mywishlists />,
      },
      {
        id: 8090000,
        pathLink: MYWHISHLISTHOSTPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.GUEST],
        componentName: <Wishlistshost />,
      },
      {
        id: 91234,
        pathLink: MYWHISHLISTLISTINGSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.GUEST],
        componentName: <Wishlistslistings />,
      }
    ]
  }
];

export const authRoutes = [
  {
    id: 1,
    pathLink: SIGNINPATH,
    guard: AuthProtect,
    componentName: <Signin />,
  },
  {
    id: 2,
    pathLink: SIGNUPPATH,
    guard: AuthProtect,
    componentName: <Signup />,
  },
  {
    id: 3,
    pathLink: RESETPASSWORDPATH,
    guard: AuthProtect,
    componentName: <Resetpassword />,
  },
  {
    id: 4,
    pathLink: FORGOTPASSWORDPATH,
    guard: AuthProtect,
    componentName: <Forgotpassword />,
  },
  {
    id: 5,
    pathLink: EMAILVERIFICATIONPATH,
    guard: AuthProtect,
    componentName: <Emailverification />,
  },
]

export const routingData = [
  {
    id: 1,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.GUEST, USERROLES.HOST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    layout: MainLayout,
    parentRoutes: routesData
  },
  {
    id: 2,
    guard: AuthProtect,
    layout: AuthLayout,
    parentRoutes: authRoutes
  },
] 