import { Outlet } from "react-router-dom";

// MUI
import CssBaseline from "@mui/material/CssBaseline";

// Components
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import HeaderMobile from "../sidebar/sidebar-mobile/sidebar-mobile";
import Footer from "../footer/footer";


// SCSS
import "./main-layout.scss";
import { Grid } from "@mui/material";
import { AlertBreadcrumbs } from "../../components/alert-breadcrumbs/alert-breadcrumbs";
import { useMainLayout } from "./use-main-layout";
import { AppBar, body, Drawer, DrawerHeader, mainContainer, mobScreen, xsNonesmFlex } from "./main-layout-mui-style";
import { useAppSelector } from "../../store/store";
// Component start here
export default function MainLayout() {
  const { openDrawer }: any = useMainLayout();
  const { layout } = useAppSelector(state => state)
  return (
    <div className="main-layout">
      <CssBaseline />
      <Grid
        sx={mainContainer}
      >
        {/* header and sidebar for sm to  x-large screens */}
        <Grid
        >
          <AppBar className="app-bar" open={openDrawer}>
            <Header setMobDrawerValue={() => { }} />
          </AppBar>

          <Drawer sx={xsNonesmFlex}
            className="drawer" variant="permanent" open={openDrawer}>
            <Sidebar />
          </Drawer>

          <HeaderMobile />

        </Grid>

        {/* body of application */}
        <div className="main-layout-body">
          <div className="body-outlet">
            <Grid
              sx={body}
            >
              <DrawerHeader />
            </Grid>
            <div className="margin-outlet-x"  style={{ backgroundImage: `url(${layout?.backgroundImage})`}}>
              <AlertBreadcrumbs />
              <div >
              <Outlet />
              </div>
            </div>
          </div>
          <div className="body-footer">
            <Footer />
          </div>
        </div>
      </Grid>
    </div>
  );
}
