export const EMPTYPATH = "";
export const SLASHPATH = "/";
export const HASHPATH = "#"; 

// may be this path will not require in future 
export const MAILPATH = '/mail';

export const SIGNINPATH = "/authentication/signin";
export const SIGNUPPATH = "/authentication/signup";
export const RESETPASSWORDPATH = "/authentication/reset-password";
export const FORGOTPASSWORDPATH = "/authentication/forgot-password";
export const EMAILVERIFICATIONPATH = "/authentication/email-verify";

export const MYBOOKINGSPATH = "/bookings";
export const LOGISTICSPATH = "/logistics";
export const ANALYTICSANDREPORTSPATH = "/analytics-and-reports";
export const TRAININGSPATH = "/trainings";
export const MYCALENDARPATH = "/calendar";
export const HELPPATH = "/help";
export const ACCOUNTSETTINGSPATH = "/account-settings";
export const NOTIFICATIONSPATH = "/notifications";
export const HOMEPATH = "/home";
export const CHANGEPASSWORDPATH = "/change-password";
export const FOODPREFERENCEPATH = "/food-preference";
export const FOODPREFERENCESEARCHPATH = "/food-preference/:searchType";
export const APIINVENTORYPATH = '/api-inventory';
export const BACKUPPATH = '/back-up';
export const AUDITLOGSPATH = '/audit-logs';
export const MANAGEPATH = '/manage';
export const LOGOUTPATH = "/log-out";

export const MENUPATH = "/menu";
export const MENUPREVIEWPATH = "menu-preview";
export const CREATEMENUPATH = "create-menu";
export const EDITMENUPATH = "edit-menu/:menuId";
export const ADDFOODITEMPATH = "add-food-item/:menuId";
export const EDITFOODITEMPATH = "edit-food-item/:foodItemId/menu-id/:menuId";
export const FOODPREVIEWPATH = "food-preview/:menuId";

export const EDITMENUPARAMS = "edit-menu";
export const ADDFOODITEMPARAM = "add-food-item";
export const EDITFOODITEMPARAM = "edit-food-item";
export const EDITFOODITEMMENUIDPARAMS = "menu-id";
export const FOODPREVIEWPARAMS = "food-preview";

export const MYLISTINGSPATH = "/listings";
export const ADDLISTINGSPATH = "add-listings";
export const EDITLISTINGSPATH = "edit-listings/:listId";
export const SINGLELISTINGSPATH = "single-listing/:listId";
export const SINGLELISTINGSGUESTPATH = "/listings/:listId"

export const EDITLISTINGSPARAM = "edit-listings";
export const SINGLELISTINGSPARAM = "single-listing"
export const SINGLELISTINGSGUESTPARAM = "listings"

export const MYINVOICEPATH = "/invoice";
export const SINGLEINVOICEDETAILPATH = ":id";

export const MYWHISHLISTPATH ="/wishlists"
export const MYWHISHLISTHOSTPATH ="hosts/:id"
export const MYWHISHLISTLISTINGSPATH ="listings/:id"

export const MYWHISHLISTHOSTPARAM ="hosts"
export const MYWHISHLISTLISTINGSPARAM ="listings"


export const REVIEWSANDFEEDBACKPATH = "/reviews-and-feedback";
export const OVERALLRATINGSPATH = "overall-ratings";
export const RATINGRECORDSPATH = "rating-records";
export const RATEGUESTPATH = "rating-records/rate-guest/:id";
export const RATEHOSTPATH =  "rating-records/rate-host/:id";


export const RATEGUESTPARAM = "rating-records/rate-guest"
export const HOSTRATEHOSTPARAM = "rating-records/rate-host";


export const RECIPEMANAGEMENTPATH = "/recipe-management";
export const ADDRECIPEPATH = "add-recipe";

export const ITHELPDESKPATH = "/it-help-desk";
export const ITHELPDESKTICKETSRECORDPATH = "tickets-record";

export const UNAUTHORIZEDPATH = '/unauthorized';


export const AUDITASSIGNMENTPATH = '/audit-assignment';
export const AUDITASSIGNMENTINITIALVISITPATH = 'Initial-visit';
export const AUDITASSIGNMENTANNOUNCEDVISITPATH = 'announced-visit';
export const AUDITASSIGNMENTUNANNOUNCEDVISITPATH = 'unannounced-visit';

export const AUDITSPATH = '/audits';
export const HOSTUSERDETAILSPATH = '/host-details/:hostId'
export const HOSTDETAILSPARAMS = `/host-details`

export const SINGLEKITCHENDETAILSGUESTVIEWPATH = '/kitchen-details/:kitchenId'
export const SINGLEKITCHENDETAILSGUESTVIEWPARAM = 'kitchen-details'

export const MANAGEUSERSTYPESPATH = '/manage/:usersType'
export const MANAGEUSERSTYPESPARAM = '/manage'

export const HOSTPERSONALPROFILEDETAILSADMINVIEWPATH = '/host-personal-profile-detail/:hostId'
export const HOSTPERSONALPROFILEDETAILSADMINVIEWPARAM = '/host-personal-profile-detail'

export const HOSTLISTINGSADMINVIEWPATH = '/host-listing-view/:hostId'
export const HOSTLISTINGSADMINVIEWPARAM = '/host-listing-view'

export const HOSTBOOKINGSADMINVIEWPARAM = '/host-booking-view'
export const HOSTBOOKINGSADMINVIEWPATH = '/host-booking-view/:hostId'
