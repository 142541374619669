import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const setStatusToIdleAction = (state: any) => {
    state.addFacilityStatus = REQUESTSTATUS.IDLE;
}

export const getFacilityListThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log(payload);
        });
}

export const getFacilityListThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // state.status = REQUESTSTATUS.SUCCEEDED;
            state.facilitiesData = payload;
            // console.log('Successfully got facilities',payload);
            state.status = 'done'; // changing status names to avoid duplicate loaders

        });
}
export const getFacilityListThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            // state.status = REQUESTSTATUS.PENDING;
            state.status = 'loading';
        });

}

export const addNewFacilityThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.addFacilityStatus = REQUESTSTATUS.FAILED;
            // console.log('Failed', payload);
        });
}

export const addNewFacilityThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log('success', payload);
            state.addFacilityStatus = REQUESTSTATUS.SUCCEEDED;
        });
}
export const addNewFacilityThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.addFacilityStatus = REQUESTSTATUS.PENDING;
        });

}








export const saveMyFacilityThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log(payload);
        });
}

export const saveMyFacilityThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
            // console.log(payload);
        });
}
export const saveMyFacilityThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;
        });

}
