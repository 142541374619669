import { createAsyncThunk } from "@reduxjs/toolkit";
import { WISHLISTSAPI } from "../../constants/axios-api-constant";
import {
  deleteDataAPI,
  getDataAPI,
  postDataAPI,
  putDataAPI,
} from "../../lib/axios";

export const getWishlistsThunk = createAsyncThunk(
  "get-wishlists",
  async ({ axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(WISHLISTSAPI, axiosInstance);
      // console.log(
      //   "🚀 ~ file: wishlists-thunk-actions.tsx:17 ~ response",
      //   response
      // );
      return response.data.userWishList;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };

      return rejectWithValue(errorData);
    }
  }
);
export const getWishlistByIdThunk = createAsyncThunk(
  "get-wishlist-by-id",
  async ({ axiosInstance, wishlistId }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${WISHLISTSAPI}/${wishlistId}`,
        axiosInstance
      );
      // console.log(
      //   "🚀 ~ file: wishlists-thunk-actions.tsx:36 ~ response",
      //   response
      // );
      return response.data.data;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const postWishlistsThunk = createAsyncThunk(
  "post-wishlists",
  async (
    { dispatch, wishlistCredentials, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        WISHLISTSAPI,
        axiosInstance,
        wishlistCredentials
      );
      openAlert("Wishlist created Successfully", "alert-success-message");
      dispatch(getWishlistsThunk({ axiosInstance }));
      return response.data.userWishList;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const deleteWishlistsThunk = createAsyncThunk(
  "delete-listings",
  async (
    { dispatch, axiosInstance, wishlistId, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(
        `${WISHLISTSAPI}/${wishlistId}`,
        axiosInstance
      );

      openAlert("Wishlist Deleted Successfully", "alert-success-message");
      dispatch(getWishlistsThunk({ axiosInstance }));
      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const editWishlistsThunk = createAsyncThunk(
  "edit-listings",
  async (
    {
      dispatch,
      axiosInstance,
      wishlistId,
      openAlert,
      wishlistCredentials,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        `${WISHLISTSAPI}/${wishlistId}`,
        axiosInstance,
        wishlistCredentials
      );
      openAlert("Wishlist Updated Successfully", "alert-success-message");
      dispatch(getWishlistsThunk({ axiosInstance }));
      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const putWishlistsListingsThunk = createAsyncThunk(
  "add-listing-in-wishlist",
  async (
    { axiosInstance, id, openAlert, listingId, setShowListingDialog }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        `${WISHLISTSAPI}/${id}/listing/${listingId}`,
        axiosInstance
      );
      setShowListingDialog(false);
      openAlert(
        "Listing added in wishlist Successfully",
        "alert-success-message"
      );

      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const putWishlistsHostsThunk = createAsyncThunk(
  "add-host-in-wishlist",
  async (
    { axiosInstance, id, openAlert, hostId, setShowListingDialog }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        `${WISHLISTSAPI}/${id}/host/${hostId}`,
        axiosInstance
        // wishlistCredentials
      );
      setShowListingDialog(false);
      openAlert("Host added in wishlist Successfully", "alert-success-message");

      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const deleteWishlistsHostsThunk = createAsyncThunk(
  "delete-host-in-wishlist",
  async (
    { axiosInstance, id, openAlert, hostId, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        `${WISHLISTSAPI}/${id}/removehost/${hostId}`,
        axiosInstance
        // wishlistCredentials
      );
      dispatch(getWishlistByIdThunk({ axiosInstance, wishlistId: id }));
      openAlert("Host deleted successfully", "alert-success-message");

      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const deleteWishlistsListingsThunk = createAsyncThunk(
  "remove-listing-in-wishlist",
  async (
    { axiosInstance, id, openAlert, listingId, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        `${WISHLISTSAPI}/${id}/removelisting/${listingId}`,
        axiosInstance
      );
      dispatch(getWishlistByIdThunk({ axiosInstance, wishlistId: id }));
      openAlert("Listing deleted successfully", "alert-success-message");

      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);
