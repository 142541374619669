import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getWishlistsThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const getWishlistsThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.wishlistsData = payload;
  });
};
export const getWishlistsThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const getWishlistsByIdThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const getWishlistsByIdThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.singleWishlistData = payload
  });
};
export const getWishlistsByIdThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};
