import { createAsyncThunk } from "@reduxjs/toolkit"
import { USERNOTIFICATIONSAPI, USERNOTIFICATIONSDELETEALLAPI, USERNOTIFICATIONSDELETEAPI, USERNOTIFICATIONSLATESTAPI, USERNOTIFICATIONSREADALLAPI, USERNOTIFICATIONSREADAPI, 
    USERNOTIFICATIONSUNREADALLAPI, USERNOTIFICATIONSUNREADAPI } from "../../constants/axios-api-constant"
import { getDataAPI, patchDataAPI } from "../../lib/axios"

export const getUserNotificationsThunk = createAsyncThunk("layout/user-notifications",
    async ({ axiosInstance, limit, offset }: any, { rejectWithValue }: any) => {
        try {
            const response: any = await getDataAPI(`${USERNOTIFICATIONSAPI}?limit=${limit}&offset=${offset}`, axiosInstance)
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                notifications: formatData?.notifications,
                notificationsMeta: formatData?.meta
            }
            return responseData
        } catch (error) {
            // console.log(error)
            const errorData = {
                message: 'error'
            }
            rejectWithValue(errorData)
        }
    }
)
export const getUserLatestNotificationsThunk = createAsyncThunk("layout/user-notifications-latest",
    async ({ axiosInstance }: any, { rejectWithValue }: any) => {
        try {
            const response: any = await getDataAPI(USERNOTIFICATIONSLATESTAPI, axiosInstance)
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                locations: formatData
            }
            return responseData
        } catch (error) {
            // console.log(error)
            const errorData = {
                message: 'error'
            }
            rejectWithValue(errorData)
        }
    }
)
export const markUserNotificationsReadThunk = createAsyncThunk("layout/user-notifications-read",
    async ({ axiosInstance }: any, { rejectWithValue }: any) => {
        try {
            const response: any = await patchDataAPI(USERNOTIFICATIONSREADAPI, axiosInstance)
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                locations: formatData
            }
            return responseData
        } catch (error) {
            // console.log(error)
            const errorData = {
                message: 'error'
            }
            rejectWithValue(errorData)
        }
    }
)
export const markUserNotificationsReadAllThunk = createAsyncThunk("layout/user-notifications-read-all",
    async ({ axiosInstance }: any, { rejectWithValue }: any) => {
        try {
            const response: any = await patchDataAPI(USERNOTIFICATIONSREADALLAPI, axiosInstance)
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                locations: formatData
            }
            return responseData
        } catch (error) {
            // console.log(error)
            const errorData = {
                message: 'error'
            }
            rejectWithValue(errorData)
        }
    }
)
export const markUserNotificationsUnReadAllThunk = createAsyncThunk("layout/user-notifications-unread-all",
    async ({ axiosInstance }: any, { rejectWithValue }: any) => {
        try {
            const response: any = await patchDataAPI(USERNOTIFICATIONSUNREADALLAPI, axiosInstance)
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                locations: formatData
            }
            return responseData
        } catch (error) {
            // console.log(error)
            const errorData = {
                message: 'error'
            }
            rejectWithValue(errorData)
        }
    }
)
export const markUserNotificationsUnReadThunk = createAsyncThunk("layout/user-notifications-unread",
    async ({ axiosInstance }: any, { rejectWithValue }: any) => {
        try {
            const response: any = await patchDataAPI(USERNOTIFICATIONSUNREADAPI, axiosInstance)
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                locations: formatData
            }
            return responseData
        } catch (error) {
            // console.log(error)
            const errorData = {
                message: 'error'
            }
            rejectWithValue(errorData)
        }
    }
)
export const markUserNotificationsDeleteThunk = createAsyncThunk("layout/user-notifications-delete",
    async ({ axiosInstance }: any, { rejectWithValue }: any) => {
        try {
            const response: any = await patchDataAPI(USERNOTIFICATIONSDELETEAPI, axiosInstance)
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                locations: formatData
            }
            return responseData
        } catch (error) {
            // console.log(error)
            const errorData = {
                message: 'error'
            }
            rejectWithValue(errorData)
        }
    }
)
export const markUserNotificationsDeleteAllThunk = createAsyncThunk("layout/user-notifications-delete-all",
    async ({ axiosInstance }: any, { rejectWithValue }: any) => {
        try {
            const response: any = await patchDataAPI(USERNOTIFICATIONSDELETEALLAPI, axiosInstance)
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                locations: formatData
            }
            return responseData
        } catch (error) {
            // console.log(error)
            const errorData = {
                message: 'error'
            }
            rejectWithValue(errorData)
        }
    }
)