import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {  IBOOKINGSTATE } from "./booking-slice-interface";




export const bookingsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: IBOOKINGSTATE) => {
        state.status = REQUESTSTATUS.PENDING
    });
};

export const bookingsButtonStatusThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: IBOOKINGSTATE) => {
        state.buttonStatus = REQUESTSTATUS.PENDING
    });
};

export const bookingsModalStatusThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: IBOOKINGSTATE) => {
        state.modalStatus = REQUESTSTATUS.PENDING
    });
};

export const bookingsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: IBOOKINGSTATE, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.bookingsData = payload.bookingsData;
            state.page=payload.meta.page;
            state.pages=payload.meta.pages;
    });
};

export const bookingsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: IBOOKINGSTATE, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED
    });
};


export const acceptBookingRequestThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: IBOOKINGSTATE, { payload }: any) => {
            state.buttonStatus = REQUESTSTATUS.SUCCEEDED;
            const singleBooking = payload.singleBooking;
            let foundIndex = state.bookingsData.findIndex((x: any) => x._id === singleBooking._id);
            state.bookingsData[foundIndex] = singleBooking;
    });
};

export const acceptBookingRequestThunkRejectReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: IBOOKINGSTATE, { payload }: any) => {
            state.buttonStatus = REQUESTSTATUS.FAILED
    });
}




export const rejectBookingRequestThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase( action.fulfilled, (state: IBOOKINGSTATE, { payload }: any) => {
            state.buttonStatus = REQUESTSTATUS.SUCCEEDED;
            const singleBooking = payload.singleBooking;
            let foundIndex = state.bookingsData.findIndex((x: any) => x._id === singleBooking._id);
            state.bookingsData[foundIndex] = singleBooking;
    });
};

export const rejectBookingRequestThunkRejectReducer = (builder: any, action: any) => {
    builder.addCase( action.rejected, (state: IBOOKINGSTATE, { payload }: any) => {
            state.buttonStatus = REQUESTSTATUS.FAILED
    });
}


export const cancelBookingRequestThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: IBOOKINGSTATE, { payload }: any) => {
            state.buttonStatus = REQUESTSTATUS.SUCCEEDED;
            const singleBooking = payload.singleBooking;
            let foundIndex = state.bookingsData.findIndex((x: any) => x._id === singleBooking._id);
            state.bookingsData[foundIndex] = singleBooking;
    });
};

export const cancelBookingRequestThunkRejectReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: IBOOKINGSTATE, { payload }: any) => {
            state.buttonStatus = REQUESTSTATUS.FAILED
    });
}

export const bookingDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: IBOOKINGSTATE, { payload }: any) => {
            state.modalStatus = REQUESTSTATUS.SUCCEEDED
            state.bookingDetails = payload.bookingDetails
    });
};

export const bookingDetailsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: IBOOKINGSTATE, { payload }: any) => {
            state.modalStatus = REQUESTSTATUS.FAILED
    });
};



export const confirmBookingRequestThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IBOOKINGSTATE, { payload }: any) => {
            state.buttonStatus = REQUESTSTATUS.SUCCEEDED
            state.bookingsData = []
        });
};

export const confirmBookingRequestThunkRejectReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IBOOKINGSTATE, { payload }: any) => {
            state.buttonStatus = REQUESTSTATUS.FAILED
        });
}











export const postBookingRequestThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: IBOOKINGSTATE, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED
            state.bookingsData = payload
        });
};

export const postBookingRequestThunkRejectReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IBOOKINGSTATE, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED
        });
}










