import { Autocomplete, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import TabView from "../common/tab-view/tab-view"
import { Fragment, useEffect, useState } from 'react'
import { foodPreferenceData } from "../../../mock-data/food-preferences";
import { FlexibleItemCard } from "../../../components/flexible-item-card/flexible-item-card";
import { FoodSearchFilter } from "../food-search-filter/food-search-filter";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useAxiosInterceptors } from "../../../hooks/use-axios-interceptors";
import { useAppAlert } from "../../../components/app-alert/use-app-alert";
import {
  getSelectedFoodPreferenceLocationSearchDetailsThunk, searchlocationforFoodPreferenceLocationSearchThunk,
  searchFoodPreferencesByLocationThunk
} from "../../../store/food-preference/food-preference-thunk-actions";

import Info from "@mui/icons-material/Info";
import { MUIERRORCOLOR } from "../../../constants/mui-styles-constant";
import SearchIcon from "@mui/icons-material/Search";
import { AppButton } from "../../../components/app-button/app-button";
import { setGeoLocationCoordinate, setLocationForSearch } from "../../../store/food-preference/food-preference-slice";
import { SINGLEKITCHENDETAILSGUESTVIEWPARAM, SLASHPATH } from "../../../constants/routes-constant";
import { IMAGEBASEURL, REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../../components/app-loader/app-loader";

export const SearchFoodByLocation = () => {

  const { foodPreference } = useAppSelector((state) => state)
  const axiosInstance = useAxiosInterceptors()
  const [searchParam, setSearchParam] = useState('')
  const [locationAddress, setLocationAddress] = useState('')
  const [locationCoordinate, setLocationCoordinate] = useState<any>('')
  const { openAlert } = useAppAlert();
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getSelectedLocationDetails = (id: any, location: any, set?: any) => {
    // console.log("id")
    dispatch(setGeoLocationCoordinate({
      longitude: "",
      latitiude: ""
    }))
    setLocationAddress?.(location)
    dispatch(setLocationForSearch(location))
    dispatch(getSelectedFoodPreferenceLocationSearchDetailsThunk({ axiosInstance, locationId: id , openAlert}))
  }
  useEffect(() => {
    // dispatch(getKitchenDetailsThunk({axiosInstance}))
    if (!!searchParam) {
      dispatch(searchlocationforFoodPreferenceLocationSearchThunk({ axiosInstance, searchParam: searchParam , openAlert }))
    }
  }, [dispatch, searchParam])

  const setSearchLocation = () => {
    // console.log(locationAddress)
    dispatch(setLocationForSearch(locationAddress))
    const searchQueryLocation = {
      location: {
        address: locationAddress,

      },
      filter: foodPreference.filters,
      date: {
        date: foodPreference?.dateForSearch,
        time: foodPreference?.timeForSearch
      },
      guests: {
        noofGuests: foodPreference?.numberOfGuests
      }
    }
    // console.log(!!searchQueryLocation?.date)
    // console.log(searchQueryLocation?.date)
    if (!!!searchQueryLocation?.location) return openAlert('Select location first');
    if (!!!searchQueryLocation?.date?.date) return openAlert('Select date first'); // if optional 
    if (!!!searchQueryLocation?.date?.time) return openAlert('Select time first');  // if optional
    if (!!!searchQueryLocation?.guests?.noofGuests) return openAlert('Select guests first'); // if optional
    // openAlert('Go ahead')
    dispatch(searchFoodPreferencesByLocationThunk({ locationSearchParameters: searchQueryLocation, axiosInstance,openAlert }))
    // console.log(searchQueryLocation)
    // return;
  }

  const getUserLocationCoordinate = () => {
    // console.log('hi')
    if (!navigator.geolocation) return openAlert('Please porvide location permission');
    navigator.geolocation.getCurrentPosition(function (position) {
      // console.log(position)
      // console.log("Latitude is :", position.coords.latitude);
      // console.log("Longitude is :", position.coords.longitude);
      dispatch(setLocationForSearch("")) // if host near me selected then empty address field

      dispatch(setGeoLocationCoordinate({
        longitude: position.coords.latitude,
        latitiude: position.coords.longitude
      }))
      setLocationCoordinate({
        longitude: position.coords.latitude,
        latitiude: position.coords.longitude
      })
      const searchQueryLocation = {
        location: {
          address: "",
          geolocation: {
            longitude: position.coords.latitude,
            latitiude: position.coords.longitude
          }
        },
        date: {
          date: foodPreference?.dateForSearch,
          time: foodPreference?.timeForSearch
        },
        filter: foodPreference.filters,
        guests: {
          noofGuests: foodPreference?.numberOfGuests
        }
      }
      // console.log(!!searchQueryLocation?.date)
      // console.log(searchQueryLocation?.date)
      if (!!!searchQueryLocation?.location?.geolocation?.longitude) return openAlert('Select location coordinate first');
      if (!!!searchQueryLocation?.date?.date) return openAlert('Select date first'); // if optional 
      if (!!!searchQueryLocation?.date?.time) return openAlert('Select time first');  // if optional
      if (!!!searchQueryLocation?.guests?.noofGuests) return openAlert('Select guests first'); // if optional
      // openAlert('Go ahead')
      dispatch(searchFoodPreferencesByLocationThunk({ locationSearchParameters: searchQueryLocation, axiosInstance, openAlert }))
      dispatch(setGeoLocationCoordinate({
        longitude: position.coords.latitude,
        latitiude: position.coords.longitude
      }))
    }, (err: any) => {
      // console.log(err)
      if (err.code === 1) return openAlert('Please allow location')
    }
    );
    // console.log(locationAddress)
    dispatch(setLocationForSearch(locationAddress))
    // const searchQueryLocation = {
    //   // location: locationAddress,
    //   geoloc: locationCoordinate,
    //   date: {
    //     date: foodPreference?.dateForSearch,
    //     time: foodPreference?.timeForSearch
    //   },
    //   filters: foodPreference.filters,
    //   guests: {
    //     noofGuests: foodPreference?.numberOfGuests
    //   }
    // }
    // console.log(!!searchQueryLocation?.date)
    // console.log(searchQueryLocation?.date)
    // // if (searchQueryLocation?.geoloc === '') return openAlert('Select location coordinate first');
    // if (!!!searchQueryLocation?.date?.date) return openAlert('Select date first'); // if optional 
    // if (!!!searchQueryLocation?.date?.time) return openAlert('Select time first');  // if optional
    // if (!!!searchQueryLocation?.guests?.noofGuests) return openAlert('Select guests first'); // if optional
    // // openAlert('Go ahead')
    // dispatch(searchFoodPreferencesByLocationThunk({ locationSearchParameters: searchQueryLocation, axiosInstance }))
    // console.log(searchQueryLocation)
  }
  return (
    <>
      {/* <FoodSearchFilter /> */}
      <></>
      <Grid
        className="card-views margin-top-3 margin-top-sm"
        container
        rowSpacing={2}
        columnSpacing={{ xs: 0, sm: 0, md: 2 }}
        minHeight='700px'
      >
        <Grid
          item
          className="box-shadow-global border-radius-20 padding-left-none"
          xs={12}
          sm={12}
          md={12}
          xl={4.5}
        >
          <Grid container spacing={2}
            className="search-header bg-black padding-bottom-1 border-radius-20-top margin-left-none width-100">

            <Grid item xs={0} md={1}></Grid>
            <Grid item xs={12} md={7} textAlign={{xs:'center', md:'start'}} className="padding-right-1">
              <Autocomplete
                size='small'
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '20px !important',
                    backgroundColor: 'white'
                  },
                  // "& .MuiInputAdornment-root": {
                  //   color: "blue"
                  // }
                }}
                options={foodPreference?.locations || []}
                // defaultValue={
                //     foodPreference?.kitchenDetails?.defaultValue
                // }
                // value={iValue}
                // defaultValue={
                //   {locationAddress:kitchen?.kitchenDetails?.location?.address?.locationName, 
                //   loctaionId:kitchen?.kitchenDetails?.location?.address?.locationId}
                // }
                disableClearable
                forcePopupIcon={false}
                getOptionLabel={(option: any) => option?.locationAddress}
                renderOption={(props: any, option: any) => (
                  <li key={option?.loctaionId} {...props}>
                    {option?.locationAddress}
                  </li>
                )}
                filterOptions={(x) => x}
                clearOnBlur={false}
                onInputChange={(e: any) => {  setSearchParam(e?.target?.value); }}
                onChange={
                  (e: any, option: any) => {
                    getSelectedLocationDetails(option?.loctaionId, option?.locationAddress)
                  }
                }
                renderInput={(params: any) => <TextField
                  {...params}
                  fullWidth
                  placeholder="Street, town or postcode"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end" onClick={() => setSearchLocation()}>
                        <IconButton className="">
                          {/* <SearchIcon /> */}
                          {/* <span className="orange-yellowish-color primary-title"> | {" "}</span> */}
                          <span className="orange-yellowish-color secondary-title">Go</span>
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />}
              />
            </Grid>
            <Grid item xs={12} md={3} marginTop="5px" textAlign={{xs:'center', md:'start'}}>

              <AppButton
                className="host-btn white-color orange-yellowish-bg-color border-radius-20 tertiary-title"
                btnText="Host Near Me"
                btnFunction={() => getUserLocationCoordinate()}
              />
            </Grid>
          </Grid>
          <div className="padding-left-sm padding-right-sm">
          <FoodSearchFilter
            spacingGrid={1} emptyMd={0.001} priceRangeMd={3} offerMd={2.8} dietaryMd={3}
            findMoreMd={3} emptySecondMd={0.1} emptyLg={0.00001} priceRangeLg={3} offerLg={2.8} dietaryLg={3}
            findMoreLg={3} emptySecondLg={0.1}
            />
            </div>
          {
            foodPreference?.locationSearchStatus === REQUESTSTATUS.PENDING ? <AppLoader /> :

              <div className="food-preference-div">
                <div className="scroll-bar">
                  {!!foodPreference?.locationBasedFoods?.length ?
                    foodPreference?.locationBasedFoods?.map((singleKitchenItem: any) => {
                      return (
                        <div key={singleKitchenItem?._id} className="margin-right-0 margin-left-0">
                          <FlexibleItemCard
                            foodImg={`${IMAGEBASEURL}${singleKitchenItem?.selectedCoverPhoto}`}
                            foodName={singleKitchenItem?.name}
                            foodLocation={
                              singleKitchenItem?.location?.address?.locationName
                            }
                            hostName={singleKitchenItem?.userName}
                            foodRating={singleKitchenItem?.userRating?.toFixed(1)}
                            closeTime={singleKitchenItem?.servingTimeTo}
                            openTime={singleKitchenItem?.servingTimeFrom}
                            recipeName='About Us'
                            recipeDescription={singleKitchenItem?.recipeDescription}
                            // maxWidth="500px"
                            // minWidth="244px"
                            gridClassName="height-100"
                            mainFunction={() =>
                              navigate(
                                `${SLASHPATH}${SINGLEKITCHENDETAILSGUESTVIEWPARAM}${SLASHPATH}${singleKitchenItem?._id}`
                              )
                            }

                          />
                          {/* <FoodImageCard foodImg={foodData.foodImg}
                        foodName={foodData.foodName}
                        foodLocation={foodData.foodLocation}
                        hostName={foodData.hostName}
                        foodRating={foodData.foodRating}
                        timingStatus={foodData.timingStatus}
                        timing={foodData.timing}
                        recipeName={foodData.recipeName}
                        recipeDescription={foodData.recipeDescription} /> */}
                        </div>
                      );
                    }) :
                    <div className="center-text margin-auto-x secondary-heading margin-top-3 margin-top-sm"> No <span className="primary-color"> host  </span> found </div>
                  }
                </div>
              </div>
          }
        </Grid>
        <Grid xs item></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={7}
        >
          <iframe className="map-frame"
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="100%" frameBorder="0" style={{ border: 0 }}></iframe>
        </Grid>
      </Grid>
    </>
  )
}