import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const trainingDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log(payload);
            // state.error = payload.error;
        });
}

export const trainingDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED; 
            state.mandatory = payload.mandatory;
            state.optional = payload.optional;
            state.total = payload.total;            
        });
}
export const trainingDataThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;
        });

}


export const completeTrainingThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log(payload);
            // state.error = payload.error;
        });
}

export const completeTrainingThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;                        
        });
}
export const completeTrainingThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;
        });
}
