import { createSlice } from "@reduxjs/toolkit";
import {
    addNewFacilityThunkFulfilledReducer, addNewFacilityThunkPendingReducer,
    addNewFacilityThunkRejectedReducer, getFacilityListThunkFulfilledReducer,
    getFacilityListThunkPendingReducer, getFacilityListThunkRejectedReducer,
    saveMyFacilityThunkFulfilledReducer, saveMyFacilityThunkPendingReducer,
    saveMyFacilityThunkRejectedReducer,
    setStatusToIdleAction
} from "./facilities-data-reducers";
import { addNewFacilityThunk, getFacilityListThunk, saveMyFacilityThunk } from "./facilities-list-actions";


const initialState = {
    status: 'idle',
    facilitiesData: [],

}

export const facilitiesSlice = createSlice(
    {
        name: 'facilities',
        initialState,
        reducers: {
            setStatusToIdle:setStatusToIdleAction
        },
        extraReducers(builder) {

            getFacilityListThunkRejectedReducer(builder, getFacilityListThunk)
            getFacilityListThunkFulfilledReducer(builder, getFacilityListThunk)
            getFacilityListThunkPendingReducer(builder, getFacilityListThunk)

            addNewFacilityThunkRejectedReducer(builder, addNewFacilityThunk)
            addNewFacilityThunkFulfilledReducer(builder, addNewFacilityThunk)
            addNewFacilityThunkPendingReducer(builder, addNewFacilityThunk)


            saveMyFacilityThunkRejectedReducer(builder, saveMyFacilityThunk)
            saveMyFacilityThunkFulfilledReducer(builder, saveMyFacilityThunk)
            saveMyFacilityThunkPendingReducer(builder, saveMyFacilityThunk)


        }
    }
)
export const { setStatusToIdle } = facilitiesSlice.actions;
export default facilitiesSlice.reducer;