
export const tooltipComponenetsProps = {
    tooltip: { sx: { maxWidth: '192px', marginTop: "33px !important", padding: '0 !important' } },
    arrow: {
        sx: {
            fontSize: 18, width: 20, color: '#FFFFFF',
            "&::before": {
                boxSizing: "border-box",
                boxShadow: 1
            }
        }
    }
}
