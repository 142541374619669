import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataAPI, postDataAPI } from "../../lib/axios";
import { groupBy } from "../../utils/hooks-utils";
import { updatePersonalUserProfileStatusThunk } from "../personal-user/personal-user-thunk-actions";

export const getFacilityListThunk = createAsyncThunk('facilities/list-facility',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        // console.log(axiosInstance)
        try {
            const response = await getDataAPI("user-profile/list-facility", axiosInstance)
            // console.log(response);
            let groupedData: any[] = [];
            const groupFacilityByCategory = groupBy(response.data.data, "category");
            const selectedFacilities = Object.entries(groupFacilityByCategory).map(
                ([category, facility]) => ({ category, facility }));
            selectedFacilities.forEach((i: any) => groupedData.push(...i.facility))

            // return response.data.data;
            // console.log(groupedData);

            return groupedData;
        } catch (error: any) {
            // console.log('catch', error);
            const errorData = {
                error: error.response.data.message
            }
            return rejectWithValue(errorData)
        }
    }
)


export const addNewFacilityThunk = createAsyncThunk('facilities/add-new-facility',
    async ({ formData, axiosInstance, openAlert , dispatch }: any, { rejectWithValue }) => {
        // console.log(formData)
        try {
            const response = await postDataAPI("user-profile/add-new-facility", axiosInstance, formData)
            const responseData = {
                success: response.data.message
            }
            openAlert(responseData.success, 'alert-success-message')
            dispatch(getFacilityListThunk({ axiosInstance }))
            return responseData;
        } catch (error: any) {
            // console.log('catch', error);
            const errorData = {
                error: error.response.statusText ? error.response.statusText : error.response.data.message
            }
            openAlert(errorData.error)
            return rejectWithValue(errorData)
        }
    }
)


export const saveMyFacilityThunk = createAsyncThunk('facilities/save-my-facility',
    async ({ facilityToBeSaved, axiosInstance, openAlert, dispatch , personalUser , setValue }: any, { rejectWithValue }) => {
        // console.log(facilityToBeSaved)
        try {
            const response = await postDataAPI("user-profile/save-my-facility", axiosInstance, facilityToBeSaved)
            // console.log(response);
            if (!personalUser?.profileCompletion?.facilities?.completed) {
                dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'facilities', openAlert }))
              }
              setValue(8)
            openAlert(response.data.message, 'alert-success-message')
            // return response;
        } catch (error: any) {
            // console.log('catch', error);
            const errorData = {
                error: error.response.data.message
            }
          
            openAlert(errorData.error, 'alert-error-message')
            return rejectWithValue(errorData)
        }
    }
)