import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { postbadgesThunkFulfilledReducer, postbadgesThunkPendingReducer, postbadgesThunkRejectedReducer } from "./badges-reducers";
import { IBADGESSTATE } from "./badges-slice-interface";
import { badgesPostThunk } from "./badges-thunk-action";


const initialState: IBADGESSTATE = {
    status: REQUESTSTATUS.IDLE,  //'idle' | 'loading' | 'succeeded' | 'failed'
    kitchenProfile: "",
    badgeIcon: false,
    selectedBadges: [],
}

export const badgesSlice = createSlice({
    name: "badges",
    initialState,
    reducers: {
        setBadgeIcon: (state, action: PayloadAction<boolean>) => {
            state.badgeIcon = action.payload;
        },
        selectedBadges: (state: any, { payload }: any) => {
            state.selectedBadges = payload
        }
    },
    extraReducers(builder) {
        postbadgesThunkRejectedReducer(builder, badgesPostThunk)
        postbadgesThunkFulfilledReducer(builder, badgesPostThunk)
        postbadgesThunkPendingReducer(builder, badgesPostThunk)

    }
})

export const { selectedBadges, setBadgeIcon, } = badgesSlice.actions;

export const badgesSelect = (state: any) => state?.badge;

export default badgesSlice.reducer;

