import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { getHostContractPdfThunkFulfilledReducer, getHostContractPdfThunkPendingReducer, getHostContractPdfThunkRejectedReducer, hostIdVerficationInfoThunkFulfilledReducer, hostIdVerficationInfoThunkRejectedReducer, hostKitchenDetailsInfoThunkFulfilledReducer, hostKitchenDetailsInfoThunkRejectedReducer, hostlicenseInfoThunkFulfilledReducer, hostlicenseInfoThunkRejectedReducer, hostpersonalInfoThunkFulfilledReducer, hostpersonalInfoThunkRejectedReducer, hostribbonsInfoThunkFulfilledReducer, hostribbonsInfoThunkRejectedReducer, hosttrainingsInfoThunkFulfilledReducer, hosttrainingsInfoThunkRejectedReducer, ThunkPendingReducer } from "./host-details-reducers";
import { getHostContractPdfThunk, hostIdVerficationInfoThunk, hostKitchenDetailsInfoThunk, hostlicenseInfoThunk, hostpersonalInfoThunk, hostribbonsInfoThunk, hosttrainingsInfoThunk } from "./host-details-thunk-actions";




const hostDetailsInitialState = {

    status: REQUESTSTATUS.IDLE,
    userId:'',
    personalInfoDetails:{} as any,
    idVerificationDetails: {} as any,
    licenseInfoDetails: {} as any,
    kitchenDetails:{} as any,
    trainingsDetails: {} as any,
    ribbonsDetails:{} as any,
    badgesDetails:{} as any,
    facilitiesDetails:{} as any,
    houseRulesDetails:{} as any,
    hostSummaryDetails: {} as any,
    getContractStatus:REQUESTSTATUS.IDLE,
    contractPdfLink:'',

}

const hostDetailsSlice = createSlice({

    name: 'host-details',

    initialState : hostDetailsInitialState,

    reducers: {},

    extraReducers(builder) {
        //host personal info thunk reducers 
        ThunkPendingReducer(builder, hostpersonalInfoThunk)
        hostpersonalInfoThunkFulfilledReducer(builder, hostpersonalInfoThunk)
        hostpersonalInfoThunkRejectedReducer(builder, hostpersonalInfoThunk)

        ThunkPendingReducer(builder, hostKitchenDetailsInfoThunk)
        hostKitchenDetailsInfoThunkFulfilledReducer(builder, hostKitchenDetailsInfoThunk)
        hostKitchenDetailsInfoThunkRejectedReducer(builder, hostKitchenDetailsInfoThunk)

        ThunkPendingReducer(builder, hostIdVerficationInfoThunk)
        hostIdVerficationInfoThunkFulfilledReducer(builder, hostIdVerficationInfoThunk)
        hostIdVerficationInfoThunkRejectedReducer(builder, hostIdVerficationInfoThunk)

        ThunkPendingReducer(builder, hostlicenseInfoThunk)
        hostlicenseInfoThunkFulfilledReducer(builder, hostlicenseInfoThunk)
        hostlicenseInfoThunkRejectedReducer(builder, hostlicenseInfoThunk)

        ThunkPendingReducer(builder, hosttrainingsInfoThunk)
        hosttrainingsInfoThunkFulfilledReducer(builder, hosttrainingsInfoThunk)
        hosttrainingsInfoThunkRejectedReducer(builder, hosttrainingsInfoThunk)

        ThunkPendingReducer(builder, hostribbonsInfoThunk)
        hostribbonsInfoThunkFulfilledReducer(builder, hostribbonsInfoThunk)
        hostribbonsInfoThunkRejectedReducer(builder, hostribbonsInfoThunk)


        getHostContractPdfThunkPendingReducer(builder, getHostContractPdfThunk)
        getHostContractPdfThunkFulfilledReducer(builder, getHostContractPdfThunk)
        getHostContractPdfThunkRejectedReducer(builder, getHostContractPdfThunk)
    }
})

export const { } = hostDetailsSlice.actions;

export default hostDetailsSlice.reducer;