import Header from "../common/header/header";
import { SearchFoodByLocation } from "../search-food-by-location/search-food-by-location";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SearchFoodByCuisine } from "../search-food-by-cuisine/search-food-by-cuisine";
import { SearchFoodByHost } from "../search-food-by-host/search-food-by-host";
import { useAppDispatch } from "../../../store/store";
import { setDateForSearch, setHostForSearch, setLocationForSearch,setGeoLocationCoordinate, 
  setNumberOfGuests, setSelectedDietary, setSelectedFacilities, setSelectedFacilitiesForChildren, 
  setSelectedFacilitiesForDisables, setSelectedHostLanguageProficiences, setSelectedHouseRules, setSelectedOffers, 
  setSelectedPrice, setTimeForSearch } from "../../../store/food-preference/food-preference-slice";
import { hideBreadcrumb, showBreadcrumb } from "../../../store/breadcrumb/breadcrumb-slice";
import { breadcrumbData } from "./search-food-by-x-data";

export const SearchFoodByX = () => {
    const [locationValue, setLocationValue] = useState<string>("");
    const { searchType } = useParams();
    const [open , setOpen] = useState(true)
    const setOpenMenu =  ['Cuisine', 'Host']
  const dispatch = useAppDispatch()
    // console.log({searchType})

  useEffect(()=>{
      // dispatch(setDateForSearch(""))
      dispatch(setNumberOfGuests(0))
      dispatch(setTimeForSearch(""))
      dispatch(setSelectedDietary([]))
      dispatch(setSelectedFacilities([]))
      dispatch(setSelectedFacilitiesForDisables([]))
      dispatch(setSelectedFacilitiesForChildren( []))
      dispatch(setSelectedHouseRules( []))
      dispatch(setSelectedHostLanguageProficiences([]))
      dispatch(setHostForSearch({}))
      dispatch(setSelectedPrice({
        startFrom: 5,
        endAt: 15
    }))
    dispatch(setSelectedOffers([]))
    dispatch(setLocationForSearch(""))
    dispatch(setGeoLocationCoordinate({
      longitude: "",
      latitiude: ""
    }))
  },[searchType])

  useEffect(() => {
    dispatch(showBreadcrumb(breadcrumbData(searchType)));
    return () => {
      dispatch(hideBreadcrumb());
    };
  }, []);

    // console.log(locationValue, 'locationValue');
    return (
      <>
        <Header
          locationValue={searchType}
          setLocationValue={setLocationValue}
          open={setOpenMenu.includes(searchType as any)}
          setOpen={setOpen}
        />
        {/* {locationValue == 'Location' ? <CardViews /> : locationValue == 'Cuisine' ? <MainContainer /> : <MainContainer />} */}
        {searchType === 'Location' && <SearchFoodByLocation /> }
        {searchType === 'Cuisine' && <SearchFoodByCuisine /> }
        {searchType === 'Host' && <SearchFoodByHost /> }
      </>
    );
}