import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { referenceDataThunk } from "./reference-data-actions";
import { referenceDataThunkFulfilledReducer, referenceDataThunkPendingReducer, referenceDataThunkRejectedReducer, setReferenceDataReducer } from "./reference-data-reducer";


const referenceDataInitialState: any = {
    ribbons: [] as any,
    badges: [] as any,
    allergens: [] as any,
    food_types: [] as any,
    dietary_attribures: [] as any,
    cuisines: [] as any,
    bookingStatus:[] as any,
    userAuditTypes:[] as any,
    userAuditStatues:[] as any,
    userAuditFilterStatuses:[] as any,
    facilities:[] as any,
    houseRules:[] as any,
    status: REQUESTSTATUS.IDLE
};

export const referenceDataSlice = createSlice({
    name: "referenceData",
    initialState: referenceDataInitialState,
    reducers: {
        setReferenceData: setReferenceDataReducer
    },
    extraReducers(builder) {
        referenceDataThunkPendingReducer(builder, referenceDataThunk)
        referenceDataThunkFulfilledReducer(builder, referenceDataThunk)
        referenceDataThunkRejectedReducer(builder, referenceDataThunk)
    }
});

export const { setReferenceData } = referenceDataSlice.actions;

export const selectRibbons = (state: any) => state?.ribbons;

export default referenceDataSlice.reducer;