import { REQUESTSTATUS } from "../../constants/axios-api-constant";


export const  saveKitchenDetailsReducers = (state:any, action:any) => {
    state.kitchenDetails=action.payload 
}

export const ThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any ,  {payload}:any) => {
        // console.log(payload)
        state.status = REQUESTSTATUS.PENDING;
    });
  };
  
  export const getKitchenDetailsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any ,  {payload}:any) => {
        // console.log(payload)
        state.getKitchenStatus = REQUESTSTATUS.PENDING;
    });
  };

  export const getKitchenDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.getKitchenStatus = REQUESTSTATUS.SUCCEEDED;
      state.kitchenDetails = payload?.kitchenDetails;
      state.houseRules = payload?.houseRules;
      state.veriffStatus =payload?.veriffStatus;
      state.dbsCheck =payload?.dbsCheck;
      state.badgesId = payload?.badgesId;
      state.myFacilities =payload?.myFacilities;
      // state.kitchenLocations = [payload?.kitchenDetails?.defaultValue]
    });
  };
  
  export const getKitchenDetailsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
      state.getKitchenStatus = REQUESTSTATUS.FAILED;
      state.kitchenDetails = '';
      state.houseRules = '';
      state.veriffStatus = '';
      state.dbsCheck = '';
      state.badgesId = '';
      state.myFacilities = '';
  
    });
  };
  export const getKitchenLocationThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.SUCCEEDED;
      state.kitchenLocations = payload?.locations
      
    });
  };
  
    
  export const getKitchenLocationThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
      state.status = REQUESTSTATUS.FAILED;
  
    });
  };

  export const postKitchenDetailsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
      state.status = REQUESTSTATUS.FAILED;
  
    });
  };

  export const postKitchenDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.SUCCEEDED;
      
    });
  };

  export const getSelectedKitchenLocationDetailsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
      state.status = REQUESTSTATUS.FAILED;
  
    });
  };

  export const getSelectedKitchenLocationDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.status = REQUESTSTATUS.SUCCEEDED;
      state.coordinates = payload?.coordinates
      
    });
  };


  export const deleteKitchenDetailsImageThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.deleteKitchenImageStatus = REQUESTSTATUS.PENDING;
      
    });
  };

  export const deleteKitchenDetailsImageThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.deleteKitchenImageStatus = REQUESTSTATUS.SUCCEEDED;
      
    });
  };

  export const deleteKitchenDetailsImageThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
      state.deleteKitchenImageStatus = REQUESTSTATUS.FAILED;
  
    });
  };