import { createAsyncThunk } from "@reduxjs/toolkit"
import { HOUSERULESAPI, KITCHENDETAILSAPI } from "../../constants/axios-api-constant";
import { getDataAPI, patchDataAPI } from "../../lib/axios";
import { moveToTab } from "../../utils/switch-utils";
import { updatePersonalUserProfileStatusThunk } from "../personal-user/personal-user-thunk-actions";

export const houseRulesThunk = createAsyncThunk('account-settings',
    async ({ houseRuleCredentials, openAlert, axiosInstance , dispatch , personalUser, setValue }: any, { rejectWithValue }) => {
        // console.log(houseRuleCredentials)
        try {

            const response = await patchDataAPI(HOUSERULESAPI, axiosInstance, houseRuleCredentials)

            const responseData = {
                houseRules: response.data.data.houseRules
            }
            if (!personalUser?.profileCompletion?.houseRules?.completed) {
                dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'houseRules', openAlert }))
              }
            //   console.log(personalUser.profileCompletion)
              openAlert("House Rules saved successfully", 'alert-success-message')
            
            setTimeout(() => {
              moveToTab(personalUser.profileCompletion, setValue , openAlert)
              }, 4000)

            return responseData;
        } catch (error: any) {
            // console.log(error)
            const errorData = {
                error: error?.response?.data?.errors || ' server is not responding '
            }

            return rejectWithValue(errorData)
        }
    })

export const getHouseRuleDataThunk = createAsyncThunk('account-settings',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(KITCHENDETAILSAPI, axiosInstance)
            const responseData = {
                houseRules: response.data.data.houseRules,
            }

            return responseData;
        } catch (error: any) {
            return rejectWithValue(error)
        }
    }
)

export const updateHouseRuleDataThunk = createAsyncThunk('account-settings',
    async ({ houseRules }: any, { rejectWithValue }) => {
        try {
            const responseData = {
                houseRules
            }

            return responseData;
        } catch (error: any) {
            return rejectWithValue(error)
        }
    }
)