import { createAsyncThunk } from "@reduxjs/toolkit";
import { BOOKINGSAPI, GUESTREVIEWAPI, OVERALLRATING } from "../../constants/axios-api-constant";
import { RATINGRECORDSPATH, REVIEWSANDFEEDBACKPATH, SLASHPATH } from "../../constants/routes-constant";
import { getDataAPI, postDataAPI } from "../../lib/axios";


export const overallHostRatingThunk = createAsyncThunk('reviews-and-feedback/overall-ratings',
    async ({ rating, relevance, axiosInstance }: any, { rejectWithValue }) => {
        let response;
        try {
            response = await getDataAPI(`${OVERALLRATING}?latest=${relevance}&findstar=${rating}`, axiosInstance);

            const responseData = {
                data: response?.data?.data,
                message: response?.data?.message
            }
            return responseData?.data;

        } catch (error: any) {

            const errorData = {
                error: error?.message
            }
            return rejectWithValue(errorData)
        }
    })




export const getMyRatingOnModalBoxThunk = createAsyncThunk('get-my-rating',
    async ({ bookingId, axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`${BOOKINGSAPI}/${bookingId}/my-rating`, axiosInstance);

            const responseData = {
                data: response?.data?.data,
                message: response?.data?.message
            }
            return responseData;

        } catch (error: any) {
            const errorData = {
                error: error?.message
            }
            rejectWithValue(errorData)
        }
    }

)



export const getRatingOnRateNowThunk = createAsyncThunk('get-rating-rate-now',
    async ({ bookingId, axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`${BOOKINGSAPI}/${bookingId}/get-rating`, axiosInstance);

            const responseData = {
                data: response?.data?.data,
                message: response?.data?.message
            }
            return responseData;

        } catch (error: any) {
            const errorData = {
                error: error?.message
            }
            rejectWithValue(errorData)
        }
    }

)




export const guestReviewPostThunk = createAsyncThunk("reviews-and-feedback/rate-guest",
    async ({ guestReviewId, guestReviewPostData, openAlert, axiosInstance, }: any, { rejectWithValue }) => {
        // console.log(guestReviewId, guestReviewPostData);

        try {
            const response = await postDataAPI(`/${BOOKINGSAPI}/${guestReviewId}/${GUESTREVIEWAPI}`, axiosInstance, guestReviewPostData);

            const responseData = {
                message: response.data.message,
            };
            // console.log(response, "resssss");

            openAlert('Your review has been Submitted', 'alert-success-message')

            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error)

        }
    }
)



export const postRateHostThunk = createAsyncThunk("reviews-and-feedback/rate-host",
    async ({ rateExpereinceObjectData, bookingId, navigate, openAlert, axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(`${BOOKINGSAPI}/${bookingId}/rate-host`, axiosInstance, rateExpereinceObjectData);
            const responseData = {
                message: response?.data?.message,
            };
            openAlert(responseData?.message, 'alert-success-message')
            setTimeout(() => {
                return navigate(REVIEWSANDFEEDBACKPATH + SLASHPATH + RATINGRECORDSPATH);
            }, 3000);
            return responseData;

        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message
            }
            openAlert(errorData.error , 'alert-error-message')
            return rejectWithValue(errorData)
        }
    }
);


export const getRatingsRecordsThunk = createAsyncThunk("reviews-and-feedback/get-rating-records",
    async ({ isRated, axiosInstance, page, totalLength, openAlert, searchText }: any, { rejectWithValue }) => {
        
        let conditionalRunApi;
        if (searchText) {
            conditionalRunApi = `${BOOKINGSAPI}?rated=${isRated}&search=${searchText}&status=COMPLETED&offset=${page}&limit=${totalLength}`
        }
        else {
            conditionalRunApi = `${BOOKINGSAPI}?rated=${isRated}&status=COMPLETED&offset=${page}&limit=${totalLength}`
        }
        try {

            const response = await getDataAPI(conditionalRunApi, axiosInstance)

            const formatData = response?.data

            const responseData = {
                ratingRecords: formatData?.bookings,
                ratingRecordsMetaData: formatData?.meta
            }
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message
            }
            return rejectWithValue(errorData)

        }
    }

)