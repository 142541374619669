import { useEffect, useState } from "react";

// MUI
import {
  Checkbox,
  ClickAwayListener,
  FormControl,
  Grid,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

// SCSS
import "./location-search.scss";
import { useAppSelector } from "../../../../../../store/store";

// Images
import searchImage from "../../../../../../assets/images/food-preferences/header/search-sm.png";
import { cuisineInfo, hostInfo } from "../../../../../../mock-data/food-preferences";
import { searchFieldData } from "./location-search-data";
import { useNavigate } from "react-router-dom";
import { FOODPREFERENCEPATH, FOODPREFERENCESEARCHPATH } from "../../../../../../constants/routes-constant";
import { groupBy } from "../../../../../../utils/hooks-utils";
import { useLocationSearch } from "./use-location-search";
import { MUIICONCOLOR, MUIPRIMARYCOLOR } from "../../../../../../constants/mui-styles-constant";

const MenuProps = {
  PaperProps: {
    sx: {
      width: "auto",
      maxWidth: '500px',
      borderRadius: "10px",
      maxHeight: "240px",
      p: "0.5rem",
      // mx: "1rem",
      my: "2rem",
      color: "#FAFAFA",
      background: "#333333",

      "& .MuiMenuItem-root": {
        px: 1,
        borderRadius: '19px'
      },
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "#333333 !important",
        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.08)'
      },
    },
  },
};



// Component Function Starts Here
const LocationSearch: React.FC<any> = (props: any) => {

  // const { locationValue, setLocationValue, showLocation, setOpen, setShowLocation, open, showCuisine, setShowCuisine, showHost, setShowHost } = props;
  // console.log(props)

  const {
    cusinesByCategory, cuisineArray, handleHostBtn, handleCuisineBtn, handleLocationChange, cuisineIn, setCuisineIn,
    cuisineData, setCuisineData, cuisine, setCuisine, hostIn, sethostIn, navigate, referenceData, cuisineLimit, setCuisineLimit,
    locationValue, setLocationValue, showLocation, setOpen, setShowLocation, open, handleCuisineLimit, foodPreference,
    showCuisine, setShowCuisine, showHost, setShowHost, hostName, setHostName, setHostInfoForSearch, dispatch, setHostForSearch
  }: any = useLocationSearch(props)

  return (
    // <ClickAwayListener onClickAway={(e: any)=> setShowCuisine(false)}>
    <Select
      fullWidth
      className="location-select"
      value={showCuisine ? cuisine : locationValue} //  first version scenrio
      // value={locationValue} // second version
      multiple={showCuisine}
      // open={open}
      defaultOpen={open}
      // onClick={() =>setOpen(true)}
      // onClose={() =>setOpen(false)}
      // onOpen={() =>setOpen(true)}
      name="location, host, or cuisine"
      displayEmpty
      // label="location, host, or cuisine"
      sx={{
        color:'white',
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${MUIICONCOLOR} !important`,
          borderRadius:'56px',
          "& > fieldset": {
            borderColor: `${MUIICONCOLOR} !important`
          },
        },
        "& .MuiSelect-icon":{
          color:'white'
        },
        "& .MuiOutlinedInput-notchedOutline.Mui-focused": {
          borderColor: MUIPRIMARYCOLOR,
          "& > fieldset": {
            borderColor: MUIPRIMARYCOLOR
          }
        },
        "& .MuiOutlinedInput-notchedOutline:hover": {
          borderColor: MUIPRIMARYCOLOR,
          "& > fieldset": {
            borderColor: MUIPRIMARYCOLOR
          }
        },
     
      }}
      size="small"
      onChange={handleLocationChange}
      MenuProps={MenuProps}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <span className="loc-span">
            <img className="icon-img" src={searchImage} alt="search-img" />  location, host, or cuisine</span>;
        }
        return <span className="loc-span-sel" >
          <img className="icon-img" src={searchImage} alt="search-img" />{" "} {selected}</span>;
      }}
    >
      {showLocation &&
        searchFieldData.map((item) => {
          return (
            <MenuItem key={item.id} value={item.value}>
              <span className="circle-span"
                style={{ width: '8px', height: '8px', background: '#FFA412', borderRadius: '50%', marginRight: '10px' }}
              > </span>
              {item.text}
            </MenuItem>)
        })
      }

      {showCuisine && cuisineArray.map((cuisine: any, index: any) => (
        // <ClickAwayListener key={cuisine.value} 
        // // onClickAway={()=> {setShowLocation(true); setShowCuisine(false);} }
        // onClickAway={() =>setOpen(false)}
        // >
        <>
          <div key={cuisine.category}>

            <>
              <ListSubheader
                disableSticky
                sx={{ px: 2, pb: 0, backgroundColor: 'transparent', color: '#FFFFFF', fontSize: '21px' }}>
                {cuisine.category}
              </ListSubheader>
              <Grid item container md={12} xs={6} justifyContent={"space-between"}>
                {cuisine.cuisine.slice(0, cuisineLimit).map((cuisineHeads: any) => {
                  return (
                    <Grid item sm={6} md={4} key={cuisineHeads.value}>
                      <MenuItem onClick={() => handleCuisineBtn(cuisineHeads.name, cuisine.category)}
                        key={cuisineHeads.id}
                        value={cuisineHeads.name}>
                        <Checkbox checked={cuisineIn.indexOf(cuisineHeads.name) > -1}
                          // onClick={()=>{return handleCuisineBtn(cuisineHeads.optionName, cuisine.cuisineHeadName );}}
                          sx={{
                            px: 0.5,
                            m: 0,
                            color: '#E0E0E0 !important',
                            "&.Mui-checked": { color: '#FF611D !important' },
                          }}
                        />
                        <ListItemText primaryTypographyProps={{ fontSize: '12px', color: '#FFFFFF' }}
                          primary={cuisineHeads.name} />
                      </MenuItem>
                    </Grid>
                  )
                })}
              </Grid>
              <div onClick={() => handleCuisineLimit()} className="primary-color padding-bottom-2 cursor-pointer"
                style={{ float: 'right' }}>
                {cuisineLimit === 10 ? index === cuisineArray.length - 1 && 'see all' :
                  index === cuisineArray.length - 1 && 'hide some'}
              </div>
            </>
          </div>
        </>
        // </ClickAwayListener>
      ))}
      {/* {showCuisine && cuisineInfo.map((cuisine) => (
        // <ClickAwayListener key={cuisine.value} 
        // // onClickAway={()=> {setShowLocation(true); setShowCuisine(false);} }
        // onClickAway={() =>setOpen(false)}
        // >
        <div key={cuisine.value}>

          <>
            <ListSubheader sx={{ px: 2, pb: 0, backgroundColor: 'transparent', color: '#FFFFFF', fontSize: '21px' }}>{cuisine.cuisineHeadName}</ListSubheader>
            <Grid item container md={12} xs={6} justifyContent={"space-between"}>
              {cuisine.cuisineHead.map((cuisineHeads) => {
                return (
                  <Grid item sm={6} md={4} key={cuisineHeads.value}>
                    <MenuItem onClick={() => { return handleCuisineBtn(cuisineHeads.optionName, cuisine.cuisineHeadName); }}
                      key={cuisineHeads.value}
                      value={cuisineHeads.optionName}>
                      <Checkbox checked={cuisineIn.indexOf(cuisineHeads.optionName) > -1}
                        // onClick={()=>{return handleCuisineBtn(cuisineHeads.optionName, cuisine.cuisineHeadName );}}
                        sx={{
                          px: 0.5,
                          m: 0,
                          color: '#E0E0E0 !important',
                          "&.Mui-checked": { color: '#FF611D !important' },
                        }}
                      />
                      <ListItemText primaryTypographyProps={{ fontSize: '12px', color: '#FFFFFF' }} primary={cuisineHeads.optionName} />
                    </MenuItem>
                  </Grid>
                )
              })}
            </Grid>
          </>
        </div>
        // </ClickAwayListener>
      ))} */}
      {showHost &&
        // <ClickAwayListener onClickAway={()=> {setShowLocation(true); setShowHost(false);} }>
        <div style={{
          display: 'flex', flexDirection: 'column', width: '230px',
          justifyContent: 'center', padding: 1,
        }}>
          <div className="search-input-div flex">
            <input
              className="search-input"
              type="text"
              placeholder="Search by Name"
              value={hostName}
              onChange={(e: any) => { setHostName(e.target.value); dispatch(setHostForSearch({ name: e.target.value, rating: foodPreference?.hostForSearch?.rating })); }}
            />
            <span className="primary-title">|</span>
            <button className="go-btn" onClick={() => setHostInfoForSearch()}>Go</button>
          </div>
          <ListSubheader sx={{ pb: 0, backgroundColor: 'transparent', color: '#FFFFFF', fontSize: '14px' }}
            disableSticky

          >
            Search by Rating</ListSubheader>
          <Grid item container md={12} xs={12} justifyContent={"space-between"}>

            {hostInfo.map((host) => (
              <Grid item sm={12} md={12} key={host.value}>
                <MenuItem sx={{ p: 0 }} key={host.value} value={host.ratingValue}
                  onClick={() => { return handleHostBtn(host.ratingValue); }}
                >
                  <Checkbox
                    // checked={hostIn?.indexOf(host.ratingValue) > -1}
                    checked={hostIn === host?.ratingValue} //for single selection
                    sx={{
                      // pr: 1.5,
                      m: 0,
                      color: '#D3D4D6 !important',
                      "&.Mui-checked": { color: '#FF611D !important' },
                    }}
                  />
                  <ListItemText sx={{ p: 0 }} primaryTypographyProps={{ fontSize: '12px', color: '#D3D4D6' }}
                    primary={host.hostRating} />
                </MenuItem>
              </Grid>
            ))}
          </Grid>

        </div>
        // </ClickAwayListener>
      }
    </Select>
    //  </ClickAwayListener>
  );
};
export default LocationSearch;
