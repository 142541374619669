import { createAsyncThunk } from "@reduxjs/toolkit";
import { DELETEMENUIMAGESAPI, MENUAPI } from "../../constants/axios-api-constant";
import { deleteDataAPI, getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";

export const getMenuListsThunk = createAsyncThunk("menu/menu-lists",
  async ({ axiosInstance,openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(MENUAPI, axiosInstance);
      // console.log('MENUAPI ', response)
      const formatData = response?.data?.data
      const  item = formatData?.map((singleItem:any)=>({id:singleItem._id,name:singleItem.category}))
      const responseData={
        menu:response?.data?.data,
        addFood: item
      } 

      // console.log(responseData)
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert?.(errorData.error)
      return rejectWithValue(errorData);
    }
  }
);

export const getSingleMenuThunk = createAsyncThunk("menu/get-single-menu",
  async ({ menuId, openAlert, axiosInstance, setMenuImagesData}: any, { rejectWithValue }) => {
    // console.log(menuId)
    try {
      const response = await getDataAPI(`${MENUAPI}/${menuId}`, axiosInstance);
      // console.log(response)
      const formatResponse = response.data;
      // console.log(formatResponse)
      const responseData = {
        singleMenu: formatResponse.data,
      };
    setMenuImagesData?.(responseData?.singleMenu?.menuImages)

      // openAlert('Menu added successfully', 'alert-success-message')
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData?.error)

      return rejectWithValue(errorData)
    }
  }
)

export const deleteMenuThunk = createAsyncThunk("menu/delete-menu",

  async ({ menuId, openAlert, axiosInstance, setDeleteModalOpen , dispatch}: any, { rejectWithValue }) => {
    try {
      const response = await deleteDataAPI(`${MENUAPI}/${menuId}`, axiosInstance);

      const responseData = {
        message: response?.data?.message,
      };

      setDeleteModalOpen(false);
    dispatch(getMenuListsThunk({ axiosInstance, openAlert }))

      openAlert('Menu Deleted successfully', 'alert-success-message')
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message  || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)

export const editMenuThunk = createAsyncThunk("menu/edit-menu",
  async ({ menuId, menuData, openAlert, axiosInstance, setMenuImagesData,closeHandler }: any, { rejectWithValue }) => {
    try {
      const response = await patchDataAPI(`${MENUAPI}/${menuId}`, axiosInstance, menuData);
      // console.log(response)
      const responseData = {
        message: response.data.message,
      };

      openAlert('Menu updated successfully', 'alert-success-message')
      setMenuImagesData("")
      closeHandler?.();
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)
export const addMenuThunk = createAsyncThunk("menu/add-menu",
  async ({ menuData, openAlert, axiosInstance,setMenuImagesData , closeHandler}: any, { rejectWithValue }) => {
    try {
      const response = await postDataAPI(MENUAPI, axiosInstance, menuData);

      const responseData = {
        message: response.data.message,
      };
      // console.log('post menu',response)
      openAlert('Menu added successfully', 'alert-success-message')
      setMenuImagesData?.("")
      closeHandler?.();
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }

      setMenuImagesData?.("")
      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)


export const deleteMenuImageThunk = createAsyncThunk("menu/delete-menu-image",
  async ({ imageId, axiosInstance, imageUrl, openAlert, menuImagesData, set , id, setMenuImagesData }: any, { rejectWithValue }) => {
    // console.log(coverImageDetail)
    try {
      // console.log("listings deleted", listingIDsToDelete);
      const response = await deleteDataAPI(`${DELETEMENUIMAGESAPI}/${imageId}/remove-image`, axiosInstance, imageUrl);

      openAlert("Image Deleted Successfully", 'alert-error-message')
      const deletingObj = menuImagesData?.filter((item: any) => item !== id);
      // console.log(deletingObj)
      setMenuImagesData(deletingObj);
      set("menuImages", deletingObj?.length ? deletingObj : "")
      return response.data.message;
    }
    catch (error: any) {
        const errorData = {
            error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
          }
          openAlert?.(errorData.error)
    //   console.log(errorData.error)
      return rejectWithValue(errorData);
    }
  }
);


export const addFoodItem = createAsyncThunk("menus/add-food-item",
  async ({ menuId, values, axiosInstance }: any, { rejectWithValue }) => {
    // console.log(values);

    try {
      const response = await postDataAPI(`menus/add-food-item/${menuId}`, values, axiosInstance);
      
      // console.log(response);
      const responseData = {
        message: response?.data?.message
      }
      
      return responseData;

    } catch (error:any) {

      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      // console.log(errorData);
      
      return rejectWithValue(errorData)
    }
  })

