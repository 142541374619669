import axios from 'axios';
import { BASEURLBACKEND, REFRESHTOKENAPI } from '../constants/axios-api-constant';
import { getDataFromStorage } from '../utils/views-utils';


export const axiosInstance = axios.create({
  // baseURL: 'http://localhost:5000/api/'
  // baseURL: `https://gateway-dev.sharemydine.co.uk/`
  baseURL: BASEURLBACKEND
})
export const axiosRefresh = axios.create({
  // baseURL: 'http://localhost:5000/api/'
  // baseURL: `https://gateway-dev.sharemydine.co.uk/`
  baseURL: BASEURLBACKEND
})

export const getDataAPI = async (url: string, axiosRef: any, params?:any) => {
  const response = await axiosRef.get(`${url}`,params)
  return response;
}

export const postDataAPI = async (url: string, axiosRef: any, body?: any) => {
  const response = await axiosRef.post(`${url}`, body)
  return response;
}

export const putDataAPI = async (url: string, axiosRef: any, body?: any) => {
  const response = await axiosRef.put(`${url}`, body)
  return response;
}

export const patchDataAPI = async (url: string, axiosRef: any, body?: any, contentType?:any) => {
  const response = await axiosRef.patch(`${url}`, body)
  return response;
}

export const deleteDataAPI = async (url: string, axiosRef: any ,  body?:any,) => {
  const response = await axiosRef.delete(`${url}`, {data:body} )
  return response;
}
