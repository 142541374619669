import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppAlert } from "./components/app-alert/use-app-alert";
import { SIGNINPATH } from "./constants/routes-constant";
import { useAxiosInterceptors } from "./hooks/use-axios-interceptors";
// import { useNavigate } from "react-router-dom";

import Routing from "./routes/routes";
import { setLoginSuccess } from "./store/auth/auth-slice";
import { logoutThunk, refreshTokenThunk } from "./store/auth/auth-thunk-actions";
import { referenceDataThunk } from "./store/reference-data/reference-data-actions";
import { setReferenceData } from "./store/reference-data/reference-data-slice";
import { useAppDispatch } from "./store/store";
import { getDataFromLocalStorage } from "./utils/views-utils";
import { axiosRefresh } from "./lib/axios"
import { myUserProfileDataThunk } from "./store/personal-user/personal-user-thunk-actions";

const isAuth =
  JSON.parse(localStorage.getItem("auth") as string) || null

const data = {
  token: isAuth?.token,
  user: isAuth?.user,
  role: isAuth?.role
};
 
const referenceData = getDataFromLocalStorage('smd-reference-data')

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInterceptors()
  const { openAlert } = useAppAlert()

  const setAuthtoken = +(localStorage.getItem('smd-auth-time') || 0)
  // console.log({setAuthtoken})
  const myapp = 'hi'
  const timeNow = Date.now()
  const timeremain = timeNow - setAuthtoken;
  // console.log(timeremain/1000 < 3600)
  useEffect(() => {
    // if (!!isAuth && (timeremain/1000 < 3600)) {
    //   dispatch(setLoginSuccess(data));
    // }
    if (!!isAuth) {
      dispatch(setLoginSuccess(data));
      // dispatch(myUserProfileDataThunk({ axiosInstance , openAlert , myapp }))

    }
    // if(!!isAuth) {
    //   dispatch(refreshTokenThunk({navigate, axiosRefresh}))
    // }
  }, [])

  useEffect(() => {
    if (!!isAuth?.token) {
    dispatch(myUserProfileDataThunk({ axiosInstance, openAlert, myapp }))
    }
  }, [])

  useEffect(() => {
    if (!!referenceData) {
      dispatch(setReferenceData(referenceData))
      return
    }
    dispatch(referenceDataThunk({ axiosInstance , openAlert }))
  }, [])


  return <Routing />;
}

export default App;
