import { useState , useEffect} from "react";
import { useAxiosInterceptors } from "../../../hooks/use-axios-interceptors";
import { getUserNotificationsThunk } from "../../../store/layout/layout-thunk-actions";
import { useAppDispatch, useAppSelector } from "../../../store/store";

export const useNotificationCard = () => {
    const [scroll, setScroll] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const dispatch= useAppDispatch();
    const axiosInstance = useAxiosInterceptors()
    const [pageLength , setPageLength] = useState(10)
    const [page, setPage] = useState(0)
    const { layout } = useAppSelector(state => state)

    useEffect(()=> {
        dispatch(getUserNotificationsThunk({axiosInstance, limit: pageLength, offset: page}))
    },[page, pageLength])

    const openCard = (event: React.MouseEvent<HTMLButtonElement>) => {

        !!anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const OPEN = Boolean(anchorEl);
    return {
         scroll, OPEN, openCard, handleClose, setScroll, pageLength , setPageLength, page, setPage, layout
    }
}
