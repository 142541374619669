import React from "react";
// MUI

import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SortIcon from "@mui/icons-material/Sort";
import { Grid } from "@mui/material";


// Components
import NotificationCard from "./notification-card/notification-card";
import NotificationMenu from "./dropdown-menu/dropdown-menu";

// Interface
import { IHEADERPROPS } from "./header-interface";

// Images
import languageIcon from "../../assets/images/layout/header/language-icon.png";

// SCSS
import "./header.scss";


// Store
import { setMobDrawer, setOpenDrawer } from "../../store/layout/layout-slice";
import { useHeader } from "./use-header";
import { xsFlexSmNone, xsNoneMdFlex, xsNoneSmFlex } from "./header-mui-style";
import SearchCard from "./search-card/search-card";

// Component Function Starts Here IHEADERPROPS
const Header: React.FC<IHEADERPROPS> = ({ setMobDrawerValue }) => {
  const { openDrawer, user, dispatch } = useHeader()

  return (
    <div className="header">
      <CssBaseline />
      <div className="header-main-top">
        <div className="second-header">
          <IconButton
            className="icon-button"
            aria-label="open drawer"
            onClick={() => dispatch(setOpenDrawer(!openDrawer))}
            sx={xsNoneSmFlex}
          >
            {openDrawer ? <MenuIcon /> : <SortIcon fontSize={"medium"} />}
          </IconButton>
          <IconButton
            className="icon-button"
            aria-label="open drawer"
            onClick={() => dispatch(setMobDrawer(true))}
            sx={xsFlexSmNone}
          >
            <MenuIcon />
          </IconButton>
          {/* <div className="header-search">
            <input
              type="text"
              placeholder="Search"
              className={`input-search ${openDrawer ? "open-search-input" : "collapsed-search-input"
                }`}
            />
            <img src={SearchIcon} alt="" className="search-icon" />
          </div> */}
          <SearchCard />
        </div>
        <div className="language-notificatios font-weight-500">
          <Grid
            className="right-header flex"
          >
            <Grid
              sx={xsNoneMdFlex}
            >
              <div className="render-language eng-uk">
                <img className="language-icon" src={languageIcon} alt="language" />
                <p className="title eng-uk">Eng-UK</p>
              </div>
            </Grid>
            <div>
              <NotificationCard />
            </div>
            <Grid
              className="user-title"
              sx={xsNoneMdFlex}
            >
              <p className="eng-uk font-weight-500">{user?.firstName}{" "}{user?.lastName}</p>
            </Grid>
          </Grid>
          <div className="notification-menu">
            <NotificationMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
