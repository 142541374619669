import { createAsyncThunk } from "@reduxjs/toolkit"
import { MYUSERPROFILEAPI, TRAININGSAPI, UPDATEPROFILECOMPLETIONAPI } from "../../constants/axios-api-constant"
import { USERROLES } from "../../constants/roles-constant"
import { patchDataAPI, postDataAPI, putDataAPI } from "../../lib/axios"
import { getDataAPI } from "../../lib/axios"
import { setProfileImage } from "../auth/auth-slice"

export const personalDetailsThunk = createAsyncThunk('personalDetails/user-profile/personal-details',
  async ({ personalCompleteProfile, axiosInstance, openAlert, setValue, auth, personalUser, dispatch }: any, { rejectWithValue }) => {

    try {
      const response = await postDataAPI('user-profile/personal-details', axiosInstance, personalCompleteProfile)

      if (!personalUser?.profileCompletion?.personalInfo?.completed && auth.role.includes(USERROLES.HOST)) {
        // console.log('hihii personal info completed')
        dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'personalInfo', openAlert }))
      }
      const responseData = {
        message: response.data.message,
      }


      openAlert('User Info Save Successfully', "alert-success-message")

      dispatch(myUserProfileDataThunk({ axiosInstance, openAlert }))
      if (auth?.role.includes(USERROLES.HOST)) { setValue(1); }
      return responseData;
    } catch (error: any) {
      // console.log('catch', error);
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding'

      }
      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)

export const profileImageThunk = createAsyncThunk('personalDetails/user-profile/profileImage',
  async ({ profileImage, axiosInstance, openAlert }: any, { rejectWithValue }) => {
    // console.log(profileImage,' profile Image');

    try {
      const response = await putDataAPI('user-profile/update-profile-image', axiosInstance, profileImage)
      // console.log(response,'response');

      const responseData = {
        message: response.data.message,
        profileImage: response.data.data,
      }
      openAlert(responseData?.message, "alert-success-message")
      // console.log(responseData,'responseData');

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding'
      }
      openAlert(errorData?.error)
      return rejectWithValue(errorData)
    }
  }
)

export const cityThunk = createAsyncThunk('referenceData/get_city',
  async ({ country, axiosInstance}: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(`reference-data/get_city?country_name=${country}`, axiosInstance)
      // console.log(response)
      const responseData = {
        city: response.data.data,
      }
     
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding'
      }
      return rejectWithValue(errorData)
    }
  }
)


export const myUserProfileDataThunk = createAsyncThunk("personal-user/user-profile",
  async ({ axiosInstance, openAlert}: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(MYUSERPROFILEAPI, axiosInstance);
      // console.log(response)
      const formatData = response?.data?.data;

      const responseData = {
        message: response.data.message,
        user: {
          userId: formatData?.userId,
          address: formatData?.address,
          city: formatData?.city,
          country: formatData?.country,
          profileImage: formatData.profileImage,
          dob: formatData?.dob,
          firstName: formatData?.firstName,
          lastName: formatData?.lastName,
          email: formatData?.email,
          defaultRole: formatData?.defaultRole,
          language: formatData?.language,
          gender: formatData?.gender,
          phoneNumber: formatData?.phoneNumber,
          aboutMe: formatData?.aboutMe,
          totalReview: formatData.totalReview,
          avgRating: formatData.avgRating
        },
        overallProfileCompletion: formatData?.overalProfileCompletion,
        profileCompletion: formatData?.profileCompletion
      };

      // console.log(response)
      // console.log({responseData})
      // openAlert(responseData.message, 'alert-success-message')

      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error.response.data.message
      }

      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)

export const updatePersonalUserProfileStatusThunk = createAsyncThunk("personal-user/user-profile-completion",
  async ({ axiosInstance, keyValue, openAlert }: any, { rejectWithValue }) => {

    try {
      const response = await patchDataAPI(`${UPDATEPROFILECOMPLETIONAPI}?key=${keyValue}`, axiosInstance);
      // console.log(response)
      const formatData = response?.data?.data;

      const responseData = {
        message: response.data.message,
        overallProfileCompletion: formatData.overalProfileCompletion,
        profileCompletion: formatData.profileCompletion
      };

      // console.log(response)
      // console.log({responseData})
      // openAlert(responseData.message, 'alert-success-message')

      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error.response.data.message
      }

      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)


export const personalUsertrainingDataThunk = createAsyncThunk('personal-user/user-trainings',
  async ({ axiosInstance }: any, { rejectWithValue }) => {
    // console.log(axiosInstance)
    try {
      const response = await getDataAPI(TRAININGSAPI, axiosInstance)

      // const responseData = {
      //     mandatory: response.data.mandotary,
      //     optional: response.data.optional,
      //     total: response.data.totals,
      // }
      const formatData = response?.data
      const responseData = {
        trainingsDetails: {
          trainingDetails: [
            {
              id: 0,
              trainingData: formatData.mandotary
            },
            {
              id: 1,
              trainingData: formatData.optional
            }
          ],
          totalTrainingsDetail: formatData.totals
        },
      };
      return responseData;
    } catch (error: any) {
      // console.log('catch', error);
      const errorData = {
        error: error?.response?.data?.message
      }
      return rejectWithValue(errorData)
    }
  }
)
