// React
import { NavLink } from "react-router-dom";

// MUI
import { ClickAwayListener, Tooltip } from "@mui/material";

// Icons
import SearchIcon from "../../../assets/images/layout/header/searchIcon.png";

// SCSS
import "./search-card.scss";

// MUI Styles
import { tooltipSearchCardComponenetsProps } from "./search-card-mui-style";

// Custom Hook
import { useSearchCard } from "./use-search-card";
import { searchbarData } from "./search-card-data";

// Component Function Starts Here
const SearchCard = () => {

  const {
    OPEN, openDrawer, query, searchName,  openSearchCard, menuClick, handleCloseSearchCard, auth, sidebarData
  } = useSearchCard()

  return (

    <ClickAwayListener onClickAway={handleCloseSearchCard}>
      <Tooltip
        placement="bottom-start"
        componentsProps={tooltipSearchCardComponenetsProps}
        PopperProps={{
          disablePortal: true,
        }}
        open={OPEN}
        title={(<>
          <div className={`header-search-card flex flex-column ${openDrawer ? 'common-cards-width-open' : 'common-cards-width-closed'}`}>
            { 
            searchbarData.filter((data:any) => data.title.toLowerCase().includes(query.toLowerCase()))
              .map((item:any) => (
                !!auth?.role?.find((role: any) => item.allowedRoles.includes(role)) && 
                <NavLink key={item.id} to={item.path} onClick={menuClick} className="header-search-card-row">
                  <span className="secondary-title font-weight-400">{item.title}</span>
                </NavLink>
              ))
            }
          </div>
        </>)}
      >
        <div className="header-search" >
          <input

            autoComplete='off'
            onClick={openSearchCard}
            onChange={openSearchCard}
            type="text"
            name='searchName'
            value={searchName}
            placeholder="Search..."
            className={`input-search ${openDrawer ? "open-search-input common-cards-width-open" : "collapsed-search-input common-cards-width-closed"}`}
          />
          <img src={SearchIcon} alt="search" className="search-icon" />
        </div>
      </Tooltip>
    </ClickAwayListener >

  );
};

export default SearchCard;
