import { MUIORANGECOLOR } from "../../../../../../constants/mui-styles-constant";

import timeClock from '../../../../../../assets/images/my-listing/clock.svg';

export  const timeButtons = [
    {
      buttonId: 1,
      btnHeader: 'Breakfast',
      btnDetail: [{
        type: "button",
        btnId: 1,
        // sx: { border: '1px solid rgba(131, 164, 249, 0.25)', color: MUIORANGECOLOR },
        btnText: "07 AM-10 AM",
        btnIcon: <img src={timeClock} alt="" className='icon-margin' />,
      }]
    },
    {
      buttonId: 2,
      btnHeader: 'Brunch',
      btnDetail: [{
        type: "button",
        btnId: 1,
        // sx: { border: '1px solid rgba(131, 164, 249, 0.25)', color: MUIORANGECOLOR },
        btnText: "10 AM-12 PM",
        btnIcon: <img src={timeClock} alt="" className='icon-margin' />,
      }]
    },
    {
      buttonId: 3,
      btnHeader: 'Lunch',
      btnDetail: [{
        type: "button",
        btnId: 1,
        // sx: { border: '1px solid rgba(131, 164, 249, 0.25)', color: MUIORANGECOLOR },
        btnText: "12 PM-05 PM",
        btnIcon: <img src={timeClock} alt="" className='icon-margin' />,
      }]
    },
    {
      buttonId: 4,
      btnHeader: 'Dinner',
      btnDetail: [{
        type: "button",
        btnId: 1,
        // sx: { border: '1px solid rgba(131, 164, 249, 0.25)', color: MUIORANGECOLOR },
        btnText: "05 PM-12 AM",
        btnIcon: <img src={timeClock} alt="" className='icon-margin' />,
      }]
    },
  ]