// MUI
import { Grid } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Components
import DateAndTime from "./components/date-and-time/date-and-time";
import Guest from "./components/guests/guest";
import LocationSearch from "./components/location-search/location-search";
import Toggler from "./components/toggler/toggler";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// SCSS
import "./header.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { useAppAlert } from "../../../../components/app-alert/use-app-alert";
import {
  searchFoodPreferencesByCuisinesThunk, searchFoodPreferencesByHostThunk, searchFoodPreferencesByLocationThunk
} from "../../../../store/food-preference/food-preference-thunk-actions";

import { useAxiosInterceptors } from "../../../../hooks/use-axios-interceptors";
import dayjs from "dayjs";
import {
  setDateForSearch, setHostForSearch, setLocationForSearch, setNumberOfGuests, setSelectedDietary,
  setSelectedFacilities, setSelectedFacilitiesForChildren, setSelectedFacilitiesForDisables, setGeoLocationCoordinate,
  setSelectedHostLanguageProficiences, setSelectedHouseRules, setSelectedOffers, setSelectedPrice, setTimeForSearch
} from "../../../../store/food-preference/food-preference-slice";
// Component Function Starts Here
const adapter = new AdapterDayjs();
const Header: React.FC<any> = (props: any) => {
  const {pathname} = useLocation();
  console.log(pathname)
  const { locationValue, setLocationValue, open, setOpen }: any = props;
  const firstAvailableSlotDay = adapter.date(new Date());
  const [numberOfGuest, setNumberOfGuest] = useState<string>("");
  // const [locationValue, setLocationValue] = useState<string>("");
  const [activeTime, setActiveTime] = useState<any>(firstAvailableSlotDay);
  const [activeShift, setActiveShift] = useState<any>('');
  const [searchFood, setSearchFood] = useState<any>([]);
  const [resetBtn, setResetBtn] = useState(false);
  const [showLocation, setShowLocation] = useState(true);
  const [showCuisine, setShowCuisine] = useState<any>(false);
  const [showHost, setShowHost] = useState<any>(false);
  const { searchType } = useParams();
  const { foodPreference } = useAppSelector(state => state)
  const { openAlert } = useAppAlert();
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInterceptors();
  const navigate = useNavigate();

  //
  // const [cuisineIn, setCuisineIn] = useState<string[]>([]);
  // const [cuisineData, setCuisineData] = useState<any>([])
  // const [cuisine, setCuisine] = useState<any>([]);
  // const [hostIn, sethostIn] = useState<any>(0);
  // const [hostName, setHostName] = useState('')

  useEffect(() => {
    const setDate = dayjs(activeTime)?.format('DD MMMM YYYY')
    dispatch(setDateForSearch(setDate))
  }, [])

  // console.log(searchType)
  const handleFoodSearch = (e: any) => {
    const searchArr = {
      activeShift: activeShift,
      searchValue: locationValue,
      numberOfGuest: numberOfGuest,
    }
    const searchQueryHost: any = {
      host: foodPreference?.hostForSearch,
      filter: foodPreference.filters,
      date: {
        date: foodPreference?.dateForSearch,
        time: foodPreference?.timeForSearch

      },
      guests: {
        noofGuests: foodPreference?.numberOfGuests
      }
    }
    const searchQueryCuisines: any = {
      cuisine: foodPreference?.cuisineForSearch,
      filter: foodPreference.filters,
      date: {
        date: foodPreference?.dateForSearch,
        time: foodPreference?.timeForSearch

      },
      guests: {
        noofGuests: foodPreference?.numberOfGuests
      }
    }
    const searchQueryLocation: any = {
      // location: foodPreference?.locationForSearch,

      location: {
        // address: "",
        // geolocation: foodPreference?.geolocation,
        // address: foodPreference?.locationForSearch,
        // ...(!!!foodPreference?.locationForSearch?.length && {geolocation: foodPreference?.geolocation} ),
        ...(!!foodPreference?.locationForSearch?.length ?
          { address: foodPreference?.locationForSearch } :
          { geolocation: foodPreference?.geolocation, address: "" })
      },
      filter: foodPreference.filters,
      date: {
        date: foodPreference?.dateForSearch,
        time: foodPreference?.timeForSearch

      },
      guests: {
        noofGuests: foodPreference?.numberOfGuests
      }
    }
    // console.log(searchType === 'Host')
    if (searchType === 'Host') {
      // console.log(!!searchQueryHost?.date)
      // console.log(searchQueryHost?.date)
      if (!!!searchQueryHost?.host?.name) return openAlert('Select host first');
      if (!!!searchQueryHost?.date?.date) return openAlert('Select date first');  // if optional 
      if (!!!searchQueryHost?.date?.time) return openAlert('Select time first');  // if optional
      if (!!!searchQueryHost?.guests?.noofGuests) return openAlert('Select guests first'); // if optional
      setResetBtn(!resetBtn)
      dispatch(searchFoodPreferencesByHostThunk({ hostSearchParameters: searchQueryHost, axiosInstance , openAlert }))
      // openAlert('Go ahead')
      return;
    }
    if (searchType === 'Cuisine') {
      // console.log(!!searchQueryCuisines?.date)
      // console.log(searchQueryCuisines?.date)
      if (!!!searchQueryCuisines?.cuisine?.length) return openAlert('Select cuisine first');
      if (!!!searchQueryCuisines?.date?.date) return openAlert('Select date first'); // if optional
      if (!!!searchQueryCuisines?.date?.time) return openAlert('Select time first');  // if optional
      if (!!!searchQueryCuisines?.guests?.noofGuests) return openAlert('Select guests first');  //if optional

      setResetBtn(!resetBtn)

      dispatch(searchFoodPreferencesByCuisinesThunk({ cuisineSearchParameters: searchQueryCuisines, axiosInstance , openAlert }))
      // openAlert('Go ahead')
      return;
    }
    if (searchType === 'Location') {
      // console.log(!!searchQueryLocation?.date)
      // console.log(searchQueryLocation?.date)
      // if (!!!searchQueryLocation?.location) return openAlert('Select location first');
      if (searchQueryLocation?.location?.address === "" && !!!searchQueryLocation?.location?.geolocation?.longitude) return openAlert('Select location');
      if (searchQueryLocation?.location?.address === "" && searchQueryLocation?.location?.geolocation?.longitude === "") return openAlert('Select your location');
      if (!!!searchQueryLocation?.date?.date) return openAlert('Select date first'); // if optional
      if (!!!searchQueryLocation?.date?.time) return openAlert('Select time first'); // if optional
      if (!!!searchQueryLocation?.guests?.noofGuests) return openAlert('Select guests first'); // if optional
      setResetBtn(!resetBtn)
      dispatch(searchFoodPreferencesByLocationThunk({ locationSearchParameters: searchQueryLocation, axiosInstance , openAlert }))

      // openAlert('Go ahead')
      return;
    }
    // console.log({ searchQueryHost, searchType })
    // setSearchFood(searchArr);
    // console.log(searchArr)
    // setResetBtn(!resetBtn)
  }


  const handleResetFood = (e: any) => {
    setShowCuisine(false);
    setShowLocation(true);
    setShowHost(false);
    setLocationValue("");
    setActiveShift('');
    setActiveShift('');
    setNumberOfGuest('');
    // setCuisineIn([])
    // setCuisineData([])
    // setCuisine([])
    // sethostIn(0)
    // setHostName('')
    // dispatch(setDateForSearch(""))
    dispatch(setNumberOfGuests(0))
    dispatch(setTimeForSearch(""))
    dispatch(setSelectedDietary([]))
    dispatch(setSelectedFacilities([]))
    dispatch(setSelectedFacilitiesForDisables([]))
    dispatch(setSelectedFacilitiesForChildren([]))
    dispatch(setSelectedHouseRules([]))
    dispatch(setSelectedHostLanguageProficiences([]))
    dispatch(setHostForSearch({}))
    dispatch(setSelectedPrice({
      startFrom: 5,
      endAt: 15
    }))
    dispatch(setSelectedOffers([]))
    dispatch(setLocationForSearch(""))
    dispatch(setGeoLocationCoordinate({
      longitude: "",
      latitiude: ""
    }))
    navigate('/food-preference')
    setResetBtn(!resetBtn)
  }
  // console.log( searchFood, 'searchFood ' );

  // const handleFoodSearch = () => {

  // }

  return (
    <Grid container spacing={1} className="dashboard-header margin-top-1 white-color">
      <Grid item xs={12} md={.5} lg={.5} xl={1.5}></Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} xl={8.5}>
        <form className="sub-header">
        <>
          <Grid container spacing={2} justifyContent={{xs:'center', md:'start'}}
          >
            <Grid item xs={10} md={6} lg={3.5} 
            className="secondary-bg-color border-radius-50-left border-radius-50-md-left padding-bottom-1 padding-top-1 padding-right-0 padding-left-0 padding-sm-0-5">
              <LocationSearch locationValue={locationValue} setLocationValue={setLocationValue}
                showLocation={showLocation} setShowLocation={setShowLocation}
                showCuisine={showCuisine} setShowCuisine={setShowCuisine}
                showHost={showHost} setShowHost={setShowHost} open={open}
                setOpen={setOpen}
              // cuisineIn ={cuisineIn} setCuisineIn ={setCuisineIn}
              // cuisineData={cuisineData} setCuisineData={setCuisineData}
              // cuisine={cuisine} setCuisine={setCuisine} 
              // hostIn={hostIn} sethostIn={sethostIn}
              // hostName={hostName} setHostName={setHostName}
              />
            </Grid>
            {/* <Grid item xs={12} lg={2}></Grid> */}
            <Grid item xs={10} md={6} lg={2.5} 
            className="secondary-bg-color padding-bottom-1 padding-top-1 padding-right-0 padding-left-0 padding-sm-0-5">
              <DateAndTime
                activeTime={activeTime}
                setActiveTime={setActiveTime}
                activeShift={activeShift}
                setActiveShift={setActiveShift}
              />
            </Grid>
            <Grid item xs={10} md={6} lg={2.5} className="secondary-bg-color padding-bottom-1 padding-top-1 padding-right-0 padding-left-0 padding-sm-0-5">
              <Guest numberOfGuest={numberOfGuest} setNumberOfGuest={setNumberOfGuest} />
            </Grid>
            <Grid item xs={10} md={6} lg={2.5} 
            className="secondary-bg-color  padding-left-0 border-radius-50-right border-radius-50-md-right  padding-top-none padding-bottom-none padding-sm-0-5">
                <button type="button"
                  onClick={resetBtn ? handleResetFood : handleFoodSearch}
                  // onClick={()=> handleFoodSearch(resetBtn)}
                  className={
                    `host-btn border-none white-color border-radius-sm-1 sub-heading font-weight-700 height-unset  margin-right-0 padding-sm-0-5
                    center-text width-100 flex align-center justify-center cursor-pointer ${resetBtn && 'reset-btn'}`}>
                  {/* <span className="sub-heading font-weight-700 margin-right-0">{resetBtn ? 'Reset Search' : 'Search'}</span> */}
                  {resetBtn ? 'Reset Search' : 'Search'}
                  {!resetBtn && <ManageSearchIcon className="margin-left-0" fontSize="large" />}
                </button>
            </Grid>
          </Grid>
        </>
        </form>
      </Grid>
      <Grid item xs={12} md={.5} lg={1}></Grid>
      {
      pathname === '/home' && 
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1} style={{zIndex:4}}>
        <Toggler />
      </Grid>
      }
    </Grid>
  );
};
export default Header;