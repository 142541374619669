import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKUPAPI } from "../../constants/axios-api-constant";
import { getDataAPI } from "../../lib/axios";


export const getBackUpDataThunk = createAsyncThunk('backup/get-backup-data',
    async ({ axiosInstance, limit, offset, openAlert, search }: any, { rejectWithValue }) => {
        // console.log(limit)
        // console.log(offset)
        //console.log(search)
        try {
            const response = await getDataAPI(`${BACKUPAPI}?limit=${limit}&offset=${offset}${search === 'Invalid Date' ? '' : `&date=${search}`}`, axiosInstance);
            // console.log(response)
            const formatData = response?.data?.data
            const responseData = {
                backupData: formatData?.backups,
                meta: formatData?.meta
            }
            // console.log(responseData.data);
            return responseData
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
            }
            openAlert?.(errorData?.error)
            rejectWithValue(errorData)
            // console.log(errorData.error);
        }
    }
)
export const getBackUpFileThunk = createAsyncThunk('backup/get-backup-file',
    async ({ backupId, axiosInstance, downloadFileClick , openAlert }: any, { rejectWithValue }) => {

        try {
            const response = await getDataAPI(`${BACKUPAPI}/${backupId}/file`, axiosInstance);
            // console.log(response)
            const responseData = {
                file: response?.data?.data
            }
            downloadFileClick?.(response?.data?.data, "nmoi")
            // console.log(responseData.data);
            return responseData
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
              }
              openAlert?.(errorData?.error)
            rejectWithValue(errorData)
            // console.log(errorData.error);
        }
    }
)

