export const otherBreadcrumbs = [
    {
        id: 2,
        linkName: '/',
        className: 'global-breadcrumb-previous-link text-decoration',
        linkPath: '#'
    },
    {
        id: 1,
        linkName: 'My bookings',
        className: 'global-breadcrumb-active',
    },
] 

export const breadcrumbdata = {
    firstLink: '/bookings',
    firstLinkName: 'My Bookings |',
    otherBreadcrumbs
  }
  export const statusTableHeader = [
    {id:0, headername:'Booking ID'} ,{id:1, headername:'Guest'} 
    ,{id:2, headername:'Name'} ,{id:3, headername:'Time of Booking'} 
     ,{id:4, headername:'Serving Person'} ,{id:5, headername:'Email'} ,
     {id:6, headername:'£ Price'} ,{id:7, headername:'Status'}
    
   ];
   export const RANDOMCOLORSCHEME: string[] = ["#d6b32b","#415f38","#9a8c19", "#d53c2f","#db708d","#641d9a","#3ad23b","#3235c9","#bace3e",
   "#f30f1d","#c891a0","#a8e82e","#2e5769","#e31035","#903dde","#6c41e8","#5580e1","#3d2ba0","#b90da2","#5f5554","#0899f8","#c9af77","#31cb08","#8c52ec",
     "#9e87c2","#84a477","#66c534","#4de78e","#bc174f","#d5b010","#508a43",];
   export const timeFrameOptions: string[] = [ 'All','Month To Date','Year To Date','This Week','Date Range' ];