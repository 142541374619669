import { REQUESTSTATUS } from "../../constants/axios-api-constant";


export const menuThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;
        });
  
  }

  export const getMenuListsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.menusData=payload.menu;
            state.menuLists=payload.menu;
            state.addFood = payload.addFood
            // console.log('Successfully got Menu',payload);
  
        });
  }

export const getMenuListsThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
      action.rejected, (state: any, { payload }: any) => {
          state.status = REQUESTSTATUS.FAILED;
          // console.log(payload);
      });
}

export const addMenuThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.upsertStatus = REQUESTSTATUS.PENDING;
        });
  
  }



export const addMenuThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
      action.fulfilled, (state: any, { payload }: any) => {
          state.upsertStatus = REQUESTSTATUS.SUCCEEDED;
          state.menusLists=payload;
          // console.log('Successfully got Menu',payload);

      });
}


export const addMenuThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.upsertStatus = REQUESTSTATUS.FAILED;
            // console.log(payload);
        });
  }


export const getSingleMenuThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
      action.fulfilled, (state: any, { payload }: any) => {
          state.status = REQUESTSTATUS.SUCCEEDED;
          state.singleMenu = payload.singleMenu;
          // console.log('Successfully got Menu',payload);

      });
}


export const getSingleMenuThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log(payload);
        });
  }


export const deleteMenuThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log(payload);
        });
  }



export const deleteMenuThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
      action.fulfilled, (state: any, { payload }: any) => {
          state.status = REQUESTSTATUS.SUCCEEDED;
          state.menuLists=payload;
          // console.log('Successfully got Menu',payload);

      });
}

export const editMenuThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.upsertStatus = REQUESTSTATUS.PENDING;
        });
  
  }

export const editMenuThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.upsertStatus = REQUESTSTATUS.FAILED;
            // console.log(payload);
        });
  }



export const editMenuThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
      action.fulfilled, (state: any, { payload }: any) => {
          state.upsertStatus = REQUESTSTATUS.SUCCEEDED;
          state.menusLists=payload;
          // console.log('Successfully got Menu',payload);

      });
}


export const addFoodItemThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log('Successfully got Menu',payload);
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.menusLists=payload;
  
        });
  }
  

  export const addFoodItemThunkRejectedReducer = (builder: any, action: any) => {
      builder.addCase(
          action.rejected, (state: any, { payload }: any) => {
              state.status = REQUESTSTATUS.FAILED;
              // console.log(payload);
          });
    }



export const clearSingleMenuReducer = (state:any) => {
    state.singleMenu = {}
}

export const deleteMenuImageThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.deleteMenuImageStatus = REQUESTSTATUS.PENDING;
      
    });
  };

  export const deleteMenuImageThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
      // console.log({ payload });
      state.deleteMenuImageStatus = REQUESTSTATUS.SUCCEEDED;
      
    });
  };

  export const deleteMenuImageThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
      state.deleteMenuImageStatus = REQUESTSTATUS.FAILED;
  
    });
  };