import { ICUISINE, IDIETARY, IFINDMORE, IFOODPREFERENCE, IHOSTINFO, IMARKS, IOFFERS, ITABSBUTTON } from "../features/food/food-preferences/food-preferences-interface";

// Images imports
import daddyDonkey from '../assets/images/food-preferences/daddy-donkey.png'
import dfTacos from '../assets/images/food-preferences/df-tacos.png'
import laChingada from '../assets/images/food-preferences/la-chingada.png'
import ellaCanta from '../assets/images/food-preferences/ella-canta.png'

export const offersInfo: IOFFERS[] = [
    { value: 1, offerName: "All Offers" },
    { value: 2, offerName: "Up to 50% off" },
    { value: 3, offerName: "weekend offers" },
    { value: 4, offerName: "Family Deals" },
];
export const dietaryInfo: IDIETARY[] = [
    { value: 1, dietaryName: "Gluten Free" },
    { value: 2, dietaryName: "Halal" },
    { value: 3, dietaryName: "Kosher" },
    { value: 4, dietaryName: "organic" },
    { value: 5, dietaryName: "Vegan" },
    { value: 6, dietaryName: "Vegetarian" },
];
export const findMoreInfo: IFINDMORE[] = [
    {
        value: 1,
        findHeadName: 'Facilities',
        findHead: [
            {
                value: 1,
                optionName: 'Parking',
            },
            {
                value: 2,
                optionName: 'Indoor Services',
            },
            {
                value: 3,
                optionName: 'Outdoor Services',
            },
        ]
    },
    {
        value: 2,
        findHeadName: 'Facilities for disables',
        findHead: [
            {
                value: 1,
                optionName: 'Wheel Chair',
            },
            {
                value: 2,
                optionName: 'Lift/Escalator',
            },
            {
                value: 3,
                optionName: 'Ramp',
            },
        ]
    },
    {
        value: 3,
        findHeadName: 'Facilities for Children',
        findHead: [
            {
                value: 1,
                optionName: 'High Chair',
            },
            {
                value: 2,
                optionName: 'Play Area',
            },
            {
                value: 3,
                optionName: 'Children Books',
            },
        ]
    },
    {
        value: 4,
        findHeadName: 'House Rules',
        findHead: [
            {
                value: 1,
                optionName: 'Smooking Permitted',
            },
            {
                value: 2,
                optionName: 'Pets',
            },
            {
                value: 3,
                optionName: 'Beef Restricted',
            },
        ]
    },
    {
        value: 5,
        findHeadName: 'Host Language Proficiences',
        findHead: [
            {
                value: 1,
                optionName: 'English',
            },
            {
                value: 2,
                optionName: 'Korean',
            },
            {
                value: 3,
                optionName: 'Turkish',
            },
        ]
    },

]
export const cuisineInfo: ICUISINE[] = [
    {
        value: 1,
        cuisineHeadName: 'Cuisine Categories',
        cuisineHead: [
            {
                value: 1,
                optionName: 'Asian',
            },
            {
                value: 2,
                optionName: 'North American',
            },
            {
                value: 3,
                optionName: 'South American',
            },
            {
                value: 4,
                optionName: 'Continental',
            },
            {
                value: 5,
                optionName: 'Fusion',
            },
            {
                value: 6,
                optionName: 'Vegan',
            },
            {
                value: 7,
                optionName: 'Vegetarian',
            },
            {
                value: 8,
                optionName: 'Mediterranean',
            },
            {
                value: 9,
                optionName: 'South American',
            },
        ]
    },
    {
        value: 2,
        cuisineHeadName: 'Regional Cuisine',
        cuisineHead: [
            {
                value: 1,
                optionName: 'South Indian',
            },
            {
                value: 2,
                optionName: 'Malaysian',
            },
            {
                value: 3,
                optionName: 'Pakistani',
            },
            {
                value: 4,
                optionName: 'Japanese',
            },
            {
                value: 5,
                optionName: 'Chinese',
            },
        ]
    },
]
export const hostInfo : IHOSTINFO[] = [
    {
        value: 1,
        ratingValue: 5,
        hostRating: '5 Excellent',
    },
    {
        value: 2,
        ratingValue: 4,
        hostRating: '4 Very Good',
    },
    {
        value: 3,
        ratingValue: 3,
        hostRating: '3 Good',
    },
    {
        value: 4,
        ratingValue: 2,
        hostRating: '2 Fair',
    },
    {
        value: 5,
        ratingValue: 1,
        hostRating: '1 Poor',
    },
]
export  const marks: any[] = [
    {
        id: 5,
        label: '€ 5',
        value: 5,
      
    },
    {
        id: 10,
        label: '€ 10',
        value: 10,
      
    },
    {
        id: 15,
        label: '€ 15',
        value: 15,
    },
    {
        id: 20,
        label: '€ 20',
        value: 20,
    },
    {
        id: 25,
        label: '€ 25',
        value: 25,
    },
    {
        id: 30,
        label: '€ 30',
        value: 30,
    },
    {
        id: 35,
        label: '€ 35',
        value: 35,
    },
    {
        id: 40,
        label: '€ 40',
        value: 40,
    },
    {
        id: 45,
        label: '€ 45',
        value: 45,
    },
    {
        id: 50,
        label: '€ 50+',
        value: 50,
    },
];
export const foodPreferenceData: IFOODPREFERENCE[] = [
    {
        id: 1,
        foodImg: daddyDonkey,
        imgAlt: "daddy-donkey",
        foodName: "Daddy Donkey",
        foodLocation: "London, England, United Kingdom",
        hostName: "Miguel Angel",
        foodRating: "4",
        timingStatus: "Opened",
        timing: "11AM to 12AM",
        recipeName: "Mexican Cuisine",
        recipeDescription: `Contemporary Mexican streetfood menu served quickly from behind a no frills takeaway counter.`,
    },
    {
        id: 2,
        foodImg: dfTacos,
        imgAlt: "df-tacos",
        foodName: "DF Tacos - Shoreditch",
        foodLocation: "London, England, United Kingdom",
        hostName: "Mariah.J",
        foodRating: "5",
        timingStatus: "Closed",
        timing: "Opens 7PM",
        recipeName: "Mexican Cuisine",
        recipeDescription: `Burritos and bottomless aguas frescas round out the menu at this industrial-chic 
        Mexican operation..`,
    },
    {
        id: 3,
        foodImg: laChingada,
        imgAlt: "la-chingada",
        foodName: "La Chingada ",
        foodLocation: "London, England, United Kingdom",
        hostName: "J. Dajango",
        foodRating: "4",
        timingStatus: "Closed",
        timing: "Opens 6PM",
        recipeName: "Mexican Cuisine",
        recipeDescription: `Lauded chef Chingada inventive Mexican menu and creative cocktails headline at this
        glam spot.`,
    },
    {
        id: 4,
        foodImg: ellaCanta,
        imgAlt: "ella-canta",
        foodName: "Ella Canta",
        foodLocation: "London, England, United Kingdom",
        hostName: "Rafael David",
        foodRating: "5",
        timingStatus: "Closed",
        timing: "Opens 6PM",
        recipeName: "Mexican Cuisine",
        recipeDescription: `Modern take on Mexican street food with casual, idiosyncratic vibe and a creative drinks menu.`,
    },
    {
        id: 5,
        foodImg: ellaCanta,
        imgAlt: "ella-canta",
        foodName: "Ella Canta",
        foodLocation: "London, England, United Kingdom",
        hostName: "Rafael David",
        foodRating: "5",
        timingStatus: "Closed",
        timing: "Opens 6PM",
        recipeName: "Mexican Cuisine",
        recipeDescription: `Modern take on Mexican street food with casual, idiosyncratic vibe and a creative drinks menu.`,
    },
    {
        id: 6,
        foodImg: daddyDonkey,
        imgAlt: "daddy-donkey",
        foodName: "Daddy Donkey",
        foodLocation: "London, England, United Kingdom",
        hostName: "Miguel Angel",
        foodRating: "5",
        timingStatus: "Opened",
        timing: "10AM to 12AM",
        recipeName: "Mexican Cuisine",
        recipeDescription: `Contemporary Mexican streetfood menu served quickly from behind a no frills takeaway counter.`,
    },

]
export const tabButtons: ITABSBUTTON[] = [
    {
        id: 1,
        btnName: 'Price Range'
    },
    {
        id: 2,
        btnName: 'Offers'
    },
    {
        id: 3,
        btnName: 'Dietary'
    },
    {
        id: 4,
        btnName: 'Fir'
    },
]