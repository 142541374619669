

export const houseRulesThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
     
          state.houseRulesStatus = 'failed';

      });
}

export const houseRulesThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
      action.fulfilled, (state: any, { payload }: any) => {
          state.houseRulesStatus = "succeeded";
          state.houseRules = payload
         
      });
}
export const houseRulesThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
      action.pending, (state: any, { payload }: any) => {
          state.houseRulesStatus = "loading";
      });
}
