import { REQUESTSTATUS } from "../../constants/axios-api-constant";


export const referenceDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            state.error = payload.error;

        });
}

export const referenceDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload)
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.badges = payload.badges
            state.allergens = payload.allergens
            state.food_types = payload.food_types
            state.dietary_attribures = payload.dietary_attribures
            state.cuisines = payload.cuisines
            state.ribbons = payload.ribbons
            state.bookingStatus = payload.bookingStatus
            state.userAuditTypes = payload.userAuditTypes
            state.userAuditStatues = payload.userAuditStatues
            state.userAuditFilterStatuses = payload.userAuditFilterStatuses
            state.facilities =  payload.facilities
            state.houseRules = payload.houseRules
        });
}
export const referenceDataThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;
        });
}

export const setReferenceDataReducer =  (state: any, {payload}: any) => { 

            state.badges = payload.badges
            state.allergens = payload.allergens
            state.food_types = payload.food_types
            state.dietary_attribures = payload.dietary_attribures
            state.cuisines = payload.cuisines
            state.ribbons = payload.ribbons
            state.bookingStatus = payload.bookingStatus
}