import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import ribbonsReducer from "./ribbons/ribbons-slice";
import badgesReducer from "./badges/badges-slice";
import authReducer from "./auth/auth-slice";
import alertReducer from "./alert/alert-slice";
import breadcrumbReducer from "./breadcrumb/breadcrumb-slice";
import listingReducer from "./listings/my-listing-slice";
import layoutReducer from "./layout/layout-slice";
import menuReducer from "./menu/menu-slice";
import bookingReducer from "./booking/booking-slice";
import recipeReducer from "./recipe/recipe-slice";
import dashboardReducer from "./dashboard/dashboard-slice";
import kitchenReducer from "./kitchen/kitchen-slice";
import myCalendarReducer from "./calendar/my-calendar-slice";
import foodItemReducer from "./food-restrictions/food-restrictions-slice";
import reviewsAndFeedbackReducer from "./reviews-and-feedback/reviews-and-feedback-slice";
import helpReducer from "./help/help-slice";
import manageReducer from './manage/manage-slice';
import auditsReducer from './audits/audits-slice'
import referenceDataReducer from "./reference-data/reference-data-slice";
import personalUserReducer from "./personal-user/personal-user-slice";
import trainingReducer from "./training/training-slice";
import houseRuleDataReducer from "./house-rules/house-rules-slice";
import facilitiesReducer from "./facilities/facilities-slice";
import idVerificationReducer from "./id-verification/id-verification-slice";
import foodReducer from "./food/food-slice";
import wishlistReducer from "./wishlists/wishlists-slice";
import hostDetailsReducer from "./host-details/host-details-slice";
import auditsAssignmentReducer from "./audit-assignment/audit-assignment-slice";
import FoodPreferenceReducer from "./food-preference/food-preference-slice";
import invoiceReducer from "./invoice/invoice-slice";
import logisticsSlice from "./logistics/logistics-slice";
import backUpReducer from "./back-up/back-up-slice";
import userDetailsAdminViewSliceReducer from "./user-details-admin-view/user-details-admin-view-slice";

const store = configureStore({
  reducer: {
    alert: alertReducer,
    breadcrumb: breadcrumbReducer,
    auth: authReducer,
    ribbons: ribbonsReducer,
    badges: badgesReducer,
    listing: listingReducer,
    kitchen: kitchenReducer,
    menu: menuReducer,
    booking: bookingReducer,
    layout: layoutReducer,
    recipe: recipeReducer,
    dashboard: dashboardReducer,
    myCalendar: myCalendarReducer,
    foodItem: foodItemReducer,
    reviewsAndFeedback: reviewsAndFeedbackReducer,
    help: helpReducer,
    manage: manageReducer,
    audits: auditsReducer,
    referenceData: referenceDataReducer,
    personalUser: personalUserReducer,
    training: trainingReducer,
    houseRules: houseRuleDataReducer,
    facilities: facilitiesReducer,
    idVerification: idVerificationReducer,
    food: foodReducer,
    wishlist: wishlistReducer,
    hostDetails: hostDetailsReducer,
    auditsAssignment:auditsAssignmentReducer,
    foodPreference: FoodPreferenceReducer,
    invoice: invoiceReducer,
    logistics:logisticsSlice,
    backup:backUpReducer,
    userDetailsAdminView: userDetailsAdminViewSliceReducer,

  },
});

export type AppDispatch = typeof store.dispatch;
// Export a hook that can be reused to resolve types
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export default store;

//TODO: rename slice to reducer 