import { USERROLES } from "../../../constants/roles-constant";
import { ACCOUNTSETTINGSPATH, ADDLISTINGSPATH, ADDRECIPEPATH, APIINVENTORYPATH, AUDITASSIGNMENTANNOUNCEDVISITPATH, AUDITASSIGNMENTPATH, AUDITASSIGNMENTUNANNOUNCEDVISITPATH, AUDITLOGSPATH, AUDITSPATH, BACKUPPATH, CHANGEPASSWORDPATH, CREATEMENUPATH, FOODPREFERENCEPATH, HELPPATH, HOMEPATH, LOGISTICSPATH, MANAGEPATH, MENUPATH, MENUPREVIEWPATH, MYBOOKINGSPATH, MYCALENDARPATH, MYINVOICEPATH, MYLISTINGSPATH, MYWHISHLISTPATH, OVERALLRATINGSPATH, RATEGUESTPATH, RATINGRECORDSPATH, RECIPEMANAGEMENTPATH, REVIEWSANDFEEDBACKPATH, SLASHPATH } from "../../../constants/routes-constant";
import { ISIDEBARDATA } from "../../sidebar/sidebar-interface";

export const searchbarData: any[] = [
    {
        id: "1",
        title: "Home",
        path: HOMEPATH,
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],

    },
    {
        id: "2",
        title: "Menu",
        path: MENUPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "3",
        title: "Menu Preview",
        allowedRoles: [USERROLES.HOST],
        path: MENUPATH + SLASHPATH + MENUPREVIEWPATH,
    },
    {
        id: "4",
        title: "Create Menu",
        allowedRoles: [USERROLES.HOST],
        path: MENUPATH + SLASHPATH + CREATEMENUPATH,
    },
    {
        id: "5",
        title: "Delete Menu",
        allowedRoles: [USERROLES.HOST],
        path: MENUPATH + SLASHPATH + MENUPREVIEWPATH,
    },
    {
        id: "6",
        title: "Edit Menu",
        allowedRoles: [USERROLES.HOST],
        path: MENUPATH + SLASHPATH + MENUPREVIEWPATH,
    },
    {
        id: "7",
        title: "Add food item",
        path: MENUPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "8",
        title: "Food preview",
        allowedRoles: [USERROLES.HOST],
        path: MENUPATH + SLASHPATH + MENUPREVIEWPATH,
    },

    {
        id: "9",
        title: "My Listings",
        path: MYLISTINGSPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "10",
        title: "Add Listings",
        path: `${MYLISTINGSPATH}${SLASHPATH}${ADDLISTINGSPATH}`,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "11",
        title: "Edit Listings",
        path: MYLISTINGSPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "12",
        title: "Delete Listings",
        path: MYLISTINGSPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "13",
        title: "My Bookings",
        path: MYBOOKINGSPATH,
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST],
    },
    {
        id: "14",
        title: "Logistics",
        path: LOGISTICSPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "15",
        title: "Reviews & Feedbacks",
        path: REVIEWSANDFEEDBACKPATH,
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST],
    },

    {
        id: "16",
        title: "Overall rating",
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST],
        path: REVIEWSANDFEEDBACKPATH + SLASHPATH + OVERALLRATINGSPATH,
    },
    {
        id: "17",
        title: "Rate guest",
        allowedRoles: [USERROLES.HOST],
        path: REVIEWSANDFEEDBACKPATH + SLASHPATH + RATINGRECORDSPATH,
    },

    {
        id: "18",
        title: "Rate host",
        allowedRoles: [USERROLES.GUEST],
        path: REVIEWSANDFEEDBACKPATH + SLASHPATH + RATINGRECORDSPATH,
    },
    {
        id: "19",
        title: "Recipes",
        path: RECIPEMANAGEMENTPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "20",
        title: "Add Recipe",
        path: `${RECIPEMANAGEMENTPATH}${SLASHPATH}${ADDRECIPEPATH}`,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "21",
        title: "Edit Recipe",
        path: RECIPEMANAGEMENTPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "22",
        title: "Delete Recipe",
        path: RECIPEMANAGEMENTPATH,
        allowedRoles: [USERROLES.HOST],
    },
    {
        id: "23",
        title: "My calendar",
        path: MYCALENDARPATH,
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST, USERROLES.COMPLIANCE],
    },
    {
        id: "24",
        title: "Help",
        path: HELPPATH,
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST],
    },
    {
        id: "25",
        title: "Account settings",
        path: ACCOUNTSETTINGSPATH,
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    },
    {
        id: "26",
        title: "Change Password",
        path: CHANGEPASSWORDPATH,
        allowedRoles: [USERROLES.HOST, USERROLES.GUEST, USERROLES.COMPLIANCE, USERROLES.SUPERADMIN],
    },
    {
        id: "27",
        title: "Food Preferences",
        path: FOODPREFERENCEPATH,
        allowedRoles: [USERROLES.GUEST],
    },
    {
        id: "28",
        title: "Wishlists",
        path: MYWHISHLISTPATH,
        allowedRoles: [USERROLES.GUEST],
    },
    {
        id: "29",
        title: "Add Wishlists",
        path: MYWHISHLISTPATH,
        allowedRoles: [USERROLES.GUEST],
    },
    {
        id: "30",
        title: "Edit Wishlists",
        path: MYWHISHLISTPATH,
        allowedRoles: [USERROLES.GUEST],
    },
    {
        id: "31",
        title: "Delete Wishlists",
        path: MYWHISHLISTPATH,
        allowedRoles: [USERROLES.GUEST],
    },
    {
        id: "31",
        title: "My Audits",
        path: AUDITSPATH,
        allowedRoles: [USERROLES.COMPLIANCE],
    },
    {
        id: "32",
        title: "Manage Users",
        path: MANAGEPATH,
        allowedRoles: [USERROLES.SUPERADMIN],
    },
    {
        id: "33",
        title: "Audit Logs",
        path: AUDITLOGSPATH,
        allowedRoles: [USERROLES.SUPERADMIN],
    },
    {
        id: "34",
        title: "Backup",
        path: BACKUPPATH,
        allowedRoles: [USERROLES.SUPERADMIN],
    },
    {
        id: "35",
        title: "Api Inventory",
        path: APIINVENTORYPATH,
        allowedRoles: [USERROLES.SUPERADMIN],
    },
    {
        id: "36",
        title: "Audit assignment",
        path: AUDITASSIGNMENTPATH,
        allowedRoles: [USERROLES.SUPERADMIN],
    },

    {
        id: "37",
        title: "Initial Visit",
        allowedRoles: [USERROLES.SUPERADMIN],
        path: AUDITASSIGNMENTPATH,
    },
    {
        id: "38",
        title: "Announced visit",
        allowedRoles: [USERROLES.SUPERADMIN],
        path: AUDITASSIGNMENTPATH + SLASHPATH + AUDITASSIGNMENTANNOUNCEDVISITPATH,
    },
    {
        id: "39",
        title: "Unannounced visit",
        allowedRoles: [USERROLES.SUPERADMIN],
        path: AUDITASSIGNMENTPATH + SLASHPATH + AUDITASSIGNMENTUNANNOUNCEDVISITPATH,
    },

];