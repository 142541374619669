import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getListingThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log(payload);
        });
}

export const getListingThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.listingsData = payload;
            // console.log('Successfully got listing',payload);

        });
}
export const getListingThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;
        });

}



export const setSortedListingsData = (state: any, action: any) => {
    state.listingsData.listings = action.payload;
}



//delete listing
export const deleteListingThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log(REQUESTSTATUS.FAILED);
        })
}
export const deleteListingThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
            // console.log('deleted');
            // state.listingsData = payload;
            // console.log('Successfully got listing',payload);

        });
}
export const deleteListingThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;
        });
}

//change listing status
export const changeListingStatusThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        })
}
export const changeListingStatusThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;

        });
}
export const changeListingStatusThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;

        });
}

// single listing by ID

export const getListingByIDThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        })
}
export const getListingByIDThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
        });
}

export const getListingByIDThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;

        });
}

// patch listing reducer
export const patchListingThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        })
}
export const patchListingThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.singleListingData = payload;

        });
}
export const patchListingThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;

        });
}

// confirm booking reducers from single lisiting guest view
export const sendBookingRequestThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        })
}
export const sendBookingRequestThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.singleListingData = payload;

        });
}
export const sendBookingRequestThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;

        });
}


// view listing
export const viewListingByIDThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        })
}
export const viewListingByIDThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.singleListingData = payload;
            state.userId = payload?.host?._id
            state.status = REQUESTSTATUS.SUCCEEDED;
        });
}

export const viewListingByIDThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;

        });
}

export const clearSingleListingsData = (state: any) => {
    state.singleListingData = [];

}



export const getReviewsByIdThunkRejectedReducer=(builder:any, action:any)=>{
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        })
}
export const getReviewsByIdThunkFulfilledReducer=(builder:any, action:any)=>{
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.reviews = payload;
            state.status = REQUESTSTATUS.SUCCEEDED;
        });
}
export const getReviewsByIdThunkPendingReducer=(builder:any, action:any)=>{
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING;

        });
}
