import { REQUESTSTATUS } from "../../constants/axios-api-constant";


export const ThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.status = REQUESTSTATUS.PENDING
    });
};



export const overallRatingPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any) => {
            state.status = REQUESTSTATUS.PENDING
        }
    )
}

export const overallRatingRejectReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any) => {
            state.status = REQUESTSTATUS.FAILED
        }
    )
}

export const getoverallThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.overallRating = payload;
            state.feedbacks = payload?.feedbacks;
            state.status = REQUESTSTATUS.SUCCEEDED
        });
};



// rate-host and rate-guest modal box data
export const getRatingOnModalThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any) => {
            state.modalBoxStatus = REQUESTSTATUS.PENDING
        }
    )
}
export const getRatingOnModalThunkRejectReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any) => {
            state.modalBoxStatus = REQUESTSTATUS.FAILED
        }
    )
}
export const getRatingOnModalThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.data = payload?.data
            state.modalBoxStatus = REQUESTSTATUS.SUCCEEDED
        });
};





export const postRateHostThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED
        });
};
export const postRateHostThunkRejectReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any) => {
            state.status = REQUESTSTATUS.FAILED
        });
}






export const postguestReviewThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any) => {
            state.submitReviewStatus = REQUESTSTATUS.SUCCEEDED
        });
}

export const postguestReviewThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any) => {
            state.submitReviewStatus = REQUESTSTATUS.FAILED
        });
}

export const postguestReviewThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.submitReviewStatus = REQUESTSTATUS.PENDING
    });
}




export const getRatingRecordsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.recordStatus = REQUESTSTATUS.PENDING
    })
}

export const getRatingsRecordsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        state.recordStatus = REQUESTSTATUS.SUCCEEDED
        state.ratingRecords = payload?.ratingRecords
        state.ratingRecordsMetaData = payload?.ratingRecordsMetaData
    });
}

export const getRatingsRecordsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any) => {
        state.recordStatus = REQUESTSTATUS.FAILED
    })
}







export const getRateNowDataThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.rateNowStatus = REQUESTSTATUS.PENDING
    })
}
export const getRateNowDataThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        state.rateNowStatus = REQUESTSTATUS.SUCCEEDED
        state.getRateNowData = payload?.data?.userDetails
    });
}
export const getRateNowDataThunkRejectReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any) => {
        state.rateNowStatus = REQUESTSTATUS.FAILED
    })
}