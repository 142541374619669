import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { IAUTHSTATEUSER } from "../auth/auth-slice-interface";

export const setAboutMeReducer = (state: any, action: any) => {
    state.setAboutMe = action.payload;
  };
  export const setPersonalDetailReducer = (state: any, action: any) => {
    state.setPersonalDetail = action.payload;
  };
  export const setLanguagesReducer = (state: any, action: any) => {
    state.setLanguages = action.payload;
  };

export const personalDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED
            state.message = payload.message
            state.isLoading = false;
        });
}

export const personalDetailsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: IAUTHSTATEUSER, { payload }: any) => {
            state.error = payload.error;
            state.status = REQUESTSTATUS.FAILED
            state.isLoading = false;
        });
}
export const ThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: IAUTHSTATEUSER) => {
        state.isLoading = true;
        state.status = REQUESTSTATUS.PENDING
    });
}

export const cityThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        });
}

export const cityThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED;
            state.city = payload.city
        });
}

export const cityThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.PENDING
        });
}

export const myUserProfileDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;
        state.user = payload.user
        state.overallProfileCompletion = payload.overallProfileCompletion
        state.profileCompletion =  payload.profileCompletion
    });
};

export const myUserProfileDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.FAILED;
    });
}


export const updatePersonalUserProfileStatusThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;
        state.overallProfileCompletion = payload.overallProfileCompletion
        state.profileCompletion =  payload.profileCompletion

    });
}

export const updatePersonalUserProfileStatusThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.FAILED;
    });
}

export const profileImageThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.SUCCEEDED;
        state.user.profileImage = payload.profileImage
    });
}

export const profileImageThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload,'payload');
        
        state.status = REQUESTSTATUS.FAILED; 
    });
}

export const personalUsertrainingDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
    //   console.log({ payload });
      state.status = REQUESTSTATUS.FAILED;
    });
  };
  
  export const personalUsertrainingDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
      state.status = REQUESTSTATUS.SUCCEEDED;
      state.trainingsDetails = payload.trainingsDetails
    });
  };


  export const personalUsertrainingDataUpdateReducer = (state: any, {payload}: any) => {
    // console.log(payload)
    state.trainingsDetails = payload.trainingsDetails
}