import { createSlice } from "@reduxjs/toolkit";
import { getAuditLogs, getUserAuditsThunk } from "./audits-thunk-actions";
import { getAuditLogsThunkFullfilledReducer, getAuditLogsThunkRejectedReducer, getAuditLogThunkPendingReducer, getUserAuditshunkPendingReducer, getUserAuditsThunkFullfilledReducer, getUserAuditsThunkRejectedReducer } from "./audits-reducers";
import { viewAuditLogsData } from "../../features/audits/audit-logs/view-audit-logs/view-audit-logs-data";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

const auditsInitialState = {
    auditLogsData: [],
    meta:{},
    status: REQUESTSTATUS.IDLE
}

export const auditLogsSclice = createSlice({
    name: 'audits',
    initialState:auditsInitialState,
    reducers:{},
    extraReducers (builder){

        getAuditLogThunkPendingReducer(builder, getAuditLogs)
        getAuditLogsThunkFullfilledReducer(builder, getAuditLogs)
        getAuditLogsThunkRejectedReducer(builder, getAuditLogs)

        getUserAuditshunkPendingReducer(builder, getUserAuditsThunk)
        getUserAuditsThunkFullfilledReducer(builder, getUserAuditsThunk)
        getUserAuditsThunkRejectedReducer(builder, getUserAuditsThunk)
              
    }
})

export default auditLogsSclice.reducer;