import { REQUESTSTATUS } from "../../constants/axios-api-constant";



export const setNumberOfGuestReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.numberOfGuests = payload;
};



export const setDateForSearchReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.dateForSearch = payload;
};

export const setTimeForSearchReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.timeForSearch = payload;
};



export const setHostForSearchReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.hostForSearch.name = payload.name;
    state.hostForSearch.rating = payload.rating;
};
export const setLocationForSearchReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.locationForSearch = payload;
};



export const setCuisineForSearchReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.cuisineForSearch = payload;
};


export const setSelectedDietaryReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.selectedDietary = payload;
    state.filters!.dietary = payload
};


export const setSelectedOffersReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.selectedOffers = payload;
    state.filters.offers = payload
};


export const setSelectedFacilitiesReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.selectedFacilities = payload;
    state.filters.facilities = payload;
};

export const setSelectedFacilitiesForDisablesReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.selectedFacilitiesForDisables = payload;
    state.filters.facilitiesForDisables = payload;
};

export const setSelectedFacilitiesForChildrenReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.selectedFacilitiesForChildren = payload;
    state.filters.facilitiesForChildren = payload;
};


export const setSelectedHouseRulesReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.selectedHouseRules = payload;
    state.filters.houseRules = payload;
};


export const setSelectedHostLanguageProficiencesReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.selectedHostLanguageProficiences = payload;
    state.filters.hostLanguageProficiences = payload;
};


export const setSelectedPriceReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.selectedPrice = payload;
    state.filters.price = payload;
};



export const setGeoLocationCoordinateReducer = (state: any, { payload }: any) => {
    // console.log(payload)
    state.geolocation = payload;
};





















export const searchFoodPreferencesByLocationThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.locationSearchStatus = REQUESTSTATUS.PENDING;
    });
};

export const searchFoodPreferencesByLocationThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.locationSearchStatus = REQUESTSTATUS.SUCCEEDED;
        state.locationBasedFoods = payload.locationBasedFoods

    });
};

export const searchFoodPreferencesByLocationThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
        state.locationSearchStatus = REQUESTSTATUS.FAILED;

    });
};



export const searchFoodPreferencesByHostThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.hostSearchStatus = REQUESTSTATUS.PENDING;
    });
};

export const searchFoodPreferencesByHostThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.hostSearchStatus = REQUESTSTATUS.SUCCEEDED;
        state.hostBasedFoods = payload.hostBasedFoods
    });
};

export const searchFoodPreferencesByHostThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
        state.hostSearchStatus = REQUESTSTATUS.FAILED;

    });
};

//search food by cuisines
export const searchFoodPreferencesByCuisinesThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.cuisineSearchStatus = REQUESTSTATUS.PENDING;
    });
};

export const searchFoodPreferencesByCuisinesThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.cuisineSearchStatus = REQUESTSTATUS.SUCCEEDED;
        state.cuisineBasedFoods = payload.cuisineBasedFoods

    });
};

export const searchFoodPreferencesByCuisinesThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
        state.cuisineSearchStatus = REQUESTSTATUS.FAILED;

    });
};

//search locations and coordinatess

export const searchlocationforFoodPreferenceLocationSearchThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.searchLocationStatus = REQUESTSTATUS.PENDING;
    });
};
export const getSelectedFoodPreferenceLocationSearchDetailsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.searchLocationStatus = REQUESTSTATUS.PENDING;
    });
};

export const searchlocationforFoodPreferenceLocationSearchThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.searchLocationStatus = REQUESTSTATUS.SUCCEEDED;
        state.locations = payload?.locations


    });
};

export const searchlocationforFoodPreferenceLocationSearchThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
        state.searchLocationStatus = REQUESTSTATUS.FAILED;

    });
};
export const getSelectedFoodPreferenceLocationSearchDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.searchLocationStatus = REQUESTSTATUS.SUCCEEDED;
        state.coordinates = payload?.coordinates

    });
};

export const getSelectedFoodPreferenceLocationSearchDetailsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log(payload)
        state.searchLocationStatus = REQUESTSTATUS.FAILED;


    });
};

