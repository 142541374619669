import { Home } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { SLASHPATH } from "../../constants/routes-constant";

import { useAppSelector } from "../../store/store";

import "./app-breadcrumbs.scss"


export const AppBreadcrumbs = () => {

    const { breadcrumb } = useAppSelector(state => state);

    return (
            <div className="global-breadcrumb">
                <Link 
                    className="global-breadcrumb-previous-link" 
                    to={ breadcrumb?.breadcrumbData?.firstLink}
                >
                    {breadcrumb?.breadcrumbData?.firstLinkName}

                </Link> 
                <Link  to={SLASHPATH}>
                    <Home className="align-middle heading-20 global-breadcrumb-icon"/>
                </Link>
                
                {
                    !!breadcrumb?.breadcrumbData?.otherBreadcrumbs?.length && 
                    breadcrumb?.breadcrumbData?.otherBreadcrumbs?.map((otherBreadcrumb:any)=> (
                        
                    otherBreadcrumb.className === "global-breadcrumb-active" ?  
                        <span 
                        key={otherBreadcrumb.id} 
                        className={otherBreadcrumb?.className}
                        >
                            {otherBreadcrumb?.linkName}
                        </span>   : 
                        <Link 
                        key={otherBreadcrumb.id} 
                        className={otherBreadcrumb?.className} 
                        to={otherBreadcrumb?.linkPath}
                        >
                            {otherBreadcrumb?.linkName}
                        </Link>   
                        
                    ))
                }
            </div>
    )
}

