import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { createSlice } from "@reduxjs/toolkit";

import { getBackUpDataThunk, getBackUpFileThunk } from "./back-up-thunk-action";
import { getBackUpDataThunkFullfilledReducer, getBackUpDataThunkPendingReducer, getBackUpDataThunkRejectedReducer, getBackUpFileThunkFullfilledReducer, getBackUpFileThunkPendingReducer, getBackUpFileThunkRejectedReducer, } from "./back-up-reducers";

const backupInitialState = {
    backups: [] as any,
    meta:{} as any,
    status: REQUESTSTATUS.IDLE,
    fileStatus: REQUESTSTATUS.IDLE,
    file:""
    
}

export const backUpSclice = createSlice({
    name: 'backup',
    initialState:backupInitialState,
    reducers:{},
    extraReducers (builder){

        getBackUpDataThunkPendingReducer(builder, getBackUpDataThunk)
        getBackUpDataThunkFullfilledReducer(builder, getBackUpDataThunk)
        getBackUpDataThunkRejectedReducer(builder, getBackUpDataThunk)

        getBackUpFileThunkPendingReducer(builder, getBackUpFileThunk)
        getBackUpFileThunkFullfilledReducer(builder, getBackUpFileThunk)
        getBackUpFileThunkRejectedReducer(builder, getBackUpFileThunk)

        
              
    }
})

export default backUpSclice.reducer;