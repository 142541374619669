import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { hideAlertMessage } from "../../store/alert/alert-slice";

import "./app-alert.scss";
import { useAppAlert } from "./use-app-alert";

export const AppAlert = (props:any) => {

    const { alertType , alertMessage , dismissAlert , clearAlertClassName} = props;

    const { alert , closeAlert  } = useAppAlert(props);

    return (
      
        <div 
        className={`alert-message-wrapper flex align-center ${alert?.alertType || alertType || 'alert-error-message'}`}>

            <span className="alert-message-wrapper-flex-grow secondary-title">{alert?.alertMessage || alertMessage}</span>
            <span 
            className={`cursor-pointer secondary-title ${clearAlertClassName}`} 
            onClick={ closeAlert || dismissAlert}>

               {" "}{" "} X

            </span>

        </div>
    )
}
