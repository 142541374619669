import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getBackUpDataThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, {payload}:any) => {
        // console.log(payload);
        state.status = REQUESTSTATUS.PENDING
    });
}

export const getBackUpDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
            // console.log('Failed', payload);
        });
}

export const getBackUpDataThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.backups = payload.backupData;
            state.meta = payload.meta
            state.status = REQUESTSTATUS.SUCCEEDED
        });
}


export const getBackUpFileThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, {payload}:any) => {
        // console.log(payload);
        state.fileStatus = REQUESTSTATUS.PENDING
    });
}

export const getBackUpFileThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.fileStatus = REQUESTSTATUS.FAILED;
            // console.log('Failed', payload);
        });
}

export const getBackUpFileThunkFullfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.fileStatus = REQUESTSTATUS.SUCCEEDED;
            state.file =payload.file
        });
}

