import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getDashboardDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            state.dashboardDetails = payload;
            state.acceptedOrders = payload.AcceptedBooking;
            state.cancelledOrders = payload.CancelledBooking;
            state.newBookingDetails = payload.NewBooking;
            state.upcomingBookingDetails = payload.UpcommingBooking;
            state.overAllRating = payload.StarPerMonth;
            state.boostListing = payload.ListingCount;
        });
}

export const getComplianceDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            state.totalAudits = payload.widgets_data.assigned;
            state.inProgressAudits = payload.widgets_data.in_progress;
            state.completedAudits = payload.widgets_data.completed;
            state.recentPerformedAudits = payload.audits;
            state.target = payload.widgets_data.target;
            state.leftTarget = payload.widgets_data.left_target;
            state.status = REQUESTSTATUS.SUCCEEDED;

        }
    )
}

export const getComplianceDetailsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        // console.log(state.status = REQUESTSTATUS.PENDING);
        state.status = REQUESTSTATUS.PENDING
    }
    )
}
export const boostMyListingRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;

        });
}

export const boostMyListingFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {

            state.status = REQUESTSTATUS.SUCCEEDED;
            state.boostMyListing = payload.boostMyListing

        });
}
export const boostMyListingPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {

            state.status = REQUESTSTATUS.PENDING;
        });
}
export const listingGuestThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload, '🍕'); 
            state.boostListingGuest = payload;
            state.boostListingGuestStatus = REQUESTSTATUS.SUCCEEDED;
        }
    )
}

export const listingGuestThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {

        state.boostListingGuestStatus = REQUESTSTATUS.PENDING
    }
    )
}
export const listingGuestThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.boostListingGuestStatus = REQUESTSTATUS.FAILED
    }
    )
}

// Top menu reducers
export const topMenuRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.status = REQUESTSTATUS.FAILED;
    });
};
export const topMenuFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        // console.log({ payload });
        state.topMenuItems = payload;
        state.status = REQUESTSTATUS.SUCCEEDED;

    });
};
export const topMenuPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.PENDING
    }
    )
}

export const getTopRatedKitchensThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        // console.log(state.status = REQUESTSTATUS.PENDING, '🍕');
        state.ratedKitchenStatus = REQUESTSTATUS.PENDING
    }
    )
}

export const getTopRatedKitchensThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.ratedKitchenStatus = REQUESTSTATUS.FAILED
    }
    )
}

export const getTopRatedKitchensThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            state.ratedKitchenStatus = REQUESTSTATUS.SUCCEEDED;
            state.ratedKitchens = payload?.kitchenData;
        }
    )
}


export const getSingleKitchensDetailsThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        // console.log(state.status = REQUESTSTATUS.PENDING, '🍕');
        state.ratedKitchenStatus = REQUESTSTATUS.PENDING
    }
    )
}

export const getSingleKitchensDetailsThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.ratedKitchenStatus = REQUESTSTATUS.FAILED
    }
    )
}

export const getSingleKitchensDetailsThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            state.ratedKitchenStatus = REQUESTSTATUS.SUCCEEDED;
            state.singleKitchen =  payload.singleKitchen

        }
    )
}


export const getRatingsByIdThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        // console.log(state.status = REQUESTSTATUS.PENDING, '🍕');
        state.ratedKitchenStatus = REQUESTSTATUS.PENDING
    }
    )
}

export const getRatingsByIdThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.ratedKitchenStatus = REQUESTSTATUS.FAILED
    }
    )
}

export const getRatingsByIdThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            state.ratedKitchenStatus = REQUESTSTATUS.SUCCEEDED;
            state.singleKitchenReviews =  payload.topReviews

        }
    )
}
export const getBestListingsByUserIdThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        // console.log(state.status = REQUESTSTATUS.PENDING, '🍕');
        state.ratedKitchenStatus = REQUESTSTATUS.PENDING
    }
    )
}

export const getBestListingsByUserIdThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.ratedKitchenStatus = REQUESTSTATUS.FAILED
    }
    )
}

export const getBestListingsByUserIdThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            state.ratedKitchenStatus = REQUESTSTATUS.SUCCEEDED;
            state.singleKitchenBestListings =  payload.bestListings

        }
    )
}