
export const STATUSCHANGETOOLTIP = {
    tooltip: { sx: { maxWidth: '130px', padding: '0 !important', boxShadow: '0px 0px 9px rgba(131, 164, 249, 0.15)' } },
    arrow: {
        sx: {
            fontSize: 15, color: '#FFFFFF', transform: 'translate(100px, 0px) !important',
            "&::before": {
                boxShadow: '0px 0px 9px rgba(131, 164, 249, 0.15)'
            }
        }
    }
}
