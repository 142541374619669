// SCSS
import "./date-and-time.scss";

import { ClickAwayListener, Grid, InputAdornment, TextField, } from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { GlobalDatePicker } from "../../../../../../components/date-picker/date-picker";

import { AppButton } from "../../../../../../components/app-button/app-button";
import { timeButtons } from "./date-and-time-data";
import { dayjsFormatDate } from "../../../../../../lib/dayjs";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../../../../store/store";
import { setDateForSearch, setTimeForSearch } from "../../../../../../store/food-preference/food-preference-slice";

import { useState, useEffect } from 'react'

// import moment from 'moment';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import bgLocale from 'date-fns/locale/bg';
import { MUIICONCOLOR, MUIPRIMARYCOLOR } from "../../../../../../constants/mui-styles-constant";

// Component Functions Starts Here
const DateAndTime: React.FC<any> = (props: any) => {
  const [dateTimeDrop, setDateTimeDrop] = useState(false);

  const { activeTime, setActiveTime, activeShift, setActiveShift }: any = props
  const dispatch = useAppDispatch()
  // console.log({ props })
  const handleDate = () => {
    setDateTimeDrop(!dateTimeDrop);
  }


  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          sx: {

            '&:hover': {
              backgroundColor: 'black !important',
              // border:`1px solid ${MUIPRIMARYCOLOR}` 
            },
            '&:focus': {
              // border:`1px solid ${MUIPRIMARYCOLOR}`,
              backgroundColor: 'orange !important'
            },
            '&.Mui-selected': {
              backgroundColor: 'orange !important'
            },
            '& .MuiButtonBase-root .MuiPickersDay-today': {
              backgroundColor: 'orange !important'
            }
          }
        },
        styleOverrides: {
          // Name of the slot
          root: {
            sx: {
              '&.MuiPickersDay-root': {
                backgroundColor: 'orange !important'
              },
              '& .MuiButtonBase-root .MuiPickersDay-today': {
                backgroundColor: 'orange !important'
              }
            }
            // Some CSS
            // color:'red' 
          },

        },
      },
    }
  });

  const setActiveHandler = (state: any) => {
    // console.log(state,'state');

    setActiveShift(state)
    // console.log(activeShift)
    // console.log(activeTime)
    // console.log(state)
    // console.log(dayjsFormatDate(activeTime,'DD/MMMM/YYYYY'))
    // console.log(activeShift)
    const date = {
      date: activeTime,
      time: state
    }
    // console.log(date)
    // console.log(activeShift)
    dispatch(setTimeForSearch(state))

  }

  useEffect(() => {
    const setDate = dayjs(activeTime)?.format('DD MMMM YYYY')
    dispatch(setDateForSearch(setDate))
  }, [])

  const changeDate = (date: any) => {
    // console.log(date)
    // console.log()
    const setDate = dayjs(date)?.format('DD MMMM YYYY')
    setActiveTime(date)
    // console.log(activeTime)
    dispatch(setDateForSearch(setDate))
  }

  // console.log(activeTime, 'getDate');

  return (
    <>
      <Grid className="cursor-pointer position-relative date-input">

        <div onClick={handleDate}>
          <TextField
            className=""
            // sx={{ borderRadius: '56px' }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  borderColor: MUIICONCOLOR,
                  borderRadius: '56px'
                },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: MUIPRIMARYCOLOR
                }
              },
              "& .MuiOutlinedInput-root:hover": {
                "& > fieldset": {
                  borderColor: MUIPRIMARYCOLOR
                }
              },
            }}
            fullWidth
            value={(activeShift || activeTime) ? `${dayjs(activeTime)?.format('DD/MM/YY')} | ${activeShift}` : ''}
            size="small" placeholder="Date & Time"
            InputProps={{
              style: { fontSize: "14px", paddingBlock: '2.5px', color: 'white', borderRadius: '56px !important' },
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon fontSize="small" sx={{ color: "#AEAEAE" }} />
                </InputAdornment>
              ),
            }} />
        </div>
        {dateTimeDrop && (
          <ClickAwayListener onClickAway={(e: any) => setDateTimeDrop(false)}>
            <div className='date-calender'>
              <Grid className="overflow-auto">
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={bgLocale}>
                    <div style={{maxWidth:'100%'}}>
                      <CalendarPicker
                        className="overflow-auto date"
                        date={activeTime}
                        onChange={(date: Date | null | undefined) => changeDate(date)}
                        disablePast
                      />
                    </div>

                  </LocalizationProvider>
                </ThemeProvider>
              </Grid>
              <span className='white-color font-weight-500 font-family-roboto padding-left-2' >Select Time</span> <br />
              <Grid container sx={{ m: '10px' }}>
                {timeButtons.map((btn: any) => {
                  return (
                    <Grid
                      item
                      // container
                      justifyContent="space-between"
                      paddingX={2.5}
                      xs={6}
                      md={6}
                      key={btn.buttonId}>
                      <div>
                        <span className='selected-field-color font-weight-500 font-family-roboto tertiary-title' >{btn.btnHeader}</span><br />
                        {
                          btn.btnDetail.map((props: any) => (<AppButton
                            key={props.btnId} {...props}
                            btnFunction={() => setActiveHandler(props.btnText)}
                            className={`title-10 primary-color ${activeShift === props.btnText ? 'border-primary-active' : 'border-gray'}`}
                          />
                          ))
                        }
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          </ClickAwayListener>
        )}
      </Grid>
    </>
  );
};
export default DateAndTime;
