import { Button } from "@mui/material";
import { IAPPBUTTONPROPS } from "./app-button-interface";

import "./app-button.scss";


export const AppButton: React.FC<any> = (props: any) => {

    const { fullWidth, sx, id, type, disabled, btnIcon, btnText, btnFunction, className, btnName, btnValue } = props;
    // console.log(props)
    return (

        <Button
            disableElevation
            fullWidth={fullWidth}
            onClick={() => { btnFunction?.(); }}
            sx={sx}
            className={`${className} width-sm-ful`}
            id={id}
            type={type as 'submit' | 'reset' | 'button' || undefined}
            disabled={disabled}
            name={btnName}
            value={btnValue}
        >
            {btnIcon} {btnText}

        </Button>
    );
};