import { createSlice } from "@reduxjs/toolkit";

import {  ratings, relevances,
  ratingEmojiModalMain
} from "../../mock-data/reviews-and-feedback-mock-data";

import {
  getoverallThunkFulFilledReducer, postRateHostThunkFulFilledReducer, postRateHostThunkRejectReducer, ThunkPendingReducer,
  postguestReviewThunkFulfilledReducer, postguestReviewThunkRejectedReducer, getRatingsRecordsThunkFulfilledReducer,
  getRatingsRecordsThunkRejectedReducer, overallRatingRejectReducer, overallRatingPendingReducer,
  getRatingOnModalThunkPendingReducer, getRatingOnModalThunkRejectReducer, getRatingOnModalThunkFulFilledReducer,
  getRatingRecordsThunkPendingReducer, getRateNowDataThunkPendingReducer, getRateNowDataThunkFulFilledReducer,
  getRateNowDataThunkRejectReducer,
} from "./reviews-and-feedback-reducers";


import { getMyRatingOnModalBoxThunk, getRatingOnRateNowThunk,
  getRatingsRecordsThunk, guestReviewPostThunk, overallHostRatingThunk, postRateHostThunk
} from "./reviews-and-feedback-thunk-action";


const reviewAndFeedbackInitialState = {
  overallRating: {},
  status: '',
  
  feedbacks: [],
  ratingRecords: [],
  recordStatus: '',

  data: {},
  modalBoxStatus: '',
  ratingRecordsMetaData: {},

  getRateNowData: {},
  rateNowStatus: '',

  ratings: ratings,
  relevances: relevances,
  ratingEmojiModalMain: ratingEmojiModalMain,

  submitReviewStatus:''
};

export const reviewsAndFeedbackSlice = createSlice({

  name: "reviewsAndFeedback",
  initialState: reviewAndFeedbackInitialState,
  reducers: {},

  extraReducers(builder) {
    ThunkPendingReducer(builder, postRateHostThunk)
    postRateHostThunkFulFilledReducer(builder, postRateHostThunk)
    postRateHostThunkRejectReducer(builder, postRateHostThunk)

    postguestReviewThunkFulfilledReducer(builder, guestReviewPostThunk)
    postguestReviewThunkRejectedReducer(builder, guestReviewPostThunk)

    getRatingRecordsThunkPendingReducer(builder, getRatingsRecordsThunk)
    getRatingsRecordsThunkFulfilledReducer(builder, getRatingsRecordsThunk)
    getRatingsRecordsThunkRejectedReducer(builder, getRatingsRecordsThunk)

    getoverallThunkFulFilledReducer(builder, overallHostRatingThunk)
    overallRatingRejectReducer(builder, overallHostRatingThunk)
    overallRatingPendingReducer(builder, overallHostRatingThunk)

    getRatingOnModalThunkPendingReducer(builder, getMyRatingOnModalBoxThunk)
    getRatingOnModalThunkRejectReducer(builder, getMyRatingOnModalBoxThunk)
    getRatingOnModalThunkFulFilledReducer(builder, getMyRatingOnModalBoxThunk)


    getRateNowDataThunkPendingReducer(builder, getRatingOnRateNowThunk)
    getRateNowDataThunkFulFilledReducer(builder, getRatingOnRateNowThunk)
    getRateNowDataThunkRejectReducer(builder, getRatingOnRateNowThunk)
  }

}
);
export default reviewsAndFeedbackSlice.reducer;
