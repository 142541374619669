import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
    addDbsCheckReducer, addDbsCheckThunkFulfilledReducer, addDbsCheckThunkRejectedReducer, addlicenseThunkFulfilledReducer, addlicenseThunkRejectedReducer,
    getContractPdfThunkFulfilledReducer, getContractPdfThunkPendingReducer, getContractPdfThunkRejectedReducer, getDbsCheckDataFileThunkFulfilledReducer, getDbsCheckDataFileThunkPendingReducer, getDbsCheckDataFileThunkRejectedReducer, getDbsCheckDataThunkFulfilledReducer, getDbsCheckDataThunkPendingReducer, getDbsCheckDataThunkRejectedReducer, 
    getLicenseDataPremisesFileThunkFulfilledReducer, 
    getLicenseDataPremisesFileThunkRejectedReducer, 
    getLicenseDataThunkFulfilledReducer, getLicenseDataThunkPendingReducer, getLicenseDataThunkRejectedReducer, ggetLicenseDataPremisesFileThunkPendingReducer, myUserProfileDataThunkFulfilledReducer, myUserProfileDataThunkRejectedReducer, 
    sentContractThunkFulfilledReducer, sentContractThunkRejectedReducer, submitVeriffReducer, ThunkPendingReducer
} from "./id-verification-reducers";
import { 
    addDbsCheckThunk, addlicenseThunk, getContractPdfThunk, getDbsCheckDataFileThunk, getDbsCheckDataThunk, getLicenseDataPremisesFileThunk, getLicenseDataThunk, 
    myUserProfileDataThunk, sentContractThunk, } from "./id-verification-thunk-actions";

const idVerificationInitialState = {
    status: REQUESTSTATUS.IDLE,
    statusDbsFile: REQUESTSTATUS.IDLE,
    statusPermisesFile: REQUESTSTATUS.IDLE,
    getDbsStatus: REQUESTSTATUS.IDLE,
    getLicenseStatus: REQUESTSTATUS.IDLE,
    dbsCheck: {} as any,
    postDbsData: {} as any,
    veriffStatus: {} as any,
    licenseData: {} as any,
    contractBookData: {} as any,
    getContractStatus:REQUESTSTATUS.IDLE,
    contractPdfLink: ''
}

const idVerificationSlice = createSlice({

    name: 'idVerification',

    initialState: idVerificationInitialState,

    reducers: {
        addDbsCheck: addDbsCheckReducer,
        submitVeriff: submitVeriffReducer,
    },

    extraReducers(builder) {

        //add dbs check thunk reducers 
        ThunkPendingReducer(builder, addDbsCheckThunk)
        addDbsCheckThunkFulfilledReducer(builder, addDbsCheckThunk)
        addDbsCheckThunkRejectedReducer(builder, addDbsCheckThunk)

        //add dbs license thunk reducers 
        ThunkPendingReducer(builder, addlicenseThunk)
        addlicenseThunkFulfilledReducer(builder, addlicenseThunk)
        addlicenseThunkRejectedReducer(builder, addlicenseThunk)

        //add dbs check thunk reducers 
        ThunkPendingReducer(builder, sentContractThunk)
        sentContractThunkFulfilledReducer(builder, sentContractThunk)
        sentContractThunkRejectedReducer(builder, sentContractThunk)

        // get user Profile data
        ThunkPendingReducer(builder, myUserProfileDataThunk)
        myUserProfileDataThunkFulfilledReducer(builder, myUserProfileDataThunk)
        myUserProfileDataThunkRejectedReducer(builder, myUserProfileDataThunk)


        // get dbs information 
        getDbsCheckDataThunkPendingReducer(builder, getDbsCheckDataThunk)
        getDbsCheckDataThunkFulfilledReducer(builder, getDbsCheckDataThunk)
        getDbsCheckDataThunkRejectedReducer(builder, getDbsCheckDataThunk)

        // get dbs file information 
        getDbsCheckDataFileThunkPendingReducer(builder, getDbsCheckDataFileThunk)
        getDbsCheckDataFileThunkFulfilledReducer(builder, getDbsCheckDataFileThunk)
        getDbsCheckDataFileThunkRejectedReducer(builder, getDbsCheckDataFileThunk)

        // get license information 
        getLicenseDataThunkPendingReducer(builder, getLicenseDataThunk)
        getLicenseDataThunkFulfilledReducer(builder, getLicenseDataThunk)
        getLicenseDataThunkRejectedReducer(builder, getLicenseDataThunk)

        // get license information 
        ggetLicenseDataPremisesFileThunkPendingReducer(builder, getLicenseDataPremisesFileThunk)
        getLicenseDataPremisesFileThunkFulfilledReducer(builder, getLicenseDataPremisesFileThunk)
        getLicenseDataPremisesFileThunkRejectedReducer(builder, getLicenseDataPremisesFileThunk)

        // get contract pdf 
        getContractPdfThunkPendingReducer(builder, getContractPdfThunk)
        getContractPdfThunkFulfilledReducer(builder, getContractPdfThunk)
        getContractPdfThunkRejectedReducer(builder, getContractPdfThunk)
    }
})

export const { addDbsCheck, submitVeriff } = idVerificationSlice.actions;

export default idVerificationSlice.reducer
