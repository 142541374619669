export const DOTANIMATIONANDSTYLE = { color:"green",fontSize:'12px', animation: "spin .5s linear infinite alternate",
"@keyframes spin": {
  "0%": {
    transform: "scale(1)",
  },
  "100%": {
    transform: "scale(.5)",
  },
},}
export const BADGE = {"& .MuiBadge-badge": {
    backgroundColor: "transparent",
    top: '1px',
    right: '1px',
  }
}