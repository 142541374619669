import { createSlice } from "@reduxjs/toolkit";
 
import { cityThunk, myUserProfileDataThunk, personalDetailsThunk, personalUsertrainingDataThunk, profileImageThunk, 
          updatePersonalUserProfileStatusThunk } from "./personal-user-thunk-actions";
import {  personalDetailsThunkFulfilledReducer, personalDetailsThunkRejectedReducer, 
          ThunkPendingReducer,cityThunkFulfilledReducer, cityThunkRejectedReducer, myUserProfileDataThunkFulfilledReducer, 
          myUserProfileDataThunkRejectedReducer, updatePersonalUserProfileStatusThunkFulfilledReducer, 
          updatePersonalUserProfileStatusThunkRejectedReducer, profileImageThunkFulfilledReducer, 
          profileImageThunkRejectedReducer, setAboutMeReducer, setPersonalDetailReducer, setLanguagesReducer,
          personalUsertrainingDataThunkFulfilledReducer, personalUsertrainingDataThunkRejectedReducer, 
          personalUsertrainingDataUpdateReducer } from "./personal-user-reducers";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

const personalUserInitialState = {
  status: REQUESTSTATUS.IDLE,
  user: {} as any,
  overallProfileCompletion: 0,
  profileCompletion:{} as any,
  error: '',
  success: '',
  message: '',
  city: [] as any,
  profileImage: '',
  // setAboutMe:'',
  // setPersonalDetail: {} as any,
  setLanguages: [] as any,
  trainingsDetails:{} as any
}

const personalUserSlice = createSlice({

    name: 'personalUser',

    initialState: personalUserInitialState,

    reducers: { 
      setAboutMe: setAboutMeReducer,
      setPersonalDetail: setPersonalDetailReducer,
      setLanguages: setLanguagesReducer,
      personalUsertrainingDataUpdate: personalUsertrainingDataUpdateReducer
    },

    extraReducers(builder) {

        //personal Details thunk reducers
        ThunkPendingReducer(builder, personalDetailsThunk)
        personalDetailsThunkFulfilledReducer(builder, personalDetailsThunk)
        personalDetailsThunkRejectedReducer(builder, personalDetailsThunk)

        ThunkPendingReducer(builder, cityThunk)
        cityThunkFulfilledReducer(builder, cityThunk)
        cityThunkRejectedReducer(builder, cityThunk)

        //get user profile
        ThunkPendingReducer(builder, myUserProfileDataThunk)
        myUserProfileDataThunkFulfilledReducer(builder, myUserProfileDataThunk)
        myUserProfileDataThunkRejectedReducer(builder, myUserProfileDataThunk)

        //get user profile
        ThunkPendingReducer(builder, updatePersonalUserProfileStatusThunk)
        updatePersonalUserProfileStatusThunkFulfilledReducer(builder, updatePersonalUserProfileStatusThunk)
        updatePersonalUserProfileStatusThunkRejectedReducer(builder, updatePersonalUserProfileStatusThunk)

        ThunkPendingReducer(builder, profileImageThunk)
        profileImageThunkFulfilledReducer(builder, profileImageThunk)
        profileImageThunkRejectedReducer(builder, profileImageThunk)


        ThunkPendingReducer(builder, personalUsertrainingDataThunk)
        personalUsertrainingDataThunkFulfilledReducer(builder, personalUsertrainingDataThunk)
        personalUsertrainingDataThunkRejectedReducer(builder, personalUsertrainingDataThunk)

    }
})

export const { setAboutMe, setPersonalDetail, setLanguages, personalUsertrainingDataUpdate } = personalUserSlice.actions;

export default personalUserSlice.reducer;