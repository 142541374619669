import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppAlert } from "../../components/app-alert/use-app-alert";
import { IIMAGEINFO } from "../../components/image-upload/image-upload-interface";
import { useAxiosInterceptors } from "../../hooks/use-axios-interceptors";
import { setProfileImage, setUploadProfileImage} from "../../store/auth/auth-slice";
import { setOpenDrawer } from "../../store/layout/layout-slice";
import { profileImageThunk } from "../../store/personal-user/personal-user-thunk-actions";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { checkImage } from "../../utils/validations-schema-utils";

//Get Windows size function
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

// Hook
export const useSidebar = () => { 
    const { openDrawer } = useAppSelector((state) => state.layout);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const { role } = useAppSelector((state) => state.auth);
    const axiosInstance = useAxiosInterceptors();
    const { personalUser } = useAppSelector((state) => state);
    
    const dispatch = useAppDispatch();
    const { auth } = useAppSelector((state) => state);
    const { openAlert } = useAppAlert();
    const navigate = useNavigate();

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener("resize", handleWindowResize);
        if (windowSize.innerWidth <= 600) {
            dispatch(setOpenDrawer(true));
        }
    }, [dispatch, windowSize.innerWidth]);
    const fileReader = (files: any) => {
        let uploadedImages= "";
        let reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = () => {
            const BASE64URL: any = reader.result;
            // console.log(BASE64URL)
            dispatch(setProfileImage(BASE64URL));
            uploadedImages = BASE64URL
            return uploadedImages;
        };
      }

    const handleImage = (event: any) => {
        //   fileReader(event.target.files[0])
          const newfile = [...event.target.files] 
          dispatch(setUploadProfileImage(false));
          let err:any = ""
          let newImages: any = []
      
          newfile.forEach((file: any) => {
            err = checkImage(file)
            return newImages.push(file)
          })  
          const profileImage  = new FormData()
        
          profileImage.append("profileImage", newImages[0] as any)
          dispatch(profileImageThunk({profileImage , axiosInstance, openAlert }))
    }; 
      
    return {
        openDrawer, role, auth, handleImage, dispatch, personalUser,navigate
    }
}