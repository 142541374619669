import { IHELP } from "./help-interface";

import greenIcon from "../../assets/images/help/green-icon.png";
import blueIcon from "../../assets/images/help/blue-icon.png";
import purpleIcon from "../../assets/images/help/purple-icon.png";
import purpleBlueIcon from "../../assets/images/help/purple-blue-icon.png";
import orangeIcon from "../../assets/images/help/orange-icon.png";
import yellowIcon from "../../assets/images/help/yellow-icon.png";

export const help: IHELP[] = [
  {
    id: 1,
    iconSrc: greenIcon,
    help: "General",
    helpDescription: [
      { id: 1, helpDescription: "About Forwarding limits" },
      { id: 2, helpDescription: "Stolen Accounts" },
      { id: 3, helpDescription: "About Creating a Business Name" },
      { id: 4, helpDescription: "How to Get an Official Business Account" },
      { id: 5, helpDescription: "About your Business Profile" },
    ],
  },
  {
    id: 2,
    iconSrc: blueIcon,
    help: "Web and desktop",
    helpDescription: [
      { id: 1, helpDescription: "About  this website" },
      { id: 2, helpDescription: "Stolen Accounts" },
      { id: 3, helpDescription: "How to login or logout" },
      { id: 4, helpDescription: "How to manage your notifications" },
      { id: 5, helpDescription: "How to verify your phone number" },
    ],
  },
  {
    id: 3,
    iconSrc: purpleIcon,
    help: "Account",
    helpDescription: [
      { id: 1, helpDescription: "How to edit your profile" },
      { id: 2, helpDescription: "Stolen Accounts" },
      { id: 3, helpDescription: "How to send media, contact and information" },
      { id: 4, helpDescription: "How to edit your business profile" },
      { id: 5, helpDescription: "About your Business Profile" },
    ],
  },
  {
    id: 4,
    iconSrc: purpleBlueIcon,
    help: "Payment",
    helpDescription: [
      { id: 1, helpDescription: "About Forwarding limits" },
      { id: 2, helpDescription: "Stolen Accounts" },
      { id: 3, helpDescription: "About Creating a Business Name" },
      { id: 4, helpDescription: "How to Get an Official Business Account" },
      { id: 5, helpDescription: "About your Business Profile" },
    ],
  },
  {
    id: 5,
    iconSrc: yellowIcon,
    help: "Business",
    helpDescription: [
      { id: 1, helpDescription: "About  this website" },
      { id: 2, helpDescription: "Stolen Accounts" },
      { id: 3, helpDescription: "How to login or logout" },
      { id: 4, helpDescription: "How to manage your notifications" },
      { id: 5, helpDescription: "How to verify your phone number" },
    ],
  },
  {
    id: 6,
    iconSrc: orangeIcon,
    help: "Customization",
    helpDescription: [
      { id: 1, helpDescription: "How to edit your profile" },
      { id: 2, helpDescription: "Stolen accounts" },
      { id: 3, helpDescription: "How to send media, contact and information" },
      { id: 4, helpDescription: "How to edit your business profile" },
      { id: 5, helpDescription: "About your Business Profile" },
    ],
  },
];
