import { useEffect, useState } from "react";
import { useAppAlert } from "../../../../components/app-alert/use-app-alert";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { USERROLES } from "../../../../constants/roles-constant";
import { useAxiosInterceptors } from "../../../../hooks/use-axios-interceptors";
import { acceptBookingRequestThunk } from "../../../../store/booking/booking-thunk-action";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { ISTATUSCHANGEMENU } from "./status-change-menu-interface";

export const adminCancelAllowedStatus = ["NEW", "ACCEPTED","PENDING", "CONFIRMED"];
export const useStatusChangeMenu = (props: ISTATUSCHANGEMENU) => {
    const { status, rejectionBookingStatus, SetTimeBookingCollapse, bookingId,IS_SUPERADMIN } = props;
    const dispatch = useAppDispatch();
    const { openAlert } = useAppAlert(); 
    const { auth } = useAppSelector((state)=>state);
   
    const ISGUEST = auth.role.includes(USERROLES.GUEST);
    const { buttonStatus } = useAppSelector((state) => state.booking);
    const [anchorElStatusChangeDropDown, setAnchorElStatusChangeDropDown] = useState<null | HTMLElement>(null);
    const [buttonId, setButtonId] = useState<string>('');
    const open = Boolean(anchorElStatusChangeDropDown);
    
    const handleClickDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        !!anchorElStatusChangeDropDown ? setAnchorElStatusChangeDropDown(null) : setAnchorElStatusChangeDropDown(event.currentTarget)
    };
    const handleClose = () => setAnchorElStatusChangeDropDown(null);
    const axiosInstance = useAxiosInterceptors();
    const acceptBooking = (bookingId:string)=>{
        setButtonId(bookingId)
        dispatch(acceptBookingRequestThunk({bookingId,axiosInstance,openAlert}));
        handleClose();
    }
    const checkGuestAndStatus = () => {
        return (ISGUEST && status === 'NEW') ? 'Pending' : status.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase());
      }
    useEffect(() => {
        buttonStatus !== REQUESTSTATUS.PENDING && setButtonId('');
    }, [buttonStatus])
    
    return {
        open, handleClickDropDown, handleClose , acceptBooking,ISGUEST,buttonId,buttonStatus,checkGuestAndStatus,
        status, rejectionBookingStatus, bookingId ,adminCancelAllowedStatus , IS_SUPERADMIN
    }
}