import { createAsyncThunk } from "@reduxjs/toolkit";
import { GETCOMPLIANCEAUDITLOGS, INITIALAUDITAPI, USERAUDITSAPI } from "../../constants/axios-api-constant";
import { getDataAPI, patchDataAPI } from "../../lib/axios";

export const getAuditLogs = createAsyncThunk('auditlogs',
    async ({ axiosInstance, limit, offset, openAlert }: any, { rejectWithValue }) => {

        try {
            const response = await getDataAPI(`${GETCOMPLIANCEAUDITLOGS}?accountType=SYS_ADMIN&limit=${limit}&offset=${offset}`, axiosInstance);
            console.log(response)
            const responseData = {
                data: response?.data?.data
            }
            // console.log(responseData.data);
            return responseData.data
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
            }
            openAlert?.(errorData.error)
            rejectWithValue(errorData.error)
            // console.log(errorData.error);
        }
    }
)

export const getUserAuditsThunk = createAsyncThunk('user-audits',
    async ({ axiosInstance, offset, limit, statusValue }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`${USERAUDITSAPI}?status=${statusValue || 'All'}&limit=${limit || 10}&offset=${offset || 0}`, axiosInstance);
            // console.log("🚀 ~ file: audits-thunk-actions.tsx:29 ~ response", response.data)

            return response.data
        } catch (error: any) {
            const errorData = {
                error: error?.message || 'Server is not responding'
            }
            rejectWithValue(errorData?.error)
            // console.log(errorData.error);
        }
    }
)


export const patchUserAuditsThunk = createAsyncThunk('user-audits',
    async ({ axiosInstance, changeStatus, dispatch, openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(USERAUDITSAPI, axiosInstance, changeStatus)
            // console.log("🚀 ~ file: audits-thunk-actions.tsx:47 ~ response", response)
            openAlert("Status Updated Successfully", "alert-success-message");
            dispatch(getUserAuditsThunk({ axiosInstance }))
            return response?.data
        } catch (error: any) {
            const errorData = {
                error: error?.message || 'Server is not responding'
            }
            rejectWithValue(errorData?.error)
            // console.log(errorData.error);
        }
    }
)

