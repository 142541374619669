import { createSlice } from "@reduxjs/toolkit";
import {
  getWishlistsThunkPendingReducer,
  getWishlistsThunkFulfilledReducer,
  getWishlistsThunkRejectedReducer,
  getWishlistsByIdThunkFulfilledReducer,
  getWishlistsByIdThunkPendingReducer,
  getWishlistsByIdThunkRejectedReducer,
} from "./wishlists-reducers";
import { getWishlistByIdThunk, getWishlistsThunk } from "./wishlists-thunk-actions";

const wishlistInitialState:any={
    singleWishlist:{},
    isCategoryFieldDisabled:false
}
const wishlistSlice = createSlice({
  name: "wishlists",
  initialState: wishlistInitialState,
  reducers: {
    setSingleWishlist: (state, action:any) => {
      state.singleWishlist = action.payload;
  },
  setIsCategoryFieldDisabled:(state,action:any)=>{
    state.isCategoryFieldDisabled = action.payload;
  }
  },
  extraReducers(builder) {
    getWishlistsThunkPendingReducer(builder, getWishlistsThunk)
    getWishlistsThunkFulfilledReducer(builder, getWishlistsThunk)
    getWishlistsThunkRejectedReducer(builder, getWishlistsThunk)

    getWishlistsByIdThunkPendingReducer(builder, getWishlistByIdThunk)
    getWishlistsByIdThunkFulfilledReducer(builder, getWishlistByIdThunk)
    getWishlistsByIdThunkRejectedReducer(builder, getWishlistByIdThunk)
  },
});



export const { setSingleWishlist,setIsCategoryFieldDisabled } = wishlistSlice.actions
export default wishlistSlice.reducer;
