import { ITABLEHEADER } from "./roles-and-rights-interface";


export const rolesAndRightsOptions = [
    {
        id: '1',
        title: 'Access Management',
        dropDown: [
            'Access Managemenest 1', 'Access Management 2'
        ]
    },
    {
        id: '2',
        title: 'Settings',
        dropDown: [
            'Settings 1', 'Settings 2'
        ]
    },
    {
        id: '3',
        title: 'Recruitment',
        dropDown: [
            'Recruitment 1', 'Recruitment 2'
        ]
    },
    {
        id: '4',
        title: 'Staff Member',
        dropDown: [
            'View Staff Member Details', 'Add/Edit Staff Member Details'
        ]
    },
    {
        id: '5',
        title: 'Staff Allocation',
        dropDown: [
            'Allocation 1', 'Allocation 2'
        ]
    },
    {
        id: '6',
        title: 'Staff Booking',
        dropDown: [
            'Booking 1', 'Booking 2'
        ]
    },
    {
        id: '7',
        title: 'Booking Calendar',
        dropDown: [
            'Calendar 1', 'Calendar 2'
        ]
    },
    {
        id: '8',
        title: 'Natural Vendors Management',
        dropDown: [
            'Test Vendors 1', 'Test Vendors 2'
        ]
    },
    {
        id: '9',
        title: 'Staff Management',
        dropDown: [
            'Test 1', 'Test 2'
        ]
    },
    {
        id: '10',
        title: 'Reports',
        dropDown: [
            'Test 1', 'Test 2'
        ]
    },
]

export const rolesAndRightsTableHeading: readonly ITABLEHEADER[] = [
    { name: "No", sort: false, id: "no" },
    { name: "Role Title", sort: true, id: "roleTitle" },
    { name: "Name", sort: true, id: "name" },
    { name: "Role Assigned By", sort: true, id: "roleAssignedBy" },
    { name: "Assigned Date", sort: true, id: "accountType" },
    { name: "Actions", sort: false, id: "actions" },
];
export const rolesAndRightsTableData:any = [
    {
      id: "1",
      role: 'Host',
      name: "A James Bond",
      assignedBy:'Faisal',
      date: "01/05/2021",
    },
    {
      id: "2",
      role: 'Guest',
      name: "B James Bond",
      assignedBy:'Faisal',
      date: "02/05/2021",
    },
    {
      id: "3",
      role: 'System Admin',
      name: "C James Bond",
      assignedBy:'Faisal',
      date: "03/05/2021",
    },
]