import { Grid, Rating } from '@mui/material'
import { Favorite } from '@mui/icons-material';
import { MUIPRIMARYTITLE, MUISECONDARYTITLE, MUITITLE10 } from '../../constants/mui-styles-constant';
import "./flexible-item-card.scss"

export const FlexibleItemCard = (props: any) => {

  const { foodImg, foodName, foodLocation, hostName, foodRating, gridClassName, mainFunction,
    timingStatus, timing, recipeName, recipeDescription, md, minWidth, maxWidth, iconFunction,
    closeTime,openTime
  } = props

  return (

    <Grid container
      className={`bg-white-color margin-top-1 margin-auto-x width-100 cursor-pointer border-radius-15 ${gridClassName}`}
      p="10px"
      boxShadow={1}
      maxWidth={maxWidth}
      minWidth={minWidth}
      onClick={() => mainFunction?.()}
    >
      <Grid item md={md || 4.5} xs={12}>
        <div className={`slidebuttmehmood width-100  overflow-hidden-x overflow-hidden-y ${md === 12 ? 'height-160px' : 'height-100'}`}>
          <img
            className={`object-fit-cover width-100 border-radius-20 ${md === 12 ? 'height-160px' : 'height-100'}`}
            src={foodImg}
            alt='card image'
          />
        </div>
      </Grid>
      <Grid xs item></Grid>
      <Grid item md={md || 7.3} xs={12} sx={{ pl: 1, pr: 1 }} className="card-details border-radius-10">
        <div className="flex justify-space-between align-center" >
          <span className="card-title font-weight-700">
            {foodName}
          </span>
          {/* <Checkbox
                        icon={<Favorite />}
                        checkedIcon={<Favorite />}
                        sx={{ color: 'rgba(131, 164, 249, 0.25) !important' }}
                      /> */}
          <Favorite
            onClick={(e: any) => { e.stopPropagation(); iconFunction?.(e) }}
            sx={{ color: 'rgba(131, 164, 249, 0.25) !important' }}
            className="cursor-pointer"
          />
        </div>
        <div className="card-subtitle title-10 font-weight-300">
          {foodLocation}
        </div>
        <div className="card-description title-10 font-weight-300">
          Hosted By{" "}
          <span className="primary-color font-weight-500">
            {hostName}
          </span>
        </div>
        <div className="card-timing-div justify-space-between flex">
          <div className="rating-div position-relative">
            <span className="card-rating title-10">
              {foodRating}/5.00
            </span>
            <div style={{ position: 'absolute' }}>
              <Rating
                sx={{ position: 'relative', bottom: '21px', left: '35px', fontSize: MUIPRIMARYTITLE , marginLeft:'5px'}}
                readOnly
                max={1}
                value={+foodRating}
                precision={0.5}
                size="small"
              />
            </div>
          </div>
          <div className="card-timing">
            <span className="time-status title-10 font-weight-300 primary-color">
               {`${!!openTime ? 'Opens: ' : ''}`} 
            </span>
            <span className="time-status title-10 font-weight-300">
               {`${!!openTime ? openTime?.slice(0,5): ''}  ${!!closeTime ? `to ${closeTime?.slice(0,5)}`: ''}`}
            </span>
          </div>
        </div>
        <hr style={{ margin: 0 }} />
        <span className="recipe-name secondary-title font-weight-700">
          {recipeName}
        </span>
        <p className="recipe-description tertiary-title break-word">
          {recipeDescription?.slice(0,90)}
        </p>
      </Grid>
    </Grid>


  )
}

