
import { NavLink } from "react-router-dom";

// MUI
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import notificationsIcon from "../../../assets/images/layout/header/notification-icon.png";
import { Button, ClickAwayListener, Divider, Tooltip, } from "@mui/material";
import ProfileImg from "../../../assets/images/layout/header/dp.png";

// Data
import { notificationData } from "./notification-card-data";

// Interface
import { INOTIFICATIONDATA } from "./notification-card-interface";

// SCSS
import "./notification-card.scss";

// Images
import timeIcon from '../../../assets/images/layout/header/notification-card/time-icon.png'

// Custom Hook
import { useNotificationCard } from "./use-notification-card";

// MUI Styles
import { tooltipNotificationCardComponenetsProps } from "./notification-card-mui-style";
import { CircleNotifications } from "@mui/icons-material";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

// Component Function Starts Here
const NotificationCard: React.FC = () => {
  const { scroll, OPEN, openCard, handleClose, setScroll,  pageLength , setPageLength, page, setPage, layout } = useNotificationCard()
  dayjs.extend(relativeTime)
  const NotoficationData = (<div className="notification-card-parent ">
    <div
      className={
        scroll ? "notification-scroll" : "notification-scroll-hidden"
      }
    >
      {!!layout?.notifications?.length ?
        layout?.notifications?.map((item: any) => (
          <div key={item.id} className=''>
            <NavLink to={item.path} >
              <div className="notification-row">
                <div className="profile-pic-and-comment">
                  <div className="profile-pic"><img src={item?.icon || ProfileImg} alt=""/></div>
                  <div className="comment-and-title">
                    <span className="font-weight-400 secondary-title title-name">{item?.data?.name}</span>
                    <span className="font-weight-400 title-10 comment-text-color">{item?.message?.slice(0,50)}</span>
                    <span className="time-icon">
                      <img src={timeIcon} alt='time'/>
                      <span className="title-10 comment-text-color">{dayjs(item?.createdAt).fromNow()}</span>
                    </span>
                  </div>
                </div>
                <div className="dot-circle">
                  <div className="dot"></div>
                </div>
              </div>
              {/* <li
                onClick={handleClose}
                className="notifications-card list "
              >
                <div>
                  <div className="profile-pic">{item.icon}</div>
                </div>
                <div className="title">
                  <span>{item.title}</span>
                  <br />
                  <span>{item.comment}</span>
                  <br />
                  <span className="notifications-card">
                    <QueryBuilderIcon fontSize="small" /> {item.time}
                  </span>
                </div>
                <div>
                  <CircleNotifications
                    sx={{ paddingRight: "6px", marginLeft: "100px", color: 'black' }}
                    fontSize="small"
                  />

                </div>
              </li> */}
            </NavLink>
            <Divider />
          </div>
        )) : 
        <div className="black-color flex align-center sub-heading justify-center height-inherit"> No notifications available</div>
      }
    </div>
    <div>
      <Button onClick={() => {setScroll(!scroll);  setPageLength(!scroll ? 100 : 10)}} className="btn">
        {scroll ? "Hide all notifications" : "Read all notifications"}
      </Button>
    </div>
  </div >)

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Tooltip
            arrow
            placement="bottom-end"
            componentsProps={tooltipNotificationCardComponenetsProps}
            PopperProps={{
              disablePortal: true,
            }}
            // sx={{ transform: translate(227, 0), }}
            // onClose={handleClose}
            open={OPEN}
            title={
              NotoficationData
            }
          >
            <Button sx={{ minWidth: 'auto' }} onClick={openCard}><img src={notificationsIcon} alt='notification-icon' /></Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default NotificationCard;
