import { searchFoodPreferencesByCuisinesThunk, searchFoodPreferencesByHostThunk, searchFoodPreferencesByLocationThunk } from "../../../store/food-preference/food-preference-thunk-actions"
import { useAppSelector } from "../../../store/store"

export const prepareFoodSearchData = (searchType: any, foodPreference: any, openAlert: any, dispatch: any, axiosInstance: any) => {
  // const { foodPreference } = useAppSelector(state => state)
  // console.log(foodPreference)
  const searchQueryHost:any = {
    host: foodPreference?.hostForSearch,
    date: {
      date: foodPreference?.dateForSearch,
      time: foodPreference?.timeForSearch

    },
    filter: foodPreference.filters,
    guests: {
      noofGuests: foodPreference?.numberOfGuests
    }
  }
  const searchQueryCuisines:any = {
    cuisine: foodPreference?.cuisineForSearch,
    date: {
      date: foodPreference?.dateForSearch,
      time: foodPreference?.timeForSearch

    },
    filter: foodPreference.filters,
    guests: {
      noofGuests: foodPreference?.numberOfGuests
    }
  }
  const searchQueryLocation:any = {
    location: {
      // address: foodPreference?.locationForSearch,
      ...(!!foodPreference?.locationForSearch?.length ?
        { address: foodPreference?.locationForSearch } :
        { geolocation: foodPreference?.geolocation, address: "" })

    },
    filter: foodPreference.filters,
    date: {
      date: foodPreference?.dateForSearch,
      time: foodPreference?.timeForSearch
    },
    guests: {
      noofGuests: foodPreference?.numberOfGuests
    }
  }
  // console.log(searchType === 'Host')
  if (searchType === 'Host') {
    // console.log(!!searchQueryHost?.date)
    // console.log(searchQueryHost?.date)
    if (!!!searchQueryHost?.host?.name) return openAlert('Select host');
    if (!!!searchQueryHost?.date?.date) return openAlert('Select date');
    if (!!!searchQueryHost?.date?.time) return openAlert('Select time');
    if (!!!searchQueryHost?.guests?.noofGuests) return openAlert('Select no. of guests');
    dispatch(searchFoodPreferencesByHostThunk({ hostSearchParameters: searchQueryHost, axiosInstance , openAlert }))
    // openAlert('Go ahead')
    return;
  }
  if (searchType === 'Cuisine') {
    // console.log(!!searchQueryCuisines?.date)
    // console.log(searchQueryCuisines?.date)
    if (!!!searchQueryCuisines?.cuisine?.length) return openAlert('Select cuisine');
    if (!!!searchQueryCuisines?.date?.date) return openAlert('Select date');
    if (!!!searchQueryCuisines?.date?.time) return openAlert('Select time');
    if (!!!searchQueryCuisines?.guests?.noofGuests) return openAlert('Select no. of guests');
    dispatch(searchFoodPreferencesByCuisinesThunk({ cuisineSearchParameters: searchQueryCuisines, axiosInstance , openAlert }))
    // openAlert('Go ahead')
    return;
  }
  if (searchType === 'Location') {
    // console.log(!!searchQueryLocation?.date)
    // console.log(searchQueryLocation?.date)
    if(searchQueryLocation?.location?.address === "" && !!!searchQueryLocation?.location?.geolocation?.longitude) return openAlert('Select location');
    if (searchQueryLocation?.location?.address === "" && searchQueryLocation?.location?.geolocation?.longitude === "") return openAlert('Select your location');
    if (!!!searchQueryLocation?.date?.date) return openAlert('Select date');
    if (!!!searchQueryLocation?.date?.time) return openAlert('Select time');
    if (!!!searchQueryLocation?.guests?.noofGuests) return openAlert('Select no. of guests');
    dispatch(searchFoodPreferencesByLocationThunk({ locationSearchParameters: searchQueryLocation, axiosInstance,openAlert }))

    // openAlert('Go ahead')
    return;
  }
}


// for optional parametersss

export const prepareFoodSearchOptionData = (searchType: any, foodPreference: any, openAlert: any, dispatch: any, axiosInstance: any) => {
  // const { foodPreference } = useAppSelector(state => state)
  // console.log(foodPreference)
  const searchQueryHost = {
    host: foodPreference?.hostForSearch,
    date: {
      date: foodPreference?.dateForSearch,
      time: foodPreference?.timeForSearch

    },
    filter: foodPreference.filters,
    guests: {
      noofGuests: foodPreference?.numberOfGuests
    }
  }
  const searchQueryCuisines = {
    cuisine: foodPreference?.cuisineForSearch,
    date: {
      date: foodPreference?.dateForSearch,
      time: foodPreference?.timeForSearch

    },
    filter: foodPreference.filters,
    guests: {
      noofGuests: foodPreference?.numberOfGuests
    }
  }
  const searchQueryLocation = {
    location: {
      address: foodPreference?.locationForSearch,
      filter: foodPreference.filters,
    },
    date: {
      date: foodPreference?.dateForSearch,
      time: foodPreference?.timeForSearch
    },
    guests: {
      noofGuests: foodPreference?.numberOfGuests
    }
  }
  // console.log(searchType === 'Host')
  if (searchType === 'Host') {
    // console.log(!!searchQueryHost?.date)
    // console.log(searchQueryHost?.date)
    if (!!!searchQueryHost?.host?.name) return openAlert('Select host');
    // if(!!!searchQueryHost?.date?.date) return openAlert('Select date');
    // if(!!!searchQueryHost?.date?.time) return openAlert('Select time');
    // if(!!!searchQueryHost?.guests?.noofGuests) return openAlert('Select no. of guests');
    dispatch(searchFoodPreferencesByHostThunk({ hostSearchParameters: searchQueryHost, axiosInstance, openAlert}))
    // openAlert('Go ahead')
    return;
  }
  if (searchType === 'Cuisine') {
    // console.log(!!searchQueryCuisines?.date)
    // console.log(searchQueryCuisines?.date)
    if (!!!searchQueryCuisines?.cuisine?.length) return openAlert('Select cuisine');
    // if(!!!searchQueryCuisines?.date?.date) return openAlert('Select date');
    // if(!!!searchQueryCuisines?.date?.time) return openAlert('Select time');
    // if(!!!searchQueryCuisines?.guests?.noofGuests) return openAlert('Select no. of guests');
    dispatch(searchFoodPreferencesByCuisinesThunk({ cuisineSearchParameters: searchQueryCuisines, axiosInstance, openAlert }))
    // openAlert('Go ahead')
    return;
  }
  if (searchType === 'Location') {
    // console.log(!!searchQueryLocation?.date)
    // console.log(searchQueryLocation?.date)
    if (!!!searchQueryLocation?.location?.address) return openAlert('Select location');
    // if(!!!searchQueryLocation?.date?.date) return openAlert('Select date');
    // if(!!!searchQueryLocation?.date?.time) return openAlert('Select time');
    // if(!!!searchQueryLocation?.guests?.noofGuests) return openAlert('Select no. of guests');
    dispatch(searchFoodPreferencesByLocationThunk({ locationSearchParameters: searchQueryLocation, axiosInstance, openAlert }))

    // openAlert('Go ahead')
    return;
  }
}