const otherBreadcrumbs = (searchType:any) => {

return [
    {
        id: 2,
        linkName: "/",
        className: "global-breadcrumb-previous-link text-decoration",
        linkPath: "#",
    },
    {
        id: 1,
        linkName: searchType,
        className: "global-breadcrumb-active",
    },
]

}
export const breadcrumbData = (searchType:any) => {
    return {
        firstLink: "/food-preference",
        firstLinkName: "Food Preference |",
        otherBreadcrumbs : otherBreadcrumbs(searchType),
    };
}

export const mockFoodsearch = [
    {
      "_id": "6391f9589857ff248a969168",
      "userId": "e2d44264-75b0-400e-95d0-8f166162e46d",
      "userName": "Tamoor Arif",
      "userRating": 0,
      "name": "Tamoor kitchen ",
      "coverPhotos": [
        {
          "id": "63754ea7-0cda-476c-b0b8-48d2dfc16378",
          "url": "users/e2d44264-75b0-400e-95d0-8f166162e46d/kitchen/cover-photos/63754ea7-0cda-476c-b0b8-48d2dfc16378.jfif"
        },
        {
          "id": "d7b861f7-a810-4955-8131-b8f6fc70eecb",
          "url": "users/e2d44264-75b0-400e-95d0-8f166162e46d/kitchen/cover-photos/d7b861f7-a810-4955-8131-b8f6fc70eecb.jfif"
        },
        {
          "id": "29426c59-0214-4652-bd57-b2d5e670d5b4",
          "url": "users/e2d44264-75b0-400e-95d0-8f166162e46d/kitchen/cover-photos/29426c59-0214-4652-bd57-b2d5e670d5b4.jfif"
        },
        {
          "id": "c5a66268-5d29-46c1-89c5-51adb2d1b2af",
          "url": "users/e2d44264-75b0-400e-95d0-8f166162e46d/kitchen/cover-photos/c5a66268-5d29-46c1-89c5-51adb2d1b2af.jfif"
        }
      ],
      "selectedCoverPhoto": "users/e2d44264-75b0-400e-95d0-8f166162e46d/kitchen/cover-photos/fe72b6be-cab0-45d2-8462-94622f1c65d0.jfif",
      "servingDays": "1111111",
      "houseRules": {
        "dressCodeRestriction": "",
        "dressCodeRestrictionApplied": false,
        "foodRestrictionApplied": true,
        "petsRestrictionApplied": false,
        "smokingRestrictionApplied": false,
        "restrictedFoods": []
      },
      "badgesId": [
        "634d7de63a0a1b59d2b82d6b"
      ],
      "language": [
        "English",
        "Malayalam"
      ],
      "myFacilities": {
        "numberOfGuest": 1,
        "icons": [
          {
            "_id": "635bc9e2ebbb9193e18c3bbc",
            "icon": "icons/facilties/facility-ramp.png",
            "imageName": "Ramp",
            "category": "Disability Arrangements",
            "userId": null
          }
        ]
      },
      "location": {
        "address": {
          "locationName": "Cemetery Lodge, 123 East Main Street, Uphall, Broxburn, West Lothian",
          "locationId": "MmNkYTAxOWU0OTFmMWExIDIzNDYxMjkwMzggYzY4NzczMzBkMTIxYjcx"
        },
        "longitude": -3.498124,
        "latitude": 55.931087
      },
      "servingTimeFrom": "00:10:00",
      "servingTimeTo": "06:26:00"
    }
  ]