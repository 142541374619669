import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const showReviewBtn = () => {
    
}


export const houseRulesThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {

            state.houseRulesStatus = 'failed';

        });
}

export const houseRulesThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.houseRulesStatus = "succeeded";
            state.houseRules = payload

        });
}
export const houseRulesThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.houseRulesStatus = "loading";
        });
}

export const getFacilityListThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.FAILED;
        });
}

export const getFacilityListThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            
            // state.status = REQUESTSTATUS.SUCCEEDED;
            state.facilitiesData = payload;
            // console.log('Successfully got facilities',payload);
            state.status = 'done'; // changing status names to avoid duplicate loaders

        });
}
export const getFacilityListThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.status = 'loading';
        });

}

export const getLogisticBookingsFulfilledThunk = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.status = REQUESTSTATUS.SUCCEEDED
            state.bookings = payload?.bookings;
        }
    )
}

export const getLogisticRecipesFulfilledThunk = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            state.logisticRecipes = payload.recipes
        }
    )

}

export const getIngredientsFulfilledThunk = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload)

            state.ingredients = payload?.ingredients
           
        }
    )

}


export const getlogisticsMenuPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any) => {

            state.getLogisticStatus = REQUESTSTATUS.PENDING
        }
    )
}

export const getlogisticsMenuRejectReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any) => {

            state.getLogisticStatus = REQUESTSTATUS.FAILED
        }
    )
}

export const getlogisticsMenuFulfilledThunk = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);
            // console.log(payload?.menuData);

            state.menuBooking = payload?.menuData;
            // state.recipeReview = payload?.menuData;
            state.shoppingReview = payload?.menuData.shoppingList;
            state.trackerAccessMsg = payload?.trackerAccessMsg;
            state.getLogisticStatus = REQUESTSTATUS.SUCCEEDED
            state.bookingID = payload?.menuData[0]?.bookingId
        }
    )
}
export const getkitchenDetailsFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.coverImg = payload;
            state.houseRules = payload?.houseRules;
            state.facilities = payload?.myFacilities?.icons;
        }
    )
}


// export const patchLogisticsTimerPendingReducer = (builder:any, action:any) => {
// builder.addCase(
//     action.pending,(state:any , {payload}: any)=>{
//         state.timerStatus= REQUESTSTATUS.PENDING
//     }
// )
// }
// export const patchLogisticsTimerFulfilledReducer = (builder:any, action:any) => {
//     builder.addCase(
//         action.fulfilled,(state:any , {payload}: any)=>{
//             console.log(payload);
//             state.houseRules = payload?.houseRules;
//             state.facilities = payload?.myFacilities?.icons;
//         }
//     )
// }

export const patchLogisticsTimerPendingReducer = (builder: any, action: any) => {
    builder.addCase(
        action.pending, (state: any, { payload }: any) => {
            state.timerStatus = REQUESTSTATUS.PENDING
        }
    )
}


export const patchLogisticsTimerFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            // console.log(payload);

            state.timerStatus = REQUESTSTATUS.SUCCEEDED
            state.timerMessage = payload?.message
        }
    )
}
export const patchLogisticsTimerRejectReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.timerStatus = REQUESTSTATUS.FAILED
        }
    )
}
