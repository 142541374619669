import profile1 from "../assets/images/my-invoice/profile-1.png";
import profile2 from "../assets/images/my-invoice/profile-2.png";
import profile3 from "../assets/images/my-invoice/profile-3.png";
import profile4 from "../assets/images/my-invoice/profile-4.png";
import profile5 from "../assets/images/my-invoice/profile-5.png";

import {
  IINVOICEDATA,
  ITIMEFRAMEOPTIONS,
  IINVOICETABLEHEADINGS,
  IINVOICEOPTIONS
} from "../features/invoice/invoice-management";
import { hideModal, showModal } from "../store/invoice/invoice-slice";
import { useAppDispatch, useAppSelector } from "../store/store";

enum namesOption {
  statusPaid = "Paid",
  statusPending = "Pending",
  statusCancelled = "Cancelled",
  statusOverdue = "Overdue",
}


export const itemsTable:IINVOICEOPTIONS[] = [
  { id: 1, option: "Item" },
  { id: 2, option: "Item Description" },
  { id: 3, option: "Quantity" },
  { id: 4, option: "Rate" },
  { id: 5, option: "Total" },
];

export const statusFilterOptions = [
  { id: 1, option: "Paid" },
  { id: 2, option: "Pending" },
  { id: 3, option: "Cancelled" },
  { id: 4, option: "Overdue" },
];
export const pagingOptions = [
  { id: 1, page: 5 },
  { id: 2, page: 10 },
  { id: 3, page: 25 },
];
export const timeFrameOptions: ITIMEFRAMEOPTIONS[] = [
  { id: 1, text: "Month To Date", case: "monthToDate" },
  { id: 2, text: "Year To Date", case: "yearToDate" },
  { id: 3, text: "This Week", case: "thisWeek" },
];
export const invoiceTableHeadings: IINVOICETABLEHEADINGS[] = [
  { id: 1, heading: "Invoice Number", headingId: "invoiceNumber" },
  { id: 2, heading: "Customer Name", headingId: "customerName" },
  { id: 3, heading: "Created Date", headingId: "createdDate" },
  { id: 4, heading: "Amount", headingId: "amount" },
  { id: 5, heading: "Due Date", headingId: "dueDate" },
  { id: 6, heading: "Status", headingId: "status" },
];

export const allInvoices: IINVOICEDATA[] = [
  {
    id: 1,
    bookingID: "234235",
    customerID: "432543125",
    invoiceNumber: "INV-65ZTE0",
    customerImage: profile1,
    customerName: "Maximillian",
    createdDate: "01-08-2022",
    amount: 45,
    dueDate: 3,
    status: namesOption.statusPaid,
    paidOn: "19 April 2021, 7:45pm ",
    address: "7 Main Road London SW40 7WS",
    email: "nasor@hotmail.com",
    phone: "01224 211671",
    items: [
      {
        id: 1,
        item: "Brain Masala",
        itemDesc: "Made with Goat meat Made with Goat meat Made with Goat meat",
        quantity: 3,
        rate: 16,
        per: "per serving",
      },
      {
        id: 2,
        item: "Toad in the Hole",
        itemDesc: "Pan-fried sausages",
        quantity: 2,
        rate: 10,
        per: "per 6 pics",
      },
      {
        id: 3,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
      {
        id: 4,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
      {
        id: 5,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
    ],
  },
  {
    id: 2,
    bookingID: "12412",
    customerID: "123123434",
    invoiceNumber: "INV-65ZTE1",
    customerImage: profile1,
    customerName: "Adam Smith",
    createdDate: "21-03-2019",
    amount: 30,
    dueDate: 5,
    status: namesOption.statusPaid,
    paidOn: "23 April 2021, 10:45pm",
    address: "53 Albert Road London E53 1FO",
    email: "ozzyhorny@sbcglobal.net",
    phone: "0161 233 0400",
    items: [
      {
        id: 1,
        item: " Fish and Chips",
        itemDesc: "UK’s most iconic dish",
        quantity: 5,
        rate: 18,
        per: "per 7 pics",
      },
      {
        id: 2,
        item: " Mashed Potatoes",
        itemDesc: "a side dish",
        quantity: 4,
        rate: 15,
        per: "per 3 pics",
      },
      {
        id: 3,
        item: "British Mushy ",
        itemDesc: "fish and chips",
        quantity: 3,
        rate: 18,
        per: "per serving",
      },
    ],
  },
  {
    id: 3,
    bookingID: "12354",
    customerID: "973465658",
    invoiceNumber: "INV-65ZTE2",
    customerImage: profile2,
    customerName: "John Doe",
    createdDate: "09-04-2021",
    amount: 33,
    dueDate: 3,
    status: namesOption.statusPending,
    paidOn: "20 April 2021, 7:45pm ",
    address: "PEEL HOUSE, 30 THE DOWNS",
    email: "info@mbeaberdeen.co.uk",
    phone: "01292 611444",
    items: [
      {
        id: 1,
        item: "Bangers and Mash",
        itemDesc: "mashed potatoes",
        quantity: 5,
        rate: 18,
        per: "per 4 pics",
      },
      {
        id: 2,
        item: "British Scones ",
        itemDesc: "Just like biscuits",
        quantity: 4,
        rate: 8,
        per: "per 6 pics",
      },
      {
        id: 3,
        item: "Cornish Pasties ",
        itemDesc: "pies with a steak",
        quantity: 8,
        rate: 15,
        per: "per serving",
      },
    ],
  },
  {
    id: 4,
    bookingID: "145623",
    customerID: "345234876",
    invoiceNumber: "INV-65ZTE3",
    customerImage: profile3,
    customerName: "Maximillian",
    createdDate: "01-03-2022",
    amount: 12,
    dueDate: 8,
    status: namesOption.statusCancelled,
    paidOn: "13 April 2021, 8:45pm ",
    address: "ANDOVER HOUSE, GEORGE YARD",
    email: "mbebanbury@btopenworld.com",
    phone: "01234 305522",
    items: [
      {
        id: 1,
        item: "Scottish Tablet",
        itemDesc: "Made from milk, sugar",
        quantity: 6,
        rate: 14,
        per: "per 2 pics",
      },
      {id: 2,
        item: "English Pickled Onions",
        itemDesc: "mildly sweet flavor",
        quantity: 8,
        rate: 17,
        per: "per pics",
      },
      {id: 3,
        item: "Cottage Pie",
        itemDesc: "made with lamb",
        quantity: 2,
        rate: 14,
        per: "per 3 pics",
      },
    ],
  },
  {
    id: 5,
    bookingID: "67934",
    customerID: "435576743",
    invoiceNumber: "INV-65ZTE4",
    customerImage: profile4,
    customerName: "Mike Hussy",
    createdDate: "01-09-2022",
    amount: 8,
    dueDate: 12,
    status: namesOption.statusOverdue,
    paidOn: "23 April 2021, 10:45pm",
    address: "4 FULLARTON STREET",
    email: "info@mbebelfast.co.uk",
    phone: "01202 299154",
    items: [
      {
        id: 1,
        item: "Bangers and Mash",
        itemDesc: "mashed potatoes",
        quantity: 5,
        rate: 18,
        per: "per 4 pics",
      },
      {id: 2,
        item: "British Scones ",
        itemDesc: "Just like biscuits",
        quantity: 4,
        rate: 8,
        per: "per 6 pics",
      },
      {id: 3,
        item: "Cornish Pasties ",
        itemDesc: "pies with a steak",
        quantity: 8,
        rate: 15,
        per: "per serving",
      },
    ],
  },
  {
    id: 6,
    bookingID: "3468",
    customerID: "425376242",
    invoiceNumber: "INV-65ZTE5",
    customerImage: profile5,
    customerName: "Maximillian",
    createdDate: "30-08-2022",
    amount: 30,
    dueDate: 6,
    status: namesOption.statusPaid,
    paidOn: "27 April 2021, 6:10pm ",
    address: "3 EDGAR BUILDING, GEORGE ST",
    email: "info@mbebirmingham.com",
    phone: "01273 706030",
    items: [
      {
        id: 1,
        item: " Fish and Chips",
        itemDesc: "UK’s most iconic dish",
        quantity: 5,
        rate: 18,
        per: "per 7 pics",
      },
      {id: 2,
        item: " Mashed Potatoes",
        itemDesc: "a side dish",
        quantity: 4,
        rate: 15,
        per: "per 3 pics",
      },
      {id: 3,
        item: "British Mushy ",
        itemDesc: "fish and chips",
        quantity: 3,
        rate: 18,
        per: "per serving",
      },
    ],
  },
  {
    id: 7,
    bookingID: "253682",
    customerID: "566583686",
    invoiceNumber: "INV-65ZTE6",
    customerImage: profile5,
    customerName: "Maximillian",
    createdDate: "15-01-2017",
    amount: 30,
    dueDate: 6,
    status: namesOption.statusPending,
    paidOn: "27 April 2021, 6:10pm ",
    address: "THOMAS HOUSE, 47 BOTANIC AVENUE",
    email: "info@mbebh1.co.uk",
    phone: "0117 987 4217",
    items: [
      {
        id: 1,
        item: "Brain Masala",
        itemDesc: "Made with Goat meat",
        quantity: 3,
        rate: 16,
        per: "per serving",
      },
      {id: 2,
        item: "Toad in the Hole",
        itemDesc: "Pan-fried sausages",
        quantity: 2,
        rate: 10,
        per: "per 6 pics",
      },
      {id: 3,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
      {id: 4,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
      {id: 5,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
    ],
  },
  {
    id: 8,
    bookingID: "12415",
    customerID: "457362523",
    invoiceNumber: "INV-65ZTE7",
    customerImage: profile1,
    customerName: "Adam Smith",
    createdDate: "15-01-2017",
    amount: 45,
    dueDate: 2,
    status: namesOption.statusPaid,
    paidOn: "19 April 2021, 7:45pm ",
    address: "FORTENAY HOUSE, 14A ONGAR ROAD",
    email: "info@elmrow.mbe.uk.com",
    phone: "01276 686658",
    items: [
      {
        id: 1,
        item: " Fish and Chips",
        itemDesc: "UK’s most iconic dish",
        quantity: 5,
        rate: 18,
        per: "per 7 pics",
      },
      {id: 2,
        item: " Mashed Potatoes",
        itemDesc: "a side dish",
        quantity: 4,
        rate: 15,
        per: "per 3 pics",
      },
      {id: 3,
        item: "British Mushy ",
        itemDesc: "fish and chips",
        quantity: 3,
        rate: 18,
        per: "per serving",
      },
    ],
  },
  {
    id: 9,
    bookingID: "366643",
    customerID: "345572572",
    invoiceNumber: "INV-65ZTE8",
    customerImage: profile2,
    customerName: "John Doe",
    createdDate: "15-01-2017",
    amount: 33,
    dueDate: 3,
    status: namesOption.statusPending,
    paidOn: "20 April 2021, 7:45pm ",
    address: " 60 WESTBURY HILL, WESTBURY ON TRYM",
    email: "info@mbebrentwood.co.uk",
    phone: "01223 505445",
    items: [
      {
        id: 1,
        item: "Scottish Tablet",
        itemDesc: "Made from milk, sugar",
        quantity: 6,
        rate: 14,
        per: "per 2 pics",
      },
      {id: 2,
        item: "English Pickled Onions",
        itemDesc: "mildly sweet flavor",
        quantity: 8,
        rate: 17,
        per: "per pics",
      },
      {id: 3,
        item: "Cottage Pie",
        itemDesc: "made with lamb",
        quantity: 2,
        rate: 14,
        per: "per 3 pics",
      },
    ],
  },
  {
    id: 10,
    bookingID: "2342",
    customerID: "134642757",
    invoiceNumber: "INV-65ZTE9",
    customerImage: profile3,
    customerName: "Maximillian",
    createdDate: "18-01-2022",
    amount: 12,
    dueDate: 8,
    status: namesOption.statusCancelled,
    paidOn: "13 April 2021, 8:45pm ",
    address: " 42 TRIANGLE WEST, BRISTOL",
    email: "westburyot@027.mbe.uk.com",
    phone: "01245 495539",
    items: [
      {
        id: 1,
        item: "Bangers and Mash",
        itemDesc: "mashed potatoes",
        quantity: 5,
        rate: 18,
        per: "per 4 pics",
      },
      {id: 2,
        item: "British Scones ",
        itemDesc: "Just like biscuits",
        quantity: 4,
        rate: 8,
        per: "per 6 pics",
      },
      {id: 3,
        item: "Cornish Pasties ",
        itemDesc: "pies with a steak",
        quantity: 8,
        rate: 15,
        per: "per serving",
      },
    ],
  },
  {
    id: 11,
    bookingID: "23464",
    customerID: "346575472",
    invoiceNumber: "INV-65ZTE10",
    customerImage: profile4,
    customerName: "Mike Hussy",
    createdDate: "06-08-2019",
    amount: 8,
    dueDate: 12,
    status: namesOption.statusOverdue,
    paidOn: "23 April 2021, 10:45pm",
    address: "THE BUSINESS CENTRE, 61 WELLFIELD ROAD",
    email: "info@mbeegham.co.uk",
    phone: "01242 530230",
    items: [
      {
        id: 1,
        item: "Scottish Tablet",
        itemDesc: "Made from milk, sugar",
        quantity: 6,
        rate: 14,
        per: "per 2 pics",
      },
      {id: 2,
        item: "English Pickled Onions",
        itemDesc: "mildly sweet flavor",
        quantity: 8,
        rate: 17,
        per: "per pics",
      },
      {id: 3,
        item: "Cottage Pie",
        itemDesc: "made with lamb",
        quantity: 2,
        rate: 14,
        per: "per 3 pics",
      },
    ],
  },
  {
    id: 12,
    bookingID: "6547",
    customerID: "255744332",
    invoiceNumber: "INV-65ZTE11",
    customerImage: profile5,
    customerName: "Maximillian",
    createdDate: "15-01-2017",
    amount: 30,
    dueDate: 6,
    status: namesOption.statusPaid,
    paidOn: "27 April 2021, 6:10pm ",
    address: " DORSET HOUSE, DUKE STREET",
    email: "info@mbecardiff.co.uk",
    phone: "01249 446142",
    items: [
      {
        id: 1,
        item: "Brain Masala",
        itemDesc: "Made with Goat meat",
        quantity: 3,
        rate: 16,
        per: "per serving",
      },
      {id: 2,
        item: "Toad in the Hole",
        itemDesc: "Pan-fried sausages",
        quantity: 2,
        rate: 10,
        per: "per 6 pics",
      },
      {id: 3,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
      {id: 4,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
      {id: 5,
        item: "Yorkshire Pudding",
        itemDesc: "a puffy bread-like treat",
        quantity: 4,
        rate: 16,
        per: "per serving",
      },
    ],
  },
  {
    id: 13,
    bookingID: "23543",
    customerID: "425676885",
    invoiceNumber: "INV-65ZTE12",
    customerImage: profile1,
    customerName: "Adam Smith",
    createdDate: "02-08-2022",
    amount: 45,
    dueDate: 2,
    status: namesOption.statusPending,
    paidOn: "19 April 2021, 7:45pm ",
    address: "20 WINCHCOMBE STREET",
    email: "info@mbechester.co.uk",
    phone: "01206 368882",
    items: [
      {
        id: 1,
        item: "Scottish Tablet",
        itemDesc: "Made from milk, sugar",
        quantity: 6,
        rate: 14,
        per: "per 2 pics",
      },
      {id: 2,
        item: "English Pickled Onions",
        itemDesc: "mildly sweet flavor",
        quantity: 8,
        rate: 17,
        per: "per pics",
      },
      {id: 3,
        item: "Cottage Pie",
        itemDesc: "made with lamb",
        quantity: 2,
        rate: 14,
        per: "per 3 pics",
      },
    ],
  },
];
