import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { IMYCALENDARINITIALSTATE } from "./calendar-slice-interface";
import { myCalendarEvents } from "../../mock-data/calendar-mock-data";


export const myCalendarAddReminder = (state: IMYCALENDARINITIALSTATE, action: any) => {
  state.myCalendarEvents.push(action.payload);
};

export const myCalendarSetviewType = (state: IMYCALENDARINITIALSTATE, action: any) => {
  state.viewType = action.payload;
  action.payload === "listWeek"
    ? (state.showListView = true)
    : (state.showListView = false);
};



// GET Reminders
export const getCalendarRemindersThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const getCalendarRemindersThunkFulfilledReducer = (builder: any, action: any) => {

  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
      // set main array to default state then add reminder on top;
      // state.myCalendarEvents = myCalendarEvents;

      // state.myCalendarEvents.push(...payload);
      state.myCalendarEvents = payload

    });
}
export const getCalendarRemindersThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}

// POST Reminders
export const postCalendarRemindersThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(
    action.rejected, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.FAILED;
    });
}

export const postCalendarRemindersThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(
    action.fulfilled, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.SUCCEEDED;
    });
}
export const postCalendarRemindersThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(
    action.pending, (state: any, { payload }: any) => {
      state.status = REQUESTSTATUS.PENDING;
    });

}