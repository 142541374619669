import { Grid } from "@mui/material"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useAppAlert } from "../../../components/app-alert/use-app-alert"
import { useAxiosInterceptors } from "../../../hooks/use-axios-interceptors"
import { useAppDispatch, useAppSelector } from "../../../store/store"
import Dietary from "../common/tab-view/component/dietary/dietary"
import FindMore from "../common/tab-view/component/find-more/find-more"
import Offers from "../common/tab-view/component/offers/offers"
import PriceRange from "../common/tab-view/component/price-range/price-range"
import {  prepareFoodSearchData, prepareFoodSearchOptionData } from "./food-search-filter-data"

export const FoodSearchFilter = (props:any) => { 
    const  {  emptyXs , emptyMd , emptyLg , priceRangeXs, priceRangeMd, priceRangeLg, 
    offerXs, offerMd, offerLg, dietaryXs, dietaryMd, dietaryLg, findMoreXs, findMoreMd, findMoreLg,  
    emptySecondXs , emptySecondMd , emptySecondLg, spacingGrid
} = props;
    const { searchType } = useParams(); 
    const axiosInstance = useAxiosInterceptors();
    const {openAlert} = useAppAlert();
    const dispatch = useAppDispatch();
    const { foodPreference } = useAppSelector(state => state)
    // console.log(foodPreference)
    
    // useEffect(()=>{
    
    // prepareFoodSearchOptionData( searchType , foodPreference , openAlert, dispatch , axiosInstance)
    
    // },[foodPreference?.filters?.dietary?.length, foodPreference?.filters?.facilities?.length ,
    //     foodPreference?.filters?.facilitiesForChildren?.length,foodPreference?.filters?.facilitiesForDisables?.length , 
    //     foodPreference?.filters?.hostLanguageProficiences?.length ,foodPreference?.filters?.houseRules?.length ,
    //     foodPreference?.filters?.offers?.length,  foodPreference?.filters?.price?.startFrom , 
    //     foodPreference?.filters?.price?.endAt]
    // )
    // if not optional ------ first version
    
    useEffect(()=>{
    
    prepareFoodSearchData( searchType , foodPreference , openAlert, dispatch , axiosInstance)
    
    },[foodPreference?.filters?.dietary?.length, foodPreference?.filters?.facilities?.length ,
        foodPreference?.filters?.facilitiesForChildren?.length,foodPreference?.filters?.facilitiesForDisables?.length , 
        foodPreference?.filters?.hostLanguageProficiences?.length ,foodPreference?.filters?.houseRules?.length ,
        foodPreference?.filters?.offers?.length,  foodPreference?.filters?.price?.startFrom , 
        foodPreference?.filters?.price?.endAt , ]
    )
    
    // console.log({foodPreference})
    return (
        <>
            <Grid container spacing={spacingGrid || 2} className="margin-top-1">
                <Grid item xs={0} md={emptyMd || 2}  lg={emptyLg || 2} >
                </Grid>
                <Grid item xs={12} md={priceRangeMd || 2} lg={priceRangeLg || 1.75}>
                    <PriceRange />
                </Grid>
                <Grid item xs={12} md={offerMd || 2} lg={offerLg || 1.75}>
                    <Offers />
                </Grid>
                <Grid item xs={12} md={dietaryMd || 2} lg={dietaryLg || 1.75}>
                    <Dietary />
                </Grid>
                <Grid item xs={12} md={findMoreMd || 2} lg={findMoreLg || 1.75}>
                    <FindMore />
                </Grid>
                <Grid item xs={0} md={emptySecondMd || 1.5} lg={emptySecondLg || 1}>
                </Grid>
            </Grid>
        </>
    )
}