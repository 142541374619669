import { createSlice } from "@reduxjs/toolkit";

// Get data from assets/data

import {
    help
  } from "../../features/help/help-data";

const INITIALSTATE = {
  help:help
};

const helpSlice = createSlice({
  name: "help",
  initialState: INITIALSTATE,
  reducers: {

  },
});

export default helpSlice.reducer;
