import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  getListingThunkRejectedReducer, getListingThunkFulfilledReducer,
  getListingThunkPendingReducer, deleteListingThunkRejectedReducer,
  deleteListingThunkFulfilledReducer, deleteListingThunkPendingReducer, changeListingStatusThunkRejectedReducer,
  changeListingStatusThunkFulfilledReducer, changeListingStatusThunkPendingReducer, setSortedListingsData,
  getListingByIDThunkRejectedReducer, getListingByIDThunkFulfilledReducer, getListingByIDThunkPendingReducer, patchListingThunkFulfilledReducer, patchListingThunkPendingReducer, patchListingThunkRejectedReducer, sendBookingRequestThunkRejectedReducer, sendBookingRequestThunkFulfilledReducer, sendBookingRequestThunkPendingReducer, viewListingByIDThunkRejectedReducer, viewListingByIDThunkFulfilledReducer, viewListingByIDThunkPendingReducer, clearSingleListingsData, getReviewsByIdThunkRejectedReducer, getReviewsByIdThunkFulfilledReducer, getReviewsByIdThunkPendingReducer
} from "./listings-reducers";
import { changeListingStatusThunk, deleteListingsThunk, getListingByIDThunk, getListingsThunk, getRatingsByIdThunk, patchListingThunk, sendBookingRequestThunk, viewListingByIDThunk } from "./listings-thunk-actions";

const listingSlice = createSlice({
  name: "listings",
  initialState: {
    calendarList: [] as any,
    singleListingData: {},
    reviews:[],
    userId:''
  },
  reducers: {
    setCalendar(state, action) {
      const calendarTimings = action.payload;
      state.calendarList = calendarTimings;
    },
    addCalendar(state, action) {
      const data = action.payload;
      const payload = JSON.parse(data);
      console.log(payload);      
      const startDate = dayjs(payload.startDate).format("YYYY-MM-DD")
      const endDate = dayjs(payload.endDate).format("YYYY-MM-DD")

      state.calendarList.push({ ...payload, startDate: startDate, endDate: endDate });
    },
    deleteTimings(state, action) {
      const data = action.payload;
      if (state.calendarList[data.index].timeRanges.length > 1) {
        state.calendarList[data.index].timeRanges.splice(data.timingIndex, 1);
      } else {
        state.calendarList.splice(data.index, 1)
      }
    },
    updateCalendar(state, action) {
      const data = action.payload;
      state.calendarList[data.index].timeRanges[data.timingIndex] = data.timing;
    },
    editDateAvailablityCalendar(state, action) {
      const payload = action.payload.date;
      const startDate = dayjs(payload.startDate).format("YYYY-MM-DD")
      const endDate = dayjs(payload.endDate).format("YYYY-MM-DD")

      state.calendarList[action.payload.editIndex] = { ...payload, startDate: startDate, endDate: endDate };
    },
    deleteCalendarData(state, action) {
      const emptyCalendar = action.payload;
      state.calendarList = emptyCalendar;
    },
    sortListingHandler: setSortedListingsData,
    clearSingleListingData: clearSingleListingsData
  },
  extraReducers(builder) {
    getListingThunkRejectedReducer(builder, getListingsThunk)
    getListingThunkFulfilledReducer(builder, getListingsThunk)
    getListingThunkPendingReducer(builder, getListingsThunk)

    deleteListingThunkRejectedReducer(builder, deleteListingsThunk)
    deleteListingThunkFulfilledReducer(builder, deleteListingsThunk)
    deleteListingThunkPendingReducer(builder, deleteListingsThunk)

    changeListingStatusThunkRejectedReducer(builder, changeListingStatusThunk)
    changeListingStatusThunkFulfilledReducer(builder, changeListingStatusThunk)
    changeListingStatusThunkPendingReducer(builder, changeListingStatusThunk)

    getListingByIDThunkRejectedReducer(builder, getListingByIDThunk)
    getListingByIDThunkFulfilledReducer(builder, getListingByIDThunk)
    getListingByIDThunkPendingReducer(builder, getListingByIDThunk)

    patchListingThunkPendingReducer(builder, patchListingThunk)
    patchListingThunkFulfilledReducer(builder, patchListingThunk)
    patchListingThunkRejectedReducer(builder, patchListingThunk)

    sendBookingRequestThunkRejectedReducer(builder, sendBookingRequestThunk)
    sendBookingRequestThunkFulfilledReducer(builder, sendBookingRequestThunk)
    sendBookingRequestThunkPendingReducer(builder, sendBookingRequestThunk)


    viewListingByIDThunkRejectedReducer(builder, viewListingByIDThunk)
    viewListingByIDThunkFulfilledReducer(builder, viewListingByIDThunk)
    viewListingByIDThunkPendingReducer(builder, viewListingByIDThunk)


    getReviewsByIdThunkRejectedReducer(builder, getRatingsByIdThunk)
    getReviewsByIdThunkFulfilledReducer(builder, getRatingsByIdThunk)
    getReviewsByIdThunkPendingReducer(builder, getRatingsByIdThunk)

  }
});

export const listingsActions = listingSlice.actions;

export const {
  addCalendar, setCalendar, deleteCalendarData, clearSingleListingData,
  updateCalendar, deleteTimings,
  editDateAvailablityCalendar, sortListingHandler,
} = listingSlice.actions;
export default listingSlice.reducer;
