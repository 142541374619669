export const showAlertMessageReducer = (state: any, action: any) => {
    state.alertMessage = action.payload.alertMessage;
    state.showAlert = true;
    state.alertType = action.payload.alertType;
}

export const hideAlertMessageReducer = (state: any) => {
    state.alertMessage = '';
    state.showAlert = false;
    state.alertType = ''
}