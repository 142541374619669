import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const ThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const hostpersonalInfoThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const hostpersonalInfoThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};
export const hostKitchenDetailsInfoThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.FAILED;

  });
};

export const hostKitchenDetailsInfoThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.personalInfoDetails = payload.personalInfoDetails
    state.kitchenDetails = payload.kitchenDetails
    state.badgesDetails = payload.badgesDetails
    state.facilitiesDetails = payload.facilitiesDetails
    state.houseRulesDetails = payload.houseRulesDetails
    state.hostSummaryDetails = payload.hostSummaryDetails
    state.userId = payload.userId
  });
};
export const hostIdVerficationInfoThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const hostIdVerficationInfoThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.idVerificationDetails = payload.idVerificationDetails
  });
};
export const hostlicenseInfoThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const hostlicenseInfoThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.licenseInfoDetails = payload.licenseInfoDetails
  });
};
export const hosttrainingsInfoThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const hosttrainingsInfoThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.trainingsDetails = payload.trainingsDetails
  });
};
export const hostribbonsInfoThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.FAILED;

  });
};

export const hostribbonsInfoThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.ribbonsDetails = payload.ribbonsDetails
  });
};

export const getHostContractPdfThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any) => {
    state.getContractStatus = REQUESTSTATUS.PENDING;
  });
};

export const getHostContractPdfThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.getContractStatus = REQUESTSTATUS.SUCCEEDED;
    state.contractPdfLink = payload.contractPdfLink;
    // state.contractBookData = payload.contractBookData;

  });
};

export const getHostContractPdfThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    // console.log({ payload });
    state.getContractStatus = REQUESTSTATUS.FAILED;
  });
};