import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  LOGINAPI,
  LOGOUTAPI,
  REFRESHTOKENAPI,
  SIGNUPAPI,
  CHANGEPASSWORDAPI,
  FORGOTPASSWORDAPI,
  RESENDEMAILAPI,
  VERIFYEMAILAPI,
  RESETPASSWORDAPI,
  VERIFICATIONSTATUS,
} from "../../constants/axios-api-constant";
import {
  EMAILVERIFICATIONPATH,
  SIGNINPATH,
} from "../../constants/routes-constant";

// import { ISIGNUPINITIALVALUES } from "../../features/authentication/signup/signup-interface";

import { getDataAPI, postDataAPI, putDataAPI } from "../../lib/axios";
import { saveDataToLocalStorage } from "../../utils/views-utils";
import { getDataFromStorage } from "../../utils/views-utils";
import { showAlertMessage } from "../alert/alert-slice";

export const changePasswordThunk = createAsyncThunk(
  "auth/change-password",
  async (
    { changePasswodCredentials, openAlert, axiosInstance }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        CHANGEPASSWORDAPI,
        axiosInstance,
        changePasswodCredentials
      );

      const responseData = {
        message: response?.data?.message,
      };

      openAlert(responseData.message, "alert-success-message");

      return responseData;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
      };
      openAlert(
        errorData?.error || "Server is not responding. Please Try Again Later"
      );

      return rejectWithValue(errorData);
    }
  }
);

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (
    {
      userCredentials,
      rememberMe,
      navigate,
      axiosInstance,
      dispatch,
      openAlert,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        LOGINAPI,
        axiosInstance,
        userCredentials
      );

      const responseData = {
        token: response.data.data.authToken, // store access token
        refreshToken: response.data.data.refreshToken, // store refresh token
        user: response.data.data.user, // store user details
        success: response.data.message,
        role: response.data.data.user.roles,
        // role: [response.data.data.user.defaultRole],  // for defaultRole cases
        // store success message
      };

      const rememberMeData = {
        email: userCredentials.email,
        password: userCredentials.password,
      };

      // dispatch(myUserProfileDataThunk({axiosInstance, openAlert}))
      if (!responseData?.user?.identityVerification?.approved) {
        setTimeout(
          () =>
            navigate(
              `${EMAILVERIFICATIONPATH}?user=${responseData?.user?.userId}&status=${responseData?.user?.identityVerification?.status}`
            ),
          2000
        );

        const unVerifiedData = {
          token: "", // store access token
          refreshToken: "", // store refresh token
          user: null, // store user details
          success: "",
          role: [],
          error: "Access Denied please verify your ID first",
          // store success message
        };
        return unVerifiedData;
      }
      localStorage.setItem("auth", JSON.stringify(responseData));
      localStorage.setItem("smd-auth-time", JSON.stringify(Date.now()));
      rememberMe &&
        localStorage.setItem(
          "smd-remember-me-user",
          JSON.stringify(rememberMeData)
        );
      navigate("/");
      return responseData;
    } catch (error: any) {
      console.log(error);
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
      };

      return rejectWithValue(errorData);
    }
  }
);

export const registerThunk = createAsyncThunk(
  "auth/register",
  async (
    { userCredentials, navigate, axiosInstance }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        SIGNUPAPI,
        axiosInstance,
        userCredentials
      );
      const formatResponse = response?.data;
      const responseData = {
        user: formatResponse?.data?.userId,
        success: formatResponse?.message,
        userEmail: userCredentials.email,
      };
      saveDataToLocalStorage("smd-user-email", response.data.data);
      navigate(`${EMAILVERIFICATIONPATH}?user=${responseData?.user}`);
      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
        userEmail: userCredentials.email,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const logoutThunk = createAsyncThunk(
  "auth/logout",

  async ({ navigate, axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await putDataAPI(LOGOUTAPI, axiosInstance);

      const responseData = {
        success: response.data.message,
      };
      localStorage.removeItem("auth");
      sessionStorage.removeItem("auth");
      return responseData;
    } catch (error: any) {
      localStorage.removeItem("auth");
      sessionStorage.removeItem("auth");
      navigate(SIGNINPATH);
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
      };
      return rejectWithValue(errorData);
    }
  }
);

export const refreshTokenThunk = createAsyncThunk(
  "auth/refreshToken",
  async ({ navigate, axiosRefresh }: any, { rejectWithValue }: any) => {
    const authData = getDataFromStorage("auth");
    // console.log(authData)
    const payload = {
      userId: authData.user.userId,
      refreshToken: authData.refreshToken,
    };
    // console.log(payload)
    try {
      const response = await putDataAPI(REFRESHTOKENAPI, axiosRefresh, payload);
      const responseData = {
        token: response.data.data.authToken,
        refreshToken: response.data.data.refreshToken,
        success: response.data.message,
        user: authData.user,
        role: authData.role,
      };
      // console.log(response)
      localStorage.setItem("auth", JSON.stringify(responseData));

      return responseData;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
      };

      localStorage.removeItem("auth");
      sessionStorage.removeItem("auth");
      navigate(SIGNINPATH);
      return rejectWithValue(errorData);
    }
  }
);

export const updateUserDetailsThunk = createAsyncThunk(
  "auth/updateUserDetails",
  async (
    {
      personalCompleteProfile,
      dispatch,
      showAlertMessage,
      setUploadProfileImage,
      setValue,
      axiosInstance,
    }: any,
    { rejectWithValue }
  ) => {
    // console.log(personalCompleteProfile)
    try {
      const response = await putDataAPI(
        `userdata/${personalCompleteProfile.id}`,
        axiosInstance,
        personalCompleteProfile
      );
      dispatch(
        showAlertMessage({
          alertMessage: "Image Uploaded successfully ",
          alertType: "alert-success-message",
        })
      );
      dispatch(setUploadProfileImage(false));
      setValue(1);
      // setShowSuccessScreen(true)
      // console.log(response)
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

// forgot password
export const forgotPasswordThunk = createAsyncThunk(
  "auth/forgot-password",
  async (
    { userCredentials, navigate, axiosInstance }: any,
    { rejectWithValue }
  ) => {
    // console.log(userCredentials)
    try {
      // eslint-disable-next-line
      const response = await postDataAPI(
        FORGOTPASSWORDAPI,
        axiosInstance,
        userCredentials
      );
      // console.log(response)
      const responseData = {
        userEmail: userCredentials.email,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
        userEmail: userCredentials.email,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "auth/reset-password",
  async (
    {
      userResetCredentials,
      setshowSuccessResetScreen,
      axiosInstance,
      params,
    }: any,
    { rejectWithValue }
  ) => {
    // console.log(userResetCredentials);
    try {
      const response = await postDataAPI(
        RESETPASSWORDAPI,
        axiosInstance,
        userResetCredentials
      );

      const responseData = {
        email: response.data.email,
        password: response.data.password,
        code: response.data.code,
      };
      // console.log(responseData)
      setshowSuccessResetScreen(true);
      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
      };
      // console.log(errorData.error);

      return rejectWithValue(errorData);
    }
  }
);

export const resendEmailThunk = createAsyncThunk(
  "auth/resend-link",
  async ({ emailCredentials, axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await postDataAPI(
        RESENDEMAILAPI,
        axiosInstance,
        emailCredentials
      );
      const responseData = {
        message: response.data.message,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error?.response?.data?.errors ||
          "Server is not responding. Please Try Again Later",
        userEmail: emailCredentials.email,
      };
      return rejectWithValue(errorData);
    }
  }
);
export const verifyEmailThunk = createAsyncThunk(
  "auth/verify-email",
  async ({ code, user, axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${VERIFYEMAILAPI}?code=${code}&email=${user}`,
        axiosInstance
      );
      const responseData = {
        message: response.data.message,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
      };

      return rejectWithValue(errorData);
    }
  }
);
export const VerificationStatusThunk = createAsyncThunk(
  "auth/verification-status",
  async ({ userId, axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${VERIFICATIONSTATUS}?userId=${userId}`,
        axiosInstance
      );
      // console.log(response);
      // const responseData = {
      //   message: response.data.message,
      //   verifyStatus: "Under Review",
      // };
      // return responseData;
      return response;
    } catch (error: any) {
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
      };

      return rejectWithValue(errorData);
    }
  }
);
