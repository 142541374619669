import { createSlice } from "@reduxjs/toolkit";

import { IMYCALENDARINITIALSTATE } from "./calendar-slice-interface";
import {
  // myCalendarEvents,
  dropDownList,
} from "../../mock-data/calendar-mock-data";
import {
  getCalendarRemindersThunkFulfilledReducer, getCalendarRemindersThunkPendingReducer,
  getCalendarRemindersThunkRejectedReducer, myCalendarAddReminder, myCalendarSetviewType,
  postCalendarRemindersThunkFulfilledReducer, postCalendarRemindersThunkPendingReducer, postCalendarRemindersThunkRejectedReducer
} from "./my-calendar-reducers";
import { getCalendarRemindersThunk, postCalendarRemindersThunk } from "./calendar-thunk-actions";

// const initialState: IMYCALENDARINITIALSTATE|any = {
const initialState: any = {
  myCalendarEvents:[],
  dropDownList,
  viewType: "dayGridMonth",
  showListView: false,
  status: 'idle'
};
const myCalendar = createSlice({
  name: "myCalendar",
  initialState,
  reducers: {
    addReminder: myCalendarAddReminder,
    setCalendarViewType: myCalendarSetviewType,
  },
  extraReducers(builder) {
    getCalendarRemindersThunkRejectedReducer(builder, getCalendarRemindersThunk)
    getCalendarRemindersThunkFulfilledReducer(builder, getCalendarRemindersThunk)
    getCalendarRemindersThunkPendingReducer(builder, getCalendarRemindersThunk)

    postCalendarRemindersThunkRejectedReducer(builder, postCalendarRemindersThunk)
    postCalendarRemindersThunkFulfilledReducer(builder, postCalendarRemindersThunk)
    postCalendarRemindersThunkPendingReducer(builder, postCalendarRemindersThunk)

  }

});

export const { addReminder, setCalendarViewType } = myCalendar.actions;
export default myCalendar.reducer;
