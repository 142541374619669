import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {  KITCHENDETAILSAPI, KITCHENDETAILSIMAGEDELETEAPI, KITCHENLOCATIONAPI } from "../../constants/axios-api-constant";
import { deleteDataAPI, getDataAPI, patchDataAPI,} from "../../lib/axios";
import { updatePersonalUserProfileStatusThunk } from "../personal-user/personal-user-thunk-actions";

export const postKitchenDetailsThunk = createAsyncThunk('kitchen/post-kitchen-detail',
    async ({ kitchenDetailsData, axiosInstance, openAlert ,personalUser, dispatch , setValue }: any, { rejectWithValue }) => {

        // console.log(kitchenDetailsData, 'thunk');

        try {
            const response = await patchDataAPI(KITCHENDETAILSAPI,  axiosInstance, kitchenDetailsData);
            // console.log(response)
            const responseData = {
                kitchen: ''
            }
            if (!personalUser?.profileCompletion?.kitchenDetails?.completed) {
                dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'kitchenDetails', openAlert }))
              }
              setValue(4)
            return responseData
        } catch (error: any) {
            // console.log(error);
            const errorData = {
                error: error?.response?.data?.errors
            }
            openAlert?.(errorData?.error)
            rejectWithValue(errorData)
        }
    }
)

export const getKitchenDetailsThunk = createAsyncThunk('kitchen/get-kitchen-details',
    async ({ axiosInstance , setKitchenCover, setServingDay , setServingTimeFrom , setServingTimeTo, 
        dispatch, setIValue, setSelectCoverImage , openAlert}: any, { rejectWithValue }) => {

        try {
            const response = await getDataAPI(KITCHENDETAILSAPI, axiosInstance);
            // console.log(response)
            const formatData = response?.data?.data;
            const responseData = {
                kitchenDetails: {
                    Id: formatData?.kitchenId,
                    userId: formatData?.userId,
                    name: formatData?.name,
                    coverPhotos: formatData?.coverPhotos,
                    selectedCoverPhoto: formatData?.selectedCoverPhoto,
                    location: formatData?.location,
                    servingDays: formatData?.servingDays,
                    servingTimeFrom: formatData?.servingTimeFrom,
                    servingTimeTo: formatData?.servingTimeTo,
                    defaultValue: {
                        locationAddress:formatData?.location?.address?.locationName || ' ', 
                        loctaionId:formatData?.location?.address?.locationId || ' '
                        }
                },
                houseRules:formatData?.houseRules,
                veriffStatus:formatData?.veriffStatus,
                dbsCheck:formatData?.dbsCheck,
                badgesId:formatData?.badgesId,
                myFacilities:formatData?.myFacilities
            }
            setKitchenCover?.(formatData?.coverPhotos)
            const newObject = {...formatData.servingDays};
            setServingDay?.(newObject)
            setSelectCoverImage?.(formatData?.selectedCoverPhoto)
            // setServingTimeFrom?.(dayjsFormatDate(formatData?.servingTimeFrom,'HH:mm:ss'))
            // setServingTimeTo?.(dayjsFormatDate(formatData?.servingTimeTo,'HH:mm:ss'))
            // const isServingTimeFrom =  formatData?.servingTimeFrom || '00:00:00'
            // const isServingTimeTo =  formatData?.servingTimeTo || '00:00:00'
            // console.log({isServingTimeFrom, isServingTimeTo});
            
            const servingTimeFrom = new Date('1970-01-01T' + formatData?.servingTimeFrom);
            const servingTimeTo= new Date('1970-01-01T' + formatData?.servingTimeTo);
            formatData?.servingTimeFrom && setServingTimeFrom?.(dayjs(servingTimeFrom))
            formatData?.servingTimeTo && setServingTimeTo?.(dayjs(servingTimeTo))
            setIValue?.(
                {
                locationAddress:formatData?.location?.address?.locationName , 
                loctaionId:formatData?.location?.address?.locationId
                }
                )
            // console.log(response)
            // console.log(responseData)
            dispatch(getSelectedKitchenLocationDetailsThunk({ axiosInstance, locationId: formatData?.location?.address?.locationId}))

            return responseData
        } catch (error: any) {
            // console.log(error);
            const errorData = {
                error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
              }
              openAlert?.(errorData?.error)
            rejectWithValue(errorData)
        }
    }
)

export const getKitchenLocationThunk = createAsyncThunk("kitchen/search-kitchen-location", 
async ({axiosInstance,searchParam , openAlert}:any, {rejectWithValue}:any) => {
    try {
        const response:any = await getDataAPI(`${KITCHENLOCATIONAPI}?search=${searchParam}`, axiosInstance)
        // console.log(response)
        const formatData = response?.data?.data
        const responseData = {
            locations : formatData
        }
        return responseData
    } catch (error:any) {
        // console.log(error)
        const errorData = {
            error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
          }
          openAlert?.(errorData.error)
        rejectWithValue(errorData)
    }
}
)

export const getSelectedKitchenLocationDetailsThunk = createAsyncThunk("kitchen/selected-kitchen-location-details", 
async ({axiosInstance,locationId, openAlert}:any, {rejectWithValue}:any) => {
    // console.log(locationId)
    try {
        const response:any = await getDataAPI(`${KITCHENLOCATIONAPI}/${locationId}`, axiosInstance)
        // console.log(response)
        const formatData = response?.data?.data
        const responseData = {
            coordinates : formatData
        }
        return responseData
    } catch (error:any) {
        // console.log(error)
        const errorData = {
            error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
          }
          openAlert?.(errorData.error)
        rejectWithValue(errorData)
    }
}
)

export const deleteKitchenDetailsImageThunk = createAsyncThunk("kitchen/delete-kitchen-details-image",
  async ({ axiosInstance, coverImageDetail, openAlert, kitchenCover, set , id, setKitchenCover }: any, { rejectWithValue }) => {
    // console.log(coverImageDetail)
    try {
      // console.log("listings deleted", listingIDsToDelete);
      const response = await deleteDataAPI(KITCHENDETAILSIMAGEDELETEAPI, axiosInstance, coverImageDetail);

      openAlert("Image Deleted Successfully", 'alert-error-message')
      const deletingObj = kitchenCover?.filter((item: any) => item !== id);
      setKitchenCover?.(deletingObj);
      set?.("kitchenCoverPhoto", deletingObj?.length ? deletingObj : "")
      return response.data.message;
    }
    catch (error: any) {
        const errorData = {
            error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
          }
          openAlert?.(errorData.error)
    //   console.log(errorData.error)
      return rejectWithValue(errorData);
    }
  }
);