
// Images
import fbIcon from "../../assets/images/layout/footer/facebook.png";
import linkedinIcon from "../../assets/images/layout/footer/linkedin.png";
import youtubeIcon from "../../assets/images/layout/footer/youtube.png";
import instaIcon from "../../assets/images/layout/footer/instagram.png";

// Interfaces
import {
  IFOOTERICONS,
  IFOOTERCONTENT,
} from "./footer-interface";
import { HASHPATH } from "../../constants/routes-constant";





export const footerData: IFOOTERICONS[] = [
  {
    id: "1",
    icon: <img src={fbIcon} alt="" />,
    path: HASHPATH,
  },
  {
    id: "2",
    icon: <img src={youtubeIcon} alt="" />,
    path: HASHPATH,
  },
  {
    id: "3",
    icon: <img src={instaIcon} alt="" />,
    path: HASHPATH,
  },
  {
    id: "4",
    icon: <img src={linkedinIcon} alt="" />,
    path: HASHPATH,
  },
];

export const footerContent: IFOOTERCONTENT[] = [
  { id: "1", text: " Terms & Conditions | ", path: HASHPATH },
  { id: "2", text: " Privacy Policy | ", path: HASHPATH },
  { id: "3", text: " Support ", path: HASHPATH },
];
