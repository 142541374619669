import { AppButton } from "../../../components/app-button/app-button"
import { ImageUpload } from "../../../components/image-upload/image-upload"
import Vegan from "../../../assets/images/menu/vegan.png";
import ECOFriendly from "../../../assets/images/menu/eco-friendly.png";
import Bakery from "../../../assets/images/menu/bakery.png";
import Halal from "../../../assets/images/menu/halal.png";
import { AnyAaaaRecord } from "dns";
import { AVAILABLESELECTCOLOR, AVAILABLETEXTCOLOR, TEXTCOLOR } from "./upsert-food-item-mui-style";
import { ImagePreviewCard } from "../../../components/image-preview-card/image-preview-card";
import { Grid } from "@mui/material";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { AppSpinnerLoader } from "../../../components/app-spinner-loader/app-spinner-loader";


const otherBreadcrumbs = (hasId: any) => {
    return [
        {
            id: 2,
            linkName: '/',
            className: 'global-breadcrumb-previous-link text-decoration',
            linkPath: '#'
        },
        {
            id: 1,
            linkName: hasId ? "Edit Food Item" : "Add Food Item",
            className: 'global-breadcrumb-active',
        },
    ]
}

export const breadcrumbdata = (id: any) => {
    return {
        firstLink: '/menu/menu-preview',
        firstLinkName: 'Menu |',
        otherBreadcrumbs: otherBreadcrumbs(id)
    }
}

export const addFoodItemButtonsDynamic = (status:any, foodItemId:any) => {
    return [
        {
            className: "btn-orange-outline width-100",
            type: "button",
            buttonId: 1,
            btnText: "Cancel",
            btnFunction: () => { }
        },
        {
            className: `btn-orange width-100`,
            type: "submit",
            buttonId: 2,
            btnText: status === REQUESTSTATUS.PENDING ? "Wait ..." : foodItemId ? "Update" : "Save",
            btnIcon: status === REQUESTSTATUS.PENDING && <AppSpinnerLoader/>,
            disabled: status === REQUESTSTATUS.PENDING,
            btnFunction: () => { }
        },
    ]

}

export const spiceLevel = [
    { id: 0, value: 'No Spice', text: 'No Spice', spiceColor: 'linear-gradient(90deg, #3A7A1C 0%, #36A900 119.84%)' },
    { id: 1, value: 'Mild', text: 'Mild', spiceColor: 'linear-gradient(91.89deg, #FF9D00 1.64%, #FFD600 102.57%)' },
    { id: 2, value: 'Spicy', text: 'Spicy', spiceColor: '#FF8908' },
    { id: 3, value: 'Hot', text: 'Hot', spiceColor: '#C72535' }
]

export const addBadges = [
    { id: 'dfsdf', value: 'Vegan', img: Vegan },
    { id: 'hkjdsfh', value: 'ECO Friendly', img: ECOFriendly },
    { id: '89diuh', value: 'Bakery Fresh', img: Bakery },
    { id: 'dfksjd', value: 'Halal', img: Halal }
]

export const upsertFoodItemFormFieldsDynamic =
    (dietary: any, allergens: any, cuisines: any, badges: any, itemCategory: any, selectMenuId: any, setBadgesFormikValue: any, 
        deleteUploadedImage: any, getNutriticsInfo:any, ingredient:any, recipe:any) => {
        return [
            {
                type: 'inputField',
                label: "Menu Category",
                id: "itemCategory",
                name: "itemCategory",
                select: true,
                selectMenuData: itemCategory,
                // selectProps: { multiple: true },
                // addNewOption: true,
                menuItemClick: selectMenuId
            },
            {
                type: 'inputField',
                name: "itemName",
                label: "Item Name",
                id: "item Name"
            },
            {
                type: 'inputField',
                label: "Item Description",
                id: "itemDescription",
                name: "itemDescription",
                multiline: true,
                minRows: 4,
                maxRows: 4,
            },
            {
                type: 'image',
                render: (formik: any, foodItemImageData: any, setFoodItemImageData: any,
                    openSelectCoverImageModal:any,setOpenSelectCoverImageModal:any,selectCoverImage:any, setSelectCoverImage:any) => (
                    <>
                        <ImageUpload
                            label="Images"
                            // setimagNotUpload={setImageNotUploaded}
                            multiple
                            name="foodImages"
                            imagesArray={foodItemImageData}
                            imgName={foodItemImageData?.[0]?.name}
                            setImagesArray={setFoodItemImageData}
                            // disabledHandler={disabledHandler}
                            errors={formik?.errors?.foodImages}
                            touched={formik?.touched?.foodImages}
                            setFieldValue={formik?.setFieldValue}
                            // deleteUploadedImage={() => deleteUploadedImage(formik)}
                            binary
                        />

                        {
                            !!foodItemImageData.length &&
                            <Grid container
                                justifyContent={'space-around'}
                                marginTop={{ xs: 1, md: 3 }}
                                maxHeight={'250px'}
                                overflow={'auto'}
                                padding={1.5}

                            >
                                {foodItemImageData?.map((single: any) => (
                                    <Grid item sm={12} xs={12} md={3} lg={2} xl={2} marginBottom={3}>
                                        <ImagePreviewCard
                                            key={single._id || single?.lastModified}
                                            singleMenuImgData={single}
                                            deleteUploadedImage={() => deleteUploadedImage(single, formik.setFieldValue)}
                                            // globalClick={()=>setOpenSelectCoverImageModal(true)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    </>

                )
            },
            {
                type: 'inputField',
                label: "Ingredients",
                id: "Ingredients",
                name: "ingredient",
                select: true,
                selectMenuData: ingredient,
                // selectMenuData: [
                //     {
                //         id: 1,
                //         Value: 'Chicken',
                //         text: 'Chicken'
                //     },
                //     {
                //         id: 2,
                //         Value: 'Egg',
                //         text: 'Egg'
                //     },
                //     {
                //         id: 3,
                //         Value: 'Bread',
                //         text: 'Bread'
                //     },
                // ],
                selectProps: { multiple: true, renderValue: (selected: any) => selected?.join(", ") },
                // addNewIngredient: true,
                addNewOption: true,
                checkbox: true,
            },

            {
                type: 'inputField',
                label: "Dietary",
                id: "dietary",
                name: "dietary",
                select: true,
                selectMenuData: dietary,
                // selectProps: { multiple: true },
                addNewOption: true,

            },
            {
                type: 'inputField',
                label: "Allergens ",
                id: "allergen",
                name: "allergen",
                select: true,
                selectMenuData: allergens,
                selectProps: { multiple: true, renderValue: (selected: any) => selected?.join(", ") },
                addNewOption: true,
                checkbox: true,
            },
            {
                type: 'inputField',
                name: "nutritionalInfo",
                label: "Nutritional Info",
                id: "nutritionalInfo",
                // sx:{input: { color: 'red' }},
                sx:(formik: any) => TEXTCOLOR(formik),
                disabled: true,
                endIcon: (set: any, setFormikField: any,formik:any) => <AppButton
                    btnText="Calculate Nutrition"
                    className="btn-orange nutrional-info-button"
                    btnFunction={() => {  getNutriticsInfo?.(set, setFormikField, formik);  }}
                />
            },
            {
                type: 'inputField',
                label: "Cuisines",
                id: "cusine",
                name: "cuisin",
                select: true,
                selectMenuData: cuisines,
                // selectProps: { multiple: true },
                // addNewOption: true,

            },
            {
                type: "button",
                name: "spice",
                id: "spice",
                label: "Spice Level",
                data: spiceLevel,

            },
            {
                type: 'inputField',
                label: "Associate Recipe",
                id: "recipe",
                name: "recipe",
                required:false,
                select: true,
                selectMenuData:recipe,
                // selectMenuData: [
                //     {
                //         id: 1,
                //         Value: 'Pizza Bread',
                //         text: 'Pizza Bread'
                //     },
                //     {
                //         id: 2,
                //         Value: 'Chicken Burger',
                //         text: 'Chicken Burger'
                //     },
                //     {
                //         id: 3,
                //         Value: 'Fruit Salad',
                //         text: 'Fruit Salad'
                //     },
                // ],
                // selectProps: { multiple: true },
                // addNewOption: true,

            },
            {
                type: "inputField",
                name: "badge",
                label: "Badge",
                id: "badge",
                select: true,
                selectMenuData: badges,
                menuItemClick: setBadgesFormikValue
            },
            {
                type: 'inputField',
                label: "Availability",
                id: "available",
                name: "available",
                sx: (formik: any) => AVAILABLETEXTCOLOR(formik),
                select: true,
                selectMenuData: [
                    {
                        id: 1,
                        Value: '',
                        text: ''
                    },
                    {
                        id: 2,
                        Value: 'available',
                        text: 'Available',
                        sx: { color: "#659C35" }
                    },
                    {
                        id: 4,
                        Value: 'unavailable',
                        text: 'Unavailable',
                        sx: { color: 'red' }

                    },
                ],
                // selectProps: { multiple: true },
                // addNewOption: true,

            },
            {
                type: 'radio',
                label: 'Age Restriction',
                id: 'age',
                name: "age",
                radioGroupClass: "margin-bottom-0",
                options: [
                    {
                        value: 'No',
                        label: 'No',
                        filterClassName: 'margin-left-1',

                    },
                    {
                        value: 'Yes',
                        label: 'Yes',
                        filterClassName: 'margin-left-1',

                    }

                ]
            },
        ]
    }


export const nutritionalInfo: any = (nutrtics:any)=> {
    return [
    { id: 0, elementName: 'Energy', quantity: "kcal", percent: nutrtics?.calories?.value},
    { id: 1, elementName: 'Saturate', quantity: "g", percent: nutrtics?.saturated_fat?.value },
    { id: 2, elementName: 'Sugar', quantity: "g", percent: nutrtics?.sugars?.value },
    { id: 3, elementName: 'Sodium', quantity: "mg", percent: nutrtics?.sodium?.value },
    { id: 4, elementName: 'Fat', quantity: "g", percent: nutrtics?.total_fat?.value }
]
}


export const nutricsInitialForFooodFormValues: any = {
    calories: {
        value: 250,
        unit: "kcal"
    },
    total_fat: {
        value: 11,
        unit: "g"
    },
    saturated_fat: {
        value: 3,
        unit: "g"
    },
    cholesterol: {
        value: 80,
        unit: "mg"
    },
    sodium: {
        value: 69,
        unit: "mg"
    },
    total_carbohydrate: {
        value: 15,
        unit: "g"
    },
    dietary_fiber: {
        value: 2,
        unit: "g"
    },
    sugars: {
        value: 7,
        unit: "g"
    },
    protein: {
        value: 22,
        unit: "g"
    },
    potassium: {
        value: 621,
        unit: "mg"
    }
}