export  const marks: any[] = [
    {
        id: 5,
        label: '€ 5',
        value: 5,
      
    },
    {
        id: 10,
        label: '€ 10',
        value: 10,
      
    },
    {
        id: 15,
        label: '€ 15',
        value: 15,
    },
    {
        id: 20,
        label: '€ 20',
        value: 20,
    },
    {
        id: 25,
        label: '€ 25',
        value: 25,
    },
    {
        id: 30,
        label: '€ 30',
        value: 30,
    },
    {
        id: 35,
        label: '€ 35',
        value: 35,
    },
    {
        id: 40,
        label: '€ 40',
        value: 40,
    },
    {
        id: 45,
        label: '€ 45',
        value: 45,
    },
    {
        id: 50,
        label: '€ 50+',
        value: 50,
    },
];