import { createAsyncThunk } from "@reduxjs/toolkit";
import { ADDCALENDARREMINDERS, CALENDARREMINDERS } from "../../constants/axios-api-constant";
import { getDataAPI, postDataAPI } from "../../lib/axios";


export const getCalendarRemindersThunk = createAsyncThunk('calendar/reminders',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(CALENDARREMINDERS, axiosInstance)
            return response.data.data;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            return rejectWithValue(errorData)
        }
    }
)
export const postCalendarRemindersThunk = createAsyncThunk('calendar/post-reminders',
    async ({ newReminder, axiosInstance, openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(ADDCALENDARREMINDERS, axiosInstance, newReminder)

            openAlert(response.data.message, 'alert-success-message')
            return response.data.message;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error, 'alert-error-message')
            return rejectWithValue(errorData)
        }
    }
)