import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { mockFoodsearch } from "../../features/food/search-food-by-x/search-food-by-x-data";

import {

    getSelectedFoodPreferenceLocationSearchDetailsThunkFulfilledReducer, getSelectedFoodPreferenceLocationSearchDetailsThunkPendingReducer,
    getSelectedFoodPreferenceLocationSearchDetailsThunkRejectedReducer, searchFoodPreferencesByCuisinesThunkFulfilledReducer,
    searchFoodPreferencesByCuisinesThunkPendingReducer, searchFoodPreferencesByCuisinesThunkRejectedReducer, searchFoodPreferencesByHostThunkFulfilledReducer,
    searchFoodPreferencesByHostThunkPendingReducer, searchFoodPreferencesByHostThunkRejectedReducer, searchFoodPreferencesByLocationThunkFulfilledReducer,
    searchFoodPreferencesByLocationThunkPendingReducer, searchFoodPreferencesByLocationThunkRejectedReducer,
    searchlocationforFoodPreferenceLocationSearchThunkFulfilledReducer, searchlocationforFoodPreferenceLocationSearchThunkPendingReducer,
    searchlocationforFoodPreferenceLocationSearchThunkRejectedReducer, setCuisineForSearchReducer, setDateForSearchReducer, setGeoLocationCoordinateReducer, setHostForSearchReducer, setLocationForSearchReducer, setNumberOfGuestReducer, setSelectedDietaryReducer, setSelectedFacilitiesForChildrenReducer, setSelectedFacilitiesForDisablesReducer, setSelectedFacilitiesReducer, setSelectedHostLanguageProficiencesReducer, setSelectedHouseRulesReducer, setSelectedOffersReducer, setSelectedPriceReducer, setTimeForSearchReducer
} from "./food-preference-reducers";

import {
   
    getSelectedFoodPreferenceLocationSearchDetailsThunk,  searchFoodPreferencesByCuisinesThunk, searchFoodPreferencesByHostThunk,
    searchFoodPreferencesByLocationThunk, searchlocationforFoodPreferenceLocationSearchThunk
} from "./food-preference-thunk-actions";




const foodPreferenceInitialState: any = {
    status: REQUESTSTATUS.IDLE,
    locationSearchStatus: REQUESTSTATUS.IDLE,
    hostSearchStatus: REQUESTSTATUS.IDLE,
    cuisineSearchStatus: REQUESTSTATUS.IDLE,
    searchLocationStatus: REQUESTSTATUS.IDLE,
    locations: [] as any,
    coordinates: {} as any,
    dateForSearch: "",
    numberOfGuests: 0,
    timeForSearch: "",
    hostForSearch: {} as any,
    locationForSearch: "",
    cuisineForSearch: [] as any,
    cuisineBasedFoods: [] as any,
    hostBasedFoods: [] as any,
    locationBasedFoods: [] as any,
    selectedDietary: [] as any,
    selectedOffers: [] as any,
    selectedFacilities: [] as any,
    selectedFacilitiesForDisables: [] as any,
    selectedFacilitiesForChildren: [] as any,
    selectedHouseRules: [] as any,
    selectedHostLanguageProficiences: [] as any,
    selectedPrice: {
        startFrom: 5,
        endAt: 15
    },
    geolocation:{
        longitude:"",
        latitiude:""
    },
    filters : {
        price : {} as any,
        offers : [] as any,
        dietary : [] as any,
        facilities: [] as any,
        facilitiesForDisables: [] as any,
        facilitiesForChildren: [] as any,
        houseRules: [] as any,
        hostLanguageProficiences: [] as any
      },


ratedKitchenStatus: REQUESTSTATUS.IDLE,
  ratedKitchens: [] as any,
  singleKitchen:{} as any,
  singleKitchenReviews:[] as any

}

const foodPreferenceSlice = createSlice({

    name: 'foodPreference',

    initialState: foodPreferenceInitialState,

    reducers: {

        setNumberOfGuests: setNumberOfGuestReducer,
        setDateForSearch: setDateForSearchReducer,
        setTimeForSearch: setTimeForSearchReducer,
        setHostForSearch: setHostForSearchReducer,
        setLocationForSearch: setLocationForSearchReducer,
        setCuisineForSearch: setCuisineForSearchReducer,
        setSelectedDietary:  setSelectedDietaryReducer,
        setSelectedOffers:  setSelectedOffersReducer,
        setSelectedFacilities:  setSelectedFacilitiesReducer,
        setSelectedFacilitiesForDisables:  setSelectedFacilitiesForDisablesReducer,
        setSelectedFacilitiesForChildren:  setSelectedFacilitiesForChildrenReducer,
        setSelectedHouseRules:  setSelectedHouseRulesReducer,
        setSelectedHostLanguageProficiences:  setSelectedHostLanguageProficiencesReducer,
        setSelectedPrice:  setSelectedPriceReducer,
        setGeoLocationCoordinate:  setGeoLocationCoordinateReducer,
    },

    
    extraReducers(builder) {
        searchFoodPreferencesByLocationThunkPendingReducer(builder, searchFoodPreferencesByLocationThunk)
        searchFoodPreferencesByLocationThunkFulfilledReducer(builder, searchFoodPreferencesByLocationThunk)
        searchFoodPreferencesByLocationThunkRejectedReducer(builder, searchFoodPreferencesByLocationThunk)

        searchFoodPreferencesByHostThunkPendingReducer(builder, searchFoodPreferencesByHostThunk)
        searchFoodPreferencesByHostThunkFulfilledReducer(builder, searchFoodPreferencesByHostThunk)
        searchFoodPreferencesByHostThunkRejectedReducer(builder, searchFoodPreferencesByHostThunk)

        searchFoodPreferencesByCuisinesThunkPendingReducer(builder, searchFoodPreferencesByCuisinesThunk)
        searchFoodPreferencesByCuisinesThunkFulfilledReducer(builder, searchFoodPreferencesByCuisinesThunk)
        searchFoodPreferencesByCuisinesThunkRejectedReducer(builder, searchFoodPreferencesByCuisinesThunk)

        searchlocationforFoodPreferenceLocationSearchThunkPendingReducer(builder, searchlocationforFoodPreferenceLocationSearchThunk)
        searchlocationforFoodPreferenceLocationSearchThunkFulfilledReducer(builder, searchlocationforFoodPreferenceLocationSearchThunk)
        searchlocationforFoodPreferenceLocationSearchThunkRejectedReducer(builder, searchlocationforFoodPreferenceLocationSearchThunk)

        getSelectedFoodPreferenceLocationSearchDetailsThunkPendingReducer(builder, getSelectedFoodPreferenceLocationSearchDetailsThunk)
        getSelectedFoodPreferenceLocationSearchDetailsThunkFulfilledReducer(builder, getSelectedFoodPreferenceLocationSearchDetailsThunk)
        getSelectedFoodPreferenceLocationSearchDetailsThunkRejectedReducer(builder, getSelectedFoodPreferenceLocationSearchDetailsThunk)
    
   
    },
})

export const    {   setNumberOfGuests, setDateForSearch, setTimeForSearch, setHostForSearch, setLocationForSearch,
                    setCuisineForSearch , setSelectedOffers, setSelectedDietary, setSelectedPrice, 
                    setSelectedHostLanguageProficiences, setSelectedFacilities,
                    setSelectedHouseRules, setSelectedFacilitiesForChildren, setSelectedFacilitiesForDisables,
                    setGeoLocationCoordinate,

                } = foodPreferenceSlice.actions;

export default foodPreferenceSlice.reducer;