import { createAsyncThunk } from "@reduxjs/toolkit";
import { DBSCHECKAPI, GETCONTRACTPDFAPI, LICENSEAPI, MYUSERPROFILEAPI, SIGNCONTRACTAPI } from "../../constants/axios-api-constant";
import { getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";
import { updatePersonalUserProfileStatusThunk } from "../personal-user/personal-user-thunk-actions";


export const addDbsCheckThunk = createAsyncThunk("id-verification/dbs-check",
  async ({ dbsFormData, openAlert, axiosInstance, personalUser, dispatch, setValue}: any, { rejectWithValue }) => {
    // console.log(dbsCheckData)
    // console.log(personalUser)
    try {
      const response = await patchDataAPI(DBSCHECKAPI, axiosInstance, dbsFormData);
      // console.log(response, 'response')
      const formatData = {
        _id: response?.data?.data?._id,
        dbsCheck: response?.data?.data?.dbsCheck
      }
      if (!personalUser?.profileCompletion?.idVerification?.completed) {
        // console.log('incomplete')
        dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'idVerification', openAlert }))
      }
      const responseData = {
        dbsCheckData: formatData
      };
      dispatch(getDbsCheckDataThunk({ axiosInstance , openAlert }))
      // console.log(response)
      setValue(2)
      openAlert("Dbs Data saved successfully", 'alert-success-message')

      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }

      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)
export const addlicenseThunk = createAsyncThunk("id-verification/license",
  async ({ addLicenseData, openAlert, axiosInstance, setValue , dispatch, personalUser }: any, { rejectWithValue }) => {
    // console.log(!personalUser?.profileCompletion?.license?.completed)
    try {
      const response = await postDataAPI(LICENSEAPI, axiosInstance, addLicenseData);

      const responseData = {
        message: response.data.message,
      };
      // console.log('license',response)
      if (!personalUser?.profileCompletion?.licenses?.completed) {
        // console.log('incomplete')
        dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'licenses', openAlert }))
      }
      openAlert(responseData.message, 'alert-success-message')
      setValue(3);
      return responseData;
    } catch (error: any) {
      // console.log(error)

      // const errorData = {
      //   error: error.response.data?.errors?.[0] || error.response.data?.message || 'server is not responsding'
      // }
      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }

      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)

export const sentContractThunk = createAsyncThunk("id-verification/send-contract",
  async ({ axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(SIGNCONTRACTAPI, axiosInstance);
      // console.log(response)
      const formatData = response?.data?.data;
      const responseData = {
        dbsCheckData: formatData?.dbsCheck,
        message:response?.data?.message
      };
      // console.log(response)
      // console.log({responseData})
      openAlert(responseData.message, 'alert-success-message')

      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }

      openAlert(errorData?.error)

      return rejectWithValue(errorData)
    }
  }
)

export const myUserProfileDataThunk = createAsyncThunk("id-verification/user-profile",
  async ({ axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(MYUSERPROFILEAPI, axiosInstance);
      const formatData = response?.data?.data
      const responseData = {

        veriffStatus: formatData?.veriffStatus,
        contractBookData:formatData?.contractBookData,
        message:response.data.message

      };
      // console.log(response)
      // console.log({responseData})
      // openAlert(responseData.message, 'alert-success-message')

      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }

      openAlert(errorData?.error)

      return rejectWithValue(errorData)
    }
  }
)
export const getDbsCheckDataThunk = createAsyncThunk("id-verification/get-dbs-data",
  async ({ axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(DBSCHECKAPI, axiosInstance);
      // console.log(response, 'response')

      const formatData = response?.data?.data
      const responseData = {

        dbsCheckData: formatData,
        veriffStatus: formatData?.veriffStatus
      };
      // console.log(response)
      // console.log({responseData})

      // openAlert(responseData.message, 'alert-success-message')

      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }

      // console.log(errorData.error !== "Forbidden" )
      errorData.error !== "Forbidden" && openAlert(errorData?.error)

      return rejectWithValue(errorData)
    }
  }
)
export const getDbsCheckDataFileThunk = createAsyncThunk("id-verification/get-dbs-data-file",
  async ({ axiosInstance, openAlert , setShowDbsImage, SetIdCoverPhoto}: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(DBSCHECKAPI, axiosInstance);
      const formatData = response?.data?.data
      const responseData = {

        dbsCheckData: formatData,
        veriffStatus: formatData?.veriffStatus
      };
      // console.log(response)
      // console.log({responseData})

     !!formatData?.dbsCheck?.file?.length &&  SetIdCoverPhoto([formatData?.dbsCheck?.file])
     !!formatData?.dbsCheck?.file?.length &&  setShowDbsImage?.(true)
      // openAlert("dbs file got", 'alert-success-message')
      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }

      openAlert(errorData?.error)

      return rejectWithValue(errorData)
    }
  }
)

export const getLicenseDataThunk = createAsyncThunk("id-verification/get-license-data",
  async ({ axiosInstance, openAlert, setShowPremisesImage , setPermisesPermissionImage }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(LICENSEAPI, axiosInstance);
      const formatData = response?.data?.data
      const responseData = {

        // licenseData: formatData?.license,
        licenseData: {
          UTRNumber: formatData?.license?.UTRNumber,
          havePremisesPermission: formatData?.license?.havePremisesPermission + "",
          haveUTRNumber: formatData?.license?.haveUTRNumber + "",
          premisesPermissionFile: formatData?.license?.premisesPermissionFile,
        },
        contractBookData : formatData?.contractBookData
      };
      // console.log(response)
      // console.log({responseData})
      // openAlert(responseData.message, 'alert-success-message')
      // formatData?.license?.havePremisesPermission && setPermisesPermissionImage?.([{url:'users/566e9f90-cc6f-465c-ab0e-963f835e75f8/kitchen/cover'}])
      formatData?.license?.premisesPermissionFile?.length && setPermisesPermissionImage?.([formatData?.license?.premisesPermissionFile])
      setShowPremisesImage && formatData?.license?.premisesPermissionFile?.length && setShowPremisesImage?.(true)
      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }
      // console.log(errorData.error !== "Forbidden" )
      errorData.error !== "Forbidden" && openAlert(errorData.error)
      // openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)
export const getLicenseDataPremisesFileThunk = createAsyncThunk("id-verification/get-license-premises-file",
  async ({ axiosInstance, openAlert, setShowPremisesImage , setPermisesPermissionImage }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(LICENSEAPI, axiosInstance);
      const formatData = response?.data?.data
      const responseData = {

        // licenseData: formatData?.license,
        licenseData: {
          UTRNumber: formatData?.license?.UTRNumber,
          havePremisesPermission: formatData?.license?.havePremisesPermission + "",
          haveUTRNumber: formatData?.license?.haveUTRNumber + "",
          premisesPermissionFile: formatData?.license?.premisesPermissionFile,
        },
        contractBookData : formatData?.contractBookData
      };
      // console.log(response)
      // console.log({responseData})
      // openAlert(responseData.message, 'alert-success-message')
      // formatData?.license?.havePremisesPermission && setPermisesPermissionImage?.([{url:'users/566e9f90-cc6f-465c-ab0e-963f835e75f8/kitchen/cover'}])
      formatData?.license?.premisesPermissionFile?.length && setPermisesPermissionImage?.([formatData?.license?.premisesPermissionFile])
      setShowPremisesImage && formatData?.license?.premisesPermissionFile?.length && setShowPremisesImage?.(true)
      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }
      // console.log(errorData.error !== "Forbidden" )
      errorData.error !== "Forbidden" && openAlert(errorData.error)
      // openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)

export const getContractPdfThunk = createAsyncThunk("id-verification/get-contract-pdf",
  async ({ userId ,axiosInstance, openAlert, setShowContract }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(`${GETCONTRACTPDFAPI}?userId=${userId}`, axiosInstance);
      const formatData = response?.data?.data
      const responseData = {

        contractPdfLink: formatData?.url,
        message:response.message
        // contractBookData : formatData?.contractBookData
      };
      // console.log(response)
      // console.log({responseData})
      openAlert(responseData.message, 'alert-success-message')
      setShowContract(true)
      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message || 'server is not responding'
      }

      openAlert(errorData?.error)

      return rejectWithValue(errorData)
    }
  }
)