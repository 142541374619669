import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { IMENUINTERFACE } from "../../features/menu/menu-items/menu-items-interface";
import {  
    addFoodItemThunkFulfilledReducer, addFoodItemThunkRejectedReducer, addMenuThunkFulfilledReducer, 
    addMenuThunkPendingReducer, 
    addMenuThunkRejectedReducer, clearSingleMenuReducer, deleteMenuImageThunkFulfilledReducer, deleteMenuImageThunkPendingReducer, deleteMenuImageThunkRejectedReducer, deleteMenuThunkFulfilledReducer, deleteMenuThunkRejectedReducer,
     editMenuThunkFulfilledReducer, editMenuThunkPendingReducer, editMenuThunkRejectedReducer, getMenuListsThunkFulfilledReducer, 
     getMenuListsThunkRejectedReducer, getSingleMenuThunkFulfilledReducer, getSingleMenuThunkRejectedReducer, 
     menuThunkPendingReducer } from "./menu-reducers";
import { 
    addFoodItem, addMenuThunk, deleteMenuImageThunk, deleteMenuThunk, editMenuThunk, getMenuListsThunk, getSingleMenuThunk 
} from "./menu-thunk-actions";


const menuInitialState : any = {
    id: 0,
    menuLists: [] as any,
    menusData: [] as any,
    singleMenu: {} as any,
    addFood: [] as any,
    status: REQUESTSTATUS.IDLE,
    upsertStatus: REQUESTSTATUS.IDLE,
    deleteMenuImageStatus: REQUESTSTATUS.IDLE,

}

const menuSlice = createSlice({
    name: 'menu',
    initialState: menuInitialState,
    reducers: {
        clearSingleMenu: clearSingleMenuReducer,
        setNewMenuData: (state, action: PayloadAction<IMENUINTERFACE>) => {
            state.menuLists = [...state.menuLists, action.payload]
        },
        setId: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        deleteMenu: (state) => {
            state.menuLists = state.menuLists.filter((menu:any) => menu.id !== state.id);
        },
        editMenuReducer: (state, action: PayloadAction<any>) => {
            const index = state.menuLists.findIndex((x:any) => x.id === action.payload.id);
            state.menuLists[index] = action.payload.editedMenu;
        },
        editFoodItem: (state, action: PayloadAction<any>) => {
            const menuIndex = state.menuLists.findIndex((x:any) => x.id === action.payload.menuId);
            const foodItemIndex = state.menuLists[menuIndex].foodItems.findIndex((x:any) => x.id === action.payload.foodItemId);
            state.menuLists[menuIndex].foodItems[foodItemIndex] = action.payload.editedFoodItem;
        },
        setNewFoodItem: (state, action: PayloadAction<any>) => {
            state.menuLists[action.payload.menuId].foodItems = [...state.menuLists[action.payload.menuId].foodItems, action.payload.newFoodItem];
        },
        deleteFoodItem: (state, action: PayloadAction<any>) => {
            const menuIndex = state.menuLists.findIndex((x:any) => x.id === action.payload.menuId);
            state.menuLists[menuIndex].foodItems = state.menuLists[menuIndex].foodItems.filter((foodItem:any) => foodItem.id !== action.payload.foodItemId);
            
        }
    },
    extraReducers(builder){
        menuThunkPendingReducer(builder,getMenuListsThunk)
        getMenuListsThunkRejectedReducer(builder,getMenuListsThunk)
        getMenuListsThunkFulfilledReducer(builder,getMenuListsThunk)


        addMenuThunkPendingReducer(builder,addMenuThunk)
        addMenuThunkRejectedReducer(builder,addMenuThunk)
        addMenuThunkFulfilledReducer(builder,addMenuThunk)


        menuThunkPendingReducer(builder,getSingleMenuThunk)
        getSingleMenuThunkRejectedReducer(builder,getSingleMenuThunk)
        getSingleMenuThunkFulfilledReducer(builder,getSingleMenuThunk)


        menuThunkPendingReducer(builder,deleteMenuThunk)
        deleteMenuThunkRejectedReducer(builder,deleteMenuThunk)
        deleteMenuThunkFulfilledReducer(builder,deleteMenuThunk)


        editMenuThunkPendingReducer(builder,editMenuThunk)
        editMenuThunkRejectedReducer(builder,editMenuThunk)
        editMenuThunkFulfilledReducer(builder,editMenuThunk)

        deleteMenuImageThunkPendingReducer(builder, deleteMenuImageThunk)
        deleteMenuImageThunkFulfilledReducer(builder, deleteMenuImageThunk)
        deleteMenuImageThunkRejectedReducer(builder, deleteMenuImageThunk)

        addFoodItemThunkFulfilledReducer(builder, addFoodItem)
        addFoodItemThunkRejectedReducer(builder, addFoodItem)
    }
})


export const {  clearSingleMenu  ,editMenuReducer, setNewMenuData, setId, deleteMenu, 
    editFoodItem, setNewFoodItem, deleteFoodItem } = menuSlice.actions
export default menuSlice.reducer;