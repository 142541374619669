import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const postbadgesThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.error = ''
            state.status = REQUESTSTATUS.SUCCEEDED
            state.success = payload.success;
            state.selectedBages = payload
        });
}

export const postbadgesThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.isLoading = false;
            state.error = payload.error;
            state.status = REQUESTSTATUS.FAILED
            state.token = ''
            state.success = ''
        });
}

export const postbadgesThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.status = REQUESTSTATUS.PENDING
    });
}