import { useEffect, useState } from 'react'
// MUI
import { Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
// Interface
// Component
import RadioButton from '../radio-button/radio-button';
import { offersData } from './offers-data';
import { useAppDispatch } from '../../../../../../store/store';
import { setSelectedOffers } from '../../../../../../store/food-preference/food-preference-slice';

const MenuProps = {
  PaperProps: {
    sx: {
      width: 'auto',
      borderRadius: '10px',
      // p: '1rem',
      maxHeight:'240px',
      mx: {xs:'0rem', md:'4rem'},
      my: '1rem',
      color: '#E0E0E0',
      background: '#333333',
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "inherit !important"
      },
    },
  },
};

const Offers = () => {

  const [offers, setOffers] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const handleOffers = (event: SelectChangeEvent<typeof offers>) => {
    const { target: { value } } = event;
    setOffers(typeof value === 'string' ? value.split(',') : value);
  };
  useEffect(() => {
    dispatch(setSelectedOffers(offers))
  },[offers?.length])

  return (
    <>
      {/* <FormControl sx={{ ml: 1 }}> */}
        <Select
          sx={
            { 
              borderRadius: '45px', 
              // width: '118.73px', 
              height: '36.24px' 
            }}
          fullWidth
          displayEmpty
          multiple
          value={offers}
          onChange={handleOffers}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span className='tabs-span'>Offers</span>;
            }

            return <span className='tabs-span'>Offers</span>;
          }}
          MenuProps={MenuProps}
        >
          {/* {offersData.map((offer) => (
            <MenuItem key={offer.value} value={offer.offerName}>
              <FormControlLabel
                value={offer.offerName}
                control={<RadioButton />}
                label={
                  <div className="offer-name tertiary-title flex">
                    <ListItemText sx={{ ml: "8px" }} primary={offer.offerName} />
                  </div>
                }
              />
            </MenuItem>
          ))} */}
          {offersData.map((offer) => (
            <MenuItem 
            sx={{ pb: .5 }} 
            key={offer.value} value={offer.offerName} onClick={(e: any) => handleOffers(e)}>
              <Checkbox checked={offers.indexOf(offer.offerName) > -1}
                sx={{
                  pr: .5,
                  m: 0,
                  color: '#E0E0E0 !important',
                  "&.Mui-checked": { color: '#FF611D !important' },
                }}
              />
              <ListItemText sx={{ p: 0, ml: 1 }} primary={offer.offerName} />
              {/* <FormControlLabel
                value={offer.offerName}
                control={<RadioButton />}
                label={
                  <div className="offer-name tertiary-title flex">
                  </div>
                }
              /> */}
            </MenuItem>
          ))}
        </Select>
      {/* </FormControl> */}
    </>
  )
}

export default Offers