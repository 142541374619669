import { createAsyncThunk } from "@reduxjs/toolkit"
import { REFERENCEDATAAPI } from "../../constants/axios-api-constant"
import { getDataAPI } from "../../lib/axios"
import { saveDataToLocalStorage } from "../../utils/views-utils"

export const referenceDataThunk = createAsyncThunk('referenceData/reference-data',
    async ({ axiosInstance , openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(REFERENCEDATAAPI, axiosInstance)
            const formatData = response?.data?.data
            const responseData = {
                ribbons: formatData?.ribbons,  // store access token 
                badges: formatData?.badges,  // store refresh token
                allergens: formatData?.allergens,          // store user details
                food_types: formatData?.food_types,
                dietary_attribures: formatData?.dietary_attribures,   // store success message
                cuisines: formatData?.cuisines,
                bookingStatus: formatData?.booking_statuses,
                userAuditTypes: formatData?.user_audit_types,
                userAuditStatues: formatData?.user_audit_statues,
                userAuditFilterStatuses: formatData?.user_audit_filter_statuses,
                facilities: formatData?.facilities,
                houseRules: formatData?.house_rules,
            }

            saveDataToLocalStorage('smd-reference-data', responseData)
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
              }
              openAlert?.(errorData?.error)
            return rejectWithValue(errorData)
        }
    }
)