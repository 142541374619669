import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { IAUTHSTATE } from "./auth-slice-interface";

// need to remove this also
export const setLoginSuccessReducer = (state: IAUTHSTATE, action: any) => {
  state.user = action.payload.user;
  state.token = action.payload.token;
  state.refreshToken = action.payload.refreshToken;
  state.status = REQUESTSTATUS.SUCCEEDED;
  state.error = "";
  state.success = action.payload.success;
  state.role = action.payload.role;
};

export const setUploadProfileImageReducer = (
  state: IAUTHSTATE,
  action: any
) => {
  state.setUploadProfileImage = action.payload;
};

export const setProfileImageReducer = (state: IAUTHSTATE, action: any) => {
  state.user!.profileImage = action.payload;
};

export const setUserDataReducer = (state: IAUTHSTATE, action: any) => {
  // state.user = action.payload;

  state.user!.userId = action.payload.userId;
  state.user!.gender = action.payload.gender;
  state.user!.country = action.payload.country;
  state.user!.dob = action.payload.dob;
  state.user!.city = action.payload.city;
  state.user!.address = action.payload.address;
  state.user!.aboutMe = "";
  state.user!.language = action.payload.language;
  state.user!.firstName = action.payload.firstName;
  state.user!.lastName = action.payload.lastName;
  state.user!.phoneNumber = action.payload.phoneNumber;
};

export const ThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: IAUTHSTATE) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const loginThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.token = payload.token;
    state.success = payload.success;
    state.role = payload.role;
    state.error = payload?.error ?? "";
  });
};

export const loginThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload }, { state });
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error || "server is not responding";
    state.user = null;
    state.token = "";
    state.success = "";
  });
};

export const logoutThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload }, { state });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = null;
    state.token = "";
    state.success = payload.success;
    state.error = "";
  });
};

export const logoutThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.token = "";
  });
};

export const changePasswordThunkFulfillReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload;
  });
};

export const changePasswordThankRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    // console.log(payload)
    state.status = REQUESTSTATUS.FAILED;
    // state.error = payload.error;
    state.error = payload?.error;
  });
};

export const registerThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    // state.token = payload.token;
    state.error = "";
    state.success = payload.success;
    state.userEmail = payload.userEmail;
  });
};

export const registerThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    // console.log(payload)
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.user = null;
    state.token = "";
    state.success = "";
    state.userEmail = payload.userEmail;
  });
};

export const updateUserDetailsThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload;
    state.error = "";
  });
};

export const updateUserDetailsThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, action: any) => {
    // console.log(action);
    state.status = REQUESTSTATUS.FAILED;
    state.error = action.payload as string;
    // state.user = null
    // state.token = ''
  });
};

// forgot password
export const forgotPasswordThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.token = payload.token;
    state.success = payload.success;
    state.role = payload.role;
    state.error = "";
  });
};

export const forgotPasswordThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.user = null;
    state.token = "";
    state.success = "";
  });
};
//resend link
export const resendLinkThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload.message;
    state.error = "";
  });
};

export const resendLinkThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.success = "";
  });
};
//Verify Email
export const verifyEmailThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload.message;
    state.error = "";
  });
};

export const verifyEmailThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.success = "";
  });
};
// reset-password
export const resetPasswordThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.error = "";
  });
};

export const resetPasswordThunkRejectReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    // console.log(payload.error);
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    // console.log(state.message);
  });
};

export const refreshTokenThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.token = payload.token;
    state.success = payload.success;
    state.role = payload.role;
    state.error = "";
  });
};

export const refreshTokenThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    // console.log('refresh ', payload)
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.user = null;
    state.token = "";
    state.success = "";
  });
};
//------------//
export const VerificationStatusThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    // console.log({ payload });
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.verifyStatus = payload?.data?.data?.status;
    // state.verifyStatus = payload?.verifyStatus;
    state.isVerified = payload?.data?.data?.isVerified;
    state.verificationRole = payload?.data?.data?.role;
  });
};

export const VerificationStatusThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    // console.log('refresh ', payload)
    state.status = REQUESTSTATUS.FAILED;
    state.verifyStatus = payload?.error || "Error";
    state.error = payload?.error;
  });
};
