import { useAppDispatch, useAppSelector } from "../../store/store";

export const useHeader = () => {
    const { openDrawer } = useAppSelector((state) => state.layout);
    const { user } = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch();


    return {
        openDrawer, user, dispatch
    }
}