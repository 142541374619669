import { useState } from "react";
// MUI
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
// SCSS
// import "./guest.scss";

// Images
import personImage from "../../../../../../assets/images/food-preferences/header/person.png";
import { useAppDispatch } from "../../../../../../store/store";
import { setNumberOfGuests } from "../../../../../../store/food-preference/food-preference-slice";
import { MUIICONCOLOR, MUIPRIMARYCOLOR, MUIWHITECOLOR } from "../../../../../../constants/mui-styles-constant";


const MenuProps = {
  PaperProps: {
    sx: {
      width: "auto",
      borderRadius: "10px",
      p: "0.5rem",
      // mx: "1rem",
      my: "2rem",
      color: "#FAFAFA",
      background: "#333333",
      maxHeight: "240px",
      overflowY: 'scroll',

      // '&::-webkit-scrollbar': {
      //   height: '90px !important',
      //   width: '4px',
      //   // background: 'rgba(131, 164, 249, 0.15)',
      //   // borderRadius: '100px !important',
      // },

      // '&::-webkit-scrollbar-thumb': {
      //   // background: '#FFCE81 !important',
      //   borderRadius: '50px !important',
      // },

      "& .MuiMenuItem-root": {
        px: 1,
        borderRadius: '10px'
      },
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "#333333 !important",
        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.08)'
      },
    },
  },
};

const numberOfGuestsData = [
  { id: 1, value: "01" }, { id: 2, value: "02" }, { id: 3, value: "03" }, { id: 4, value: "04" }, { id: 5, value: "05" },
  { id: 6, value: "06" }, { id: 7, value: "07" }, { id: 8, value: "08" }, { id: 9, value: "09" }, { id: 10, value: "10" },
]

// Component Function Starts Here
const Guest: React.FC<any> = (props: any) => {
  const { numberOfGuest, setNumberOfGuest }: any = props;
  const dispatch = useAppDispatch()
  // Handle Change
  const handleGuestChange = (event: SelectChangeEvent) => {
    setNumberOfGuest(event.target.value as string);
    dispatch(setNumberOfGuests(event.target.value))
  };

  return (
    <Select
      fullWidth
      className="guest-select"
      value={numberOfGuest}
      name="Guest"
      displayEmpty
      // label="Guest"
      size="small"
              
      sx={{
        color:'white',
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${MUIICONCOLOR} !important`,
          borderRadius:'56px',
          "& > fieldset": {
            borderColor: `${MUIICONCOLOR} !important`
          },
        },
        "& .MuiSelect-icon":{
          color:'white'
        },
        "& .MuiOutlinedInput-notchedOutline.Mui-focused": {
          borderColor: MUIPRIMARYCOLOR,
          "& > fieldset": {
            borderColor: MUIPRIMARYCOLOR
          }
        },
        "& .MuiOutlinedInput-notchedOutline:hover": {
          borderColor: MUIPRIMARYCOLOR,
          "& > fieldset": {
            borderColor: MUIPRIMARYCOLOR
          }
        },
     
      }}
      onChange={handleGuestChange}
      inputProps={{color:'white'}}
      MenuProps={MenuProps}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <span className="guest-span" >
            <img className="icon-img" src={personImage} alt="guest-img" />  Guests</span>;
        }
        return <span className="guest-span-sel" >
          <img className="icon-img" src={personImage} alt="guest-img" />{selected} Guests</span>;
      }}
    >
      {/* <div className="guest-scroll"> */}
      {
        numberOfGuestsData.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              <span style={{ fontSize: '12px' }}>{item.id}</span>
            </MenuItem>)
        })
      }
      {/* </div> */}
    </Select>
  );
};
export default Guest;
