import { axiosInstance , axiosRefresh } from "../lib/axios";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/store";
import { getDataFromStorage } from "../utils/views-utils";
import { refreshTokenThunk } from "../store/auth/auth-thunk-actions";
import { useNavigate } from "react-router-dom";

export const useAxiosInterceptors = () => {
    const { auth } = useAppSelector(state => state);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authToken = getDataFromStorage('auth')
    // console.log(authToken)

    useEffect(() => {
        const requestIntercept = axiosInstance.interceptors.request.use(
            (config:any) => {
                // console.log(config)
                if (!!authToken?.token) {
                    // console.log(authToken)
                    config.headers['Authorization'] = `Bearer ${authToken?.token}`
                }
                return config;
            }, (error:any) => Promise.reject(error)
        );

        const responseIntercept = axiosInstance.interceptors.response.use(
            (response:any) => response,
            async (error:any) => {
                // console.log(authToken?.token)
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    await dispatch(refreshTokenThunk({navigate, axiosRefresh}))
                    // console.log({auth})
                    // console.log(authToken)
                    prevRequest.headers['Authorization'] = `Bearer ${authToken?.token}`;
                    // console.log({prevRequest})
                    return axiosInstance(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.request.eject(requestIntercept);
            axiosInstance.interceptors.response.eject(responseIntercept);
        }
    }, [authToken?.token, axiosInstance])

    return axiosInstance;
}