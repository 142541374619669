

import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const postribbonThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.error = ''
            state.status = REQUESTSTATUS.SUCCEEDED
            state.success = payload.success;
            state.ribbonListData = payload
        });
}

export const postribbonThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.isLoading = false;
            state.error = payload.error;
            state.status = REQUESTSTATUS.FAILED
            state.token = ''
            state.success = ''
        });
}

export const postribbonThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.status = REQUESTSTATUS.PENDING
    });
}
export const getribbonThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(
        action.fulfilled, (state: any, { payload }: any) => {
            state.error = ''
            state.status = REQUESTSTATUS.SUCCEEDED
            state.ribbonListData = payload
        });
}

export const getribbonThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(
        action.rejected, (state: any, { payload }: any) => {
            state.isLoading = false;
            state.error = payload.error;
            state.status = REQUESTSTATUS.FAILED
            state.token = ''
            state.success = ''
        });
}

export const getribbonThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.status = REQUESTSTATUS.PENDING
    });
}