import { Navigate } from "react-router-dom";
import { getDataFromStorage } from "../utils/views-utils";

export const AuthProtect: React.FC<any> = (props: any) => {

  // const { auth } = useAppSelector((state) => state);
  const auth = getDataFromStorage('auth')

  return !auth?.token ? (
    <>{props.children}</>
  ) : (
    <Navigate to="/" />
  );
  // uncomment it if backend does not work
  // return <>{props.children}</>

};
