import { createAsyncThunk } from "@reduxjs/toolkit";
import { BOOKINGSAPI, KITCHENDETAILSAPI, LISTINGSAPI, LISTINGSBYHOSTAPI, RECOMMENDEDLISTINGSAPI, TOPRATEDKITCHENSAPI } from "../../constants/axios-api-constant";
import { getDataAPI, patchDataAPI } from "../../lib/axios";


export const getHostDashboardData = createAsyncThunk('host/dashboard',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI('dashboard/host/dashboard-detail', axiosInstance);

            const responseData = {
                data: response?.data
            }

            return responseData.data
        } catch (error) {
            // console.log(error);
            const errorData = {
                error: 'Server is not responding'
            }
            return rejectWithValue(errorData)
        }
    }
)

export const getComplianceDashboardData = createAsyncThunk('compliance/dashboard',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI('/dashboard/compliance-officer', axiosInstance);
            // console.log(response.data.data, '🍔');

            const responseData = {
                data: response.data.data
            }

            return responseData.data;

        } catch (error) {
            // console.log(error);
            const errorData = {
                error: 'Server is not responding'
            }
            return rejectWithValue(errorData)
        }
    }
)
export const boostMyListingThunk = createAsyncThunk('dashboard/boost-listing',
    async ({ boostMyListingCredentials, kitchenId, openAlert, axiosInstance, setHandleSuccessOpen, handleSuccessClose }: any, { rejectWithValue }) => {

        try {

            const response = await patchDataAPI(`user-profile/boost-listing/${kitchenId}`, axiosInstance, boostMyListingCredentials)

            const responseData = {
                boostMyListing: response.data.message
            }
            setHandleSuccessOpen(true);

            setTimeout(handleSuccessClose, 3000);
            openAlert(responseData?.boostMyListing, 'alert-success-message')

            return responseData;
        } catch (error: any) {
            // console.log(error)
            const errorData = {
                error: error?.response?.data?.message || ' server is not responding '
            }
            openAlert(errorData?.error)

            return rejectWithValue(errorData)
        }
    })

export const boostListingGuestThunk = createAsyncThunk('guest/boost-listing-guest',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(RECOMMENDEDLISTINGSAPI, axiosInstance);
            // console.log(response)
            const responseData = {
                data: response.data.data
            }

            return responseData.data
        } catch (error) {
            // console.log(error);
            const errorData = {
                error: 'Server is not responding'
            }
            return rejectWithValue(errorData)
        }
    }
)

//     // top menu Thunk

export const getTopMenuDashboardData = createAsyncThunk('dashboard/top-rate-menu',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI('menus/menu-items/trending', axiosInstance);
            // console.log(response );

            const responseData = {
                data: response.data.data
            }

            return responseData.data;

        } catch (error) {
            // console.log(error);
            const errorData = {
                error: 'Server is not responding'
            }
            return rejectWithValue(errorData)
        }
    }
)
export const getTopRatedKitchensThunk = createAsyncThunk('dashboard/top-rated-kitchens',
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(TOPRATEDKITCHENSAPI, axiosInstance);
            // console.log(response );

            const responseData = {
                kitchenData: response.data.data
            }

            return responseData;

        } catch (error) {
            // console.log(error);

            const errorData = {
                error: 'Server is not responding'
            }
            return rejectWithValue(errorData)
        }
    }
)

export const getSingleKitchensDetailsThunk = createAsyncThunk('dashboard/single-kitchen-detail',
    async ({ kitchenId, axiosInstance, dispatch }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`${KITCHENDETAILSAPI}/${kitchenId}/guest`, axiosInstance);
            // console.log(response );
            const formatData = response?.data?.data;
            const { userId, aboutMe, avgRating, firstName, lastName , createdAt,profileImage, 
                    veriffStatus,totalReview} = formatData?.limitedHostDetails?.data
            const { badgesId, coverPhotos, houseRules, language, myFacilities, name , servingDays, userRating, userId:userID,selectedCoverPhoto, location } = formatData?.limitedKitchenDetails?.data
            dispatch(getRatingsByIdThunk({axiosInstance, userId}))
            // dispatch(getBestListingsByUserIdThunk({axiosInstance, userId}))
            
            const responseData = {
                singleKitchen:  {
                    userId,
                    aboutMe,
                    avgRating , 
                    firstName,
                    lastName,
                    totalReview,
                    createdAt,
                    profileImage, 
                    veriffStatus,
                    badgesId,
                    coverPhotos,
                    houseRules,
                    language,
                    myFacilities, 
                    KitchenName:name,
                    servingDays,
                    userRating,
                    selectedCoverPhoto,
                    location
                }
            }

            return responseData;

        } catch (error) {
            // console.log(error);

            const errorData = {
                error: 'Server is not responding'
            }
            return rejectWithValue(errorData)
        }
    }
)


export const getRatingsByIdThunk = createAsyncThunk("dashboard/get-top-reviews",
  async ({ axiosInstance, userId }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${BOOKINGSAPI}/getrating?userId=${userId}&limit=2&offset=0`, axiosInstance);
    //   console.log(response);
      const formatData = response?.data?.data
      const responseData = {
        topReviews : formatData.reviews
      }
      return responseData;

    } catch (error: any) {
    //   console.log(error)
      const errorData = {
        error: error.response.data.message,
      };

      return rejectWithValue(errorData);
    }
  }
);

export const getBestListingsByUserIdThunk = createAsyncThunk("dashboard/get-best-listings",
  async ({ axiosInstance, userId }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${LISTINGSBYHOSTAPI}?hostId=${userId}&limit=6&offset=0`, axiosInstance);
    //   console.log(response);
      const formatData = response?.data?.data
      const responseData = {
        bestListings : formatData.listings
      }
      return responseData;

    } catch (error: any) {
    //   console.log(error)
      const errorData = {
        error: error.response.data.message,
      };

      return rejectWithValue(errorData);
    }
  }
);