import { useState } from "react";
import { USERROLES } from "../../../../constants/roles-constant";
import { useAppSelector } from "../../../../store/store";
import { ICOLAPSABLETABLE } from "./collapsable-table-interface";


export const useCollapseTable = (props: ICOLAPSABLETABLE) => {
    const { auth } = useAppSelector((state) => state);
    const ISGUEST = auth.role.includes(USERROLES.GUEST);
    const { SetTimeBookingCollapse, bookingData, createdDate, handleOpenBookingDetailOrRejectionModal, index, randomColor,IS_SUPERADMIN } = props;
    const [expandTable, setexpandTable] = useState<boolean>(true);
    return {
        SetTimeBookingCollapse, bookingData, createdDate, handleOpenBookingDetailOrRejectionModal, index, randomColor, expandTable, setexpandTable, ISGUEST,IS_SUPERADMIN
    };
};