import { createAsyncThunk } from "@reduxjs/toolkit";
import { GETCONTRACTPDFAPI, HOSTDETAILSFORAUDITSAPI, HOSTDETAILSIDVERFICATIONFORAUDITSAPI, HOSTDETAILSKITCHENDETAILSFORAUDITSAPI, HOSTDETAILSLICENSEINFOFORAUDITSAPI, HOSTDETAILSRIBBONSFORAUDITSAPI, HOSTDETAILSTRAININGSFORAUDITSAPI, USERDETAILSFORADMINVIEW } from "../../constants/axios-api-constant";
import { getDataAPI } from "../../lib/axios";
import { dayjsFormatDate } from "../../lib/dayjs";
import { groupBy } from "../../utils/hooks-utils";

export const hostpersonalInfoThunk = createAsyncThunk("user-details-admin-view/get-personal-info",
  async ({ hostId, openAlert, axiosInstance }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${USERDETAILSFORADMINVIEW}/${hostId}`, axiosInstance);
      // console.log(response)
      const responseData = {
        message: response?.data?.message,
      };

      openAlert(responseData.message, 'alert-success-message')

      return responseData;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData?.error || 'Server is not responding. Please Try Again Later')

      return rejectWithValue(errorData)
    }
  }
)
export const hostKitchenDetailsInfoThunk = createAsyncThunk("user-details-admin-view/get-kitchen-details-info",
  async ({ hostId, openAlert, axiosInstance , referenceData }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${USERDETAILSFORADMINVIEW}/${hostId}/${HOSTDETAILSKITCHENDETAILSFORAUDITSAPI}`,
        axiosInstance);
      // console.log(response)
      const formatData = response?.data?.data
      const { userId ,firstName, lastName, email, address, city, country, gender, phoneNumber, dob, language , profileImage } = formatData?.personal
      const { name, selectedCoverPhoto, servingDays, coverPhotos, badgesId, myFacilities, houseRules, servingTimeFrom,
        servingTimeTo, location
      } = formatData?.kitchen
 
      const responseData = {
        userId: userId,
        personalInfoDetails: {
          'Full Name': `${firstName} ${lastName}`,
          'Email Address': email,
          'Phone Number': phoneNumber,
          'Gender': gender,
          'Date of Birth':dayjsFormatDate(dob, 'MM/DD/YYYY'),
          'Address': address,
          'City': city,
          'Country': country,
          'Languages You Speak': language,
        },
        kitchenDetails: {
          'KitchenName': name,
          'KitchenCoverPhoto': selectedCoverPhoto,
          'KitchenOtherPhotos': coverPhotos,
          'KitchenLocation': location?.address?.locationName,
          'ServingDays': servingDays,
          'ServingTo': `To ${servingTimeTo}`,
          'ServingFrom': `From ${servingTimeFrom}`,
        },
        badgesDetails: badgesId,
        facilitiesDetails: {
          numberOfGuests: myFacilities?.numberOfGuest,
          facilities: myFacilities?.icons
        },
        houseRulesDetails:houseRules,
        hostSummaryDetails: {
          fullName: `${firstName} ${lastName}`,
          kitchenName: name,
          phoneNumber: phoneNumber,
          address: address,
          totalOrder: formatData?.personal?.totalOrders || 0,
          totalEarnings: formatData?.personal?.totalEarnings|| 0,
          ratings: formatData?.personal?.avgRating || 0,
          aboutMe: formatData?.personal?.aboutMe || ' ',
          profileImage
        }
        // houseRulesDetails: [
        //   {
        //     name:'Food Restrictions',
        //     value: houseRules.foodRestrictionApplied,
        //     filter: houseRules.restrictedFoods
        //   },
        //   {
        //     name:'Smoking Permission',
        //     value:houseRules.smokingRestrictionApplied,
        //     filter: undefined
        //   },
        //   {
        //     name:'Dress Code',
        //     value:houseRules.dressCodeRestrictionApplied,
        //     filter:houseRules.dressCodeRestriction
        //   },
        //   {
        //     name:'Pets Restriction',
        //     value:houseRules.petsRestrictionApplied,
        //     filter:undefined
        //   }
        // ]
      };

      // openAlert('Host Details', 'alert-success-message')

      return responseData;
    } catch (error: any) {

      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData?.error || 'Server is not responding. Please Try Again Later')

      return rejectWithValue(errorData)
    }
  }
)
export const hostIdVerficationInfoThunk = createAsyncThunk("user-details-admin-view/get-id-verification-info",
  async ({ hostId, openAlert, axiosInstance , setShowDbsImage}: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${USERDETAILSFORADMINVIEW}/${hostId}/${HOSTDETAILSIDVERFICATIONFORAUDITSAPI}`,
        axiosInstance);
      // console.log(response)
      const formatData = response?.data?.data
      const responseData = {
        idVerificationDetails: {
          hasDbsCheck: formatData?.dbsCheck?.status + "",
          dbsCertificate: formatData?.dbsCheck?.file,
          isVerified: formatData?.veriffStatus?.action === 'success' ? true : false
        }
      };

      !!formatData.dbsCheck?.file?.length && setShowDbsImage?.(true)
      // openAlert('', 'alert-success-message')

      return responseData;
    } catch (error: any) {

      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData?.error || 'Server is not responding. Please Try Again Later')

      return rejectWithValue(errorData)
    }
  }
)
export const hostlicenseInfoThunk = createAsyncThunk("user-details-admin-view/get-license-info",
  async ({ hostId, openAlert, axiosInstance , setShowLicense }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${USERDETAILSFORADMINVIEW}/${hostId}/${HOSTDETAILSLICENSEINFOFORAUDITSAPI}`,
        axiosInstance);
      // console.log(response)

      const formatData = response?.data?.data;

      const responseData = {
        licenseInfoDetails: {
          utrNumber: formatData?.license?.UTRNumber,
          havePremisesPermission: formatData?.license?.havePremisesPermission + "",
          haveUTRNumber: formatData?.license?.haveUTRNumber + "",
          premisesPermissionFile: formatData?.license?.premisesPermissionFile,
          isContractSigned: formatData?.contractBookData?.status === 'signed' ? true : false,
          contractFile: formatData?.dbs_pdf_file,
          hostId: formatData?._id
        }
      };
      // openAlert(responseData.message, 'alert-success-message')
      !!formatData?.license?.premisesPermissionFile?.length && setShowLicense?.(true)
      return responseData;
    } catch (error: any) {
      // console.log(error)
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData?.error || 'Server is not responding. Please Try Again Later')

      return rejectWithValue(errorData)
    }
  }
)
export const hosttrainingsInfoThunk = createAsyncThunk("user-details-admin-view/get-trainings-info",
  async ({ hostId, openAlert, axiosInstance }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${USERDETAILSFORADMINVIEW}/${hostId}/${HOSTDETAILSTRAININGSFORAUDITSAPI}`,
        axiosInstance);
      // console.log(response)
      const formatData = response?.data?.data
      const responseData = {
        trainingsDetails: {
          trainingDetails: [
            {
              id: 0,
              trainingData: formatData?.mandotary
            },
            {
              id: 1,
              trainingData: formatData?.optional
            }
          ],
          totalTrainingsDetail: formatData?.totals
        },
      };

      // openAlert(responseData.message, 'alert-success-message')

      return responseData;
    } catch (error: any) {

      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData?.error || 'Server is not responding. Please Try Again Later')

      return rejectWithValue(errorData)
    }
  }
)
export const hostribbonsInfoThunk = createAsyncThunk("user-details-admin-view/get-ribbons-info",
  async ({ hostId, openAlert, axiosInstance }: any, { rejectWithValue }) => {
    try {

      const response = await getDataAPI(`${USERDETAILSFORADMINVIEW}/${hostId}/${HOSTDETAILSRIBBONSFORAUDITSAPI}`,
        axiosInstance);
      // console.log(response)
      const formatData = response?.data?.data
      const responseData = {
        ribbonsDetails: formatData,
      };

      // openAlert('', 'alert-success-message')

      return responseData;
    } catch (error: any) {

      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert(errorData?.error || 'Server is not responding. Please Try Again Later')

      return rejectWithValue(errorData)
    }
  }
)

export const getHostContractPdfThunk = createAsyncThunk("user-details-admin-view/get-contract-pdf",
  async ({ userId ,axiosInstance, openAlert, setShowContract }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(`${GETCONTRACTPDFAPI}?userId=${userId}`, axiosInstance);
      const formatData = response?.data?.data
      const responseData = {

        contractPdfLink: formatData?.url,
        message:response.message
        // contractBookData : formatData?.contractBookData
      };
      // console.log(response)
      // console.log({responseData})
      openAlert(responseData.message, 'alert-success-message')
      setShowContract?.(true)
      return responseData;
    } catch (error: any) {
      // console.log(error)

      const errorData = {
        error: error?.response?.data?.message
      }

      openAlert(errorData.error)

      return rejectWithValue(errorData)
    }
  }
)