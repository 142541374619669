import {
  IDROPDOWN,
  IEVENTSDATA,
} from "../features/calendar/mycalendar-data-interface";
import "../features/calendar/my-calendar.scss";

export const myCalendarEvents: IEVENTSDATA[]|any = [
  {
    id: "1",
    title: "Weekend Special",
    date: "2022-11-10",
    start: "2022-11-10",
    end: "2022-11-10",
    className: "blueish-bg ",
    // extendedProps:{ bookingId:'123' , type:'lunch'}
  },
  {
    id: "2",
    title: "Weekend Special",
    date: "2022-11-21",
    start: "2022-11-21T13:00:00",
    end: "2022-11-21T15:30:00",
    className: "blueish-bg ",
  },
  {
    id: "3",
    title: "Weekend Special",
    date: "2022-11-27",
    start: "2022-11-27T07:00:00",
    end: "2022-11-27T13:30:00",
    className: "blueish-bg ",
  },
  {
    id: "4",
    title: "Promotion Party",
    date: "2022-11-11",
    start: "2022-11-11T10:00:00",
    end: "2022-11-11T13:30:00",
    className: "blue-bg ",
  },
  {
    id: "5",
    title: "Promotion Party",
    date: "2022-11-18",
    start: "2022-11-18T1300:00",
    end: "2022-11-18T20:30:00",
    className: "blue-bg ",
  },
  {
    id: "6",
    title: "Promotion Party",
    date: "2022-11-28",
    start: "2022-11-28T11:00:00",
    end: "2022-11-28T19:30:00",
    className: "blue-bg ",
  },
  {
    id: "7",
    title: "Wedding Party",
    date: "2022-11-11",
    start: "2022-11-11T09:00:00",
    end: "2022-11-11T16:30:00",
    className: "parties-bg ",
    extendedProps:{
      bookingId:'123',
      type:'parties'
    }
  },
  {
    id: "8",
    title: "Wedding Party",
    date: "2022-11-09",
    start: "2022-11-09T06:00:00",
    end: "2022-11-09T14:30:00",
    className: "parties-bg ",
    extendedProps:{
      bookingId:'123',
      type:'parties'
    }
  },
  {
    id: "9",
    title: "Wedding Party",
    date: "2022-11-25",
    start: "2022-11-25T11:00:00",
    end: "2022-11-25T13:30:00",
    className: "parties-bg ",
    extendedProps:{
      bookingId:'123',
      type:'parties'
    }
  },
  {
    id: "10",
    title: "Shahi Nashta",
    date: "2022-11-12",
    start: "2022-11-12T12:00:00",
    end: "2022-11-12T13:30:00",
    className: "green-bg ",
  },
  {
    id: "11",
    title: "Shahi Nashta",
    date: "2022-11-02",
    start: "2022-11-02T13:00:00",
    end: "2022-11-02T13:30:00",
    className: "green-bg ",
  },
  {
    id: "12",
    title: "Shahi Nashta",
    date: "2022-11-06",
    start: "2022-11-06T13:00:00",
    end: "2022-11-06T13:30:00",
    className: "green-bg ",
  },
  {
    id: "13",
    title: "Hi Tea",
    date: "2022-06-26",
    start: "2022-06-26T13:00:00",
    end: "2022-06-26T13:30:00",
    className: "orange-bg ",
  },
  {
    id: "14",
    title: "Hi Tea",
    date: "2022-08-20",
    start: "2022-08-20T13:00:00",
    end: "2022-08-20T13:30:00",
    className: "orange-bg ",
  },
  {
    id: "15",
    title: "Hi Tea",
    date: "2022-08-03",
    start: "2022-08-03T08:00:00",
    end: "2022-08-01T08:30:00",
    className: "orange-bg ",
  },
  {
    id: "16",
    title: "Hi Tea",
    date: "2022-07-06",
    start: "2022-07-06T08:00:00",
    end: "2022-07-06T10:30:00",
    className: "orange-bg ",
  },
];

export const dropDownList: IDROPDOWN[] = [
  {
    id: "1",
    label: "Parties",
    type:'parties',
    className:'parties-bg'
  },
  {
    id: "2",
    label: "Dinner",
    type:'dinner',
    className:'dinner-bg'
  },
  {
    id: "3",
    label: "Brunch",
    type:'brunch',
    className:'brunch-bg'
  },
  {
    id: "4",
    label: "Breakfast",
    type:'breakfast',
    className:'breakfast-bg'
  },
  {
    id: "5",
    label: "Lunch",
    type:'lunch',
    className:'lunch-bg'
  },
  {
    id: "6",
    label: "Reminders",
    type:'reminder',
    className:'reminder-bg'
  },
];
//TODO :: make data for all the forms in  the application and table .
