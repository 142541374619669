import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  ANNOUNCEDAUDITAPI,
  GETCOMPLIANCEOFFICERSAPI,
  GETHOSTSFORAUDITAPI,
  INITIALAUDITAPI,
  KITCHENLOCATIONAPI,
  POSTANNOUNCEDAUDITAPI,
  POSTINITIALAUDITAPI,
  POSTUNANNOUNCEDAUDITAPI,
  UNANNOUNCEDAUDITAPI,
} from "../../constants/axios-api-constant";
import { getDataAPI, postDataAPI } from "../../lib/axios";

export const getInitialAuditsThunk = createAsyncThunk(
  "initial-audits",
  async (
    {
      axiosInstance,
      limit = 10,
      offset = 0,
      assigned = false,
      search,
      status = "All",
      createdAt,
      latitude,
      longitude,
      auditorId,
      hostId,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        assigned,
        search,
        status,
        createdAt,
        latitude,
        longitude,
        auditorId,
        hostId,
      };
      const response = await getDataAPI(
        // `${INITIALAUDITAPI}?limit=${limit || 10}&offset=${offset || 0}&assigned=${assigned}&search=${search}`,
        `${INITIALAUDITAPI}`,

        axiosInstance,
        {params}
      );
     
      const responseData = {
        initialAuditData: response.data.data.audits,
        meta: response.data.data.meta,
      };
      // console.log("Data comming from initial");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error.response.data.message ||
          "Server is not responding. Please Try Again Later",
      };
      return rejectWithValue(errorData);
    }
  }
);

export const getAnnouncedAuditsThunk = createAsyncThunk(
  "announced-audits",
  async (
    {
      axiosInstance,
      limit,
      offset,
      search,
      status,
      createdAt,
      latitude,
      longitude,
      auditorId,
      hostId,
    }: any,
    { rejectWithValue }
  ) => {
    const params = {
      limit,
      offset,
      search,
      status,
      createdAt,
      latitude,
      longitude,
      auditorId,
      hostId,
    };
    try {
      const response = await getDataAPI( 
        `${ANNOUNCEDAUDITAPI}`,
        axiosInstance,
        {params}
      );
      const responseData = {
        initialAuditData: response.data.data.audits,
        meta: response.data.data.meta,
      };
      // console.log("Data comming from Announced");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error.response.data.message ||
          "Server is not responding. Please Try Again Later",
      };
      return rejectWithValue(errorData);
    }
  }
);

export const getUnannouncedAuditsThunk = createAsyncThunk(
  "unannounced-audits",
  async (
    {
      axiosInstance,
      limit,
      offset,
      search,
      status,
      createdAt,
      latitude,
      longitude,
      auditorId,
      hostId,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        search,
        status,
        createdAt,
        latitude,
        longitude,
        auditorId,
        hostId,
      };
      const response = await getDataAPI(
        `${UNANNOUNCEDAUDITAPI}`,
        axiosInstance,
        {params}
      );
      const responseData = {
        initialAuditData: response.data.data.audits,
        meta: response.data.data.meta,
      };
      // console.log("Data comming from unAnnounced");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error.response.data.message ||
          "Server is not responding. Please Try Again Later",
      };
      return rejectWithValue(errorData);
    }
  }
);

export const getHostsForAuditsThunk = createAsyncThunk(
  "get-hosts-for-audits",
  async ({ axiosInstance, auditType, search }: any, { rejectWithValue }) => {
    try {
      const params={
        auditType, search
      }
      const response = await getDataAPI(
        `${GETHOSTSFORAUDITAPI}`,
        axiosInstance,
        {params}
      );
      // console.log("🚀 ~ file: audit-assignment-thunk-actions.tsx:188 ~ response", response)
      const responseData = {
        data: response.data.data,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error.response.data.message ||
          "Server is not responding. Please Try Again Later",
      };
      return rejectWithValue(errorData);
    }
  }
);

export const getComplianceOfficersThunk = createAsyncThunk(
  "get-cmp-officers",
  async ({ axiosInstance, search }: any, { rejectWithValue }) => {
    try {
      const params={
        search
      }
      const response = await getDataAPI(
        `${GETCOMPLIANCEOFFICERSAPI}`,
        axiosInstance,
        {params}
      );
      const responseData = {
        data: response.data.data.complianceOfficers,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error.response.data.message ||
          "Server is not responding. Please Try Again Later",
      };
      return rejectWithValue(errorData);
    }
  }
);

export const assignInitialAuditThunk = createAsyncThunk(
  "assign-initial-audits",
  async (
    { dispatch, auditCredentials, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        POSTINITIALAUDITAPI,
        axiosInstance,
        auditCredentials
      );
      dispatch(
        getInitialAuditsThunk({
          axiosInstance,
          limit: 10,
          offset: 0,
          assigned: "false",
          search: "",
        })
      );
      openAlert(response.data.data.message, "alert-success-message");
      return response.data.data.message;
    } catch (error: any) {
      const errorData = {
        error:
          error.response.data.message ||
          "Server is not responding. Please Try Again Later",
      };
      return rejectWithValue(errorData);
    }
  }
);

export const assignAnnouncedAuditThunk = createAsyncThunk(
  "assign-announced-audits",
  async (
    { dispatch, auditCredentials, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    // console.log("🚀 ~ file: audit-assignment-thunk-actions.tsx:272 ~ auditCredentials", auditCredentials)
    try {
      const response = await postDataAPI(
        POSTANNOUNCEDAUDITAPI,
        axiosInstance,
        auditCredentials
      );
      dispatch(
        getAnnouncedAuditsThunk({
          axiosInstance,
          limit: 10,
          offset: 0,
          search: "",
        })
      );
      openAlert(response.data.data.message, "alert-success-message");
      return response.data.data.message;
    } catch (error: any) {
      const errorData = {
        error:
          error.response.data.message ||
          "Server is not responding. Please Try Again Later",
      };
      openAlert(errorData.error);
      return rejectWithValue(errorData);
    }
  }
);
export const assignUnannouncedAuditThunk = createAsyncThunk(
  "assign-unannounced-audits",
  async (
    { dispatch, auditCredentials, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        POSTUNANNOUNCEDAUDITAPI,
        axiosInstance,
        auditCredentials
      );
      dispatch(
        getUnannouncedAuditsThunk({
          axiosInstance,
          limit: 10,
          offset: 0,
          search: "",
        })
      );
      openAlert(response.data.data.message, "alert-success-message");
      return response.data.data.message;
    } catch (error: any) {
      const errorData = {
        error:
          error.response.data.message ||
          "Server is not responding. Please Try Again Later",
      };
      openAlert(errorData.error);
      return rejectWithValue(errorData);
    }
  }
);


export const getLocationThunk = createAsyncThunk("audit-assignment/search-kitchen-location", 
async ({axiosInstance,searchParam , openAlert}:any, {rejectWithValue}:any) => {
    try {
        const response:any = await getDataAPI(`${KITCHENLOCATIONAPI}?search=${searchParam}`, axiosInstance)
        console.log(response)
        const formatData = response?.data?.data
        const responseData = {
            locations : formatData
        }
        return responseData
    } catch (error:any) {
        // console.log(error)
        const errorData = {
            error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
          }
          openAlert?.(errorData.error)
        rejectWithValue(errorData)
    }
}
)

export const getSelectedLocationDetailsThunk = createAsyncThunk("audit-assignment/selected-kitchen-location-details", 
async ({axiosInstance,locationId, openAlert}:any, {rejectWithValue}:any) => {
    // console.log(locationId)
    try {
        const response:any = await getDataAPI(`${KITCHENLOCATIONAPI}/${locationId}`, axiosInstance)
        // console.log(response)
        const formatData = response?.data?.data
        const responseData = {
            coordinates : formatData
        }
        return responseData
    } catch (error:any) {
        // console.log(error)
        const errorData = {
            error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
          }
          openAlert?.(errorData.error)
        rejectWithValue(errorData)
    }
}
)