import { createSlice } from "@reduxjs/toolkit";
// import { manageUsersTableData } from "../../features/manage-users/users-lists/users-lists-data";
import { rolesAndRightsTableData } from "../../features/manage-users/roles-and-rights/roles-and-rights-data";
import {
  addNewUserFromModal, updateSelectedUser,
  deleteManageUserSelectedUser, getMangeUsersFullfilledReducer, getMangeUsersRejectedReducer, getUsersSuccessReducer,
  getUsersRejectedReducer, getUsersPendingReducer, changeUserStatusRejectedReducer, changeUserStatusSuccessReducer, changeUserStatusPendingReducer,
  deleteUserRejectedReducer, deleteUserSuccessReducer, deleteUserPendingReducer, editUserRejectedReducer, editUserSuccessReducer, editUserPendingReducer,
  createNewUserRejectedReducer, createNewUserSuccessReducer, createNewUserPendingReducer, getUserRightsSuccessReducer, getUserRightsRejectedReducer,
  getUserRightsPendingReducer,
  postUserRightsRejectedReducer,
  postUserRightsSuccessReducer,
  postUserRightsPendingReducer,
  getBookingsByIdRejectedReducer,
  getBookingsByIdSuccessReducer,
  getBookingsByIdPendingReducer,
  getListingsByUserIdRejectedReducer,
  getListingsByUserIdSuccessReducer,
  getListingsByUserIdPendingReducer
}
  from "./manage-reducers";
import {
  changeUserStatusThunk, createNewUserThunk, deleteUserThunk, editUserThunk,
  getBookingsByIdThunk,
  getListingsByUserIdThunk,
  getMangeUsersThunk, getUserRightsThunk, getUsersThunk, postUserRightsThunk
} from "./manage-users-thunk-actions";

const initialState = {
  mangeUserCard: {
    Active: 0,
    inActive: 0,
    totaluser: 0
  },
  reminders: { title: '' },
  recentActivitiesData: [],
  bookingDataForAdmin: [],
  manageUsersData: [],
  rolesAndRightsData: rolesAndRightsTableData,
  status: 'idle',
  permissions: {},
  userToUpdate: {},
  listingDataForAdmin: [],
  bookingsData: [],
  pages: 0,
  page: 0
};

const manageSlice = createSlice({
  name: "manage",
  initialState,
  reducers: {
    addNewUser: addNewUserFromModal,
    editSelectedUser: updateSelectedUser,
    deleteManageUserSelecteUser: deleteManageUserSelectedUser
  },
  extraReducers(builder) {
    getMangeUsersFullfilledReducer(builder, getMangeUsersThunk)
    getMangeUsersRejectedReducer(builder, getMangeUsersThunk)

    getUsersRejectedReducer(builder, getUsersThunk)
    getUsersSuccessReducer(builder, getUsersThunk)
    getUsersPendingReducer(builder, getUsersThunk)


    changeUserStatusRejectedReducer(builder, changeUserStatusThunk)
    changeUserStatusSuccessReducer(builder, changeUserStatusThunk)
    changeUserStatusPendingReducer(builder, changeUserStatusThunk)


    deleteUserRejectedReducer(builder, deleteUserThunk)
    deleteUserSuccessReducer(builder, deleteUserThunk)
    deleteUserPendingReducer(builder, deleteUserThunk)

    editUserRejectedReducer(builder, editUserThunk)
    editUserSuccessReducer(builder, editUserThunk)
    editUserPendingReducer(builder, editUserThunk)


    createNewUserRejectedReducer(builder, createNewUserThunk)
    createNewUserSuccessReducer(builder, createNewUserThunk)
    createNewUserPendingReducer(builder, createNewUserThunk)


    getUserRightsRejectedReducer(builder, getUserRightsThunk)
    getUserRightsSuccessReducer(builder, getUserRightsThunk)
    getUserRightsPendingReducer(builder, getUserRightsThunk)


    postUserRightsRejectedReducer(builder, postUserRightsThunk)
    postUserRightsSuccessReducer(builder, postUserRightsThunk)
    postUserRightsPendingReducer(builder, postUserRightsThunk)


    getBookingsByIdRejectedReducer(builder, getBookingsByIdThunk)
    getBookingsByIdSuccessReducer(builder, getBookingsByIdThunk)
    getBookingsByIdPendingReducer(builder, getBookingsByIdThunk)


    getListingsByUserIdRejectedReducer(builder, getListingsByUserIdThunk)
    getListingsByUserIdSuccessReducer(builder, getListingsByUserIdThunk)
    getListingsByUserIdPendingReducer(builder, getListingsByUserIdThunk)
  }
});

export const { addNewUser,
  // editStatus, 
  editSelectedUser, deleteManageUserSelecteUser } = manageSlice.actions;
export default manageSlice.reducer;
