export const REQUESTSTATUS = {
  IDLE: "idle",
  PENDING: "pending",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};
export const BASEURLBACKEND = `${process.env.REACT_APP_BASE_URL_BACKEND}`;
export const IMAGEBASEURL = `${process.env.REACT_APP_BASE_IMAGE_URL}`;
// export const IMAGEBASEURL = "https://smd-s3-public-dev-001.s3.eu-west-2.amazonaws.com/"
export const LOGINAPI = "auth/signin";
export const LOGOUTAPI = "auth/signout";
export const SIGNUPAPI = "auth/signup";
export const CHANGEPASSWORDAPI = "auth/change-password";
export const REFRESHTOKENAPI = "auth/refresh-token";
export const REFERENCEDATAAPI = "reference-data";
export const KITCHENDETAILSAPI = "user-profile/kitchen-details";
export const HOUSERULESAPI = "user-profile/house-rules";
export const RESENDEMAILAPI = "auth/resend-link";
export const VERIFYEMAILAPI = "auth/verify-email";
export const VERIFICATIONSTATUS = "auth/verification-status";

export const FORGOTPASSWORDAPI = "auth/forgot-password";
export const TRAININGSAPI = "user-trainings";
export const FOODRESTRICTIONSAPI = "user-profile/house-rules/food-ingredients";
export const RESTRICTFOODAPI =
  "user-profile/house-rules/food-ingredients/restrict";
export const RESETPASSWORDAPI = "auth/confirm-forgot-password";
export const COMPLETETRAININGAPI = "user-trainings/mark-completed";
export const RIBBONSAPI = "user-ribbons";
export const BADGESAPI = "user-profile/kitchen-details/add-badges";

// bookings api
export const BOOKINGSAPI = "bookings";
export const BOOKINGSMYRATING = "booking/myrating";
export const SENDBOOKINGREQUEST = "/bookings/send-booking-request ";

export const OVERALLRATING = "bookings/myrating";

export const CALENDARREMINDERS = "calendar/reminders";
export const ADDCALENDARREMINDERS = "calendar/add-reminder";
export const LISTINGSAPI = "listings";
export const CHANGELISTINGSTATUS = "listings/change-listing-status";
export const DBSCHECKAPI = "user-profile/dbs-info";
export const MENUAPI = "menus";
export const MYUSERPROFILEAPI = "user-profile/my-profile";
export const LICENSEAPI = "user-profile/licenses";
export const SIGNCONTRACTAPI = "user-profile/sign-contract";
export const GUESTREVIEWAPI = "rate-guest";
export const ADDFOODITEMAPI = "menus/add-food-item";
export const FOODITEMAPI = "menus/food-item";
export const NUTRITIONSAPI = "menus/food-item/get-nutritional-info";
export const WISHLISTSAPI = "wishlist";

export const RECIPESAPI = "/recipes";

export const GETUSERCOUNTACTIVITYAPI =
  "dashboard/admin/get-usercount-and-activity";
export const UPDATEPROFILECOMPLETIONAPI =
  "user-profile/update-profile-completion";

export const GETCONTRACTPDFAPI = "/user-profile/contract-book/pdf-link";

export const INGREDIENTSAPI = "/ingredients";

export const USERAUDITSAPI = "user-audits";

export const USERS = "/users";
export const USERSRIGHTS = "/user-rights";

export const KITCHENLOCATIONAPI = "search/locations";

export const HOSTDETAILSFORAUDITSAPI = "user-audits";
export const HOSTDETAILSRIBBONSFORAUDITSAPI = "ribbons";
export const HOSTDETAILSIDVERFICATIONFORAUDITSAPI = "id-verification-info";
export const HOSTDETAILSKITCHENDETAILSFORAUDITSAPI = "personal-kitchen-details";
export const HOSTDETAILSLICENSEINFOFORAUDITSAPI = "license-info";
export const HOSTDETAILSTRAININGSFORAUDITSAPI = "trainings";

export const INITIALAUDITAPI = "/user-audits/initial-audits";
export const ANNOUNCEDAUDITAPI = "/user-audits/announced-audits";
export const UNANNOUNCEDAUDITAPI = "/user-audits/unannounced-audits";
export const POSTINITIALAUDITAPI = "/user-audits/assign-initial-audits";
export const POSTANNOUNCEDAUDITAPI = "/user-audits/assign-announced-audits";
export const POSTUNANNOUNCEDAUDITAPI = "/user-audits/assign-unannounced-audits";
export const GETHOSTSFORAUDITAPI = "/user-audits/get-hosts-for-audits";
export const GETCOMPLIANCEOFFICERSAPI = "/user-audits/get-cmp-officers";
export const GETCOMPLIANCEAUDITLOGS = "dashboard/admin/get-all-auditlog";

export const FOODPREFERENCESSEARCHBYLOCATIONAPI = "/search/by-location";
export const FOODPREFERENCESSEARCHBYHOSTAPI = "/search/by-host";
export const FOODPREFERENCESSEARCHBYCUISINESAPI = "/search/by-cuisine";

export const FOODPREFERENCESSEARCHLOCATIONAPI = "search/locations";

export const BACKUPAPI = "backups";

export const USERNOTIFICATIONSAPI = "user-notifications";
export const USERNOTIFICATIONSLATESTAPI = "user-notifications/latest";
export const USERNOTIFICATIONSREADAPI = "user-notifications/read";
export const USERNOTIFICATIONSREADALLAPI = "user-notifications/read-all";
export const USERNOTIFICATIONSUNREADAPI = "user-notifications/unread";
export const USERNOTIFICATIONSUNREADALLAPI = "user-notifications/unread-all";
export const USERNOTIFICATIONSDELETEAPI = "user-notifications/delete";
export const USERNOTIFICATIONSDELETEALLAPI = "user-notifications/delete-all";

export const TOPRATEDKITCHENSAPI = "user-profile/top-rated";

export const LOGISTICSTIMER = "logistics/timer";
export const RECOMMENDEDLISTINGSAPI = "search/recommeded-listings";
export const KITCHENDETAILSIMAGEDELETEAPI =
  "user-profile/kitchen-details/cover-photo";

export const LISTINGSBYHOSTAPI = "listings/by-host";
export const ALLINGREDIENTSAPI = "ingredients/all";

export const USERDETAILSFORADMINVIEW = "system-admin/user";

export const DELETEMENUIMAGESAPI = "/menus";
export const DELETEFOODITEMSIMAGESAPI = "/menus/food-item";
