
// Ribbons

import { createAsyncThunk } from "@reduxjs/toolkit";
import { RIBBONSAPI } from "../../constants/axios-api-constant";
import { getDataAPI, postDataAPI } from "../../lib/axios";
import { updatePersonalUserProfileStatusThunk } from "../personal-user/personal-user-thunk-actions";


export const addRibbonsPostThunk = createAsyncThunk("ribbons/post",
  async ({ ribbonData, openAlert, axiosInstance, showRibbonCard, dispatch, personalUser }: any, { rejectWithValue }) => {
    try {
      const response = await postDataAPI(RIBBONSAPI, axiosInstance, ribbonData);
      // const response:any = {};

      const responseData = {
        message: response?.data?.message,
      };
      if (!personalUser?.profileCompletion?.ribbons?.completed) {
        // console.log('incomplete ribbon')
        dispatch(updatePersonalUserProfileStatusThunk({ axiosInstance, keyValue: 'ribbons', openAlert }))
      }

      openAlert('Deal added successfully', 'alert-success-message')
      showRibbonCard()
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding'
      }
      openAlert(errorData?.error || 'server is not responding')

      return rejectWithValue(errorData)
    }
  }
)


// getRibbonsData 

export const ribbonsListGetThunk = createAsyncThunk('ribbons/get',
  async ({ axiosInstance , openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(RIBBONSAPI, axiosInstance)
// console.log(response.data.data)
      return response.data.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || 'Server is not responding. Please Try Again Later'
      }
      openAlert?.(errorData.error)
      return rejectWithValue(errorData)
    }
  }
)