export const MUIPRIMARYCOLOR = '#ff611d';
export const MUISECONDARYCOLOR = '#333333';

export const MUIBLACKCOLOR = '#000000';
export const MUIWHITECOLOR = '#ffffff';
export const MUIDARKGRAYCOLOR = '#3c3c3c';
export const MUIGRAYCOLOR = '#747070';
export const MUIORANGECOLOR = '#ff8908';
export const MUIYELLOWCOLOR = '#ffb80e';
export const MUIORANGEYELLOWISHCOLOR = '#ffa412';
export const MUIMIDGREENCOLOR = '#00d27a';
export const MUIDARKWHITESMOKECOLOR = '#f5f8fe';

export const MUIERRORCOLOR = '#dc3545';
export const MUISUCCESSCOLOR = '#165c27';
export const MUIALERTGREENCOLOR = '#d0e7d6';
export const MUISELECTEDTAPCOLOR = '#1c1b1b';
export const MUIRATESTARCOLOR = '#ffce31';
export const MUIICONCOLOR = '#c4c4c4';
export const MUISELECTEDFIELDCOLOR = '#6c757d';
export const MUIHOVERCOLOR = '#fee6dd';
export const MUILINKCOLOR = '#59a9ff';
export const MUISOLITUDECOLOR = '#E9EFFD';

export const MUIPRIMARYHEADING = '32px';
export const MUISECONDARYHEADING ='24px';
export const MUITERTIARYHEADING = '28px';
export const MUIHEADING48 ='48px';
export const MUIHEADING42 ='42px';
export const MUIHEADING20 ='20px';
export const MUIHEADING22 ='22px';
export const MUIHEADING18 = '18px';

export const MUIPRIMARYTITLE ='16px';
export const MUISECONDARYTITLE = '14px';
export const MUITERTIARYTITLE ='12px';
export const MUITITLE15 = '15px';
export const MUITITLE13 ='13px';
export const MUITITLE10 = '10px';

export const  MUIBORDERRADIUS4 = '4px';
export const  MUIBORDERRADIUS5 = '5px';
export const  MUIBORDERRADIUS10 = '10px';
export const  MUIBORDERRADIUS15 = '15px';
export const  MUIBORDERRADIUS20 = '20px';
export const  MUIBORDERRADIUSROUNDED = '50%'

export const MUIFONTWEIGHT300 = '300';
export const MUIFONTWEIGHT400 = '400';
export const MUIFONTWEIGHT500 = '500';
export const MUIFONTWEIGHT600 = '600';
export const MUIFONTWEIGHT700 = '700';
export const MUIFONTWEIGHT800 = '800';
export const MUIFONTWEIGHT900 = '900';
