// import {
//   BOOSTLISTING,
//   FEATURELIST,
//   IBOOKING,
//   IBOOKINGCOLUMNS,
//   IOPERATIONDATA,
//   ORDERCARDS,
//   PROFILECOMPLETIONSTATUS,
//   TOPMENUITEMS,
//   UPCOMINGBOOKING,
// } from "../interface/dashboard-interface";

// BOOST LISTING Images
import pizza from "../assets/images/dashboard/top-menu-items/pizza.png";
import fries from "../assets/images/dashboard/top-menu-items/fries.png";
import hot_cereal from "../assets/images/dashboard/top-menu-items/hot-cereal.png";

// ORDER CARDS IMAGES
import lost_revenue from "../assets/images/dashboard/order-book/lost-revenue.png";
import gross_revenue from "../assets/images/dashboard/order-book/gross-revenue.png";

// OPERATION IMAGES
import calenderBigImg from "../assets/images/dashboard/operations/calender-big.png";
import mobileImg from "../assets/images/dashboard/operations/mobile.png";
import feedbackImg from "../assets/images/dashboard/operations/feedback.png";


// Order Cards Data
export const orderCardsData: any[] = [
  {
    id: "3",
    imgSrc: lost_revenue,
    title: "Lost Revenue",
    value: "00",
    valuePercentage: "0%",
    color: "error-color",
  },
  {
    id: "4",
    imgSrc: gross_revenue,
    title: "Gross Revenue",
    value: "00",
    valuePercentage: "0.00%",
    color: "gross-green-color",
  },
];

// Booking Cards Columns
export const bookingColumnsData: any[] = [
  {
    colId: "1",
    colName: "Guest Name",
  },
  {
    colId: "2",
    colName: "Menu",
  },
  {
    colId: "3",
    colName: "Date",
  },
  {
    colId: "4",
    colName: "Time",
  },
];

// Profile Completion Data
export const profileCompletionStatusData: any[] = [
  {
    id: 1,
    statusName: "Personal Info",
  },
  {
    id: 2,
    statusName: "Licenses",
  },
  {
    id: 3,
    statusName: "Trainings",
  },
  {
    id: 4,
    statusName: "Cuisines",
  },
];

// Operations Data

export const operationData: any[] = [
  {
    id: 1,
    img: calenderBigImg,
    link: "/my-bookings",
    name: "Missed Bookings",
  },
  {
    id: 2,
    img: mobileImg,
    link: "/reviews-and-feedback/rate-guest",
    name: "Unread Ratings",
  },
  {
    id: 3,
    img: feedbackImg,
    link: "/reviews-and-feedback/overall-ratings",
    name: "Feedback",
  },
];


// Boost Listing Data
export const boostListingData: any[] = [
  // {
  //   listingName: "3 Listings Featured for 7 Days",
  //   listingDate: "02/12/2021",
  // },
];

// Feature Listing Data
export const FeatureData: any[] = [
  {
    id: 1,
    itemName: "Number of Listing",
    itemText: "03",
    itemCheckbox: false,
  },
  {
    id: 2,
    itemName: "Price",
    itemText: "£7.00",
    itemCheckbox: false,
  },
  {
    id: 3,
    itemName: "Duration",
    itemText: "7 days",
    itemCheckbox: false,
  },
  {
    id: 4,
    itemName: "Start Date",
    itemText: "22 Movember",
    itemCheckbox: false,
  },
  {
    id: 5,
    itemName: "End Date",
    itemText: "29 Movember, 2021",
    itemCheckbox: false,
  },
];

// Top Menu Data
export const TopMenuItemsData: any[] = [
  {
    id: 1,
    itemImg: fries,
    imgName: "French Fries",
  },
  {
    id: 2,
    itemImg: pizza,
    imgName: "Pizza",
  },
  {
    id: 3,
    itemImg: hot_cereal,
    imgName: "Multigrain hot Cereal",
  },
];
