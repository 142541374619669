import { createAsyncThunk } from "@reduxjs/toolkit";
import { BOOKINGSAPI, SENDBOOKINGREQUEST } from "../../constants/axios-api-constant";
import { getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";

// get booking data
export const getBookingsListThunk = createAsyncThunk('bookings',
    async ({ axiosInstance, limit, offset, status,search,from,to }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`${BOOKINGSAPI}?limit=${limit}&offset=${offset}&status=${status}&search=${search}&from=${from}&to=${to}`, axiosInstance);
            const responseData = {
                bookingsData: response.data.bookings,
                meta:response.data.meta
            }
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message || 'Server is not responding. Please Try Again Later'
            }
            return rejectWithValue(errorData)
        }
    }
);

export const acceptBookingRequestThunk = createAsyncThunk("accept-booking",
    async ({ bookingId, axiosInstance,openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(`${BOOKINGSAPI}/${bookingId}/accept`, axiosInstance, bookingId);
            const responseData = {
                message: response.data.message,
                singleBooking:response.data.data
            };
            openAlert(responseData.message,'alert-success-message');
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error);
            return rejectWithValue(errorData)
        }
    }
);

export const rejectBookingRequestThunk = createAsyncThunk("reject-booking",
    async ({ rejectionReasonObj, axiosInstance,openAlert,bookingId }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(`${BOOKINGSAPI}/${bookingId}/reject`, axiosInstance, rejectionReasonObj);
            const responseData = {
                message: response.data.message,
                singleBooking:response.data.data
            };
            openAlert(responseData.message,'alert-success-message');
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error);
            return rejectWithValue(errorData)
        }
    }
);


export const cancelBookingRequestThunk = createAsyncThunk("cancel-booking",
    async ({ cancellationReasonObj, axiosInstance,openAlert,bookingId }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(`${BOOKINGSAPI}/${bookingId}/cancel`, axiosInstance, cancellationReasonObj);
            const responseData = {
                message: response.data.message,
                singleBooking:response.data.data
            };
            openAlert(responseData.message,'alert-success-message');
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error);
            return rejectWithValue(errorData)
        }
    }
);

export const getBookingsDetailsThunk = createAsyncThunk('view',
    async ({ axiosInstance, bookingId }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`${BOOKINGSAPI}/${bookingId}/view`, axiosInstance);
            const responseData = {
                bookingDetails: response.data.data.booking,
            }
            
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message || 'Server is not responding. Please Try Again Later'
            }
            return rejectWithValue(errorData)
        }
    }
);

export const confirmBookingRequestThunk = createAsyncThunk("confirm-booking",
    async ({ axiosInstance,setOpen,bookingId,dispatch,openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(`${BOOKINGSAPI}/${bookingId}/confirm`, axiosInstance, bookingId);
            const responseData = {
                message: response.data.message,
            };
            setOpen(true);
            openAlert(responseData.message,'alert-success-message')
            dispatch(getBookingsListThunk({ axiosInstance, limit: 10, offset: 0,status: 'ACCEPTED',search: '', from: '', to: '' }))
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error);
            return rejectWithValue(errorData)
        }
    }
);


export const completeBookingRequestThunk = createAsyncThunk("complete-booking",
    async ({ axiosInstance,bookingId,dispatch,openAlert }: any, { rejectWithValue }) => {
        try {
            const response = await patchDataAPI(`${BOOKINGSAPI}/${bookingId}/complete`, axiosInstance);
            const responseData = {
                message: response.data.message,
            };
            openAlert(responseData.message,'alert-success-message')
            dispatch(getBookingsListThunk({ axiosInstance, limit: 10, offset: 0,status: 'ALL',search: '', from: '', to: '' }))
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            openAlert(errorData.error);
            return rejectWithValue(errorData)
        }
    }
);








export const postBookingRequestThunk = createAsyncThunk("bookings/send-booking-request",
    async ({ bookingRequest, axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await postDataAPI(SENDBOOKINGREQUEST, axiosInstance, bookingRequest);
            const responseData = {
                message: response.data.message,
            };
            return responseData;
        } catch (error: any) {
            const errorData = {
                error: error.response.data.message
            }
            return rejectWithValue(errorData)
        }
    }
);




