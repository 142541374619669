import { createSlice } from "@reduxjs/toolkit";
import { IRIBBONSSTATE } from "./ribbons-slice-interface";

import {
  postribbonThunkFulfilledReducer, postribbonThunkRejectedReducer,
  postribbonThunkPendingReducer, getribbonThunkPendingReducer, getribbonThunkFulfilledReducer,
  getribbonThunkRejectedReducer
} from "./ribbon-reducers";

import { addRibbonsPostThunk, ribbonsListGetThunk } from "./ribbon-thunk-action";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

const ribbonInitialState: IRIBBONSSTATE | null = {
  status: REQUESTSTATUS.IDLE,  //'idle' | 'loading' | 'succeeded' | 'failed'
  ribbonListData: [],
  name: "",
  showRibbonForm: false,
};

export const ribbonsSlice = createSlice({
  name: "ribbons",
  initialState: ribbonInitialState,

  reducers: {
    setRibbons: (state: string | any, { payload }) => {
      const { formvalues } = payload.payload;

      state?.ribbonListData?.push(formvalues);
    },
    setShowRibbonsForm: (state: any, { payload }) => {
      state.showRibbonForm = payload
    }
  },

  extraReducers(builder) {
    postribbonThunkPendingReducer(builder, addRibbonsPostThunk)
    postribbonThunkFulfilledReducer(builder, addRibbonsPostThunk)
    postribbonThunkRejectedReducer(builder, addRibbonsPostThunk)

    getribbonThunkPendingReducer(builder, ribbonsListGetThunk)
    getribbonThunkFulfilledReducer(builder, ribbonsListGetThunk)
    getribbonThunkRejectedReducer(builder, ribbonsListGetThunk)
  },
});

export const ribbonsActions = ribbonsSlice.actions;

export const selectRibbons = (state: any) => state?.ribbons;

export default ribbonsSlice.reducer;
