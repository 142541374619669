import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { assignAnnouncedAuditThunkFullfilledReducer, assignAnnouncedAuditThunkRejectedReducer, assignInitialAuditThunkFullfilledReducer, assignInitialAuditThunkPendingReducer, assignInitialAuditThunkRejectedReducer, assignUnannouncedAuditThunkFullfilledReducer, assignUnannouncedAuditThunkRejectedReducer, getAnnouncedAuditsThunkFullfilledReducer, getAnnouncedAuditsThunkRejectedReducer, getComplianceOfficersThunkFullfilledReducer, getComplianceOfficersThunkPendingReducer, getComplianceOfficersThunkRejectedReducer, getHostsListThunkFullfilledReducer, getHostsListThunkPendingReducer, getHostsListThunkRejectedReducer, getInitialAuditsThunkFullfilledReducer, getInitialAuditsThunkPendingReducer, getInitialAuditsThunkRejectedReducer, getLocationThunkFulfilledReducer, getLocationThunkPendingReducer, getLocationThunkRejectedReducer, getSelectedLocationDetailsThunkFulfilledReducer, getSelectedLocationDetailsThunkPendingReducer, getSelectedLocationDetailsThunkRejectedReducer, getUnannouncedAuditsThunkFullfilledReducer, getUnannouncedAuditsThunkRejectedReducer, setSortedAndReversedArray } from "./audit-assignment-reducers";
import { IAUDITASSIGNMENTSTATE } from "./audit-assignment-slice-interface";
import { assignAnnouncedAuditThunk, assignInitialAuditThunk, assignUnannouncedAuditThunk, getAnnouncedAuditsThunk, getComplianceOfficersThunk, getHostsForAuditsThunk, getInitialAuditsThunk, getLocationThunk, getSelectedLocationDetailsThunk, getUnannouncedAuditsThunk, } from "./audit-assignment-thunk-actions";

const auditsAssignmentInitialState:IAUDITASSIGNMENTSTATE | any = {
    auditsAssignmentData:[],
    hostsList:[],
    complianceOfficersList:[],
    page:0,
    pages:0,
    total:0,
    status: REQUESTSTATUS.IDLE,
    hostListStatus: REQUESTSTATUS.IDLE,
    complianceOfficersListStatus: REQUESTSTATUS.IDLE,
    formSubmissionStatus: REQUESTSTATUS.IDLE,
    locationStatus: REQUESTSTATUS.IDLE,
    locations: [] as any,
    coordinates: {} as any,

}

export const auditsAssignmentSlice = createSlice({
    name: 'auditsAssignment',
    initialState:auditsAssignmentInitialState,
    reducers:{
        setSortedAndReversedArr:setSortedAndReversedArray
    },
    extraReducers (builder){
        getInitialAuditsThunkPendingReducer(builder,getInitialAuditsThunk);
        getInitialAuditsThunkRejectedReducer(builder,getInitialAuditsThunk);
        getInitialAuditsThunkFullfilledReducer(builder,getInitialAuditsThunk);

        getInitialAuditsThunkPendingReducer(builder,getAnnouncedAuditsThunk);
        getAnnouncedAuditsThunkRejectedReducer(builder,getAnnouncedAuditsThunk);
        getAnnouncedAuditsThunkFullfilledReducer(builder,getAnnouncedAuditsThunk);

        getInitialAuditsThunkPendingReducer(builder,getUnannouncedAuditsThunk);
        getUnannouncedAuditsThunkRejectedReducer(builder,getUnannouncedAuditsThunk);
        getUnannouncedAuditsThunkFullfilledReducer(builder,getUnannouncedAuditsThunk);
        
        getHostsListThunkPendingReducer(builder,getHostsForAuditsThunk);
        getHostsListThunkRejectedReducer(builder,getHostsForAuditsThunk);
        getHostsListThunkFullfilledReducer(builder,getHostsForAuditsThunk);

        getComplianceOfficersThunkPendingReducer(builder,getComplianceOfficersThunk);
        getComplianceOfficersThunkRejectedReducer(builder,getComplianceOfficersThunk);
        getComplianceOfficersThunkFullfilledReducer(builder,getComplianceOfficersThunk);

        assignInitialAuditThunkPendingReducer(builder,assignInitialAuditThunk);
        assignInitialAuditThunkRejectedReducer(builder,assignInitialAuditThunk);
        assignInitialAuditThunkFullfilledReducer(builder,assignInitialAuditThunk);

        assignInitialAuditThunkPendingReducer(builder,assignAnnouncedAuditThunk);
        assignAnnouncedAuditThunkFullfilledReducer(builder,assignAnnouncedAuditThunk);
        assignAnnouncedAuditThunkRejectedReducer(builder,assignAnnouncedAuditThunk);

        assignInitialAuditThunkPendingReducer(builder,assignUnannouncedAuditThunk);
        assignUnannouncedAuditThunkRejectedReducer(builder,assignUnannouncedAuditThunk);
        assignUnannouncedAuditThunkFullfilledReducer(builder,assignUnannouncedAuditThunk);


        getLocationThunkPendingReducer(builder,getLocationThunk);
        getLocationThunkFulfilledReducer(builder,getLocationThunk);
        getLocationThunkRejectedReducer(builder,getLocationThunk);

        getSelectedLocationDetailsThunkPendingReducer(builder,getSelectedLocationDetailsThunk);
        getSelectedLocationDetailsThunkRejectedReducer(builder,getSelectedLocationDetailsThunk);
        getSelectedLocationDetailsThunkFulfilledReducer(builder,getSelectedLocationDetailsThunk);
    }
})
export const { setSortedAndReversedArr } = auditsAssignmentSlice.actions;
export default auditsAssignmentSlice.reducer;