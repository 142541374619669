import { createSlice } from "@reduxjs/toolkit";

import {
  loginThunkFulfilledReducer,
  loginThunkRejectedReducer,
  logoutThunkFulfilledReducer,
  logoutThunkRejectedReducer,
  registerThunkFulfilledReducer,
  registerThunkRejectedReducer,
  setLoginSuccessReducer,
  setProfileImageReducer,
  setUploadProfileImageReducer,
  setUserDataReducer,
  ThunkPendingReducer,
  updateUserDetailsThunkFulfilledReducer,
  updateUserDetailsThunkRejectedReducer,
  changePasswordThunkFulfillReducer,
  changePasswordThankRejectedReducer,
  forgotPasswordThunkFulfilledReducer,
  forgotPasswordThunkRejectedReducer,
  resetPasswordThunkFulfilledReducer,
  resetPasswordThunkRejectReducer,
  refreshTokenThunkFulfilledReducer,
  refreshTokenThunkRejectedReducer,
  resendLinkThunkFulfilledReducer,
  resendLinkThunkRejectedReducer,
  verifyEmailThunkFulfilledReducer,
  verifyEmailThunkRejectedReducer,
  VerificationStatusThunkFulfilledReducer,
  VerificationStatusThunkRejectedReducer,
} from "./auth-reducers";

import {
  VerificationStatusThunk,
  changePasswordThunk,
  forgotPasswordThunk,
  loginThunk,
  logoutThunk,
  refreshTokenThunk,
  registerThunk,
  resendEmailThunk,
  resetPasswordThunk,
  updateUserDetailsThunk,
  verifyEmailThunk,
} from "./auth-thunk-actions";

import { IAUTHSTATE } from "./auth-slice-interface";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

const authInitialState: IAUTHSTATE = {
  status: REQUESTSTATUS.IDLE, //'idle' | 'loading' | 'succeeded' | 'failed'
  user: null,
  error: "",
  token: "",
  refreshToken: "",
  success: "",
  role: [],
  setUploadProfileImage: false,
  message: "",
  userEmail: "",
  verifyStatus: null,
  isVerified: false,
  verificationRole: null,
  clearSignupInitialValues: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    defaultRole: "HOST",
  },
};

const authSlice = createSlice({
  name: "auth",

  initialState: authInitialState,

  reducers: {
    clearSignupForm: (state: any, action) => {
      state.clearSignupInitialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        defaultRole: action.payload,
      };
    },
    resetErrorAndMessage: (state: any) => {
      state.error = "";
      state.message = "";
    },
    // no more reducers can be made here. only these two if needed i will make.
    setUploadProfileImage: setUploadProfileImageReducer,
    setProfileImage: setProfileImageReducer,

    setLoginSuccess: setLoginSuccessReducer,
    setUserData: setUserDataReducer,
  },

  extraReducers(builder) {
    //login thunk reducers
    ThunkPendingReducer(builder, loginThunk);
    loginThunkFulfilledReducer(builder, loginThunk);
    loginThunkRejectedReducer(builder, loginThunk);

    //register thunk reducers
    ThunkPendingReducer(builder, registerThunk);
    registerThunkFulfilledReducer(builder, registerThunk);
    registerThunkRejectedReducer(builder, registerThunk);

    //logout thunk reducers
    ThunkPendingReducer(builder, logoutThunk);
    logoutThunkFulfilledReducer(builder, logoutThunk);
    logoutThunkRejectedReducer(builder, logoutThunk);

    //Change Password reducers
    ThunkPendingReducer(builder, changePasswordThunk);
    changePasswordThunkFulfillReducer(builder, changePasswordThunk);
    changePasswordThankRejectedReducer(builder, changePasswordThunk);

    // updateUserDetails thunk reducers
    ThunkPendingReducer(builder, updateUserDetailsThunk);
    updateUserDetailsThunkFulfilledReducer(builder, updateUserDetailsThunk);
    updateUserDetailsThunkRejectedReducer(builder, updateUserDetailsThunk);

    // forgot password reducers

    ThunkPendingReducer(builder, forgotPasswordThunk);
    forgotPasswordThunkFulfilledReducer(builder, forgotPasswordThunk);
    forgotPasswordThunkRejectedReducer(builder, forgotPasswordThunk);

    // reset-password reducers
    // resend link reducers

    ThunkPendingReducer(builder, resendEmailThunk);
    resendLinkThunkFulfilledReducer(builder, resendEmailThunk);
    resendLinkThunkRejectedReducer(builder, resendEmailThunk);
    // verify email reducers

    ThunkPendingReducer(builder, verifyEmailThunk);
    verifyEmailThunkFulfilledReducer(builder, verifyEmailThunk);
    verifyEmailThunkRejectedReducer(builder, verifyEmailThunk);
    // reset-password reducers

    ThunkPendingReducer(builder, resetPasswordThunk);
    resetPasswordThunkFulfilledReducer(builder, resetPasswordThunk);
    resetPasswordThunkRejectReducer(builder, resetPasswordThunk);

    // refresh token
    ThunkPendingReducer(builder, refreshTokenThunk);
    refreshTokenThunkFulfilledReducer(builder, refreshTokenThunk);
    refreshTokenThunkRejectedReducer(builder, refreshTokenThunk);

    ThunkPendingReducer(builder, VerificationStatusThunk);
    VerificationStatusThunkFulfilledReducer(builder, VerificationStatusThunk);
    VerificationStatusThunkRejectedReducer(builder, VerificationStatusThunk);
  },
});

export const {
  setLoginSuccess,
  setProfileImage,
  setUserData,
  setUploadProfileImage,
  clearSignupForm,
  resetErrorAndMessage,
} = authSlice.actions;

export default authSlice.reducer;
