import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CHANGEPASSWORDPATH, LOGOUTPATH } from "../../../constants/routes-constant";
import { useAxiosInterceptors } from "../../../hooks/use-axios-interceptors";
import { logoutThunk } from "../../../store/auth/auth-thunk-actions";
import { useAppDispatch } from "../../../store/store";


export const useDropdownMenu = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const axiosInstance = useAxiosInterceptors();
    const [anchorElNotification, setAnchorElNotification] =
        useState<null | HTMLElement>(null);
    const open = Boolean(anchorElNotification);

    const handleClickNotification = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        !!anchorElNotification ? setAnchorElNotification(null) : setAnchorElNotification(event.currentTarget)
    };
    const handleCloseNotification = (event: string) => {
        if (event === LOGOUTPATH) {
            dispatch(logoutThunk({ navigate, axiosInstance }))
        }
        if (event === CHANGEPASSWORDPATH) {
            navigate(CHANGEPASSWORDPATH)
        }

        setAnchorElNotification(null);
    };

    const handleClose = () => setAnchorElNotification(null);

    return {
        open, handleClickNotification, handleCloseNotification, handleClose
    }
}