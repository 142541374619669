export const moveToTab = ( scenerio:any , navigate:any , openAlert:any  ) => {
    if(!scenerio?.personalInfo?.completed) {
        navigate(0);
        openAlert('Kindly fill the personal details  to complete profile')
        return;
    }
    if(!scenerio?.idVerification?.completed) {
        navigate(1);
        openAlert('Kindly complete the Id verification  to complete profile')
        return;
    }
    if(!scenerio?.licenses?.completed) {
        navigate(2);
        openAlert('Kindly fill the licenses details to complete profile')

        return;
    }
    if(!scenerio?.kitchenDetails?.completed ) {
        openAlert('Kindly fill the kitchen details to complete profile')

        navigate(3);
        return;
    }
    if(!scenerio?.trainings?.completed) {
        navigate(4);
        openAlert('Kindly complete the trainings  to complete profile')

        return;
    }
    if(!scenerio?.ribbons?.completed) {
        navigate(5);
        openAlert('Kindly fill the ribbons to complete profile')

        return;
    }
    if(!scenerio?.badges?.completed) {
        navigate(6);
        openAlert('Kindly provide the badges  to complete profile')

        return;
    }
    if(!scenerio?.facilities?.completed) {
        navigate(7);
        openAlert('Kindly save the facilties  to complete profile')

        return;
    }
    if(!scenerio?.houseRules?.completed) {
        navigate(8);
        openAlert('Kindly fill the  house rules  to complete profile')

        return;
    }
}