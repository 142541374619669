import { useState, useEffect } from 'react';
// MUI
import { FormControl, Grid, MenuItem, Select, Slider } from '@mui/material';
// Interface
import { marks } from './price-range-data';
// SCSS
import './price-range.scss'
import { useAppDispatch } from '../../../../../../store/store';
import { setSelectedPrice } from '../../../../../../store/food-preference/food-preference-slice';
import { MUIWHITECOLOR } from '../../../../../../constants/mui-styles-constant';

const MenuProps = {
    PaperProps: {
        sx: {
            overflow:'auto',
            width: {xs:'300px !important',md:'500px !important'},
            maxWidth: {xs:'300px !important',md:'500px !important'},
            borderRadius: '10px',
            p:{xs:'0rem', md:'1rem'},
            mx: {xs:'0rem', md:'12rem'},
            my: {xs:'0rem', md:'1rem'},
            color: '#E0E0E0',
            background: '#333333',
            "& .MuiMenuItem-root:hover": {
                backgroundColor: "inherit !important"
            },
        },
    },
};

const minDistance = 10;

const PriceRange = () => {

    const [priceRange, setPriceRange] = useState<number[]>([5, 10]);
    const dispatch = useAppDispatch();

    const handlePriceRange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 50 - minDistance);
                setPriceRange([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setPriceRange([clamped - minDistance, clamped]);
            }
        } else {
            setPriceRange(newValue as number[]);
        }
    };
    useEffect(() => {
        dispatch(setSelectedPrice({
            startFrom: priceRange[0],
            endAt: priceRange[1]
        }))
    }, [priceRange[0], priceRange[1]])

    // console.log({ priceRange })
    return (
        <>
            {/* <FormControl sx={{ ml: 1 }}> */}
            <Select
                fullWidth
                sx={{ 
                    borderRadius: '45px', 
                    // width: '118.73px', 
                    height: '36.24px' }}
                value={priceRange}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <span className='tabs-span'>Price Range</span>;
                    }

                    return <span className='tabs-span'>Price Range</span>;
                }}
                MenuProps={MenuProps}
            >
                <MenuItem>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <>
                            <Slider
                                sx={{
                                    color: '#ffa412', height: 8,
                                    '& .MuiSlider-thumb': {
                                        borderRadius: 0,
                                        height: 24,
                                        width: 8,
                                        backgroundColor: '#ffa412',
                                        border: '1px solid #fff',
                                        boxShadow: ' -1px 0px 23px 3px rgba(247,213,96,0.75)',
                                    },
                                    '& .MuiSlider-rail': {
                                        color: '#bfbfbf',
                                        opacity: 1,
                                        height: 10,
                                    },
                                    '& .MuiSlider-value': {
                                        color: 'white',
                                    },
                                    '& .MuiSlider-markLabel': {
                                        overflow:'auto',
                                        color: MUIWHITECOLOR,
                                        fontSize:{xs:'.5rem', md:'1rem'}
                                    }
                                }}
                                getAriaLabel={() => 'Minimum distance'}
                                value={priceRange}
                                onChange={handlePriceRange}
                                disableSwap
                                max={50}
                                min={5}
                                step={5}
                                marks={marks}

                            />
                            {/* <Grid sx={{ display: 'flex' }}>
                                    {marks.map((mark) => {
                                        return (
                                            <div key={mark.id}>
                                                <span className={`slider-label tertiary-title ${priceRange.includes(mark.id) && "slider-label-selected"}`}>
                                                    {mark.label}
                                                </span>
                                            </div>)
                                    })}
                                </Grid> */}
                        </>
                    </Grid>
                </MenuItem>
            </Select>
            {/* </FormControl> */}
        </>
    )
}

export default PriceRange