import { useState } from "react";
import { useAppSelector } from "../../../store/store";
import { sidebarData } from "../../sidebar/sidebar-data";

export const useSearchCard = () => {
    const [query, setQuery] = useState("")
    const [searchName, setSearchName] = useState("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { openDrawer } = useAppSelector(state => state.layout)
    const  { auth } = useAppSelector( state => state)


    // const sortedData = sidebarData.sort((a, b) => {
    //     let titleA = a.title.toUpperCase();
    //     let titleB = b.title.toUpperCase();

    //     if (titleA < titleB) {
    //         return -1;
    //     }
    //     if (titleA > titleB) {
    //         return 1;
    //     }
    //     return 0;
    // })

    const openSearchCard = (event: any) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSearchName(event.target.value);
        setQuery(event.target.value)

    };

    const menuClick = () => {
        setSearchName('')
        setAnchorEl(null);
    }


    const handleCloseSearchCard = () => setAnchorEl(null);

    const OPEN = Boolean(anchorEl);

    return {
        OPEN,openDrawer, query, searchName, openSearchCard, menuClick, handleCloseSearchCard, auth, sidebarData
    }
}